import {
  Alert,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Skeleton,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../utils/themes/theme-context";
import { Columns } from "../../../utils/interfaces/table/table-interfaces";
import GeneralTable from "../../../utils/generic-components/table/table";
import { getDevices } from "../../../utils/requests/devices/get-devices-details";
import { DevicesDetails } from "../../../utils/interfaces/vehicles/devices-details-interface";
import { getDeviceCalibration } from "../../../utils/requests/devices/get-device-calibration";
import { DeviceCalibration } from "../../../utils/interfaces/vehicles/device-calibration-interface";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { getAllDeviceCalibration } from "../../../utils/requests/devices/get-all-device-calibration";
import NestedTable from "../../../utils/generic-components/table/nested-table";
import { getSinglePreCal } from "../../../utils/requests/products/get-single-precal-data";
import { useNavigate } from "react-router";

const autoWidthTableStyle = {
  width: "auto", // Set your desired width here
};

let columns: Columns = {
  AxleGroupName: {
    type: "text",
    display: "Axle Group Name",
    data: "axle_group",
    sort: "AxleGroup",
  },
  AxleGroupNumber: {
    type: "text",
    display: "Axle Group Number",
    data: "axle_group_number",
    sort: "AxleGroupNumber",
  },
  PreCal: {
    type: "link",
    display: "Pre-Calibration",
    sort: "PreCal",
  },
  DateTime: {
    type: "time",
    display: "Date Time",
    data: "date_time",
    sort: "DateTime",
  },
  UpdatedAt: {
    type: "time",
    display: "Updated At",
    data: "updated_at",
    sort: "UpdatedAt",
  },
  SensorCalibrations: {
    type: "subhead",
    display: "Sensor Calibrations",
    data: "sensor_calibrations",
    subColumns: {
      SensorPort: {
        type: "text",
        display: "Sensor Port",
        data: "sensor_port", // Access nested data
      },
      Empty: {
        type: "text",
        display: "Empty Weights",
        data: "empty_weight",
        sort: "Empty",
      },
      Heavy: {
        type: "text",
        display: "Heavy Weights",
        data: "heavy_weight",
        sort: "Heavy",
      },
      EmptyADC: {
        type: "text",
        display: "Empty Weights ADC",
        data: "empty_adc",
        sort: "EmptyADC",
      },
      HeavyADC: {
        type: "text",
        display: "Heavy Weights ADC",
        data: "heavy_adc",
        sort: "HeavyADC",
      },
    },
  },
};

export default function AllDeviceCalibration(props: {
  device: DevicesDetails;
}) {
  var { theme } = useContext(AppContext);
  const [serialNumber, setserialNumber] = useState<string>();
  const [deviceCalibrationData, setDeviceCalibrationData] = useState<
    DeviceCalibration[] | null
  >();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [preCalName, setPreCalName] = useState<string | null>(null);
  let userNavigate = useNavigate();

  const deviceidget = props.device.id;
  const productId = props.device.product_id;

  //TODO: This need to be the UUID
  useEffect(() => {
    // Fetch the serial number
    const fetchSerialNumber = async () => {
      try {
        const response = await getDevices(deviceidget);
        setserialNumber(response.id);
      } catch (err) {
        console.log(err);
      }
    };

    fetchSerialNumber(); // Fetch the serial number when the component mounts or when deviceidget changes
  }, [deviceidget]);

  // Function to fetch pre-calibration name based on ID
  const fetchPreCalibrationName = async (preCalibrationId: string) => {
    try {
      const preCalData = await getSinglePreCal(preCalibrationId);
      if (preCalData && preCalData.name) {
        setPreCalName(preCalData.name);
      }
    } catch (error) {
      console.error("Error fetching pre-calibration name:", error);
    }
  };

  useEffect(() => {
    // Fetch device calibration data whenever the serial number changes
    const fetchDeviceCalibrationData = async () => {
      try {
        if (serialNumber) {
          const response = await getAllDeviceCalibration(serialNumber);

          if (!response) {
            setErrorMessage("No Data Available");
            setDeviceCalibrationData(null);
          } else if (
            response.errors &&
            response.errors.length > 0 &&
            response.errors[0] === "unsupported product feature"
          ) {
            setErrorMessage("Unsupported Product Feature.");
            setDeviceCalibrationData(null);
          } else if (
            response.errors &&
            response.errors.length > 0 &&
            response.errors[0] === "database record not found"
          ) {
            setErrorMessage("Database record not found.");
            setDeviceCalibrationData(null);
          } else {
            setDeviceCalibrationData(response.data);

            // Fetch pre-calibration name for each data item
            for (const dataItem of response.data) {
              if (dataItem.precal_id) {
                await fetchPreCalibrationName(dataItem.precal_id);
              }
            }

            setErrorMessage(null); // Clear any previous error message
          }
        }
      } catch (err) {
        setDeviceCalibrationData(null);
      }
    };

    fetchDeviceCalibrationData(); // Fetch device calibration data when the serial number changes
  }, [serialNumber]);

  const handlePreCalibrationClick = (precal_id: string) => {
    // Navigate to ProductPrecalsCard page with the selected precalibration ID as a parameter
    setTimeout(
      () => userNavigate(`/products/${productId}?precalId=${precal_id}`),
      400
    );
  };

  return (
    <>
      {errorMessage ? (
        <Box>
          <Backdrop
            open={true}
            sx={{
              position: "relative",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          >
            <Alert variant="filled" severity="warning">
              {errorMessage ? errorMessage : null}
            </Alert>
          </Backdrop>
        </Box>
      ) : deviceCalibrationData ? (
        <GeneralTable
          data={deviceCalibrationData.map((dataItem) => ({
            AxleGroupName: dataItem.axle_group_name,
            AxleGroupNumber: dataItem.axle_group_number,
            PreCal: (
              <Button
                onClick={() => handlePreCalibrationClick(dataItem.precal_id)}
              >
                {preCalName}
              </Button>
            ),
            DateTime: dataItem.date_time,
            UpdatedAt: dataItem.updated_at,
            SensorCalibrations: (
              <NestedTable
                data={dataItem.sensor_calibrations}
                columns={columns.SensorCalibrations.subColumns}
              />
            ),
          }))}
          columns={columns}
          loading={false}
          noDataMessage="No Calibration Data"
          customTableStyle={autoWidthTableStyle}
        />
      ) : (
        <Skeleton variant="rectangular" width={650} height={200} />
      )}
    </>
  );
}
