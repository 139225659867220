import { Auth } from "aws-amplify";
import { buildBaseUrl } from "../build-base-url";

export const deleteUser = async (username: string) => {
	const url = buildBaseUrl()
	try {
		const authData = await Auth.currentAuthenticatedUser();
		const response = await fetch(
			`${url}users/${username}`,
			{
				method: "DELETE",
				headers: {
					Authorization: `Bearer ${authData.signInUserSession.accessToken.jwtToken}`,
				},
			}
		);
		if (response.status === 200) {
			return "success";
		} else {
			let data = await response.json();
			return data.errors;
		}
	} catch (err) {
		console.log(err);
	}
};