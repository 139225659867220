import { LoadingButton } from "@mui/lab";
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Typography,
	Grid,
	Button,
	Alert,
	Backdrop,
	Box,
} from "@mui/material";
import { getUser } from "../../../utils/interfaces/users/user-details-interfaces";
import { AppContext } from "../../../utils/themes/theme-context";
import { useContext, useState } from "react";
import {
	putEnableUser,
	putSuspendUser,
} from "../../../utils/requests/user-details/put-suspend-enable";
import { deleteUser } from "../../../utils/requests/user-details/delete-user";
import { useNavigate } from "react-router-dom";

export default function SuspendDelete(props: {
	user: getUser;
	suspending: boolean;
	deleting: boolean;
	setDisplayModal: Function;
	setFetchUpdatedUser: Function;
	fetchUpdatedUser: boolean;
	displayModal: boolean;
	setSuspending: Function;
	setDeleting: Function;
}) {
	var { perms, theme } = useContext(AppContext);
	const [loading, setLoading] = useState<boolean>(false);
	const [response, setResponse] = useState<string>("");
	const [confirm, setConfirm] = useState<boolean>(false);
	let Navigate = useNavigate();

	const handleSuspend = () => {
		setLoading(true);
		suspendUser().then((response: string) => {
			setResponse(response);
		});
	};

	const handleEnable = () => {
		setLoading(true);
		enableUser().then((response: string) => {
			setResponse(response);
		});
	};

	const handleDelete = () => {
		setLoading(true);
		deleteCurrentUser().then((response: string) => {
			setResponse(response);
		});
	};

	const suspendUser = async () => {
		try {
			const response = await putSuspendUser(props.user.data.username);
			setLoading(false);
			props.setFetchUpdatedUser(!props.fetchUpdatedUser);
			setConfirm(true);
			return response;
		} catch (err) {
			console.log(err);
			setLoading(false);
		}
	};

	const enableUser = async () => {
		try {
			const response = await putEnableUser(props.user.data.username);
			setLoading(false);
			props.setFetchUpdatedUser(!props.fetchUpdatedUser);
			setConfirm(true);
			return response;
		} catch (err) {
			console.log(err);
			setLoading(false);
		}
	};

	const deleteCurrentUser = async () => {
		try {
			const response = await deleteUser(props.user.data.username);
			setLoading(false);
			setConfirm(true);
			return response;
		} catch (err) {
			console.log(err);
			setLoading(false);
		}
	};

	const handleConfirm = () => {
		if (props.suspending) {
			props.setDisplayModal(false);
			props.setSuspending(false);
			props.setDeleting(false);
			setConfirm(false);
		} else {
			Navigate(`/users/`);
			props.setSuspending(false);
			props.setDeleting(false);
			setConfirm(false);
		}
	};

	const confirmationModal = () => {
		return (
			<Card elevation={8} sx={{ borderRadius: 3 }}>
				<CardHeader title={response === "success" ? "Success" : "Error"} />
				<CardContent>
					<Divider />
					<Alert severity={response === "success" ? "success" : "error"}>
						{response}
					</Alert>
					<Typography margin={2}>
						{response === "success"
							? props.deleting
								? "Click Confirm To Return To User Dashboard"
								: "Click Confirm To Continue"
							: "Click Confirm To Make Changes And Try Again"}
					</Typography>
					<Grid
						container
						spacing={2}
						alignItems={"center"}
						justifyContent={"center"}
						margin={1}
					>
						<Grid item xs={12}>
							<Button
								onClick={handleConfirm}
								variant="contained"
								color={theme.nextbuttoncolor}
							>
								Confirm
							</Button>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		);
	};

	const suspendEnableModal = () => {
		return (
			<Card>
				<CardHeader
					title={
						props.user.data.enabled === true ? "Suspend User" : "Enable User"
					}
					subheader={
						props.user.data.enabled === true
							? "This users account will be suspended."
							: "This users account will be enabled."
					}
				/>
				<CardContent>
					<Divider />
					<Typography margin={2}>
						{props.user.data.enabled === true
							? "Press Confirm To Suspend This User."
							: "Press Confirm to Enable This User."}
					</Typography>
					<Grid
						container
						spacing={2}
						alignItems={"center"}
						justifyContent={"center"}
						margin={1}
					>
						<Grid item xs={6}>
							<Button
								onClick={() => props.setDisplayModal(false)}
								variant="contained"
								color={theme.backbuttoncolor}
							>
								Cancel
							</Button>
						</Grid>
						<Grid item xs={6}>
							<LoadingButton
								loading={loading}
								onClick={
									props.user.data.enabled === true
										? handleSuspend
										: handleEnable
								}
								variant="contained"
								color={theme.nextbuttoncolor}
							>
								Confirm
							</LoadingButton>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		);
	};

	const deleteModal = () => {
		return (
			<Card>
				<CardHeader
					title={"Delete User"}
					subheader={"This User's Account Will Be Permanentely Deleted."}
				/>
				<CardContent>
					<Divider />
					{perms.username === props.user.data.username ? (
						<Alert severity="error">
							Warning: You Are About To Delete Your Own Account.
						</Alert>
					) : (
						<Typography margin={2}>
							Press Confirm To Permanenetly Delete This User.
						</Typography>
					)}
					<Grid
						container
						spacing={2}
						alignItems={"center"}
						justifyContent={"center"}
						margin={1}
					>
						<Grid item xs={6}>
							<Button
								onClick={() => props.setDisplayModal(false)}
								variant="contained"
								color={theme.backbuttoncolor}
							>
								Cancel
							</Button>
						</Grid>
						<Grid item xs={6}>
							<LoadingButton
								loading={loading}
								onClick={handleDelete}
								variant="contained"
								color={theme.nextbuttoncolor}
							>
								Confirm
							</LoadingButton>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		);
	};

	const manageModals = () => {
		if (confirm === true) {
			return confirmationModal();
		} else if (props.suspending === true) {
			return suspendEnableModal();
		} else if (props.deleting === true) {
			return deleteModal();
		}
	};

	return (
		<>
			<Backdrop open={props.displayModal} sx={{ zIndex: 500 }}>
				<Box marginLeft={40}>
					{props.suspending || props.deleting ? manageModals() : null}
				</Box>
			</Backdrop>
		</>
	);
}
