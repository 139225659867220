/*CONFLUENCE DOCUMENTATION: https://airweigh.atlassian.net/l/cp/Kc3A9urW */

import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  TextField,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { getClients } from "../../../utils/requests/new-user/get-clients";
import CreateClient from "./create-client";
import { newuser } from "../../../utils/interfaces/new-user/new-user-interface";
import {
  client,
  clientResponse,
} from "../../../utils/interfaces/new-user/client-interface";
import { AppContext } from "../../../utils/themes/theme-context";
import { getUserFromUsername } from "../../../utils/requests/user-details/get-user-from-username";

export const ClientSelectionCreation = (props: {
  nextButtonCallback: Function; //true means disabled button, false means not
  setClientCallback: Function;
  enterInformationDomain: Function; //setdomain
  setClientId: Function;
  validateAndSetError: Function;
  newUser: newuser;
  setCurClientName: Function;
  handleNext: Function;
}) => {
  var { perms } = useContext(AppContext);
  const [clients, setClients] = useState<client[]>([]);
  const [corpClient, setCorpClient] = useState<client[]>([]);
  const [chosenclient, setChosenClient] = useState<string>("");
  //if domain is set, true : false
  const [chooseorcreate, setChooseOrCreate] = useState<boolean>(
    props.newUser.user_domain ? true : false
  ); //false=choose, true=create

  //fills clients options whenever components mounts
  useEffect(() => { 
    props.nextButtonCallback(true); //true to disable next button
    fetchClients().then((response: clientResponse) => {
      setClients(response.data);
    });
  }, []);

  //checks if next should be enabled each time chosenclient is set
  useEffect(() => {
    checkNext();
  }, [chosenclient]);

  const fetchClients = async () => {
    try {
      if (perms.user_type === "aw") {
        // Use the API for user_type "aw"
        const response = await getClients();
        return response;
      } else if (perms.user_type === "corp") {
        // Use the API for user_type "corp"
        const response = await getUserFromUsername(perms.username);
        setCorpClient(response.data.client_name);
		props.setCurClientName(response.data.client_name);
        return response;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleClientChange = (value: client | null) => {
    if (value) {
      setChosenClient(value.client);
      props.setClientId(value.id);
      props.setCurClientName(value.client);
    }
  };

  const handleNewClientClick = () => {
    setChooseOrCreate(true);
  };

  const checkNext = () => {
    if (chosenclient || perms.user_type === "corp") {
      props.nextButtonCallback(false);
    } else {
      props.nextButtonCallback(true);
    }
  };

  useEffect(() => {
    if (perms.user_type === "corp") {
      props.newUser.user_type = "corp"; // Update the user type
    }
  }, []);

  //once clients have been succesfully fetched the autocomplete will be shown with
  // all options
  const chooseClient = () => {
    return (
      <>
        <Card>
          <CardHeader subheader="Choose Or Create Client" />
          <CardContent>
            {clients && perms.user_type === "aw" ? (
              <Box padding={3}>
                <Autocomplete
                  disablePortal
                  options={clients}
                  getOptionLabel={(option: client) => option.client}
                  renderInput={(params) => (
                    <TextField {...params} label="Select A Client" />
                  )}
                  onChange={(event, value) => handleClientChange(value)}
                />
              </Box>
            ) : perms.user_type === "corp" ? (
              <Box padding={3}>
                <TextField
                  label="Selected Client"
                  value={corpClient}
                  InputProps={{ readOnly: true }}
                />
              </Box>
            ) : null}
            {perms.user_type !== "corp" ? (
              <Button
                onClick={() => {
                  handleNewClientClick();
                }}
                variant="contained"
              >
                Create A New Client
              </Button>
            ) : null}
          </CardContent>
        </Card>
      </>
    );
  };

  return (
    <>
      {chooseorcreate === false ? (
        chooseClient()
      ) : (
        <CreateClient
          setChooseOrCreate={setChooseOrCreate}
          setClient={props.setClientCallback}
          enterInformationDomain={props.enterInformationDomain}
          setClientId={props.setClientId}
          validateAndSetError={props.validateAndSetError}
          newUser={props.newUser}
          handleNext={props.handleNext}
          setCurClientName={props.setCurClientName}
        />
      )}
    </>
  );
};
