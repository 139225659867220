// ForgotPasswordForm.tsx

import { View, Heading } from "@aws-amplify/ui-react";
import {
  TextField,
  Button,
  Card,
  Stack,
  Typography,
  IconButton,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { AppContext } from "./utils/themes/theme-context";
import { validateEmail } from "./utils/regex/forms";
import { PostForgotPassword } from "./utils/requests/login/postforgotpassword";
import { PatchForgotPassword } from "./utils/requests/login/patchforgotpassword";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface ForgotPasswordFormProps {
  setCurrentPage: (page: string) => void; // Function to set current page
}

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({
  setCurrentPage,
}) => {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [codeError, setCodeError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const { theme } = useContext(AppContext);
  const [submitted, setSubmitted] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    const error = validateEmail(newEmail, "Invalid email format");
    setEmailError(error);
  };

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      // Call the PostForgotPassword function with the email
      await PostForgotPassword(email);
      // If the request is successful, set submitted to true
      setSubmitted(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const isPasswordValid = (password: string) => {
    //password validation logic
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    return passwordRegex.test(password);
  };

  const handleDone = async () => {
    if (!code.trim()) {
      setCodeError("Code is required");
      return;
    }
    if (!newPassword.trim()) {
      setNewPasswordError("New password is required");
      return;
    }

    if (!isPasswordValid(newPassword)) {
      setNewPasswordError(
        "Password must contain at least 8 characters including atleast 1 uppercase letter, 1 lowercase letter and 1 number"
      );
      return;
    }

    if (!isPasswordValid(confirmPassword)) {
      setConfirmPasswordError(
        "Password must contain at least 8 characters including atleast 1 uppercase letter, 1 lowercase letter and 1 number"
      );
      return;
    }

    if (newPassword !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      return;
    }

    setLoading(true);

    try {
    const response = await PatchForgotPassword(code, newPassword, email);
    if (response.status === 200) {
      setCurrentPage("login");
    } else {
      setCodeError("Please enter a valid code");
      console.error("Error:", response);
    }
  } catch (error) {
    console.error("Error:", error);
  } finally {
    // Set loading state to false
    setLoading(false);
  }
  };

  return (
    <div>
      <div className="card-container">
        <Card
          className="sign-in-card"
          style={{ maxWidth: "500px", width: "90%", margin: "0px auto" }}
        >
          <div className="sign-in-card-inner">
            <Stack spacing={3}>
              <View textAlign="center">
                <Heading level={3} style={{ textAlign: "center" }}>
                  <img
                    alt="Air-Weigh logo"
                    src="/images/color-Air-Weigh_sans_subtext-web.png"
                    width={300}
                    height={80}
                  />
                </Heading>
              </View>

              {!submitted && (
                <>
                  <Typography>
                    Please enter the email associated with your account:
                  </Typography>
                  <TextField
                    type="email"
                    label="Email"
                    variant="outlined"
                    value={email}
                    onChange={handleEmailChange}
                    fullWidth
                    required
                    margin="normal"
                    error={!!emailError}
                    helperText={emailError}
                  />
                  <div
                    style={{
                      display: "center",
                      justifyContent: "space-between",
                      margin: "10px",
                    }}
                  >
                    <Typography>
                      <i>
                        A temporary confirmation code will be sent to this email
                        on Submission. This code is valid for 1 hour.
                      </i>
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      onClick={handleFormSubmit}
                    >
                      Submit
                    </Button>
                    <Button
                      onClick={() => setCurrentPage("login")} // Go back to login page
                      variant="contained"
                      color={theme.backbuttoncolor}
                    >
                      Cancel
                    </Button>
                  </div>
                </>
              )}

              {submitted && (
                <>
                  <div
                    style={{
                      display: "center",
                      justifyContent: "space-between",
                      margin: "10px",
                    }}
                  >
                    <Typography>
                      <i>
                        Please check your email for the temporary confirmation
                        code. This code is valid for 1 hour.
                      </i>
                    </Typography>
                  </div>
                  <Typography>Enter code:</Typography>
                  <TextField
                    type="number"
                    label="Code"
                    variant="outlined"
                    value={code}
                    onChange={(e) => {
                      setCode(e.target.value);
                      setCodeError("");
                    }}
                    fullWidth
                    required
                    margin="normal"
                    error={!!codeError}
                    helperText={codeError}
                  />
                  <Typography>New password:</Typography>
                  <TextField
                    type={showPassword ? "text" : "password"}
                    label="New password"
                    variant="outlined"
                    value={newPassword}
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                      setNewPasswordError("");
                    }}
                    fullWidth
                    required
                    margin="normal"
                    error={!!newPasswordError}
                    helperText={newPasswordError}
                    InputProps={{
                      //  InputProps for password visibility toggle
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Typography>Confirm password:</Typography>
                  <TextField
                    type={showConfirmPassword ? "text" : "password"}
                    label="Confirm password"
                    variant="outlined"
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                      setConfirmPasswordError("");
                    }}
                    fullWidth
                    required
                    margin="normal"
                    error={!!confirmPasswordError}
                    helperText={confirmPasswordError}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle confirm password visibility"
                            onClick={() =>
                              setShowConfirmPassword(!showConfirmPassword)
                            }
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    <div>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleDone}
                      disabled={loading}
                    >
                      Done
                    </Button>
                    {loading && <CircularProgress />}
                    </div>
                    <Button
                      onClick={() => setCurrentPage("login")} // Go back to login page
                      variant="contained"
                      color={theme.backbuttoncolor}
                    >
                      Cancel
                    </Button>
                  </div>
                </>
              )}
            </Stack>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
