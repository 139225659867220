import { Auth } from "aws-amplify";
import { buildBaseUrl } from "../build-base-url";
import { SingleFirmwareGet } from "../../interfaces/products/single-product-details-interface";

export async function fetchSingleFirmwareData(productId: string, firmwareVersion: string): Promise<SingleFirmwareGet> {
  const url = buildBaseUrl()
  try {
    const authData = await Auth.currentAuthenticatedUser();
    const response = await fetch(`${url}products/${productId}/firmware/${firmwareVersion}`, {
      headers: {
        Authorization: `Bearer ${authData.signInUserSession.accessToken.jwtToken}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
