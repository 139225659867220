export interface ProductListData {
    id: string;
    name: string;
    release_date: string;
    serial_identifier: string;
    comments: string;
    updated_at: string;
    // active_units: ActiveUnitsData;
    most_recent_firmware: string;
    last_firmware_update: string;
    // active_tickets: number;
    diagnostic_support: boolean;
    firmware_update_support: boolean;
    registration_support: boolean;
    precalibration_support: boolean;
    shared_configuration_support: boolean;
    calibration_support: boolean;
    configuration_support: boolean;
    fault_support: boolean;
    manufacture_registration_support: boolean;
}

export interface UpdateProductListData {
    name: string;
    comments: string;
    updated_at: string;
}

export interface getProductListData {
    data: ProductListData[];
    next_page?: string;
}

export interface FirmwareUpdatePOST {
    firmware_version: string;
    file: string; // Base64 encoded file content
    file_hash: string; // SHA256 hash of the file content
    client_restrictions?: string[];
    service_plan_restrictions?: string[];
    release_notes?: string;
    comments?: string;
}

interface ApiResponse {
    data: ProductListData[];
    next_page: string;
}

export function getEmptyProduct(): ProductListData {
    return {
        id: "",
        name: "",
        release_date: "",
        serial_identifier: "",
        comments: "",
        updated_at: "",
        // active_units: {
        //     count: 0,
        //     date_time: "",
        //     id: "",
        //     type: "",
        // },
        most_recent_firmware: "",
        last_firmware_update: "",
        // active_tickets: 0,
        diagnostic_support: false,
        firmware_update_support: false,
        registration_support: false,
        precalibration_support: false,
        shared_configuration_support: false,
        calibration_support: false,
        configuration_support: false,
        fault_support: false,
        manufacture_registration_support: false,
    };
}