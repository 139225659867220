import { AppBar, Divider, Toolbar } from "@mui/material";
import { useContext } from "react";
import { AppContext } from "../utils/themes/theme-context";

function Footer() {
	var { theme } = useContext(AppContext);
	return (
		<>
		<AppBar
			sx={{ top: "auto", bottom: 0, backgroundColor: theme.primary}}
		>
			<Divider color={"#FFFFFF"}/>
			<Toolbar></Toolbar>
		</AppBar>
		</>
	);
}

export default Footer;
