import { format, parseISO } from "date-fns";

export function rfcToDateAndTime(rfc: string) {
	const parsedDate = parseISO(rfc);
	const formattedDate = parsedDate.toLocaleDateString('en-US', {
	  month: '2-digit',
	  day: '2-digit',
	  year: 'numeric',
	  hour: 'numeric',
	  minute: 'numeric',
	});
	return `${formattedDate}`;
  }
  

export function rfcToDate(rfc: string) {
	const parsedDate = parseISO(rfc);
	const formattedDate = format(parsedDate, "MM-dd-yyyy");
	return formattedDate;
}

export function dateToRfc(date: string) {
	const tempdate = new Date(date);
	const rfc3339 = format(tempdate, "yyyy-MM-dd'T'HH:mm:ssXXX");
	return rfc3339;
}

export function formatDateForTimeSelector (date: string) {
	const tempdate = new Date(date);
	const betterdate = format(tempdate, "yyyy-MM-dd");
	return betterdate;
}