// DOCUMENTATION: https://airweigh.atlassian.net/wiki/spaces/AWC/pages/1849327620/Line+Chart

import { useContext } from "react";
import {
	CartesianGrid,
	Legend,
	Line,
	LineChart,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";
import { AppContext } from "../../themes/theme-context";
import { Box } from "@mui/material";

export default function MyLineChart(props: {
	data: any[];
	categories: string[];
	lessthaneight?: boolean; //default true, if set to false then getrandomcolor is used instead of preset colors
}) {
	var { theme } = useContext(AppContext);

	//random colors if more than 8 categories are passed
	const getRandomColor = () => {
		const letters = "0123456789ABCDEF";
		let color = "#";
		for (let i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * 16)];
		}
		return color;
	};

	//a more corporate color palette, doesnt support scaling though :(
	const colors = ["#304D6D", "#a1784f", "#5b646b", "#46a04a", "#000", "#e36868", "#89dcd5", "#dbba4d" ]

	return (
		<>
			<Box margin={2}>
				<LineChart width={600} height={300} data={props.data} margin={{ top: 0, right: 20, bottom: 0, left: 20 }}>
					{props.categories.map((data: any, index: number) => {
						return (
							<Line
								connectNulls
								type="monotone"
								dataKey={data}
								stroke={!props.lessthaneight ? colors[index] : getRandomColor()} 
								strokeWidth={3}
								key={index}
							/>
						);
					})}
					<CartesianGrid stroke="#ccc" />
					<XAxis dataKey="name" type="category" domain={[2017, 2022]} />
					<YAxis height={100}/>
					<Tooltip />
					<Legend />
				</LineChart>
			</Box>
		</>
	);
}
