import { buildBaseUrl } from '../build-base-url';

interface ForgotPasswordRequest {
    email: string;
  }

export const PostForgotPassword = async (email: string): Promise<Response> => {
    const url = buildBaseUrl()

    const requestData: ForgotPasswordRequest = {
        email: email
      };

    const response = await fetch(`${url}users/forgot_password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    });
    return response;
  };

  