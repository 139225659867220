import { Auth } from "aws-amplify";
import { buildBaseUrl } from "../build-base-url";
import { formatgetUsersData } from "../users/get-users";

export const getUsersForClients = async () => {
    const authData = await Auth.currentAuthenticatedUser();
    const url = buildBaseUrl();
    let baseurl = `users?`;
  
    const response = await fetch(`${url}${baseurl}`, {
      headers: {
        Authorization: `Bearer ${authData.signInUserSession.accessToken.jwtToken}`,
      },
    });
    
    var data = await response.json();
    return data.data;
  };
  