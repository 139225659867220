import { Auth } from "aws-amplify";
import { buildBaseUrl } from "../build-base-url";

export const createNewProduct = async (newProductName: string, newProductSNI: string) => {
  try {
    const url = buildBaseUrl();
    const authData = await Auth.currentAuthenticatedUser();
    const response = await fetch(`${url}products`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authData.signInUserSession.accessToken.jwtToken}`,
      },
      body: JSON.stringify({
        name: newProductName,
        serial_identifier: newProductSNI,
        // Add other properties here as needed
      }),
    });
    if (response.ok) {
      // Product created successfully
      return true;
    } else {
      // Handle error
      console.error('Error creating product:', response.statusText);
      return false;
    }
  } catch (err) {
    console.error('Error creating product Main:', err);
    return false;
  }
};
