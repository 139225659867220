import { createContext } from "react";

export interface Theme {
	primary: string;
	secondary: string;
	tertiary: string;
	quaternary: string;
	subtitle: string;
	backbuttoncolor: "inherit" | "error" | "success" | "info" | "warning" | "primary" | "secondary" | undefined,
	nextbuttoncolor: "inherit" | "error" | "success" | "info" | "warning" | "primary" | "secondary" | undefined,
	basicbuttoncolor: "inherit" | "error" | "success" | "info" | "warning" | "primary" | "secondary" | undefined,
	selectedbuttoncolor: string;
	nonactivebuttoncolor: string;
}

export const ThemeConstructor = () => {
    const theme: Theme = {
		primary: "#304D6D",
		secondary: "#E1D9D1",
		tertiary: "#548ab7",
		quaternary: "#81c784",
		subtitle: "#808080",
		backbuttoncolor: "error",
		nextbuttoncolor: "success",
		basicbuttoncolor: "info",
		selectedbuttoncolor: "#304D6D",
		nonactivebuttoncolor: "#548ab7",
	}
    return {
        ...theme,
    }
}

export const AppContext = createContext<{
	theme: Theme;
	perms: any;
}>({
	theme: ThemeConstructor(),
	perms: null,
});