import { Auth } from "aws-amplify";
import { buildBaseUrl } from '../build-base-url';
import { FirmwareUpdatePOST } from "../../interfaces/products/products-list-interface";

export const updateFirmware = async (id: string, data: FirmwareUpdatePOST): Promise<Response> => {
    const url = buildBaseUrl()
    const authData = await Auth.currentAuthenticatedUser();
    const response = await fetch(`${url}products/${id}/firmware`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authData.signInUserSession.accessToken.jwtToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return response;
  };