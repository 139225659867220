/*CONFLUENCE DOCUMENTATION: https://airweigh.atlassian.net/l/cp/Kc3A9urW */

import {
	Alert,
	Backdrop,
	Paper,
	Button,
	Box,
	Grid,
	Card,
	CardHeader,
	CardContent,
	Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { postNewUser } from "../../../utils/requests/new-user/post-new-user";
import {
	newUserPostResponse,
	newuser,
} from "../../../utils/interfaces/new-user/new-user-interface";
import LoadingButton from "@mui/lab/LoadingButton";
import { AppContext, Theme } from "../../../utils/themes/theme-context";
import { errorResponse } from "../../../utils/interfaces/error-interface";

export const PostAndError = (props: {
	newActiveStep: Function;
	newUser: newuser;
	setPosting: Function;
}) => {
	var { theme } = useContext(AppContext);
	const [postresponse, setPostResponse] = useState<
		newUserPostResponse | errorResponse
	>();
	const [submitted, setSubmitted] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	let Navigate = useNavigate();

	const getPostResponse = () => {
		postUser()
			.then((response: newUserPostResponse) => {
				setPostResponse(response);
				setSubmitted(true);
			})
			.catch((err: errorResponse) => {
				console.log(err);
				setPostResponse(err);
				setSubmitted(true);
			});
	};

	const postUser = async () => {
		try {
			setLoading(true);
			const response = await postNewUser(props.newUser);
			setLoading(false);
			return response;
		} catch (err) {
			console.log(err);
		}
	};

	function handleSuccessClick() {
		Navigate(`/users/`);
	}

	function handleBackClick() {
		props.newActiveStep(3);
		props.setPosting(false);
		setSubmitted(false);
	}

	const prePostModal = () => {
		return (
			<>
				<Paper variant="outlined">
					<Card>
						<CardHeader
							title="Submit New User:"
							subheader="Click the button below to submit new user. An email will be sent 
								the provided email containing a temporary password to login."
						/>
						<CardContent>
							<Grid container spacing={2}>
								<Grid item xs={6}>
									<Button
										onClick={handleBackClick}
										variant="contained"
										color={theme.backbuttoncolor}
									>
										Cancel
									</Button>
								</Grid>
								<Grid item xs={6}>
									<Box display="flex" justifyContent="flex-end">
										<LoadingButton
											color={theme.nextbuttoncolor}
											onClick={getPostResponse}
											variant="contained"
											loading={loading}
										>
											Submit
										</LoadingButton>
									</Box>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				</Paper>
			</>
		);
	};

	const postPostModal = () => {
		if (postresponse) {
			if ("errors" in postresponse) {
				return (
					<Paper variant="outlined">
						<Card>
							<Alert severity="error">{postresponse.errors}</Alert>
							<CardHeader
								title="Submission Failed"
								subheader="Click the button below to edit and resubmit New
								User."
							/>
							<CardContent>
								<Box display="flex" justifyContent="flex-end">
									<Button
										onClick={handleBackClick}
										variant="contained"
										color={theme.basicbuttoncolor}
									>
										Edit and Resubmit
									</Button>
								</Box>
							</CardContent>
						</Card>
					</Paper>
				);
			} else {
				return (
					<Paper variant="outlined">
						<Card>
							<Alert severity="success">New User Successfully Created</Alert>
							<CardHeader subheader="A new user has been created." />
							<CardContent>
								<Typography marginBottom={4}>
									1. The user will be issued the provided permissions
									<br />
									2. A welcome email will be sent to the provided email
									containing sign in instructions
								</Typography>
								<Box display="flex" justifyContent="flex-end">
									<Button
										onClick={handleSuccessClick}
										variant="contained"
										color={theme.basicbuttoncolor}
									>
										Return to User List
									</Button>
								</Box>
							</CardContent>
						</Card>
					</Paper>
				);
			}
		}
	};

	return (
		<>
			<Box display={"flex"} marginLeft={40} marginRight={10}>
				{!submitted ? prePostModal() : postPostModal()}
			</Box>
		</>
	);
};
