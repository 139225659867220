import React, { useState, useContext, useEffect, useRef } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Button,
  Divider,
  Box,
  IconButton,
  Stack,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { AppContext } from "../../../utils/themes/theme-context";
import { DevicesDetails } from "../../../utils/interfaces/vehicles/devices-details-interface";
import DeleteDevice from "./device-action-elements/delete-device";
import RegisterClientAction from "./device-action-elements/register-client";
import UnregisterClientAction from "./device-action-elements/unregister-client";

export default function DeviceAction(props: { device: DevicesDetails; onRegisteredClientName: (clientName: string) => void; onUnRegisteredClientName: (clientName: string) => void; }) {
  const { theme } = useContext(AppContext);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isUpdateAssignDialogOpen, setIsUpdateAssignDialogOpen] = useState(false);
  const [isUnregisterDialogOpen, setIsUnregisterDialogOpen] = useState(false);

  const openDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const openUpdateAssignDialog = () => {
    setIsUpdateAssignDialogOpen(true);
  };

  const closeUpdateAssignDialog = () => {
    setIsUpdateAssignDialogOpen(false);
  };

  const openUnregisterDialog = () => {
    setIsUnregisterDialogOpen(true);
  };

  const closeUnregisterDialog = () => {
    setIsUnregisterDialogOpen(false);
  };

  const updateClientName = (newClientName: string) => {
    props.onRegisteredClientName(newClientName);
  };

  const deleteClientName = (DeletedClientName: string) => {
    props.onUnRegisteredClientName(DeletedClientName);
  };


  return (
    <>
      <Card elevation={8} sx={{ borderRadius: 3 }}>
        <CardHeader
          title="Device Action"
          action={
            <IconButton aria-label="info">
              <InfoOutlinedIcon />
            </IconButton>
          }
        />
        <Divider />
        <CardContent>
          <Box paddingTop={2}>
            <Stack direction={"column"} spacing={2}>
              <Button
                variant="contained"
                color={theme.backbuttoncolor}
                fullWidth
                onClick={openDeleteDialog}
              >
                Delete Device
              </Button>
              <Button
                variant="contained"
                color={theme.backbuttoncolor}
                fullWidth
                onClick={openUpdateAssignDialog}
              >
                Update/Assign Client Registration
              </Button>
              <Button
                variant="contained"
                color={theme.backbuttoncolor}
                fullWidth
                onClick={openUnregisterDialog}
              >
                Delete Client Registration
              </Button>
            </Stack>
          </Box>
        </CardContent>
      </Card>
      <DeleteDevice open={isDeleteDialogOpen} onClose={closeDeleteDialog} device={props.device} />
      <RegisterClientAction open={isUpdateAssignDialogOpen} onClose={closeUpdateAssignDialog} device={props.device} onUpdateClientName={updateClientName} />
      <UnregisterClientAction open={isUnregisterDialogOpen} onClose={closeUnregisterDialog} device={props.device} onDeleteClientName={deleteClientName} />
    </>
  );
}
