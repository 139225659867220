import { Auth } from "aws-amplify";
import { buildBaseUrl } from "../build-base-url";
import { VehicleData } from "../../interfaces/vehicles/vehicles-interfaces";

export const getOneVehicle = async (vehicle_id: string | undefined) => {
    const url = buildBaseUrl()
    if (!vehicle_id) {
        return;
    }
    try {
        const authData = await Auth.currentAuthenticatedUser();
        const response = await fetch(`${url}vehicles/${vehicle_id}`, {
            headers: {
                Authorization: `Bearer ${authData.signInUserSession.accessToken.jwtToken}`,
            },
        });

        const data = await response.json();
        return data;
    } catch (err) {
        console.log(err);
    }
};

export const getOneVehicleByVehicleID = async (vehicle_id: string | undefined): Promise<VehicleData> => {
    const url = buildBaseUrl()
    try {
        const authData = await Auth.currentAuthenticatedUser();
        const response = await fetch(`${url}vehicles/vehicle_id/${vehicle_id}`, {
          headers: {
            Authorization: `Bearer ${authData.signInUserSession.accessToken.jwtToken}`,
          },
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error(error);
        throw error;
      }
};