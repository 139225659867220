import {
  Alert,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../utils/themes/theme-context";
import { Columns } from "../../../utils/interfaces/table/table-interfaces";
import GeneralTable from "../../../utils/generic-components/table/table";
import { getDevices } from "../../../utils/requests/devices/get-devices-details";
import { DevicesDetails } from "../../../utils/interfaces/vehicles/devices-details-interface";
import { getDeviceCalibration } from "../../../utils/requests/devices/get-device-calibration";
import { DeviceCalibration } from "../../../utils/interfaces/vehicles/device-calibration-interface";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { getAllDeviceCalibration } from "../../../utils/requests/devices/get-all-device-calibration";
import { getAllDeviceDiagnostics } from "../../../utils/requests/devices/get-all-device-diagnostics";
import { fetchSingleProductData } from "../../../utils/requests/products/get-single-product";
import {
  AxleGroup,
  DiagnosticsRequest,
} from "../../../utils/interfaces/devices/device-diagnostics-interface";
import NestedTable from "../../../utils/generic-components/table/nested-table";

const autoWidthTableStyle = {
  width: "auto", // Set your desired width here
};

let columns: Columns = {
  FirmwareVersion: {
    type: "text",
    display: "Firmware Version",
    data: "firmware_version",
    sort: "FirmwareVersion",
  },
  SignalStrength: {
    type: "text",
    display: "Signal Strength",
    data: "signal_strength",
    sort: "SignalStrength",
  },
  DateAndTime: {
    type: "time",
    display: "Date and Time",
    data: "date_time",
    sort: "DateAndTime",
  },
  AxleGroups: {
    type: "subhead",
    display: "Axle Groups",
    data: "axle_groups",
    subColumns: {
      GroupName: {
        type: "text",
        display: "Group Name",
        data: "group_name",
        sort: "GroupName",
      },
      Weight: {
        type: "number",
        display: "Weight",
        data: "weight",
        sort: "Weight",
      },
      Ratio: {
        type: "number",
        display: "Ratio",
        data: "ratio",
        sort: "Ratio",
      },
      Offset: {
        type: "number",
        display: "Offset",
        data: "offset",
        sort: "Offset",
      },
      SensorReadings: {
        type: "subhead",
        display: "Sensor Readings",
        data: "sensor_readings",
        subColumns: {
          AdValue: {
            type: "number",
            display: "AD Value",
            data: "ad_value",
            sort: "AdValue",
          },
          EmptyAd: {
            type: "number",
            display: "Empty Ad",
            data: "empty_ad",
            sort: "EmptyAd",
          },
          EmptyCal: {
            type: "number",
            display: "Empty Calibration",
            data: "empty_cal",
            sort: "EmptyCal",
          },
          HeavyAd: {
            type: "number",
            display: "Heavy Ad",
            data: "heavy_ad",
            sort: "HeavyAd",
          },
          HeavyCal: {
            type: "number",
            display: "Heavy Calibration",
            data: "heavy_cal",
            sort: "HeavyCal",
          },
          SensorPort: {
            type: "number",
            display: "Sensor Port",
            data: "sensor_port",
            sort: "SensorPort",
          },
        },
      },
    },
  },
};

export default function DeviceDiagnosticsMostRecent(props: {
  device: DevicesDetails;
}) {
  var { theme } = useContext(AppContext);
  const [diagnosticSupport, setDiagnosticSupport] = useState<boolean>();
  const [deviceDiagnosticsData, setDeviceDiagnosticsData] = useState<
    DiagnosticsRequest[] | null
  >();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [refreshInterval, setRefreshInterval] = useState(30000); // 30 seconds in milliseconds
  const [lastRefreshed, setLastRefreshed] = useState<string | null>(null);

  const deviceid = props.device.id;
  const productidget = props.device.product_id;

  useEffect(() => {
    //Fetch the product id
    const fetchDiagnosticSupport = async () => {
      try {
        const response = await fetchSingleProductData(productidget);
        setDiagnosticSupport(response.diagnostic_support);
      } catch (err) {
        console.log(err);
      }
    };

    // fetchSerialNumber(); // Fetch the serial number when the component mounts or when deviceidget changes
    fetchDiagnosticSupport();
  }, [productidget]);

  const fetchDeviceDiagnosticsData = async () => {
    try {
      if (deviceid) {
        const response = await getAllDeviceDiagnostics(deviceid);

        if (!response) {
          setErrorMessage("No Data Available");
          setDeviceDiagnosticsData(null);
        } else if (
          response.errors &&
          response.errors.length > 0 &&
          response.errors[0] === "unsupported product feature"
        ) {
          setErrorMessage("Unsupported Product Feature.");
          setDeviceDiagnosticsData(null);
        } else if (
          response.errors &&
          response.errors.length > 0 &&
          response.errors[0] === "database record not found"
        ) {
          setErrorMessage("Database record not found.");
          setDeviceDiagnosticsData(null);
        } else {
          setDeviceDiagnosticsData(response.data);
          // Update the last_refreshed property for the entire table
          const lastRefreshed = new Date().toLocaleString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          });
          setLastRefreshed(lastRefreshed);
          setErrorMessage(null); // Clear any previous error message
        }
      }
    } catch (err) {
      setDeviceDiagnosticsData(null);
    }
  };

  useEffect(() => {
    if (diagnosticSupport === true) {
      // Fetch device Diagnostics data whenever the serial number changes
      fetchDeviceDiagnosticsData();
      // Clear the interval when the component unmounts
      return () => { };
    } else {
      setErrorMessage("Unsupported Product Feature.");
    }
  }, [deviceid, diagnosticSupport]);

  const handleRefreshClick = () => {
    // Manually trigger data refresh when the button is clicked
    fetchDeviceDiagnosticsData();
  };

  return (
    <>
      <Card elevation={8} sx={{ borderRadius: 3 }}>
        <CardHeader
          title="Most Recent Device Diagnostics"
          action={
            <IconButton aria-label="info">
              <InfoOutlinedIcon />
            </IconButton>
          }
        />
        <CardContent>
          <Divider />
          {errorMessage ? (
            <Box>
              <Backdrop
                open={true}
                sx={{
                  position: "relative",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              >
                <Alert
                  variant="filled"
                  severity="warning"
                  style={{ width: "100%" }}
                >
                  {errorMessage ? errorMessage : null}
                </Alert>
              </Backdrop>
            </Box>
          ) : deviceDiagnosticsData ? (
            <div>
              <Typography style={{ fontSize: "16px" }}>
                <strong> Table Last Refresh Time: </strong>{" "}
                <i>{lastRefreshed}</i>
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={handleRefreshClick}
                style={{ marginBottom: "10px" }}
              >
                Refresh Now
              </Button>
              <GeneralTable
                data={
                  deviceDiagnosticsData && deviceDiagnosticsData.length > 0
                    ? [
                      {
                        FirmwareVersion:
                          deviceDiagnosticsData[0].firmware_version,
                        SignalStrength:
                          deviceDiagnosticsData[0].signal_strength,
                        DateAndTime: deviceDiagnosticsData[0].date_time,
                        AxleGroups: (
                          <NestedTable
                            data={deviceDiagnosticsData[0].axle_groups}
                            columns={columns.AxleGroups.subColumns}
                          />
                        ),
                      },
                    ]
                    : []
                }
                columns={columns}
                loading={false}
                noDataMessage="No Diagnostics Data"
                customTableStyle={autoWidthTableStyle}
              />
            </div>
          ) : (
            <Skeleton variant="rectangular" width={650} height={200} />
          )}
        </CardContent>
      </Card>
    </>
  );
}
