import Header from './nav/header'
import Footer from './nav/footer'
import { Outlet, Link } from "react-router-dom";
import SideDrawer from './nav/sidedrawer'
import { Box } from '@mui/material';

function Layout({signOut, user, usertype}:any){
    return(
        <Box >
            <Header signOut={signOut}/>
            <Box sx={{display:'flex'}}>
                <SideDrawer signOut={signOut} user={user} usertype={usertype}/>
                <Outlet />
            </Box>
            <Footer/>
        </Box> 
    )
}

export default Layout;