//get-vehicles

import { MapPoint } from "../map/generic-interface";

export interface getVehiclesDataType {
	data: VehicleData[];
	next_page: string;
}

export interface VehicleData {
	vehicle_id: string;
	vehicle_updated_at: string;
	client_id: string;
	device_id: string;
	firmware_id: string;
	product_id: string;
	vehicle_name: string;
	vehicle_vin: string;
	client: string;
	product: string;
	firmware_version: string;
	latest_weights: Weight[];
	vehicle_registration_date: string;
	year_total_tickets: number;
	open_tickets: number;
	updated_at: string;
}

//Graph Weights

export interface GraphSingleWeightDatapoint {
	name: string;
	weight: number | null;
	[key: string]: any; //acounts for variable amounts of data and different names of axle weights
}

interface AxleWeight {
	//internal version
	name: string;
	weight: {
		value: number;
		received: boolean;
	};
	quality: {
		value: number;
		received: boolean;
	};
}

export interface AxleWeightExternal {
	//external version
	name: string;
	weight: {
		value: number;
		received: boolean;
	};
	quality: {
		value: number;
		received: boolean;
	};
}

interface Weight {
	value: {
		value: number;
		received: boolean;
	};
	quality: {
		value: number;
		received: boolean;
	};
}

export interface GraphVehicleData {
	id: string;
	date_time: string;
	sequence: string;
	vehicle_id: string;
	device_id: string;
	interval_id: string;
	axle_weights: AxleWeight[];
	weight: Weight;
	rmp: {
		received: boolean;
		value: number;
	};
	odometer: {
		received: boolean;
		value: number;
	};
	parking_break: {
		received: boolean;
		value: boolean;
	};
	velocity: {
		received: boolean;
		value: number;
	};
	coolant_temp: {
		received: boolean;
		value: number;
	};
	latitude: {
		received: boolean;
		value: number;
	};
	longitude: {
		received: boolean;
		value: number;
	};
	updated_at: string;
}

//weights locations

/**
 * DefaultWeightsReturnType is json model to match the response from the server
 * @param {UnformattedWeightsDataPoint[]} data - The data of the weights
 * @param {string} next_page - The next page of the weights
 * @returns {DefaultWeightsReturnType} - The formatted weights data
 */
export interface DefaultWeightsReturnType {
	data: UnformattedWeightsDataPoint[];
	next_page: string;
}


export interface UnformattedWeightsDataPoint {
	id: string;
	date_time: string;
	sequence: string;
	vehicle_id: string;
	device_id: string;
	interval_id: string;
	axle_weights: AxleWeight[];
	weight: Weight;
	rmp: Rmp;
	odometer: Odometer;
	parking_break: ParkingBreak;
	velocity: Velocity;
	coolant_temp: CoolantTemp;
	latitude: {
		received: boolean;
		value: number;
	};
	longitude: {
		received: boolean;
		value: number;
	};
	updated_at: string;
}

interface AxleWeight {
	name: string;
	weight: {
		value: number;
		received: boolean;
	};
	quality: {
		value: number;
		received: boolean;
	};
}

interface Weight {
	value: {
		value: number;
		received: boolean;
	};
	quality: {
		value: number;
		received: boolean;
	};
}

interface Rmp {
	received: boolean;
	value: number;
}

interface Odometer {
	received: boolean;
	value: number;
}

interface ParkingBreak {
	received: boolean;
	value: boolean;
}

interface Velocity {
	received: boolean;
	value: number;
}

interface CoolantTemp {
	received: boolean;
	value: number;
}

//others, sort later

// export const FORMATTED_VEHICLE_WEIGHT = "FormattedVehicleWeight";

/**
 * FormattedWeights is used to format the weights data for the table and map
 * @param {number} number - The number of the weight
 * @param {number} latitude - The latitude of the weight
 * @param {number} longitude - The longitude of the weight
 * @param {number} weight - The weight of the vehicle
 * @param {string} date_time - The date and time of the weight
 * @param {string} snapshot - The snapshot of the weight
 * @param {string} more_details - The more details of the weight
 * @returns {FormattedWeight} - The formatted weights data
 */
export interface FormattedWeight extends MapPoint {
	number: number;
	latitude: number;
	longitude: number;
	weight: number;
	date_time: string;
	snapshot: string;
	more_details: string;
	objectType: string;
}

/**
 * FormattedWeightswithData is used to format the weights data for the table and map
 * @param {FormattedWeight[]} data - The data of the formatted weights
 * @returns {FormattedWeightswithData} - The formatted weights data
 */
export interface FormattedWeightswithData {
	//used in weightslocations to pass to table and map correctly
	data: FormattedWeight[];
}

export interface SingleVehicle {
	//used for vehicles table rows
	Client: string;
	ClientId: string;
	Firmware: string;
	LastActivity: string;
	Name: string;
	OpenTickets: number;
	RegistrationDate: string;
	Tickets: number;
	VehicleId: string;
	Vin: string;
}

/**
 * WeightsCallbackResponse is used to format the weights data for the callback response
 * @param {string} date_time - The date and time of the weight
 * @param {number} latitude - The latitude of the weight
 * @param {number} longitude - The longitude of the weight
 * @param {string} number - The number of the weight
 * @param {string} snapshot - The snapshot of the weight
 * @param {string} weight - The weight of the vehicle
 * @param {string} more_details - The more details of the weight
 * @returns {WeightsCallbackResponse} - The formatted weights data
 */
export interface WeightsCallbackResponse {
	//used when clicking on 'go' in vehicle details
	date_time: string;
	latitude: number;
	longitude: number;
	number: number;
	snapshot: string;
	weight: string;
	more_details: string;
}

export interface Vehicle {
	data: {
		vehicle_id: string;
		vehicle_updated_at: string;
		client_id: string;
		device_id: string;
		firmware_id: string;
		product_id: string;
		vehicle_name: string;
		vehicle_vin: string;
		client: string;
		product: string;
		firmware_version: string;
		latest_weights: Weight[];
		vehicle_registration_date: string;
		year_total_tickets: number;
		open_tickets: number;
		updated_at: string;
		device_serial: string;
	};
}

interface Weight {
	id: string;
	date_time: string;
	sequence: string;
	vehicle_id: string;
	device_id: string;
	interval_id: string;
	axle_weights: AxleWeight[];
	weight: ValueReceived;
	rmp: ValueReceived;
	odometer: ValueReceived;
	parking_break: ValueReceived;
	velocity: ValueReceived;
	coolant_temp: ValueReceived;
	latitude: ValueReceived<number>;
	longitude: ValueReceived<number>;
	updated_at: string;
}

interface AxleWeight {
	name: string;
	weight: ValueReceived;
	quality: ValueReceived;
}

interface ValueReceived<T = number> {
	value: T;
	received: boolean;
}

export function getEmptyVehicle (): VehicleData {
	return {
		vehicle_id: '',
		vehicle_updated_at: '',
		client_id: '',
		device_id: '',
		firmware_id: '',
		product_id: '',
		vehicle_name: '',
		vehicle_vin: '',
		client: '',
		product: '',
		firmware_version: '',
		latest_weights: [],
		vehicle_registration_date: '',
		year_total_tickets: 0,
		open_tickets: 0,
		updated_at: '',
	};
}