import { Auth } from "aws-amplify";
import { buildBaseUrl } from "../build-base-url";

export const fetchClientsServicePlan = async (id: string) => {
  const url = buildBaseUrl()
  try {
    const authData = await Auth.currentAuthenticatedUser();
    const response = await fetch(`${url}service_plans/${id}/clients`, {
      headers: {
        Authorization: `Bearer ${authData.signInUserSession.accessToken.jwtToken}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
