import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Divider,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import IconButton from "@mui/material/IconButton";
import { useContext } from "react";
import { AppContext } from "../../../utils/themes/theme-context";
import { DashboardStats } from "../../../utils/interfaces/dashboard/dashboard-stats-interface";
import { rfcToDateAndTime } from "../../../utils/custom-functions/rfc3339-conversions";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'

export default function DashBoardStatistics(props: { dashboardStats: DashboardStats }) {
	var { theme } = useContext(AppContext);
	return (
		<>
			<Card elevation={8} sx={{ borderRadius: 3 }}>
				<CardHeader
					title="Dashboard Stats"
					action={
						<IconButton aria-label="info">
							<InfoOutlinedIcon />
						</IconButton>
					}
				/>
				<CardContent>
					<Divider />
					<Stack direction={"row"} spacing={12} marginTop={2} marginLeft={8} marginRight={8}>
						<Stack direction={"column"} spacing={2} alignItems={"center"}  flex={1}>
							<Typography>Total Devices</Typography>
							<Divider flexItem orientation="horizontal" />
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<Box
									sx={{
										borderRadius: "20%",
										padding: "10px",
									}}
								>
									<Typography>{props.dashboardStats?.total_devices.total}</Typography>
								</Box>
								<Tooltip title={props.dashboardStats?.total_devices.date_time !== undefined
									? rfcToDateAndTime(props.dashboardStats?.total_devices.date_time)
									: ''} placement="right">
									<CalendarTodayIcon color="inherit" fontSize="small" sx={{ marginLeft: 1 }} />
								</Tooltip>
							</Box>
						</Stack>
						<Stack direction={"column"} spacing={2} alignItems={"center"}  flex={1}>
							<Typography>Registered Devices</Typography>
							<Divider flexItem orientation="horizontal" />
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<Box
									sx={{
										borderRadius: "20%",
										padding: "10px",
									}}
								>
									<Typography>{props.dashboardStats?.registered_devices.total}</Typography>
								</Box>
								<Tooltip title={props.dashboardStats?.registered_devices.date_time !== undefined
									? rfcToDateAndTime(props.dashboardStats?.registered_devices.date_time)
									: ''} placement="right">
									<CalendarTodayIcon color="inherit" fontSize="small" sx={{ marginLeft: 1 }} />
								</Tooltip>
							</Box>
						</Stack>
						<Stack direction={"column"} spacing={2} alignItems={"center"}  flex={1}>
							<Typography>Active Devices</Typography>
							<Divider flexItem orientation="horizontal" />
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<Box
									sx={{
										borderRadius: "20%",
										padding: "10px",
									}}
								>
									<Typography>{props.dashboardStats?.active_devices.total}</Typography>
								</Box>
								<Tooltip title={props.dashboardStats?.active_devices.date_time !== undefined
									? rfcToDateAndTime(props.dashboardStats?.active_devices.date_time)
									: ''} placement="right">
									<CalendarTodayIcon color="inherit" fontSize="small" sx={{ marginLeft: 1 }} />
								</Tooltip>
							</Box>
						</Stack>
					</Stack>
				</CardContent>
			</Card>
		</>
	);
}