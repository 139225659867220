import React from 'react';
import { Button, Box, Alert, AlertTitle } from '@mui/material';

interface LoadMoreButtonProps {
  hasMore: boolean; // Whether there are more items to load
  loading: boolean; // Whether the component is currently loading
  onLoadMore: () => void; // Function to call when the button is clicked
  errorMessage?: string; // Optional error message to display
  loadingText?: string; // Optional custom loading text
  buttonText?: string; // Optional custom button text
}

const LoadMoreButton: React.FC<LoadMoreButtonProps> = ({
  hasMore,
  loading,
  onLoadMore,
  errorMessage,
  loadingText = "Loading...",
  buttonText = "Load More"
}) => {
  if (!hasMore) return null;

  return (
    <Box margin={1}>
      {errorMessage && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {errorMessage}
        </Alert>
      )}
      <Alert
        severity="info"
        action={
          <Button variant="contained" onClick={onLoadMore} disabled={loading}>
            {loading ? loadingText : buttonText}
          </Button>
        }
      >
        <AlertTitle>
          Not All Items have been Retrieved. Click <i><b>Load More</b></i> to Retrieve Next Page.
        </AlertTitle>
      </Alert>
    </Box>
  );
};

export default LoadMoreButton;
