// NewServicePlanDialog.js

import { useContext, useState } from "react";
import {
  Dialog,
  Paper,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Stepper,
  Step,
  StepLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  TableRow,
  TableBody,
  TableContainer,
  Table,
  TableCell,
  Typography,
  IconButton,
  Box,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { AppContext } from "../../../utils/themes/theme-context";
import { ServicePlansInterface } from "../../../utils/interfaces/service/service-list-interface";
import { PostNewServicePlan } from "../../../utils/requests/serviceplans/post-newserviceplan";

export default function NewServicePlanDialog({
  open,
  handleClose,
  servicePlanList,
}: {
  open: boolean;
  handleClose: () => void;
  servicePlanList: ServicePlansInterface[];
}) {
  const [formData, setFormData] = useState({
    name: "",
    comments: "",
    faultAlerts: false,
    firmwareUpdates: false,
    heartbeatAlerts: false,
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({
    name: "",
    faultAlerts: "",
    firmwareUpdates: "",
    heartbeatAlerts: "",
  });
  const [activeStep, setActiveStep] = useState(0);
  var { theme } = useContext(AppContext);
  const [isServicePlanPosted, setisServicePlanPosted] =
    useState<boolean>(false);

  const handleNext = () => {
    if (activeStep === 0) {
      // Validate name field
      if (!formData.name.trim()) {
        setErrors({ ...errors, name: "Name is required" });
        return;
      } else if (
        formData.name.length > 100 ||
        /[!@#$%^&*(),.?":{}|<>]/.test(formData.name)
      ) {
        setErrors({
          ...errors,
          name: "Plan name must be less than 100 characters and contain no special characters except space",
        });
        return;
      } else if (
        servicePlanList.some(
          (plan) => plan.name.toLowerCase() === formData?.name.toLowerCase()
        )
      ) {
        setErrors({ ...errors, name: "Plan name must be unique" });
        return;
      } else {
        setErrors({ ...errors, name: "" });
      }
    }
    if (activeStep === 1) {
      // Validate radio buttons
      if (
        formData.faultAlerts === undefined ||
        formData.firmwareUpdates === undefined ||
        formData.heartbeatAlerts === undefined
      ) {
        setErrors({
          ...errors,
          faultAlerts:
            formData.faultAlerts === undefined
              ? "Fault Alerts selection is required"
              : "",
          firmwareUpdates:
            formData.firmwareUpdates === undefined
              ? "Firmware Updates selection is required"
              : "",
          heartbeatMonitoring:
            formData.heartbeatAlerts === undefined
              ? "Heartbeat Monitoring selection is required"
              : "",
        });
        return;
      } else {
        setErrors({
          ...errors,
          faultAlerts: "",
          firmwareUpdates: "",
          heartbeatMonitoring: "",
        });
      }
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCloseDialog = () => {
    // Reset form data
    setFormData({
      name: "",
      comments: "",
      faultAlerts: false,
      firmwareUpdates: false,
      heartbeatAlerts: false,
    });

    // Reset errors
    setErrors({
      name: "",
      faultAlerts: "",
      firmwareUpdates: "",
      heartbeatAlerts: "",
    });

    // Reset active step to 0
    setActiveStep(0);

    // Close the dialog
    handleClose();
  };

  const handleSubmit = async () => {
    try {
      const postNewServicePlan: ServicePlansInterface = {
        name: formData.name,
        comments: formData.comments,
        service_plan_features: {
          fault_alerts: formData.faultAlerts,
          firmware_updates: formData.firmwareUpdates,
          heartbeat_alerts: formData.heartbeatAlerts,
        },
        updated_at:
          new Date().toISOString().replace(/\.\d{3}/, "") +
          new Date().getMilliseconds().toString().padStart(3, "0") +
          "Z",
      };

      // POST data
      const response = await PostNewServicePlan(postNewServicePlan);

      if (response.ok) {
        // Firmware update successful
        setisServicePlanPosted(true);
        setActiveStep((prevStep) => prevStep + 1);
      } else {
        // Handle error response from the server
        setisServicePlanPosted(false);
        setActiveStep((prevStep) => prevStep + 1);
        console.error("Error updating firmware:", response.statusText);
      }
    } catch (error) {
      // Handle other errors
      setisServicePlanPosted(false);
      setActiveStep((prevStep) => prevStep + 1);
      console.error("Error:", error);
    }

    // Reset form data and close dialog
    setFormData({
      name: "",
      comments: "",
      faultAlerts: false,
      firmwareUpdates: false,
      heartbeatAlerts: false,
    });
  };

  const steps = ["Details", "Features", "Review", "Confirmation"];
  // no special characters but space is fine <100 characters.
  return (
    <Dialog open={open} onClose={handleCloseDialog}>
      <DialogTitle variant="h4" align="center">
        New Service Plan
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 2,
            top: 2,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          style={{ wordSpacing: "80px" }}
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === 0 && (
          <>
            <Typography
              variant="h6"
              align="center"
              style={{ fontWeight: "bold", marginTop: "10px" , marginBottom: '10px'}}
            >
              Enter Service Plan Details
            </Typography>
            <Divider style={{ marginBottom: '10px'}} />
            <TextField
              margin="dense"
              label="Name"
              fullWidth
              name="name"
              value={formData.name}
              onChange={(e) => {
                setFormData({ ...formData, name: e.target.value });
                setErrors({ ...errors, name: "" });
              }}
              error={!!errors.name}
              helperText={errors.name}
            />
            <TextField
              margin="dense"
              label="Comments"
              fullWidth
              name="comments"
              value={formData.comments}
              onChange={(e) =>
                setFormData({ ...formData, comments: e.target.value })
              }
            />
          </>
        )}
        {activeStep === 1 && (
          <Box style={{ margin: "30px" }}>
            <Typography
              variant="h6"
              align="center"
              style={{ fontWeight: "bold", marginTop: "10px" }}
            >
              Select Service Plan Features
            </Typography>
            <Divider
              style={{ marginBottom: "10px", marginTop: "10px" }}
            ></Divider>
            <TableContainer component={Paper} style={{ padding: "20px" }}>
              <div style={{ marginBottom: "10px" }}>Firmware Updates:</div>
              <RadioGroup
                aria-label="Firmware Updates"
                name="firmwareUpdates"
                value={formData.firmwareUpdates.toString()}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    firmwareUpdates: e.target.value === "true",
                  })
                }
                style={{ flexDirection: "row", marginBottom: "10px" }}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="True"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="False"
                />
              </RadioGroup>
              {errors.firmwareUpdates && (
                <div style={{ color: "red" }}>{errors.firmwareUpdates}</div>
              )}
              <Divider />
              <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                Fault Alerts:
              </div>
              <RadioGroup
                aria-label="Fault Alerts"
                name="faultAlerts"
                value={formData.faultAlerts.toString()}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    faultAlerts: e.target.value === "true",
                  })
                }
                style={{ flexDirection: "row", marginBottom: "10px" }}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="True"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="False"
                />
              </RadioGroup>
              {errors.faultAlerts && (
                <div style={{ color: "red" }}>{errors.faultAlerts}</div>
              )}
              <Divider />
              <div style={{ marginBottom: "10px", marginTop: "10px" }}>
                Heartbeat Monitoring:
              </div>
              <RadioGroup
                aria-label="Heartbeat Monitoring"
                name="heartbeatMonitoring"
                value={formData.heartbeatAlerts.toString()}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    heartbeatAlerts: e.target.value === "true",
                  })
                }
                style={{ flexDirection: "row", marginBottom: "10px" }}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="True"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="False"
                />
              </RadioGroup>
              {errors.heartbeatMonitoring && (
                <div style={{ color: "red" }}>{errors.heartbeatMonitoring}</div>
              )}
            </TableContainer>
          </Box>
        )}
        {activeStep === 2 && (
          <div>
            <Typography
              variant="h6"
              align="center"
              style={{
                fontWeight: "bold",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              Review
            </Typography>
            <Divider style={{ marginBottom: "10px" }} />
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>{formData.name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Comments</TableCell>
                    <TableCell>{formData.comments || "-"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Fault Alerts</TableCell>
                    <TableCell>
                      {formData.faultAlerts ? "True" : "False"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Firmware Updates</TableCell>
                    <TableCell>
                      {formData.firmwareUpdates ? "True" : "False"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Heartbeat Alerts</TableCell>
                    <TableCell>
                      {formData.heartbeatAlerts ? "True" : "False"}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
        {activeStep === 3 && (
          <div style={{ padding: "40px" }}>
            {isServicePlanPosted ? (
              <Typography variant="h6" style={{ color: "green" }}>
                Service Plan Added Successful
              </Typography>
            ) : (
              <Typography variant="h6" style={{ color: "red" }}>
                Service Plan Addition Unsuccessful
              </Typography>
            )}
          </div>
        )}
      </DialogContent>
      <DialogActions style={{ margin: "10px" }}>
        <Box display="flex" justifyContent="space-between" width="100%">
          {activeStep === 0 && (
            <Button
              variant="contained"
              onClick={handleCloseDialog}
              color={theme.backbuttoncolor}
            >
              Cancel
            </Button>
          )}
          {activeStep !== 0 && activeStep !== steps.length - 1 && (
            <Button
              variant="contained"
              color={theme.backbuttoncolor}
              onClick={handleBack}
            >
              Back
            </Button>
          )}
          {activeStep < 2 && (
            <Button
              variant="contained"
              onClick={handleNext}
              color={theme.nextbuttoncolor}
            >
              Next
            </Button>
          )}
          {activeStep === 2 && (
            <Button
              variant="contained"
              onClick={handleSubmit}
              color={theme.nextbuttoncolor}
            >
              Submit
            </Button>
          )}
          {activeStep === 3 && (
            <Box display="flex" justifyContent="center" width="100%">
              <Button
                variant="contained"
                onClick={handleCloseDialog}
                color={theme.nextbuttoncolor}
              >
                Done
              </Button>
            </Box>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
}
