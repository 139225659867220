import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { ServicePlansInterface } from "../../../utils/interfaces/service/service-list-interface";
import { AppContext } from "../../../utils/themes/theme-context";
import CloseIcon from "@mui/icons-material/Close";
import { putUpdatedServicePlans } from "../../../utils/requests/serviceplans/put-service-plan";
import { fetchSingleServicePlan } from "../../../utils/requests/serviceplans/get-single-serviceplan";
import { useParams } from "react-router-dom";

interface EditServicePlanDialogProps {
  open: boolean;
  onClose: () => void;
  servicePlan: ServicePlansInterface | null;
  servicePlanList: ServicePlansInterface[];
}

const EditServicePlanDialog: React.FC<EditServicePlanDialogProps> = ({
  open,
  onClose,
  servicePlan,
  servicePlanList,
}) => {
  const editDialogRef = useRef<HTMLDivElement | null>(null);
  const [finalEditedServicePlan, setFinalEditedServicePlan] =
    useState<ServicePlansInterface | null>(null);
  const [activeStep, setActiveStep] = useState(0);
  const [planNameError, setPlanNameError] = useState<string | null>(null);
  var { theme } = useContext(AppContext);
  const [isServicePlanPosted, setisServicePlanPosted] =
    useState<boolean>(false);

  const steps = ["Details", "Features", "Review", "Confirmation"];

  useEffect(() => {
    // Fetch service plan data when the dialog is opened
    if (open && servicePlan?.id) {
      // Call your data fetching function
      fetchSingleServicePlan(servicePlan.id)
        .then((data: ServicePlansInterface) => {
          setFinalEditedServicePlan(data);
        })
        .catch((error) => {
          console.error("Error fetching service plan:", error);
        });
    }
  }, [open, servicePlan?.id]);

  const handleSaveEdit = async () => {
    if (!finalEditedServicePlan) return;

    try {
      // POST data
      const response = await putUpdatedServicePlans(finalEditedServicePlan);

      if (response) {
        // Firmware update successful
        setisServicePlanPosted(true);
        setActiveStep((prevStep) => prevStep + 1);
      } else {
        // Log specific error messages if available
        if (response.status === 400) {
          const data = await response.json();
          console.error("Bad request error:", data.errors);
        } else if (response.status === 401) {
          const data = await response.json();
          console.error("Unauthorized error:", data.errors);
        } else if (response.status === 403) {
          const data = await response.json();
          console.error("Forbidden error:", data.errors);
        } else if (response.status === 500) {
          const data = await response.json();
          console.error("Server error:", data.errors);
        }

        setisServicePlanPosted(false);
        setActiveStep((prevStep) => prevStep + 1);
      }
    } catch (error) {
      // Handle other errors
      console.error("Error:", error);
      setisServicePlanPosted(false);
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleCloseEditDialog = () => {
    setActiveStep(0);
    setFinalEditedServicePlan(null);
    onClose();
  };

  const handleNext = () => {
    if (activeStep === 0) {
      // Validate plan name
      if (!finalEditedServicePlan?.name) {
        setPlanNameError("Plan name cannot be empty");
      } else if (
        finalEditedServicePlan.name.length > 100 ||
        /[!@#$%^&*(),.?":{}|<>]/.test(finalEditedServicePlan.name)
      ) {
        setPlanNameError(
          "Plan name must be less than 100 characters and contain no special characters except space"
        );
      } else if (
        servicePlanList.some(
          (plan) =>
            plan.name.toLowerCase() ===
              finalEditedServicePlan?.name.toLowerCase() &&
            plan.id !== finalEditedServicePlan?.id // Exempt the current service plan
        )
      ) {
        setPlanNameError("Plan name must be unique");
      } else {
        setPlanNameError(null);
        setActiveStep((prevStep) => prevStep + 1);
      }
    } else if (activeStep === 1) {
      setActiveStep((prevStep) => prevStep + 1);
    } else if (activeStep === 2) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  return (
    <Dialog open={open} onClose={handleCloseEditDialog}>
      <DialogTitle variant="h4" align="center" style={{margin: '20px'}}>
        Edit Service Plan Details
        <IconButton
          aria-label="close"
          onClick={handleCloseEditDialog}
          sx={{
            position: "absolute",
            right: 2,
            top: 2,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent ref={editDialogRef}>
        {/* Stepper */}
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          style={{ wordSpacing: "80px" }}
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === 0 && (
          <>
            <Typography
              variant="h6"
              align="center"
              style={{
                fontWeight: "bold",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              Enter Service Plan Details
            </Typography>
            <Divider style={{ marginBottom: "10px" }} />
            <TextField
              label="Plan"
              defaultValue={servicePlan?.name}
              onChange={(e) =>
                setFinalEditedServicePlan((prev) => ({
                  ...prev!,
                  name: e.target.value,
                }))
              }
              fullWidth
              margin="normal"
              error={!!planNameError}
              helperText={planNameError}
            />
            <TextField
              label="Comments"
              defaultValue={servicePlan?.comments}
              onChange={(e) =>
                setFinalEditedServicePlan((prev) => ({
                  ...prev!,
                  comments: e.target.value,
                }))
              }
              fullWidth
              margin="normal"
            />
          </>
        )}
        {activeStep === 1 && (
          <Box style={{ margin: "30px" }}>
            <Typography
              variant="h6"
              align="center"
              style={{ fontWeight: "bold", marginTop: "10px" }}
            >
              Select Service Plan Features
            </Typography>
            <Divider
              style={{ marginBottom: "10px", marginTop: "10px" }}
            ></Divider>
            <TableContainer component={Paper} style={{ padding: "20px" }}>
              <div style={{ marginBottom: "10px" }}>Firmware Updates:</div>
              <RadioGroup
                aria-label="Firmware Updates"
                name="firmwareUpdates"
                value={finalEditedServicePlan?.service_plan_features.firmware_updates.toString()}
                onChange={(e) => {
                  setFinalEditedServicePlan((prev) => ({
                    ...prev!,
                    service_plan_features: {
                      ...prev!.service_plan_features,
                      firmware_updates: e.target.value === "true",
                    },
                  }));
                }}
                style={{ flexDirection: "row", marginBottom: "10px" }}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="True"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="False"
                />
              </RadioGroup>
              {/* {errors.firmwareUpdates && (
              <div style={{ color: "red" }}>{errors.firmwareUpdates}</div>
            )} */}
              <Divider />
              <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                Fault Alerts:
              </div>
              <RadioGroup
                aria-label="Fault Alerts"
                name="faultAlerts"
                value={finalEditedServicePlan?.service_plan_features.fault_alerts.toString()}
                onChange={(e) =>
                  setFinalEditedServicePlan((prev) => ({
                    ...prev!,
                    service_plan_features: {
                      ...prev!.service_plan_features,
                      fault_alerts: e.target.value === "true",
                    },
                  }))
                }
                style={{ flexDirection: "row", marginBottom: "10px" }}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="True"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="False"
                />
              </RadioGroup>
              {/* {errors.faultAlerts && (
              <div style={{ color: "red" }}>{errors.faultAlerts}</div>
            )} */}
              <Divider />
              <div style={{ marginBottom: "10px", marginTop: "10px" }}>
                Heartbeat Monitoring:
              </div>
              <RadioGroup
                aria-label="Heartbeat Monitoring"
                name="heartbeatMonitoring"
                value={finalEditedServicePlan?.service_plan_features.heartbeat_alerts.toString()}
                onChange={(e) =>
                  setFinalEditedServicePlan((prev) => ({
                    ...prev!,
                    service_plan_features: {
                      ...prev!.service_plan_features,
                      heartbeat_alerts: e.target.value === "true",
                    },
                  }))
                }
                style={{ flexDirection: "row" }}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="True"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="False"
                />
              </RadioGroup>
              {/* {errors.heartbeatMonitoring && (
              <div style={{ color: "red" }}>{errors.heartbeatMonitoring}</div>
            )} */}
            </TableContainer>
          </Box>
        )}
        {activeStep === 2 && (
          <div>
            <Typography
              variant="h6"
              align="center"
              style={{
                fontWeight: "bold",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              Review
            </Typography>
            <Divider style={{ marginBottom: "10px" }} />
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Name:</TableCell>
                    <TableCell>{finalEditedServicePlan?.name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Comments:</TableCell>
                    <TableCell>{finalEditedServicePlan?.comments}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Firmware Updates:</TableCell>
                    <TableCell>
                      {finalEditedServicePlan?.service_plan_features.firmware_updates.toString()}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Fault Alerts:</TableCell>
                    <TableCell>
                      {finalEditedServicePlan?.service_plan_features.fault_alerts.toString()}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Heartbeat Monitoring:</TableCell>
                    <TableCell>
                      {finalEditedServicePlan?.service_plan_features.heartbeat_alerts.toString()}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
        {activeStep === 3 && (
          <div style={{ padding: "40px" }}>
            <Typography
              variant="h6"
              style={{ color: isServicePlanPosted ? "green" : "red" }}
            >
              {isServicePlanPosted
                ? "Service plan update successful!"
                : "Service plan update failed!"}
            </Typography>
          </div>
        )}
      </DialogContent>
      <DialogActions style={{ margin: "10px" }}>
        <Box display="flex" justifyContent="space-between" width="100%">
          {activeStep === 0 && (
            <Button
              variant="contained"
              onClick={handleCloseEditDialog}
              color={theme.backbuttoncolor}
            >
              Cancel
            </Button>
          )}
          {activeStep !== 0 && activeStep !== steps.length - 1 && (
            <Button
              variant="contained"
              color={theme.backbuttoncolor}
              onClick={() => setActiveStep((prevStep) => prevStep - 1)}
            >
              Back
            </Button>
          )}
          {activeStep !== steps.length - 1 &&
            activeStep !== steps.length - 2 && (
              <Button
                variant="contained"
                onClick={handleNext}
                color={theme.nextbuttoncolor}
              >
                Next
              </Button>
            )}
          {activeStep === steps.length - 2 && (
            <Button
              variant="contained"
              onClick={handleSaveEdit}
              color={theme.nextbuttoncolor}
            >
              Save
            </Button>
          )}
          {activeStep === steps.length - 1 && (
            <Box display="flex" justifyContent="center" width="100%">
              <Button
                variant="contained"
                onClick={handleCloseEditDialog}
                color={theme.nextbuttoncolor}
              >
                Done
              </Button>
            </Box>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default EditServicePlanDialog;
