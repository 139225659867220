import "@aws-amplify/ui-react/styles.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./layout";
import Nopage from "./pages/404/404";
import UsersHeader from "./pages/user-list/users";
import Dashboard from "./pages/dashboard/dashboard";
import UserDetails from "./pages/user-list/userdetails";
import NewUser from "./pages/new-user/newuser";
import "./App.css";
import { useEffect, useState } from "react";
import { getActiveUserDetails } from "./utils/requests/authentication/get-activeuser-details";
import {
  AccessDenied,
  determineContent,
} from "./pages/access-denied-and-loading/access-denied-and-loading";
import {
  Theme,
  AppContext,
  ThemeConstructor,
} from "./utils/themes/theme-context";
import VehiclesList from "./pages/vehicles-list/vehicles";
import VehicleDetails from "./pages/vehicles-list/vehicle-details";
import { UserPermissionsModel } from "./utils/requests/authentication/user-permissions-model";
import ClientList from "./pages/client-page/clients";
import { SingleClientDetailsPage } from "./pages/client-page/single-client-details-page";
import ProductList from "./pages/product-page/products";
import { SingleProductDetailsPage } from "./pages/product-page/single-product-details-page";
import DevicesList from "./pages/devices-page/devices";
import { SingleDeviceDetailsPage } from "./pages/devices-page/single-device";
import NoAccess from "./pages/NoAccess/noaccess";
import WelcomePageCorp from "./pages/Welcome-Page-corp/welcome-page-corp";
import NewUserCsv from "./pages/new-user-csv/new-user-csv";
import IntegrationList from "./pages/integration/integration";
import { SingleIntegrationPage } from "./pages/integration/single-integration-page";
import { SetUserPerms } from "./pages/new-user/forms/set-user-perms";
import AboutPage from "./pages/about-page/aboutPage";
import ServiceList from "./pages/service-page/service-list";
import SingleService from "./pages/service-page/single-service-details";
import ReportsList from "./pages/reports-page/reports";

function App(props: { signOut: any; user: any }) {
  const [perms, setPerms] = useState<UserPermissionsModel | null>(null);
  const theme: Theme = ThemeConstructor();
  const [usertype, setUserType] = useState("");

  useEffect(() => {
    getActiveUserDetails()
      .then((response: { data: UserPermissionsModel }) => {
        setPerms(response.data);
        setUserType(response.data.user_type);
      })
      .catch((error: string) => {
        console.error("Error fetching active user details:", error);
      });
  }, []);

  return (
    <AppContext.Provider value={{ theme, perms }}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Layout
                signOut={props.signOut}
                user={props.user}
                usertype={usertype}
              />
            }
          >
            {perms?.user_type === "aw" ? (
              <>
                <Route index element={<Dashboard />} />
                <Route path="users" element={<UsersHeader />} />
                <Route path="users/:id" element={<UserDetails />} />
                <Route path="newuser" element={<NewUser />} />
                <Route path="newusercsv" element={<NewUserCsv />} />
                {perms.aw_permissions.integrations_read ? (
                  <>
                    <Route path="integrators" element={<IntegrationList />} />
                    <Route
                      path="/integrators/:id"
                      element={<SingleIntegrationPage />}
                    />
                  </>
                ) : (
                  <Route path="*" element={<NoAccess />} />
                )}
                <Route path="vehicles" element={<VehiclesList />} />
                <Route
                  path="vehicles/:vehicle_id"
                  element={<VehicleDetails />}
                />
                {perms.aw_permissions.clients_read ? (
                  <>
                    <Route path="clients" element={<ClientList />} />
                    <Route
                      path="/clients/:id"
                      element={<SingleClientDetailsPage />}
                    />
                  </>
                ) : (
                  <Route path="*" element={<NoAccess />} />
                )}
                <Route path="service" element={<ServiceList />} />
                <Route
                  path="/service/:id"
                  element={<SingleService />}
                />
                <Route path="products" element={<ProductList />} />
                <Route
                  path="/products/:id"
                  element={<SingleProductDetailsPage />}
                />
                <Route path="device" element={<DevicesList />} />
                <Route
                  path="device/:id"
                  element={<SingleDeviceDetailsPage />}
                />
                <Route path="reports" element={<ReportsList />} />
                <Route path="*" element={<Nopage />} />
                <Route path="about" element={<AboutPage />} />
              </>
            ) : perms?.user_type === "corp" ? (
              <>
                <Route index element={<WelcomePageCorp />} />
                {/* Check permissions for specific actions */}
                {perms?.corporate_permissions?.users_read && (
                  <Route path="users" element={<UsersHeader />} />
                )}
                <Route path="users/:id" element={<UserDetails />} />
                <Route path="newuser" element={<NewUser />} />
                <Route path="newusercsv" element={<NewUserCsv />} />
                <Route path="*" element={<NoAccess />} />
                <Route path="about" element={<AboutPage />} />
              </>
            ) : (
              <>
                <Route index element={<AccessDenied />} />
                <Route path="*" element={<AccessDenied />} />
              </>
            )}
          </Route>
        </Routes>
      </BrowserRouter>
    </AppContext.Provider>
  );
}

export default App;
