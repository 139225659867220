import {
  Alert,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  LinearProgress,
  Skeleton,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState, useRef } from "react";
import { AppContext } from "../../../utils/themes/theme-context";
import { getVehicleWeights } from "../../../utils/requests/vehicles-routes/get-vehicle-weights";
import MyLineChart from "../../../utils/generic-components/graphs/line-chart";
import { rfcToDateAndTime } from "../../../utils/custom-functions/rfc3339-conversions";
import {
  AxleWeightExternal,
  DefaultWeightsReturnType,
  FormattedWeightswithData,
  GraphSingleWeightDatapoint,
  GraphVehicleData,
} from "../../../utils/interfaces/vehicles/vehicles-interfaces";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import IconButton from "@mui/material/IconButton";
import { Columns } from "../../../utils/interfaces/table/table-interfaces";
import { getProductsPreCals } from "../../../utils/requests/products/get-products-precals";
import {
  PostProductPreCalsRequest,
  ProductsPrecals,
  ProductsPrecalsResponse,
} from "../../../utils/interfaces/products/products-precals-interface";
import { ProductListData } from "../../../utils/interfaces/products/products-list-interface";
import { useNavigate, useParams } from "react-router";
import React from "react";
import GeneralTable from "../../../utils/generic-components/table/table";
import clients from "../../client-page/clients";
import CloseIcon from "@mui/icons-material/Close";
import {
  validateName,
  validatePreCal,
  validateWeight,
} from "../../../utils/regex/forms";
import { fetchSingleProductData } from "../../../utils/requests/products/get-single-product";
import { width } from "@mui/system";
import { WidthFull } from "@mui/icons-material";
import { getSinglePreCal } from "../../../utils/requests/products/get-single-precal-data";
import { postProductsPreCals } from "../../../utils/requests/products/post-product-precals";
import { deleteSinglePreCal } from "../../../utils/requests/products/delete-single-precal";

let columns: Columns = {
  Name: {
    type: "text",
    display: "Name",
    data: "client",
    sort: "Name",
  },
  DateTime: {
    type: "time",
    display: "Date & Time",
    data: "date_time",
    sort: "DateTime",
  },
  Make: {
    type: "text",
    display: "Make",
    data: "make",
    sort: "Make",
  },
  Model: {
    type: "text",
    display: "Model",
    data: "model ",
    sort: "Model",
  },
  Link: {
    type: "link",
    display: "Link",
    sort: "Version",
  },
};

export default function ProductPrecalsCard(props: {
  products: ProductListData;
}) {
  var { theme } = useContext(AppContext);
  const { id, version } = useParams<{ id?: string; version?: string }>();
  const [loading, setLoading] = useState<boolean>(true);
  const [productPrecals, setProductPrecals] = useState<
    ProductsPrecals[] | null
  >([]);
  const [isAddPrecalDialogOpen, seAddPrecalDialogOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const makeRef = useRef<HTMLInputElement | null>(null);
  const modelRef = useRef<HTMLInputElement | null>(null);
  const nameRef = useRef<HTMLInputElement | null>(null);
  const heavyweightRef = useRef<HTMLInputElement | null>(null);
  const emptyweightRef = useRef<HTMLInputElement | null>(null);
  const heavyweightadcRef = useRef<HTMLInputElement | null>(null);
  const emptyweightadcRef = useRef<HTMLInputElement | null>(null);
  const optionsRef = useRef<HTMLInputElement | null>(null);
  const [makeError, setMakeError] = useState<string>("");
  const [modelError, setModelError] = useState<string>("");
  const [nameError, setNameError] = useState<string>("");
  const [heavyWeightError, setHeavyWeightError] = useState<string>("");
  const [emptyWeightError, setEmptyWeightError] = useState<string>("");
  const [heavyWeightADCError, setHeavyWeightADCError] = useState<string>("");
  const [emptyWeightErrorADC, setEmptyWeightADCError] = useState<string>("");
  const [preCalDetails, setPreCalDetails] = useState({
    make: "",
    model: "",
    name: "",
    heavyweight: null as number | null,
    emptyweight: null as number | null,
    heavyweightadc: null as number | null,
    emptyweightadc: null as number | null,
    options: [] as string[],
  });
  const [isPreCalUpdated, setisPreCalUpdated] = useState<boolean>(false);
  const [preCalibrationSupport, setPreCalibrationSupport] = useState(false);
  const [errorStringMessage, setErrorStringMessage] = useState<string | null>(
    null
  );
  const [dialogLoading, setDialogLoading] = useState(false);
  const [selectedPreCal, setSelectedPreCal] = useState<ProductsPrecals | null>(
    null
  );
  const [dialogOpen, setDialogOpen] = useState(false);
  const [errorAlert, setErrorAlert] = useState<string | null>(null);
  const [deleteResult, setDeleteResult] = useState("");
  const [success, setSuccess] = useState(false);
  const [deleteResultColor, setDeleteResultColor] = useState("");
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(true);
  const [deleteString, setDeleteString] = useState("");
  const [deleteStringError, setDeleteStringError] = useState("");
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [deletionTime, setDeletionTime] = useState<string | null>(null);
  let userNavigate = useNavigate();

  const productID = props.products.id;

  const openDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
    setActiveStep(0); // Start from the first step
    setDeleteResult("");
  };

  const closeDeleteDialog = () => {
    // Only allow closing if deletion is not successful

    setIsDeleteDialogOpen(false);
    // Clear the text field when the dialog is closed
    setDeleteResult("");
  };

  const handleDeleteStringEntered = (event: { target: { value: any } }) => {
    const inputDeleteString = event.target.value;
    setDeleteString(inputDeleteString);

    // Enable the Delete button only when inputSerialNumber matches the device's serial number
    if (inputDeleteString === "DELETE") {
      setIsDeleteButtonDisabled(false);
      setDeleteStringError("");
    } else {
      setIsDeleteButtonDisabled(true);
      setDeleteStringError("Spell DELETE correctly");
    }
  };

  useEffect(() => {
    const fetchProductPreCalibrationSupport = async () => {
      const respdata = await fetchSingleProductData(productID);
      setPreCalibrationSupport(respdata.precalibration_support);
    };
    fetchProductPreCalibrationSupport();
  }, [productID]);

  useEffect(() => {
    const fetchData = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const precalIdParam = urlParams.get("precalId");

      if (precalIdParam) {
        try {
          const preCalDetails = await getSinglePreCal(precalIdParam);

          setSelectedPreCal(preCalDetails);
          setDialogLoading(false); // Clear the loading state when data is fetched
          setDialogOpen(true);
        } catch (error) {
          // Handle errors
          console.error("Error fetching precalibration details:", error);
        }
      }
    };

    fetchData();
  }, []); // This effect will run once when the component mounts

  const fetchPreCalsData = async (id: string | undefined) => {
    try {
      if (id) {
        const response = await getProductsPreCals(id);

        if (
          response.errors &&
          response.errors.length > 0 &&
          response.errors[0] === "unsupported product feature"
        ) {
          setErrorStringMessage("Unsupported Product Feature.");
          setProductPrecals(null);
        } else {
          setProductPrecals(response.data);
          setErrorStringMessage(null); // Clear any previous error message
        }
      }
    } catch (err) {
      setProductPrecals(null);
    }
  };

  useEffect(() => {
    fetchPreCalsData(id);
  }, [id]);

  async function handleUpdatePreCalPOST(
    event: React.MouseEvent<HTMLButtonElement> | null
  ) {
    try {
      // Prepare the data for the API request
      const precalspostdata: PostProductPreCalsRequest = {
        name: preCalDetails.name,
        make: preCalDetails.make,
        model: preCalDetails.model,
        heavy_weight: preCalDetails.heavyweight || 0,
        empty_weight: preCalDetails.emptyweight || 0,
        heavy_adc: preCalDetails.heavyweightadc || 0,
        empty_adc: preCalDetails.emptyweightadc || 0,
        options: preCalDetails.options,
      };

      // Call the API to send the data to the backend
      const response = await postProductsPreCals(productID, precalspostdata);

      if (response.ok) {
        // Log success
        setisPreCalUpdated(true);
        setActiveStep((prevStep) => prevStep + 1);
        await fetchPreCalsData(id);
      } else {
        // Log failure
        setisPreCalUpdated(false);
        setActiveStep((prevStep) => prevStep + 1);
        // Log the error message if available
        response &&
          console.error("Error upsating pre-cal:", response.statusText);
      }
    } catch (error) {
      // Handle other errors
      console.error("Error:", error);
      setisPreCalUpdated(false);
      setActiveStep((prevStep) => prevStep + 1);
    }
  }

  const handleNextStep = () => {
    if (activeStep === 0) {
      const make = makeRef.current?.value || "";
      const model = modelRef.current?.value || "";
      const name = nameRef.current?.value || "";
      const heavyweight = parseFloat(heavyweightRef.current?.value || "0") || 0;
      const emptyweight = parseFloat(emptyweightRef.current?.value || "0") || 0;
      const heavyweightadc =
        parseFloat(heavyweightadcRef.current?.value || "0") || 0;
      const emptyweightadc =
        parseFloat(emptyweightadcRef.current?.value || "0") || 0;
      const options = optionsRef.current?.value || "";

      let valid = true;

      const makeValueError = validatePreCal(make, "Invalid Make.");
      if (!make) {
        setMakeError("Make is required");
        valid = false;
      } else if (makeValueError) {
        setMakeError(makeValueError);
        valid = false;
      } else {
        setMakeError("");
      }

      const modelValueError = validatePreCal(model, "Invalid Model.");
      if (!model) {
        setModelError("Model is required");
        valid = false;
      } else if (modelValueError) {
        setModelError(modelValueError);
        valid = false;
      } else {
        setModelError("");
      }

      const nameValueError = validatePreCal(name, "Invalid Name.");
      if (!name) {
        setNameError("Name is required");
        valid = false;
      } else if (nameValueError) {
        setNameError(nameValueError);
        valid = false;
      } else {
        setNameError("");
      }

      const heavyWeightValueError = validateWeight(
        heavyweight,
        "Invalid Heavy Weight Value. It should be a positive Integer value."
      );
      if (!heavyweight) {
        setHeavyWeightError("Heavy Weight Entry is Required");
        valid = false;
      } else if (heavyWeightValueError) {
        setHeavyWeightError(heavyWeightValueError);
        valid = false;
      } else {
        setHeavyWeightError("");
      }

      const emptyWeightValueError = validateWeight(
        emptyweight,
        "Invalid Empty Weight Value. It should be a positive Integer value."
      );
      if (!emptyweight) {
        setEmptyWeightError("Empty Weight Entry is Required");
        valid = false;
      } else if (emptyWeightValueError) {
        setEmptyWeightError(emptyWeightValueError);
        valid = false;
      } else {
        setEmptyWeightError("");
      }

      const heavyWeightValueErrorADC = validateWeight(
        heavyweightadc,
        "Invalid Heavy Weight ADC Value. It should be a positive Integer value."
      );
      if (!heavyweightadc) {
        setHeavyWeightADCError("Heavy Weight ADC Entry is Required");
        valid = false;
      } else if (heavyWeightValueErrorADC) {
        setHeavyWeightADCError(heavyWeightValueErrorADC);
        valid = false;
      } else {
        setHeavyWeightADCError("");
      }

      const emptyWeightValueErrorADC = validateWeight(
        emptyweightadc,
        "Invalid Empty Weight ADC Value. It should be a positive Integer value."
      );
      if (!emptyweightadc) {
        setEmptyWeightADCError("Empty Weight ADC Entry is Required");
        valid = false;
      } else if (emptyWeightValueErrorADC) {
        setEmptyWeightADCError(emptyWeightValueErrorADC);
        valid = false;
      } else {
        setEmptyWeightADCError("");
      }

      if (!valid) {
        return; // Don't proceed to the next step if validation fails
      }

      const updatedDetails = {
        make,
        model,
        name,
        heavyweight,
        emptyweight,
        heavyweightadc,
        emptyweightadc,
        options: options.split(",").map((option) => option.trim()), // Convert comma-separated options to an array
      };

      setPreCalDetails(updatedDetails);
    }

    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleAddPrecal = () => {
    seAddPrecalDialogOpen(true);
  };

  function handleCloseAddPrecal(): void {
    seAddPrecalDialogOpen(false);
    setActiveStep(0);
    setMakeError("");
    setModelError("");
    setNameError("");
    setHeavyWeightError("");
    setEmptyWeightError("");
    setHeavyWeightADCError("");
    setEmptyWeightADCError("");
    setPreCalDetails({
      make: "",
      model: "",
      name: "",
      heavyweight: 0,
      emptyweight: 0,
      heavyweightadc: 0,
      emptyweightadc: 0,
      options: [],
    });
  }

  const handleDialogOpen = async (precalID: any) => {
    try {
      setDialogLoading(true); // Set the loading state when opening the dialog
      const data = await getSinglePreCal(precalID);
      setSelectedPreCal(data); //make interface and then create usestate and import
      setDialogLoading(false); // Clear the loading state when data is fetched
      setDialogOpen(true);
    } catch (error) {
      console.error("Error fetching firmware data:", error);
    }
  };

  const handleDialogClose = () => {
    setSelectedPreCal(null);
    setDialogOpen(false);
    setErrorAlert(null);
    setIsDeleteDialogOpen(false);
  };

  const handlePrevStep = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleDeletePreCal = async () => {
    setDeleteInProgress(true);

    try {
      // Make API call to delete the pre-calibration
      const response = await deleteSinglePreCal(selectedPreCal!.id);

      if (response === "success") {
        // Log success
        setSuccess(true);
        setDeleteResult("Pre-Cal was successfully deleted");
        setDeleteResultColor("green");
        // Set the deletion time
        const now = new Date();
        setDeletionTime(now.toLocaleString());

        await fetchPreCalsData(id); // Refresh the pre-calibration data
      } else {
        // Log failure
        console.error("Pre-Calibration Deletion Failed");
        setDeleteResult("Error deleting pre-cal: ${errorMessages}");
        setDeleteResultColor("red");
        // Log the error message if available
        response &&
          console.error("Error deleting pre-cal:", response.statusText);
      }
    } catch (error) {
      // Handle other errors
      console.error("Error deleting pre-cal:", error);
      setDeleteResult("An error occurred while making the request");
      setDeleteResultColor("red");
    }

    setActiveStep(2);
  };

  const linkContent = dialogLoading ? (
    <CircularProgress size={24} color="primary" />
  ) : (
    "DETAILS"
  );

  const steps = ["Enter Pre-Cal data", "Review", "Confirmation"];

  return (
    <>
      <Card elevation={8} sx={{ borderRadius: 3 }}>
        <CardHeader
          title="Pre-Cals"
          action={
            //<React.Fragment>. This allows you to have multiple elements inside the action prop of <CardHeader>.
            <React.Fragment>
              <Button
                variant="contained"
                onClick={handleAddPrecal}
                disabled={!preCalibrationSupport}
              >
                Add Pre-Cal
              </Button>
              <IconButton aria-label="info">
                <InfoOutlinedIcon />
              </IconButton>
            </React.Fragment>
          }
        />
        <CardContent>
          <Divider />

          {/* Render the Precal Table */}
          {errorStringMessage ? (
            <Box>
              <Backdrop
                open={true}
                sx={{
                  position: "relative",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              >
                <Alert
                  variant="filled"
                  severity="warning"
                  style={{ width: "100%" }}
                >
                  {errorStringMessage ? errorStringMessage : null}
                </Alert>
              </Backdrop>
            </Box>
          ) : preCalibrationSupport ? (
            <>
              <GeneralTable
                data={productPrecals!.map((precalsdata) => ({
                  Make: precalsdata.make,
                  Model: precalsdata.model,
                  Name: precalsdata.name,
                  DateTime: precalsdata.date_time,
                  Link: linkContent,
                  HeavyWeight: precalsdata.heavy_weight,
                  EmptyWeight: precalsdata.empty_weight,
                  HeavyWeightADC: precalsdata.heavy_adc,
                  EmptyWeightADC: precalsdata.empty_adc,
                  PrecalID: precalsdata.id,
                }))}
                columns={columns}
                noDataMessage={"No Pre-Cals to fetch "}
                rowClickCallback={(rowData: any) =>
                  handleDialogOpen(rowData.PrecalID)
                }
                rows={10}
                rowsoptions={[10, 25, 50, 100]}
              />

              <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle variant="h4" align="center">
                  Pre-Cal Details
                  <IconButton
                    aria-label="close"
                    onClick={handleDialogClose}
                    sx={{
                      position: "absolute",
                      right: 2,
                      top: 2,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent sx={{ margin: "16px" }}>
                  <br />
                  <Divider />
                  {dialogLoading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100px",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  ) : (
                    selectedPreCal && (
                      <>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell>Name</TableCell>
                              <TableCell>{selectedPreCal.name}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Make:</TableCell>
                              <TableCell>{selectedPreCal.make}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Model:</TableCell>
                              <TableCell>{selectedPreCal.model}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Date & Time:</TableCell>
                              <TableCell>
                                {" "}
                                {new Date(
                                  selectedPreCal.date_time
                                ).toLocaleString()}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Heavy Weight:</TableCell>
                              <TableCell>
                                {selectedPreCal.heavy_weight}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Empty Weight:</TableCell>
                              <TableCell>
                                {selectedPreCal.empty_weight}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Heavy Weight ADC:</TableCell>
                              <TableCell>{selectedPreCal.heavy_adc}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Empty Weight ADC:</TableCell>
                              <TableCell>{selectedPreCal.empty_adc}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Options:</TableCell>
                              <TableCell>
                                {selectedPreCal.options.map((option) => (
                                  <TableRow key={option}>
                                    <TableCell>{option}</TableCell>
                                  </TableRow>
                                ))}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>

                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          marginTop={4}
                        >
                          <Button
                            variant="contained"
                            color={theme.backbuttoncolor}
                            onClick={openDeleteDialog}
                          >
                            Delete Pre-Cal
                          </Button>
                        </Box>
                        <Box textAlign="center" marginTop={4}>
                          {errorAlert && (
                            <Alert severity="error">{errorAlert}</Alert>
                          )}
                        </Box>
                      </>
                    )
                  )}
                </DialogContent>
              </Dialog>
            </>
          ) : (
            <Skeleton variant="rectangular" width={650} height={200} />
          )}

          {/* Dialog for Upload Firmware Button */}
          <Dialog open={isAddPrecalDialogOpen} onClose={handleCloseAddPrecal}>
            <DialogTitle variant="h5" align="center">
              Add Pre-Cal
              <IconButton
                aria-label="close"
                onClick={handleCloseAddPrecal}
                sx={{
                  position: "absolute",
                  right: 2,
                  top: 2,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Box
                flexGrow={1}
                marginLeft={"2%"}
                marginRight={"2%"}
                marginTop={8}
                marginBottom={10}
              >
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                {/* Step content */}
                {activeStep === 0 && (
                  /* Step 1: Enter Information */
                  <div>
                    <Typography
                      paddingTop={2}
                      variant="h6"
                      align="center"
                      style={{ fontWeight: "bold" }}
                    >
                      Enter Pre-Cal Details
                    </Typography>
                    <TextField
                      label="Make"
                      fullWidth
                      margin="normal"
                      inputRef={makeRef}
                      error={!!makeError}
                      helperText={makeError}
                      value={preCalDetails.make}
                      onChange={(e) => {
                        setPreCalDetails({
                          ...preCalDetails,
                          make: e.target.value,
                        });
                      }}
                    />
                    <TextField
                      label="Model"
                      fullWidth
                      margin="normal"
                      inputRef={modelRef} // Attach the ref to the TextField
                      error={!!modelError}
                      helperText={modelError}
                      value={preCalDetails.model}
                      onChange={(e) => {
                        setPreCalDetails({
                          ...preCalDetails,
                          model: e.target.value,
                        });
                      }}
                    />
                    <TextField
                      label="Name"
                      fullWidth
                      margin="normal"
                      inputRef={nameRef} // Attach the ref to the TextField
                      error={!!nameError}
                      helperText={nameError}
                      value={preCalDetails.name}
                      onChange={(e) => {
                        setPreCalDetails({
                          ...preCalDetails,
                          name: e.target.value,
                        });
                      }}
                    />
                    <TextField
                      label="Heavy Weight"
                      fullWidth
                      margin="normal"
                      inputRef={heavyweightRef} // Attach the ref to the TextField
                      error={!!heavyWeightError}
                      helperText={heavyWeightError}
                      value={
                        preCalDetails.heavyweight === null
                          ? ""
                          : String(preCalDetails.heavyweight)
                      }
                      onChange={(e) => {
                        const newValue = parseFloat(e.target.value);
                        setPreCalDetails({
                          ...preCalDetails,
                          heavyweight: isNaN(newValue) ? null : newValue,
                        });
                      }}
                    />
                    <TextField
                      label="Empty Weight"
                      fullWidth
                      margin="normal"
                      inputRef={emptyweightRef} // Attach the ref to the TextField
                      error={!!emptyWeightError}
                      helperText={emptyWeightError}
                      value={
                        preCalDetails.emptyweight === null
                          ? ""
                          : String(preCalDetails.emptyweight)
                      }
                      onChange={(e) => {
                        const newValue = parseFloat(e.target.value);
                        setPreCalDetails({
                          ...preCalDetails,
                          emptyweight: isNaN(newValue) ? null : newValue,
                        });
                      }}
                    />
                    <TextField
                      label="Heavy Weight ADC"
                      fullWidth
                      margin="normal"
                      inputRef={heavyweightadcRef} // Attach the ref to the TextField
                      error={!!heavyWeightADCError}
                      helperText={heavyWeightADCError}
                      value={
                        preCalDetails.heavyweightadc === null
                          ? ""
                          : String(preCalDetails.heavyweightadc)
                      }
                      onChange={(e) => {
                        const newValue = parseFloat(e.target.value);
                        setPreCalDetails({
                          ...preCalDetails,
                          heavyweightadc: isNaN(newValue) ? null : newValue,
                        });
                      }}
                    />
                    <TextField
                      label="Empty Weight ADC"
                      fullWidth
                      margin="normal"
                      inputRef={emptyweightadcRef} // Attach the ref to the TextField
                      error={!!emptyWeightErrorADC}
                      helperText={emptyWeightErrorADC}
                      value={
                        preCalDetails.emptyweightadc === null
                          ? ""
                          : String(preCalDetails.emptyweightadc)
                      }
                      onChange={(e) => {
                        const newValue = parseFloat(e.target.value);
                        setPreCalDetails({
                          ...preCalDetails,
                          emptyweightadc: isNaN(newValue) ? null : newValue,
                        });
                      }}
                    />
                    <TextField
                      label="Options"
                      fullWidth
                      margin="normal"
                      inputRef={optionsRef}
                      value={preCalDetails.options.join(", ")} // Convert array to comma-separated string
                      onChange={(e) => {
                        const optionsArray = e.target.value
                          .split(",")
                          .map((opt) => opt.trim());
                        setPreCalDetails({
                          ...preCalDetails,
                          options: optionsArray,
                        });
                      }}
                    />
                  </div>
                )}
                {activeStep === 1 && (
                  /* Step 2: Review */
                  <div>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={2}>
                            <Typography
                              variant="h6"
                              align="center"
                              style={{ fontWeight: "bold" }}
                            >
                              Review
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            colSpan={2}
                            style={{ background: "#f0f0f0" }}
                          >
                            <Typography
                              variant="body1"
                              style={{ fontWeight: "bold" }}
                            >
                              Entered Pre-Cal Details:
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Make:</TableCell>
                          <TableCell>{preCalDetails.make}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Model: </TableCell>
                          <TableCell>{preCalDetails.model}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Name:</TableCell>
                          <TableCell>{preCalDetails.name}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Heavy Weight:</TableCell>
                          <TableCell>{preCalDetails.heavyweight}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Empty Weight:</TableCell>
                          <TableCell>{preCalDetails.emptyweight}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Heavy Weight ADC:</TableCell>
                          <TableCell>{preCalDetails.heavyweightadc}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Empty Weight ADC:</TableCell>
                          <TableCell>{preCalDetails.emptyweightadc}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Options:</TableCell>
                          <TableCell>
                            {preCalDetails.options.join(", ")}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                )}
                {activeStep === 2 && (
                  /* Step 3: Result */
                  <div style={{ padding: "40px" }}>
                    {isPreCalUpdated ? (
                      <Typography variant="h6" style={{ color: "green" }}>
                        Pre-Cal Updated Successful
                      </Typography>
                    ) : (
                      <Typography variant="h6" style={{ color: "red" }}>
                        Pre-Cal Update Failed
                      </Typography>
                    )}
                  </div>
                )}
              </Box>
              {/* Buttons */}
              <DialogActions>
                <Box display="flex" justifyContent="space-between" width="100%">
                  {activeStep === 0 && (
                    <Button variant="contained" onClick={handleCloseAddPrecal}>
                      Close
                    </Button>
                  )}
                  {activeStep > 0 && (
                    <Button variant="contained" onClick={handlePrevStep}>
                      Back
                    </Button>
                  )}
                  {activeStep === 1 ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleUpdatePreCalPOST}
                    >
                      Add Pre-Cal
                    </Button>
                  ) : activeStep < steps.length - 1 ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNextStep}
                    >
                      Next
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCloseAddPrecal}
                    >
                      Finish
                    </Button>
                  )}
                </Box>
              </DialogActions>
            </DialogContent>
          </Dialog>

          {/* Delete Pre-Cal Confirmation Dialog */}
          <Dialog open={isDeleteDialogOpen} onClose={closeDeleteDialog}>
            <DialogTitle variant="h5" align="center">
              Delete Pre-Cal
            </DialogTitle>
            <DialogContent>
              <Stepper activeStep={activeStep} alternativeLabel>
                <Step>
                  <StepLabel>Confirmation</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Delete Pre-Cal</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Result</StepLabel>
                </Step>
              </Stepper>
              {activeStep === 0 && (
                <div>
                  <Typography paddingTop={5}>
                    This is an irreversible action. We cannot retrieve the pre
                    cal details after it is deleted.
                  </Typography>
                  <Typography>
                    This action can cause serious consequences like deletion of
                    other important details linked to this pre cal data
                  </Typography>
                  <Typography color="red">
                    Are you sure you want to delete this pre-cal?
                  </Typography>
                  <Box mt={2} display="flex" justifyContent="space-between">
                    <Button
                      onClick={closeDeleteDialog}
                      variant="contained"
                      color={theme.nextbuttoncolor}
                    >
                      No
                    </Button>
                    <Button
                      onClick={() => setActiveStep(1)}
                      variant="contained"
                      color={theme.backbuttoncolor}
                    >
                      Yes
                    </Button>
                  </Box>
                </div>
              )}
              {activeStep === 1 && (
                <div>
                  <Typography
                    paddingTop={3}
                    paddingBottom={2}
                    paddingLeft={2}
                    paddingRight={2}
                  >
                    Enter "DELETE" in the text box below
                  </Typography>
                  <TextField
                    label="Enter DELETE"
                    variant="outlined"
                    fullWidth
                    required
                    value={deleteString}
                    onChange={handleDeleteStringEntered}
                    error={deleteStringError !== ""}
                    helperText={deleteStringError}
                  />
                  <Typography color="red" padding={5}>
                    Are you sure you want to delete this pre-cal?
                  </Typography>
                  <Box mt={2} justifyContent="space-between" display="flex">
                    <Button
                      onClick={closeDeleteDialog}
                      variant="contained"
                      color={theme.nextbuttoncolor}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleDeletePreCal}
                      color={theme.backbuttoncolor}
                      variant="contained"
                      disabled={isDeleteButtonDisabled}
                    >
                      Delete
                    </Button>
                  </Box>
                </div>
              )}
              {activeStep === 2 && (
                <div>
                  <Typography padding={5} fontSize={24}>
                    <span style={{ color: deleteResultColor }}>
                      {deleteResult}
                    </span>
                  </Typography>
                  {success ? (
                    <Box
                      mt={1}
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      {/* Receipt information */}
                      <Box mt={1}>
                        <Typography fontSize={22}>
                          <strong>Details of Pre-Cal deleted:</strong>
                        </Typography>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell>Make:</TableCell>
                              <TableCell>{selectedPreCal?.make}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Model:</TableCell>
                              <TableCell>{selectedPreCal?.model}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Name:</TableCell>
                              <TableCell>{selectedPreCal?.name}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Time of Deletion:</TableCell>
                              <TableCell>{deletionTime}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Box>
                      <Box mt={2} padding={3}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleDialogClose}
                        >
                          OK
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    <Box mt={2} justifyContent="center" display="flex">
                      <Button
                        onClick={closeDeleteDialog}
                        variant="contained"
                        color="primary"
                      >
                        OK
                      </Button>
                    </Box>
                  )}
                </div>
              )}
            </DialogContent>
          </Dialog>
        </CardContent>
      </Card>
    </>
  );
}
