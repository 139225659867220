//A successful response indicates a list of clients the user is authorized to see has been returned.

import { Auth } from "aws-amplify";
import { buildBaseUrl } from "../build-base-url";

export const getClientDetails = async () => {
	const url = buildBaseUrl()
	try {
		const authData = await Auth.currentAuthenticatedUser();
		const response = await fetch(`${url}clients`, {
			headers: {
				Authorization: `Bearer ${authData.signInUserSession.accessToken.jwtToken}`,
			},
		});
		const data  = await response.json(); //Extracting data array from response
		return data;
	} catch (err) {
		console.log(err);
	}
};