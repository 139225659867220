import { Auth } from "aws-amplify";
import { buildBaseUrl } from "../build-base-url";

export const getAllDeviceCalibration = async (serialnumber: string | undefined) => {
    const url = buildBaseUrl()
    if (!serialnumber) {
        return;
    }
    try {
        const authData = await Auth.currentAuthenticatedUser();
        const response = await fetch(`${url}device/${serialnumber}/calibration`, {
            // const response = await fetch(`${url}device/0100000001/calibration`, {
            headers: {
                Authorization: `Bearer ${authData.signInUserSession.accessToken.jwtToken}`,
            },
        });

        const data = await response.json();
        return data;
       
    } catch (err) {
        console.log("error from getAllDeviceCalibration error", err);
    }
};