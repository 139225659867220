// DOCUMENTATION: https://airweigh.atlassian.net/wiki/spaces/AWC/pages/1843396672/Details+Table

import { LoadingButton } from "@mui/lab";
import {
	Alert,
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Divider,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableRow,
	TextField,
	Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../themes/theme-context";
import {
	DetailTableData,
	DetailTableDataPoint,
} from "../../interfaces/details-table/details-table-interfaces";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';

export default function DetailsTable(props: {
	data: DetailTableData;
	putCallback: Function;
	editing: boolean;
	close: boolean;
}) {
	var { theme } = useContext(AppContext);
	const [data, setData] = useState<DetailTableDataPoint[]>(props.data.data);
	const [originalData, setOriginalData] = useState<DetailTableDataPoint[]>(props.data.data);
	const [editingmode, setEditingMode] = useState<boolean>(false);
	const [alert, setAlert] = useState<string>("");
	//alert is set by the callback from details or whatever other file is calling this component, passed to putcallback
	const [loadingbutton, setLoadingButton] = useState<boolean>(false); //true = loading, false = not

	useEffect(() => {
		// Reset data and originalData when props.data changes
		setData([...props.data.data]); //edited data
		setOriginalData([...props.data.data]); // Use spread operator to avoid direct mutation
	}, [props.data]);


	//fires when edit button clicked
	const editClick = () => {
		setAlert("");
		setEditingMode(true);
	};

	const saveClick = () => {

		// Trigger validation for each text field
		const updatedData = data.map((datapoint, index) => {
			let error = "";

			if (datapoint.regex) {
				const errorMessage = `Invalid ${datapoint.label}`;
				error = validateInput(datapoint.content, datapoint.regex, errorMessage);
			} else {
				if (datapoint.required && datapoint.content === "") {
					error = "Field Is Required";
				}
			}

			 // Return a new datapoint object with the error property
			return {
				...datapoint,
				error
			};
		});

		setData(updatedData);

		// Check if there are any errors in the updated data
		const hasErrors = updatedData.some(item => item.error !== "");

		if (hasErrors) {
			setLoadingButton(false); // Stop the loading spinner if there are errors
			return;
		}

		// Proceed with saving if no errors
		setLoadingButton(true);
		let dataprop = props.data; // Get the existing data prop
		dataprop.data = [...updatedData]; // Update the data prop with the validated data

		props.putCallback(dataprop, (error: string) => {
			console.error("Error from putCallback:", error);
			setAlert(error);
			setLoadingButton(false); // Ensure to stop the loading spinner
		}, setEditingMode, setLoadingButton);
	};

	const cancelEditing = () => {
		setData([...originalData]); // Reset data to a new array reference
		setEditingMode(!editingmode); // Exit editing mode
	};

	const closeClick = () => {
		props.putCallback();
	};

	function validateInput(input: string, regex: Function, errorMessage: string) {
		return regex(input, errorMessage);
	}

	//sets errors and calls validate input to process whenever a textfield is changed
	//error will be set when validateinput when regex fails
	function validateAndSetError(
		input: string,
		regex: Function,
		errorMessage: string,
		index: number
	) {
		const error = input ? validateInput(input, regex, errorMessage) : "";
		//This creates a new array by applying a transformation to each item in the data array. 
		//The map method is used to create a new array, ensuring that the original array is not mutated. 
		const updatedData = data.map((item, i) => {
			if (i === index) { //Check if the current index is the one that needs to be updated.
				return {
					...item,
					error
				}; //If the index matches, it creates a new object with the same properties as item but with the error property updated
			}
			return item;
		});
		setData(updatedData);
	}

	const handleTextfieldChange = (
		datapoint: DetailTableDataPoint,
		index: number,
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const newValue = event.target.value;

		if (datapoint.regex) {
			let errorMessage = `Invalid ${datapoint.label}`;
			validateAndSetError(newValue, datapoint.regex, errorMessage, index);
		} else {
			const newError = newValue === "" && datapoint.required ? "Field Is Required" : "";

			const updatedData = data.map((item, i) => {
				if (i === index) {
					return {
						...item,
						content: newValue,
						error: newError,
					};
				}
				return item;
			});
			setData(updatedData);
		}
	};

	const viewOnlyData = () => {
		if (data) {
			return (
				<Box margin={2}>
					<Table>
						<TableBody>
							{data.map((datapoint: DetailTableDataPoint, index: number) => {
								return (
									<TableRow key={index}>
										<TableCell>
											{/* <Box position={"relative"} left={20}> */}
											<Box position={"relative"}>
												<Typography variant="subtitle1" color={theme.subtitle}>
													{datapoint.label}
												</Typography>
											</Box>
											<Typography fontWeight={"bold"}>
												{!datapoint.content
													? "Not Specified"
													: datapoint.content}
											</Typography>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</Box>
			);
		}
	};

	const editData = () => {
		return (
			<>
				{alert ? <Alert severity="error">{alert}</Alert> : null}
				<Stack spacing={2} margin={2}>
					{data.map((datapoint: DetailTableDataPoint, index: number) => {
						return (
							<TextField
								key={datapoint.label}
								defaultValue={datapoint.content || ""}
								id={datapoint.label}
								label={datapoint.label}
								variant="outlined"
								disabled={!datapoint.editable}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
									handleTextfieldChange(datapoint, index, event)
								}
								error={!!datapoint.error}
								helperText={datapoint.error || ""}
							/>
						);
					})}
				</Stack>
			</>
		);
	};

	const action = () => {
		if (props.close) {
			return (
				<Button
					color={theme.backbuttoncolor}
					variant="contained"
					onClick={() => closeClick()}
				>
					Close
				</Button>
			);
		} else if (props.editing) {
			if (editingmode) {
				return (
					<LoadingButton
						color={theme.nextbuttoncolor}
						variant="contained"
						onClick={() => saveClick()}
						// disabled={disabledbutton}
						loading={loadingbutton}
					>
						Save
					</LoadingButton>
				);
			} else {
				return (
					<>
						<Button
							color={theme.basicbuttoncolor}
							variant="contained"
							onClick={() => editClick()}
						>
							Edit
						</Button>
						<IconButton aria-label="info">
							<InfoOutlinedIcon />
						</IconButton>
					</>
				);
			}
		} else {
			return <IconButton aria-label="info">
				<InfoOutlinedIcon />
			</IconButton>;
		}
	};

	return (
		<>
			<Card elevation={8} sx={{ borderRadius: 3 }}>  {/* elevation: adjust the shadow for 3D effect and BorderRadius for the rounded edges. */}
				<CardHeader title="Details" action={action()} />
				<CardContent>
					<>
						<Divider />
						{editingmode ? editData() : viewOnlyData()}
						{editingmode ? (
							<Button
								color={theme.backbuttoncolor}
								variant="contained"
								onClick={cancelEditing}
							>
								Cancel
							</Button>
						) : null}
					</>
				</CardContent>
			</Card>
		</>
	);
}
