import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../utils/themes/theme-context";
import { Stack, Typography } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import BusinessIcon from "@mui/icons-material/Business";
import CategoryIcon from "@mui/icons-material/Category";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
import InfoIcon from "@mui/icons-material/Info";
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import AssessmentSharpIcon from '@mui/icons-material/AssessmentSharp';

const drawerWidth = 240;

export default function SideDrawer({ signOut, user, usertype }: any) {
  var { theme } = useContext(AppContext);
  let navigate = useNavigate();

  // const isAwUser = user && user.attributes['custom:type'] === "aw";
  const isAwUser = user && usertype === "aw";

  const clickHandler = (input: string) => {
    navigate(input);
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          zIndex: "1000",
          backgroundColor: theme.primary,
        },
      }}
    >
      <Toolbar sx={{ backgroundColor: theme.primary }} />
      <Divider color={"#FFFFFF"} />
      <List>
        <ListItem>
          <Typography color={"#FFFFFF"}>Hello {user.username}</Typography>
        </ListItem>
      </List>
      <Divider color={"#FFFFFF"} />
      <List>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText onClick={() => clickHandler("/")}>
              <Stack direction={"row"} spacing={2}>
                <HomeIcon style={{ color: "#FFFFFF" }} />
                <Typography color={"#FFFFFF"}>Home</Typography>
              </Stack>
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <Divider color={"#FFFFFF"} />
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText onClick={() => clickHandler("/users")}>
              <Stack direction={"row"} spacing={2}>
                <PersonIcon style={{ color: "#FFFFFF" }} />
                <Typography color={"#FFFFFF"}>Users</Typography>
              </Stack>
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <Divider color={"#FFFFFF"} />
        {isAwUser && (
          <>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText onClick={() => clickHandler("/integrators")}>
                  <Stack direction={"row"} spacing={2}>
                    <ControlCameraIcon style={{ color: "#FFFFFF" }} />
                    <Typography color={"#FFFFFF"}>Integration</Typography>
                  </Stack>
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <Divider color={"#FFFFFF"} />
          </>
        )}
        <Divider color={"#FFFFFF"} />
        {isAwUser && (
          <>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText onClick={() => clickHandler("/vehicles")}>
                  <Stack direction={"row"} spacing={2}>
                    <LocalShippingIcon style={{ color: "#FFFFFF" }} />
                    <Typography color={"#FFFFFF"}>Vehicles</Typography>
                  </Stack>
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <Divider color={"#FFFFFF"} />
          </>
        )}
        {isAwUser && (
          <>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText onClick={() => clickHandler("/clients")}>
                  <Stack direction={"row"} spacing={2}>
                    <BusinessIcon style={{ color: "#FFFFFF" }} />
                    <Typography color={"#FFFFFF"}>Client</Typography>
                  </Stack>
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <Divider color={"#FFFFFF"} />
          </>
        )}
        {isAwUser && (
          <>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText onClick={() => clickHandler("/service")}>
                  <Stack direction={"row"} spacing={2}>
                    <CardMembershipIcon style={{ color: "#FFFFFF" }} />
                    <Typography color={"#FFFFFF"}>Service</Typography>
                  </Stack>
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <Divider color={"#FFFFFF"} />
          </>
        )}
        {isAwUser && (
          <>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText onClick={() => clickHandler("/products")}>
                  <Stack direction={"row"} spacing={2}>
                    <CategoryIcon style={{ color: "#FFFFFF" }} />
                    <Typography color={"#FFFFFF"}>Products</Typography>
                  </Stack>
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <Divider color={"#FFFFFF"} />
          </>
        )}
        {isAwUser && (
          <>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText onClick={() => clickHandler("/device")}>
                  <Stack direction={"row"} spacing={2}>
                    <DeviceHubIcon style={{ color: "#FFFFFF" }} />
                    <Typography color={"#FFFFFF"}>Devices</Typography>
                  </Stack>
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <Divider color={"#FFFFFF"} />
          </>
        )}
        {isAwUser && (
          <>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText onClick={() => clickHandler("/reports")}>
                  <Stack direction={"row"} spacing={2}>
                    <AssessmentSharpIcon style={{ color: "#FFFFFF" }} />
                    <Typography color={"#FFFFFF"}>Reports</Typography>
                  </Stack>
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <Divider color={"#FFFFFF"} />
          </>
        )}
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText onClick={() => clickHandler("/about")}>
              <Stack direction={"row"} spacing={2}>
                <InfoIcon style={{ color: "#FFFFFF" }} />
                <Typography color={"#FFFFFF"}>About</Typography>
              </Stack>
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <Divider color={"#FFFFFF"} />
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText onClick={signOut}>
              <Stack direction={"row"} spacing={2}>
                <LogoutIcon style={{ color: "#FFFFFF" }} />
                <Typography color={"#FFFFFF"}>Sign Out</Typography>
              </Stack>
            </ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
      <Divider color={"#FFFFFF"} />
    </Drawer>
  );
}
