import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { PreconfigData } from "../../../utils/interfaces/clients/preconfiguration-review-table-interface";

const ReviewTable: React.FC<{ newPreconfigData: PreconfigData }> = ({
  newPreconfigData,
}) => {
  return (
    <div>
      <h2>Review of Preconfigured Data</h2>
      {/* Table for Name and Product */}
      <Table style={{ borderCollapse: "collapse", width: "100%" }}>
        <TableHead>
          <TableRow>
            <TableCell
              style={{
                border: "1px solid #000000",
                backgroundColor: "#f0f0f0",
                textAlign: "left",
              }}
            >
              Name
            </TableCell>
            <TableCell
              style={{
                border: "1px solid #000000",
                backgroundColor: "#f0f0f0",
                textAlign: "left",
              }}
            >
              Product
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell
              style={{ border: "1px solid #000000", textAlign: "left" }}
            >
              {newPreconfigData.name}
            </TableCell>
            <TableCell
              style={{ border: "1px solid #000000", textAlign: "left" }}
            >
              {newPreconfigData.type}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      {/* Table for Configuration */}
      <h2>Configuration</h2>
      <Table style={{ borderCollapse: "collapse", width: "100%" }}>
        <TableHead>
          <TableRow>
            <TableCell
              style={{
                border: "1px solid #000000",
                backgroundColor: "#f0f0f0",
                textAlign: "left",
              }}
            >
              Device Type
            </TableCell>
            <TableCell
              style={{
                border: "1px solid #000000",
                backgroundColor: "#f0f0f0",
                textAlign: "left",
              }}
            >
              Baudrate
            </TableCell>
            <TableCell
              style={{
                border: "1px solid #000000",
                backgroundColor: "#f0f0f0",
                textAlign: "left",
              }}
            >
              Weight Display
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell
              style={{ border: "1px solid #000000", textAlign: "left" }}
            >
              {newPreconfigData.configuration.device_type}
            </TableCell>
            <TableCell
              style={{ border: "1px solid #000000", textAlign: "left" }}
            >
              {newPreconfigData.configuration.baudrate}
            </TableCell>
            <TableCell
              style={{ border: "1px solid #000000", textAlign: "left" }}
            >
              {String(newPreconfigData.configuration.weight_display)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      {/* Table for Groups */}
      <h4>Groups</h4>
      {newPreconfigData.configuration.groups.map((group, index) => (
        <div key={index}>
          <h5>Group {index + 1}</h5>
          <Table style={{ borderCollapse: "collapse", width: "100%" }}>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    border: "1px solid #000000",
                    backgroundColor: "#f0f0f0",
                    textAlign: "left",
                  }}
                >
                  Group Name
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #000000",
                    backgroundColor: "#f0f0f0",
                    textAlign: "left",
                  }}
                >
                  Group Number
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  style={{ border: "1px solid #000000", textAlign: "left" }}
                >
                  {group.group_name}
                </TableCell>
                <TableCell
                  style={{ border: "1px solid #000000", textAlign: "left" }}
                >
                  {group.group_number}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          {/* Table for Sensor Ports */}
          <Table style={{ borderCollapse: "collapse", width: "100%" }}>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    border: "1px solid #000000",
                    backgroundColor: "#f0f0f0",
                    textAlign: "left",
                  }}
                >
                  Sensor Port
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #000000",
                    backgroundColor: "#f0f0f0",
                    textAlign: "left",
                  }}
                >
                  Type
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {group.sensor_ports.map((sensor_port, index) => (
                <TableRow key={index}>
                  <TableCell
                    style={{ border: "1px solid #000000", textAlign: "left" }}
                  >
                    {sensor_port.port}
                  </TableCell>
                  <TableCell
                    style={{ border: "1px solid #000000", textAlign: "left" }}
                  >
                    {sensor_port.type}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      ))}

      {/* Table for Calibration */}
      <h2>Calibration</h2>
      {newPreconfigData.calibration.map((calibration, index) => (
        <div key={index}>
          <h4>Axle Group {index + 1}</h4>
          <Table style={{ borderCollapse: "collapse", width: "100%" }}>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    border: "1px solid #000000",
                    backgroundColor: "#f0f0f0",
                    textAlign: "left",
                  }}
                >
                  Axle Group Name
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #000000",
                    backgroundColor: "#f0f0f0",
                    textAlign: "left",
                  }}
                >
                  Axle Group Number
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #000000",
                    backgroundColor: "#f0f0f0",
                    textAlign: "left",
                  }}
                >
                  Sensor Port
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #000000",
                    backgroundColor: "#f0f0f0",
                    textAlign: "left",
                  }}
                >
                  Empty ADC
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #000000",
                    backgroundColor: "#f0f0f0",
                    textAlign: "left",
                  }}
                >
                  Empty Weight
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #000000",
                    backgroundColor: "#f0f0f0",
                    textAlign: "left",
                  }}
                >
                  Heavy ADC
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #000000",
                    backgroundColor: "#f0f0f0",
                    textAlign: "left",
                  }}
                >
                  Heavy Weight
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {calibration.sensor_calibrations.map(
                (sensor_calibration, index) => (
                  <TableRow key={index}>
                    <TableCell
                      style={{ border: "1px solid #000000", textAlign: "left" }}
                    >
                      {calibration.axle_group_name}
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid #000000", textAlign: "left" }}
                    >
                      {calibration.axle_group_number}
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid #000000", textAlign: "left" }}
                    >
                      {sensor_calibration.sensor_port}
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid #000000", textAlign: "left" }}
                    >
                      {sensor_calibration.empty_adc}
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid #000000", textAlign: "left" }}
                    >
                      {sensor_calibration.empty_weight}
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid #000000", textAlign: "left" }}
                    >
                      {sensor_calibration.heavy_adc}
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid #000000", textAlign: "left" }}
                    >
                      {sensor_calibration.heavy_weight}
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </div>
      ))}
    </div>
  );
};

export default ReviewTable;
