import { Auth } from "aws-amplify";
import { buildBaseUrl } from "../build-base-url";
import { ProductListData } from '../../interfaces/products/products-list-interface';

export async function fetchSingleProductData(productId: string): Promise<ProductListData> {
  const url = buildBaseUrl()
  try {
    const authData = await Auth.currentAuthenticatedUser();
    const response = await fetch(`${url}products/${productId}`, {
      headers: {
        Authorization: `Bearer ${authData.signInUserSession.accessToken.jwtToken}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
