import { Auth } from "aws-amplify";
import {
	CreateputUser,
	User,
	getUser,
	putUser,
} from "../../interfaces/users/user-details-interfaces";
import { buildBaseUrl } from "../build-base-url";

export const putUserPermissions = async (
	username: string,
	user: User,
	updated_at: string
) => {
	const url = buildBaseUrl()
	const putUser: putUser = CreateputUser();
	putUser.updated_at = updated_at;
	putUser.data = user;
	const authData = await Auth.currentAuthenticatedUser();
	const response = await fetch(`${url}users/${username}`, {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${authData.signInUserSession.accessToken.jwtToken}`,
		},
		body: JSON.stringify(user),
	});

	let data = await response.json();
	return data;
};
