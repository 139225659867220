import { Card, View } from '@aws-amplify/ui-react';
import React from 'react';

interface AccountCreationStatusProps {
    isAccountCreated: boolean;
  }

  const AccountCreationStatus: React.FC<AccountCreationStatusProps> = ({ isAccountCreated }) => {
    return (
    <div style={{ paddingTop: "40px" }}>
      <View textAlign="center">
        <h3
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            marginBottom: "20px",
          }}
        >
          Account Creation Status
        </h3>
      </View>
      <Card
        style={{
          padding: "20px",
          border: "2px solid #e0e0e0",
          borderRadius: "10px",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          width: "600px",
          margin: "auto",
        }}
      >
        {isAccountCreated ? (
          <>
            <p
              style={{
                color: "green",
                fontWeight: "bold",
                fontSize: "26px",
                marginBottom: "10px",
              }}
            >
              <span
                style={{ display: "block", textAlign: "center" }}
              >{`Account Created Successfully`}</span>
            </p>
            <p
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "20px",
                marginBottom: "10px",
              }}
            >
              <span
                style={{ display: "block", textAlign: "center" }}
              >{`All the users created will be receiving an email on the registered email address with a temporary password for the first time they sign into their account.`}</span>
            </p>
          </>
        ) : (
          <>
            <p
              style={{
                color: "red",
                fontWeight: "bold",
                fontSize: "26px",
                marginBottom: "10px",
              }}
            >
              <span style={{ display: "block", textAlign: "center" }}>
                {"Error Creating Account"}
              </span>
            </p>
            <p
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "20px",
                marginBottom: "10px",
              }}
            >
              <span style={{ display: "block", textAlign: "center" }}>
                {"Error Creating Account"}
              </span>
              <span style={{ display: "block", textAlign: "center" }}>
                {"There was an error detected while creating the users. Please try the process again and ensure you have followed the template and guidelines to create bulk users carefully"}
              </span>
            </p>
          </>
        )}
      </Card>
    </div>
  );
};

export default AccountCreationStatus;
