import { Auth } from "aws-amplify";
import { dateToRfc } from "../../custom-functions/rfc3339-conversions";
import {
	DefaultWeightsReturnType,
	FormattedWeight,
	FormattedWeightswithData,
	UnformattedWeightsDataPoint,
} from "../../interfaces/vehicles/vehicles-interfaces";
import { buildBaseUrl } from "../build-base-url";

export const getVehicleWeights = async (
	vehicle_id: string | undefined,
	start_date?: string | undefined,
	end_date?: string | undefined
) => {
	const url = buildBaseUrl()
	if (!vehicle_id) {
		return;
	}
	let baseurl = `vehicles/${vehicle_id}/weights?`;
	if (start_date && end_date) {
		baseurl =
			baseurl +
			`start_time=${dateToRfc(start_date)}&end_time=${dateToRfc(end_date)}`;
	}
	try {
		const authData = await Auth.currentAuthenticatedUser();
		const response = await fetch(`${url}${baseurl}`, {
			headers: {
				Authorization: `Bearer ${authData.signInUserSession.accessToken.jwtToken}`,
			},
		});
		var data = await response.json() as DefaultWeightsReturnType;
		return formatgetVehicleWeights(data);
	} catch (err) {
		console.log(err);
	}
};

export function formatgetVehicleWeights(data: DefaultWeightsReturnType): [FormattedWeightswithData, DefaultWeightsReturnType] {
	let unformatteddata = data;
	var formattedWeights: FormattedWeight[] = unformatteddata.data.map((weight: UnformattedWeightsDataPoint, index: number) => {
		// Check if lat, lng, and weight are present
		if (!weight.latitude.received || !weight.longitude.received || !weight.weight.value.received)
			return undefined;

		return {
			number: index + 1,
			latitude: weight.latitude?.value,
			longitude: weight.longitude?.value,
			weight:weight.weight.value.value,
			date_time: weight.date_time ?? "Not Specified",
			snapshot: "go",
			more_details: "open",
		}
	}).filter((weight): weight is FormattedWeight => weight !== undefined);

	let vehicleData: FormattedWeightswithData = {
		data: formattedWeights,
	};

	return [vehicleData, data];
}
