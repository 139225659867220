import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  LinearProgress,
  Skeleton,
  Slide,
  Stack,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { getOneVehicle } from "../../utils/requests/vehicles-routes/get-one-vehicle";
import { useNavigate, useParams } from "react-router-dom";
import Details from "./vehicle-details-elements/details";
import Actions from "./vehicle-details-elements/actions";
import Status from "./vehicle-details-elements/status";
import WeightsAndLocations from "./vehicle-details-elements/weights-locations";
// Tickets future use
// import Tickets from "./vehicle-details-elements/tickets";
import { AppContext } from "../../utils/themes/theme-context";
import { errorResponse } from "../../utils/interfaces/error-interface";
import { Vehicle } from "../../utils/interfaces/vehicles/vehicles-interfaces";
import { getVehicleWeights } from "../../utils/requests/vehicles-routes/get-vehicle-weights";
import WeightsLocationsTab from "./weights-table-tab/weights-locations-tab";

export default function VehicleDetails() {
  var { theme } = useContext(AppContext);
  const [vehicle, setVehicle] = useState<Vehicle>();
  let { client_id, vehicle_id } = useParams();
  const [invalidUser, setInvalidUser] = useState<boolean>(false);
  const [transition, setTransition] = useState<boolean>(true);
  let userNavigate = useNavigate();
  const [activeButton, setActiveButton] = useState<string>("Home");
  const [displayDetailsTable, setDisplayDetailsTable] = useState<boolean>(true);
  const [displayWeights, setDisplayWeights] = useState<boolean>(false);
  const [isLoadingWeights, setIsLoadingWeights] = useState<boolean>(false);
  // Tickets future use
  // const [displayTickets, setDisplayTickets] = useState<boolean>(false);

  //single vehicle details
  useEffect(() => {
    fetchVehicle()
      .then((response: Vehicle) => {
        if (!response.data) {
          setInvalidUser(true);
        }
        setVehicle(response);
      })
      .catch((err: errorResponse) => {
        console.log(err);
        setInvalidUser(true);
      });
  }, []);

  //called in weightslocations and graphweights
  const fetchWeights = async () => {
    try {
      const response = await getVehicleWeights(vehicle_id);
      return response;
    } catch (err) {
      console.log(err);
    }
  };

  const fetchVehicle = async () => {
    try {
      const response = await getOneVehicle(vehicle_id);
      return response;
    } catch (err) {
      console.log(err);
      setInvalidUser(true);
    }
  };

  //return to all vehicles (vehicle dashboard)
  const handleBack = () => {
    setTransition(false);
    setTimeout(() => userNavigate(`/vehicles`), 400);
  };

  const loadingDisplay = () => {
    return (
      <>
        <LinearProgress />
        <Stack direction={"row"} spacing={3} marginTop={5}>
          <Stack direction={"column"} spacing={3}>
            <Skeleton variant="rectangular" width={275} height={500} />
            <Skeleton variant="rectangular" width={275} height={200} />
          </Stack>
          <Divider flexItem orientation="vertical" />
          <Stack direction={"column"} spacing={3}>
            <Skeleton variant="rectangular" width={700} height={200} />
            <Skeleton variant="rectangular" width={700} height={400} />
          </Stack>
          <Stack direction={"column"} spacing={3}>
            <Skeleton variant="rectangular" width={275} height={150} />
            <Skeleton variant="rectangular" width={275} height={100} />
          </Stack>
        </Stack>
      </>
    );
  };

  const handleButtonClick = async (buttonName: string) => {
    setActiveButton(buttonName);
    setDisplayDetailsTable(buttonName === "Home");
    setDisplayWeights(buttonName === "Weights");
    // Tickets future use
    // setDisplayTickets(buttonName === "Tickets");
  };

  return (
    <>
      <Slide direction="left" in={transition} timeout={400}>
        <Box flexGrow={1} marginLeft={"2%"} marginRight={"2%"} marginTop={10}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <h2>Vehicle Details</h2>
            </Grid>
            <Grid item xs={6}>
              <Box
                display="flex"
                justifyContent="flex-end"
                marginTop={3}
                marginRight={2}
              >
                <Button
                  color={theme.backbuttoncolor}
                  onClick={handleBack}
                  variant={"contained"}
                >
                  Back
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Divider />
          <Grid>
            <Box
              display="flex"
              justifyContent="flex-start"
              marginTop={1}
              marginBottom={1}
            >
              <Button
                variant="contained"
                style={{
                  marginRight: "10px",
                  background:
                    activeButton === "Home"
                      ? theme.selectedbuttoncolor
                      : theme.nonactivebuttoncolor,
                }}
                onClick={() => handleButtonClick("Home")}
              >
                Home
              </Button>
              <Button
                variant="contained"
                style={{
                  marginRight: "10px",
                  background:
                    activeButton === "Weights"
                      ? theme.selectedbuttoncolor
                      : theme.nonactivebuttoncolor,
                }}
                onClick={() => handleButtonClick("Weights")}
              >
                Weights{" "}
                {isLoadingWeights ? (
                  <CircularProgress
                    size={20}
                    color="inherit"
                    style={{ marginLeft: "5px" }}
                  />
                ) : null}
              </Button>
              {/* Tickets future use */}
              {/* <Button
                variant="contained"
                style={{
                  marginRight: "10px",
                  background:
                    activeButton === "Tickets"
                      ? theme.selectedbuttoncolor
                      : theme.nonactivebuttoncolor,
                }}
                onClick={() => handleButtonClick("Tickets")}
              >
                Tickets
              </Button> */}
            </Box>
          </Grid>
          <Divider />
          {invalidUser ? (
            <Box padding={2}>
              <Alert severity="error">Invalid Vehicle</Alert>
            </Box>
          ) : vehicle && displayDetailsTable ? (
            <>
              <Stack
                direction={"row"}
                spacing={3}
                marginTop={5}
                marginBottom={10}
              >
                <Stack direction={"column"} spacing={3}>
                  <Details vehicle={vehicle} />
                  <Actions />
                </Stack>
                <Divider flexItem orientation="vertical" />
                <Stack direction={"column"} spacing={3}>
                  <Status vehicle={vehicle} />
                  <WeightsAndLocations fetchWeights={fetchWeights} />
                </Stack>
                {/* Tickets future use */}
                {/* <Divider flexItem orientation="vertical" />
                <Stack direction={"column"} spacing={3} minWidth={700}> */}
                  {/* <Tickets /> */}
                  {/* <AlertsFaults fetchWeights={fetchWeights} />  weights over time */}
                {/* </Stack> */}
              </Stack>
            </>
          ) : !isLoadingWeights && displayWeights ? (
            <>
              <Stack direction={"column"} spacing={3} marginBottom={10} marginTop={6}>
                  <WeightsLocationsTab fetchWeights={fetchWeights} />
                </Stack>
            </>
          ) 
          // Tickets future use
          // : displayTickets ? (
          //   <Box padding={2}>
          //     <Typography variant="h4">Tickets Data Display</Typography>
          //   </Box>
          // ) 
          : (
            loadingDisplay()
          )}
        </Box>
      </Slide>
    </>
  );
}
