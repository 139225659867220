//For SignUp Page No Authentication necessasry
import { PostNewUserRegistration } from "../../interfaces/new-user-registration/new-user-registration-post-interface";
import { buildBaseUrl } from "../build-base-url";

export const postNewUserRegistration = async (newUserRegistration: PostNewUserRegistration) => {
 try {
    const url = buildBaseUrl();
    const response = await fetch(`${url}users/new`, {
      method: "POST",
      body: JSON.stringify(newUserRegistration),
    });

    if (!response.ok) {
      throw new Error("Failed to register user.");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    throw new Error("An error occurred during user registration.");
  }
};