import { Alert, AlertTitle, Box, Divider, LinearProgress, Skeleton, Stack } from "@mui/material";
import IntegrationStatus from "./dashboard-elements/integration-status-card";
import UnitsAndErrors from "./dashboard-elements/units-and-errors-card";
import { useEffect, useState } from "react";
import { getDashboardStats } from "../../utils/requests/dashboard-page/get-dashboard-page-stats";
import { DashboardStats } from "../../utils/interfaces/dashboard/dashboard-stats-interface";
import DashBoardStatistics from "./dashboard-elements/dashboardstats-card";
import ProductList from "../product-page/products";
import ProductDashboardCard from "./dashboard-elements/products-dashboard-card";

function Dashboard() {
  const [dashboardstats, setDashboardStats] = useState<DashboardStats | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchDashboardStats = async () => {
      try {
        setLoading(true);
        const data = await getDashboardStats();
        if (data) {
          setDashboardStats(data);
          setErrorMessage("");
        } else {
          setErrorMessage("Failed to fetch dashboard stats");
        }
      } catch (error: any) {

        if (error.response) {
          switch (error.response.status) {
            case 400:
              setErrorMessage("Bad Request - Invalid request or bad data.");
              break;
            case 401:
              setErrorMessage("Unauthorized - User lacks permission to access the resource.");
              break;
            case 403:
              setErrorMessage("Forbidden - User lacks permission to access the resource.");
              break;
            case 500:
              setErrorMessage("Server Error - Server encountered an issue processing the request.");
              break;
            default:
              setErrorMessage("An unexpected error occurred. Please try again later.");
              break;
          }
        } else if (error.request) {
          setErrorMessage("No response received. Please check your network connection.");
        } else {
          setErrorMessage("Error setting up the request. Please try again later.");
        }
      }
      finally {
        setLoading(false); // Set loading to false whether fetch succeeds or fails
      }
    };

    fetchDashboardStats();
  }, []);

  const loadingDisplay = () => {
    return (
      <>
        <LinearProgress />
        <Stack direction="row" spacing={3} marginTop={5}>
          <Stack direction="column" spacing={3}>
          <Skeleton variant="rectangular" width={700} height={800} />
          </Stack>
          <Divider flexItem orientation="vertical" />
          <Stack direction="column" spacing={3}>           
          <Skeleton variant="rectangular" width={800} height={200} />
          </Stack>
        </Stack>
      </>
    );
  }

  return (
    <>
      <Box flexGrow={1} marginLeft={"5%"} marginRight={"5%"} marginTop={10}>
        <h2>Dashboard</h2>
        <Divider />
        <>
          {loading ? (loadingDisplay()) : dashboardstats ? (
            <Stack direction="row" spacing={5} marginTop={5} marginBottom={10}>
              <Stack direction="column" spacing={2} flex={1}>
                <Stack direction="column" spacing={2}>
                <ProductDashboardCard dashboardStats={dashboardstats} />
                </Stack>
              </Stack>
              <Divider flexItem orientation="vertical" />
              <Stack direction="column" spacing={2} flex={1}>
                <Stack direction="row" spacing={2}>
                  <div style={{ flex: 1 }}>
                    <DashBoardStatistics dashboardStats={dashboardstats} />
                  </div>
                </Stack>
                {/* <UnitsAndErrors /> */}
              </Stack>
            </Stack>
          ) : (
            <Alert variant="filled" severity="warning">
              <AlertTitle>No Stats available</AlertTitle>
            </Alert>
          )}
        </>
      </Box>
    </>
  );
}

export default Dashboard;
