import DetailsTable from "../../../utils/generic-components/Details Table/details-table";
import { DetailTableData } from "../../../utils/interfaces/details-table/details-table-interfaces";
import {
  rfcToDateAndTime,
} from "../../../utils/custom-functions/rfc3339-conversions";
import {
  ProductListData,
} from "../../../utils/interfaces/products/products-list-interface";
import { putUpdatedProductDetails } from "../../../utils/requests/products/put-updated-product-details";
import { fetchSingleProductData } from "../../../utils/requests/products/get-single-product";

export default function Details(props: { products: ProductListData, allProducts: ProductListData[] }) {

  const checkProductNameUnique = (name: string, currentProductName: string): boolean => {
    return !props.allProducts.some(
      (product) => product.name.toLowerCase() === name.toLowerCase() && product.name !== currentProductName
    );
  };

  // Validate and set errors in DetailTableData
  const validateData = (data: DetailTableData): DetailTableData => {
    const currentProductName = props.products.name; // Current product's name

    const updatedData = data.data.map((item) => {
      let error = "";
      if (item.label === "Product Name" && !checkProductNameUnique(item.content, currentProductName)) {
        item.error = "Product name must be unique.";
      } else if (item.content === "" && item.editable) {
        item.error = "Field is required.";
      } else {
        item.error = "";
      }
      return item;
    });

    return { ...data, data: updatedData };
  };

  let DetailTableData: DetailTableData = {
    data: [
      {
        label: "Product Name",
        content: props.products.name,
        editable: true,
        error: "",
      },
      {
        label: "Release Date",
        content: rfcToDateAndTime(props.products.release_date),
        editable: false,
        error: "",
      },
      {
        label: "Product Serial Identification",
        content: props.products.serial_identifier,
        editable: false,
        error: "",
      },
      {
        label: "Comments",
        content: props.products.comments,
        editable: true,
        error: "",
      },
    ],
  };

  //extend functionality in the future
  const putCallback = async (
    data: DetailTableData,
    setAlert: Function,
    setEditingMode: Function,
    setLoadingButton: Function
  ) => {
    // Validate data and set errors
    const validatedData = validateData(data);

    // Check for errors before proceeding
    if (validatedData.data.some(item => item.error !== "")) {
      setAlert("Please fix the errors before saving.");
      setLoadingButton(false);
      return;
    }

    const productName = validatedData.data.find(item => item.label === "Product Name")?.content;

    try {
      const latestProductsData = await fetchSingleProductData(
        props.products.id
      );

      const updatedProductDetails = {
        name: productName !== null ? productName : "",
        comments: data.data[3].content !== null ? data.data[3].content : "",
        updated_at: props.products.updated_at, // Include the updated_at value passed from the prop
      };

      // Include the updated_at value from the latest client data
      updatedProductDetails.updated_at = latestProductsData.updated_at;

      try {
        const response = await putUpdatedProductDetails(updatedProductDetails, props.products.id);

        if (response && response.data) {
          const fetchedUpdatedProducts = response.data;

          const isUpdated =
            fetchedUpdatedProducts.name === updatedProductDetails.name &&
            fetchedUpdatedProducts.comments === updatedProductDetails.comments;
          if (isUpdated) {
            setEditingMode(false);
            setAlert(""); // Clear the alert on success
          }
        } else {
          console.log("Error updating product data");
        }
      } catch (error) {
        console.log("Error updating product data:", error);
        setAlert("Unable to save, Try Again");
      }
    } catch (error) {
      console.log("Error fetching latest product data:", error);
      setAlert("Unable to save, Try Again");
    }

    setEditingMode(false);
    setLoadingButton(false);
  };

  return (
    <>
      <DetailsTable
        data={DetailTableData}
        putCallback={putCallback}
        editing={true}
        close={false}
      />
    </>
  );
}
