export function buildBaseUrl() {
	if (process.env.REACT_APP_USER_BRANCH) {
		switch (process.env.REACT_APP_USER_BRANCH) {
			// case "dev":
			// 	return "https://bltso66ba5.execute-api.us-west-2.amazonaws.com/dev/";
			case "dev":
				return "https://awcloudapi.air-weigh.com/dev/";
			// case "prod":
			// 	return "https://bltso66ba5.execute-api.us-west-2.amazonaws.com/prod/";
			case "prod":
				return "https://awcloudapi.air-weigh.com/prod/";
			// case "uat":
			// 	return "https://bltso66ba5.execute-api.us-west-2.amazonaws.com/uat/";
			case "uat":
				return "https://awcloudapi.air-weigh.com/uat/";
			default:
				break;
		}
	}
	return "https://awcloudapi.air-weigh.com/dev/";
	// return "http://localhost:8080/";
}
