import {
  Box,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Menu,
  MenuItem,
  Skeleton,
  Slide,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Columns } from "../../utils/interfaces/table/table-interfaces";
import GeneralTable from "../../utils/generic-components/table/table";
import { useNavigate, useParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Integration,
  IntegrationResponse,
} from "../../utils/interfaces/integration/integration-interface";
import { getIntegration } from "../../utils/requests/integraton/get-integration";

export default function IntegrationList() {
  const [data, setData] = useState<Integration[] | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const [transitionout, setTransitionOut] = useState<boolean>(true);
  let userNavigate = useNavigate();
  const [anchorEls, setAnchorEls] = useState<Array<null | HTMLElement>>([]); //controls the position of the actions menu
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedIntegrator, setSelectedIntegrator] =
    useState<Integration | null>(null);

  //Action cell Icon and options
  const renderActionsCell = (rowData: any, index: number) => {
    return (
      <>
        <IconButton
          aria-label="actions"
          aria-controls={`actions-menu-${rowData.id}`}
          aria-haspopup="true"
        >
          <MoreHorizIcon />
        </IconButton>
        <Menu
          id={`actions-menu-${rowData.id}`}
          anchorEl={anchorEls[index]} //This attribute specifies the element that the menu should be anchored to. It uses the anchorEls state array to control the position of the menu.
          open={Boolean(anchorEls[index])} //This attribute determines whether the menu should be open or closed based on the value in the anchorEls array.
          //anchorEls[index] is null/not null. Boolean(someValue) returns true if someValue is truthy (not null, undefined, false, 0, or an empty string), and false otherwise.
        >
          <MenuItem>
            <EditIcon /> Edit
          </MenuItem>
          <MenuItem>
            <DeleteIcon /> Delete
          </MenuItem>
        </Menu>
      </>
    );
  };

  let columns: Columns = {
    Name: {
      type: "link",
      display: "Name",
      data: "",
      sort: "Name",
    },
    IntegrationType: "Integration Type",
    ContactEmail: {
      type: "text",
      display: "Contact Email",
      data: "contact_email",
      sort: "ContactEmail",
    },
    ContactPhone: {
      type: "text",
      display: "Contact Phone",
      data: "contact_phone",
      sort: "ContactPhone",
    },
    Comments: {
      type: "text",
      display: "Comments",
      data: "comments",
      sort: "Comments",
    },
    UpdatedAt: {
      type: "time",
      display: "Updated At",
      data: "updated_at",
      sort: "UpdatedAt",
    },
    IntervalsDateandTime: {
      type: "time",
      display: "Last Interval",
      data: "date_time",
      sort: "IntervalsDateandTime",
    },
    // Intervals: {
    //   type: "subhead",
    //   display: "Intervals",
    //   data: "intervals",
    //   subColumns: {
    //     UpdatedAt: {
    //       type: "time",
    //       display: "Updated At",
    //       data: "updated_at", // Access nested data
    //     },
    //     DateTime: {
    //       type: "time",
    //       display: "Date Time",
    //       data: "date_time",
    //       sort: "DateTime",
    //     },
    //     IntegratorId: {
    //       type: "text",
    //       display: "Integrator Id",
    //       data: "integrator_id",
    //       sort: "IntegratorId",
    //     },
    //     ClientId: {
    //       type: "text",
    //       display: "Client Id",
    //       data: "client_id",
    //       sort: "ClientId",
    //     },
    //     Success: {
    //       type: "text",
    //       display: "Success",
    //       data: "success",
    //       sort: "Success",
    //     },
    //     ErrorMessage: {
    //       type: "text",
    //       display: "Error Message",
    //       data: "error_message",
    //       sort: "ErrorMessage",
    //     },
    //     TotalUnits: {
    //       type: "number",
    //       display: "Total Units",
    //       data: "total_units",
    //       sort: "TotalUnits",
    //     },
    //     UpdatedUnits: {
    //       type: "number",
    //       display: "Updated Units",
    //       data: "updated_units",
    //       sort: "UpdatedUnits",
    //     },
    //     NewUnits: {
    //       type: "number",
    //       display: "New Units",
    //       data: "new_units",
    //       sort: "NewUnits",
    //     },
    //     StaleUnits: {
    //       type: "number",
    //       display: "Stale Units",
    //       data: "stale_units",
    //       sort: "StaleUnits",
    //     },
    //     geotab_interval: {
    //       type: "subhead",
    //       display: "GeoTab Intervals",
    //       data: "geotab_interval",
    //       subColumns: {
    //         GrossFeed: {
    //           type: "text",
    //           display: "Gross Feed",
    //           data: "gross_feed",
    //           sort: "GrossFeed",
    //         },
    //         GrossQualityFeed: {
    //           type: "text",
    //           display: "Gross Quality Feed",
    //           data: "gross_quality_feed",
    //           sort: "GrossQualityFeed",
    //         },
    //         NetFeed: {
    //           type: "text",
    //           display: "Net Feed",
    //           data: "net_feed",
    //           sort: "NetFeed",
    //         },
    //         SteerFeed: {
    //           type: "text",
    //           display: "Steer Feed",
    //           data: "steer_feed",
    //           sort: "SteerFeed",
    //         },
    //         DriveFeed: {
    //           type: "text",
    //           display: "Drive Feed",
    //           data: "drive_feed",
    //           sort: "DriveFeed",
    //         },
    //         TrailerFeed: {
    //           type: "text",
    //           display: "Trailer Feed",
    //           data: "trailer_feed",
    //           sort: "TrailerFeed",
    //         },
    //         DriveSteerQualityFeed: {
    //           type: "text",
    //           display: "Drive Steer Quality Feed",
    //           data: "drive_steer_quality_feed",
    //           sort: "DriveSteerQualityFeed",
    //         },
    //         TrailerQualityFeed: {
    //           type: "text",
    //           display: "Trailer Quality Feed",
    //           data: "trailer_quality_feed",
    //           sort: "TrailerQualityFeed",
    //         },
    //       },
    //     },
    //   },
    // },
    // Actions: {
    //   type: "actions",
    //   display: "Actions",
    //   render: renderActionsCell,
    // },
  };

  useEffect(() => {
    const fetchIntegrators = async () => {
      setLoading(true);
      try {
        const response = await getIntegration();
        setData(response.data);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };

    fetchIntegrators();
  }, []);

  function handleNameRowClick(selectedIntegrator: Integration) {
    setTransitionOut(false);
    setTimeout(
      () => userNavigate(`/integrators/${selectedIntegrator.id}`),
      400
    );
  }

  function handleRowClick(selectedIntegrator: Integration) {
    setSelectedIntegrator(selectedIntegrator);
    setDialogOpen(true);
  }

  function handleCloseDialog() {
    setSelectedIntegrator(null);
    setDialogOpen(false);
  }

  function handleNewTabRowClick(selectedIntegrator: Integration) {
    // setTransitionOut(false);
    window.open(`/integrators/${selectedIntegrator.id}`, "_blank");
  }

  const geotabintervalcellstyle = {
    width: "46%", // Adjust the width as needed
  };

  return (
    <>
      <Slide direction="right" in={transitionout} timeout={400}>
        <Box
          flexGrow={1}
          marginLeft={"5%"}
          marginRight={"5%"}
          marginTop={10}
          marginBottom={10}
        >
          <h2>Integration Dashboard</h2>
          <Divider />
          <Grid item xs={4} width="40%">
            <Box paddingTop={2}>
              <TextField
                type="text"
                placeholder="Search by any field"
                variant="outlined"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" aria-label="search">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>
          {data ? (
            <GeneralTable
              data={data.map((integrator) => ({
                id: integrator.id,
                Name: integrator.name,
                IntegrationType: integrator.integration_type,
                Contact: integrator.contact,
                ContactEmail: integrator.contact_email,
                ContactPhone: integrator.contact_phone,
                Comments: integrator.comments,
                UpdatedAt: integrator.updated_at,
                IntervalsDateandTime:
                  integrator.intervals && integrator.intervals.length > 0
                    ? integrator.intervals[0].date_time
                    : null,
                // Intervals: (
                //   <>
                //     {/* "DETAILS" link to open the dialog */}
                //     <span
                //       style={{ color: "blue", cursor: "pointer" }}
                //       onClick={() => handleRowClick(integrator)}
                //     >
                //       DETAILS
                //     </span>
                //     <Box>
                //       <Dialog
                //         open={dialogOpen}
                //         onClose={handleCloseDialog}
                //         PaperProps={{ style: { maxWidth: "98vw" } }}
                //       >
                //         <DialogTitle>
                //           Intervals Details{" "}
                //           <IconButton
                //             aria-label="close"
                //             onClick={handleCloseDialog}
                //             sx={{
                //               position: "absolute",
                //               right: 2,
                //               top: 2,
                //               color: (theme) => theme.palette.grey[500],
                //             }}
                //           >
                //             <CloseIcon />
                //           </IconButton>
                //         </DialogTitle>
                //         <DialogContent>
                //           {/* Display the NestedTable in the dialog */}
                //           <NestedTable
                //             data={selectedIntegrator?.intervals || []}
                //             columns={columns.intervals?.subColumns}
                //             cellStyles={{
                //               geotab_interval: geotabintervalcellstyle,
                //             }}
                //           />
                //         </DialogContent>
                //         <DialogActions>
                //           <Button onClick={handleCloseDialog}>Close</Button>
                //         </DialogActions>
                //       </Dialog>
                //     </Box>
                //   </>
                // ),

                // (
                //   <NestedTable
                //     data={
                //       integrator?.Intervals!.length > 0
                //         ? // data[0]?.Intervals
                //           [integrator?.Intervals![0]]
                //         : []
                //     }
                //     columns={columns.Intervals.subColumns}
                //   />
                // ),
              }))}
              columns={columns}
              loading={loading}
              noDataMessage={"Unable To Fetch Integrations"}
              rowClickCallback={handleNameRowClick}
              rows={10}
              rowsoptions={[10, 25, 50, 100]}
              rowNewTabClickCallback={handleNewTabRowClick}
            />
          ) : (
            <>
              <LinearProgress />
              <Stack spacing={2}>
                <Skeleton variant="rectangular" height={50} animation="wave" />
                <Skeleton variant="rectangular" height={50} animation="wave" />
                <Skeleton variant="rectangular" height={50} animation="wave" />
              </Stack>
            </>
          )}
        </Box>
      </Slide>
    </>
  );
}
