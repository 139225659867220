// *****************************************************************************//
// For delete devices tokencopy isvery implements. it is inthe text field to be 
// copied. So if hte token message changes hte tokencopy code will change
// ****************************************************************************//

import { useState, useContext, useEffect, useRef } from "react";
import {
    Button,
    Typography,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    Stepper,
    Step,
    StepLabel,
    Box,
    Table,
    TableBody,
    TableCell,
    TableRow,
    CircularProgress,
    IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../../utils/themes/theme-context";
import { DevicesDetails } from "../../../../utils/interfaces/vehicles/devices-details-interface";
import { deleteDevice, deleteDeviceToken } from "../../../../utils/requests/devices/delete-device";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { DeleteDeviceInterface } from "../../../../utils/interfaces/devices/registration-device-interface";

export default function DeleteDevice(props: { device: DevicesDetails; open: boolean; onClose: () => void }) {
    const { theme } = useContext(AppContext);
    const [activeStep, setActiveStep] = useState(0);
    const [serialNumber, setSerialNumber] = useState("");
    const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(true);
    const [serailNumberError, setSerialNumberError] = useState("");
    const [deleteResult, setDeleteResult] = useState("");
    const [responseforToken, setResponseforToken] = useState<string>("");
    const [deleteInProgress, setDeleteInProgress] = useState(false);
    const [deleteResultColor, setDeleteResultColor] = useState("");
    let userNavigate = useNavigate();
    const [success, setSuccess] = useState(false);
    const [deletionTime, setDeletionTime] = useState<string | null>(null);
    const [token, setToken] = useState("");
    const [copiedToken, setCopiedToken] = useState("");
    const [isHovered, setIsHovered] = useState(false);

    const closeDeleteDialog = () => {
        // Only allow closing if deletion is not successful
        if (!success) {
            props.onClose();
            // Clear the text field when the dialog is closed
            setSerialNumber("");
            setDeleteResult("");
        }
    };

    const handleSerialNumberChange = (event: { target: { value: any } }) => {
        const inputSerialNumber = event.target.value;
        setSerialNumber(inputSerialNumber);

        // Enable the Delete button only when inputSerialNumber matches the device's serial number
        if (inputSerialNumber === props.device.serial) {
            setIsDeleteButtonDisabled(false);
            setSerialNumberError("");
        } else {
            setIsDeleteButtonDisabled(true);
            setSerialNumberError("Serial Number does not match");
        }
    };

    const handleDelete = async () => {
        setDeleteInProgress(true);
        try {

            const response = await deleteDevice(props.device.id);
            // Update the result message based on success or failure
            if (response === "success") {
                setSuccess(true);
                setDeleteResult("Device was successfully deleted");
                setDeleteResultColor("green");

                // Set the deletion time
                const now = new Date();
                setDeletionTime(now.toLocaleString());
            } else {
                setDeleteResult(`${response}`);
                // setResponseforToken(response)
                setDeleteResultColor("red");
            }
        } catch (error) {
            setDeleteResult("An error occurred while making the request");
            setDeleteResultColor("red");
        } finally {
            setDeleteInProgress(false); // Reset delete operation status
        }

        setActiveStep(2);
    };

    const handleTokenChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setToken(event.target.value);
    };

    const handleFinalDelete = async () => {
        setDeleteInProgress(true);
        try {
            // Define the DeleteDeviceInterface object
            const deleteDeviceData: DeleteDeviceInterface = {
                overwrite: token, // Use the token as the value for the overwrite property
            };

            const response = await deleteDeviceToken(`${props.device.id}`, deleteDeviceData.overwrite);
            if (response === "success") {
                setSuccess(true);
                setDeleteResult("Device was successfully deleted");
                setDeleteResultColor("green");
                const now = new Date();
                setDeletionTime(now.toLocaleString());
            } else {
                //status check 400 and bad user error
                setDeleteResult(`Error deleting device: ${response}`);
                setDeleteResultColor("red");
            }
        } catch (error) {
            setDeleteResult("An error occurred while making the request");
            setDeleteResultColor("red");
        } finally {
            setDeleteInProgress(false);
        }

        setActiveStep(2);
    };

    // Access the message from the array
    const errorMessage = deleteResult.split(".")[0].trim();

    // Extract the portion after "token: "
    const tokenPart = errorMessage.split("token: ")[1]?.trim();

    // Further split the tokenPart to remove any trailing part after the token
    const tokencopy = tokenPart?.split(" ")[0]?.trim();

    // Function to copy token to clipboard
    const copyTokenToClipboard = () => {
        navigator.clipboard.writeText(tokencopy);
        setCopiedToken(tokencopy);
    };
    return (
        <>
            {/* Delete Device Confirmation Dialog */}
            <Dialog open={props.open} onClose={closeDeleteDialog}>
                <DialogTitle variant="h4" align="center">Delete Device</DialogTitle>
                <DialogContent>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        <Step>
                            <StepLabel>Confirmation</StepLabel>
                        </Step>
                        <Step>
                            <StepLabel>Enter Serial Number</StepLabel>
                        </Step>
                        <Step>
                            <StepLabel>Result</StepLabel>
                        </Step>
                    </Stepper>
                    <Divider style={{ marginTop: "15px" }} />
                    {activeStep === 0 && (
                        <Box sx={{ paddingY: 3, paddingX: 5, borderRadius: 8, textAlign: 'center' }}>
                            <Typography paddingTop={2}>
                                This is an irreversible action. We cannot retrieve the device
                                after it is deleted.
                            </Typography>
                            <Typography paddingTop={2}>
                                This action can cause serious consequences like deletion of
                                other important details linked to this device
                            </Typography>
                            <Typography color="red" paddingTop={2}>
                                Are you sure you want to delete this device?
                            </Typography>
                            <Box mt={2} display="flex" justifyContent="space-between">
                                <Button
                                    onClick={closeDeleteDialog}
                                    variant="contained"
                                    color={theme.nextbuttoncolor}
                                >
                                    No
                                </Button>
                                <Button
                                    onClick={() => setActiveStep(1)}
                                    variant="contained"
                                    color={theme.backbuttoncolor}
                                >
                                    Yes
                                </Button>
                            </Box>
                        </Box>
                    )}
                    {activeStep === 1 && (
                        <div>
                            <Typography
                                paddingTop={3}
                                paddingBottom={2}
                                paddingLeft={2}
                                paddingRight={2}
                            >
                                Enter Serial Number of the device you wish to delete:
                            </Typography>
                            <TextField
                                label="Enter Serial Number"
                                variant="outlined"
                                fullWidth
                                required
                                value={serialNumber}
                                onChange={handleSerialNumberChange}
                                error={serailNumberError !== ""}
                                helperText={serailNumberError}
                            />
                            <Typography color="red" align="center" padding={3}>
                                Are you sure you want to delete this device?
                            </Typography>
                            <Box mt={2} justifyContent="space-between" display="flex">
                                {deleteInProgress ? (
                                    <CircularProgress /> // Show loading indicator
                                ) : (
                                    <>
                                        <Button
                                            onClick={closeDeleteDialog}
                                            variant="contained"
                                            color={theme.nextbuttoncolor}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            onClick={handleDelete}
                                            color={theme.backbuttoncolor}
                                            variant="contained"
                                            disabled={isDeleteButtonDisabled}
                                        >
                                            Delete
                                        </Button>
                                    </>
                                )}
                            </Box>
                        </div>
                    )}
                    {activeStep === 2 && (
                        <div>
                            {success ? (
                                <>
                                    <Typography padding={2} variant="h5" color={deleteResultColor} align="center"><b>{deleteResult}</b></Typography>
                                    <Box
                                        mt={1}
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="center"
                                    >
                                        {/* Receipt information */}
                                        <Box mt={1}>
                                            <Typography align="center" fontSize={22}>
                                                <strong>Details of Device deleted:</strong>
                                            </Typography>
                                            <Table>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>Device Serial Number:</TableCell>
                                                        <TableCell>{props.device.serial}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Device UUID:</TableCell>
                                                        <TableCell>{props.device.id}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Time of Deletion:</TableCell>
                                                        <TableCell>{deletionTime}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </Box>
                                        <Box mt={2} padding={3}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => userNavigate(`/device`)}
                                            >
                                                Go to Devices Page
                                            </Button>
                                        </Box>
                                    </Box></>
                            )
                                : (
                                    <>
                                        <Typography padding={2} variant="h5" color={"red"} align="center"><b>Error Deleting the Device.</b></Typography>
                                        {deleteResult && deleteResult.includes(":") ? (
                                            <div>
                                                <Typography padding={2} fontSize={20} color={"red"}>{errorMessage}</Typography>
                                                <Typography padding={2} variant="h6" color={"red"}>This device has Calibration and Configuratoin details attached. Deleting this device will delete these resources.</Typography>
                                                <Box display="flex" alignItems="center" gap={2}>
                                                    <TextField
                                                        variant="outlined"
                                                        label="Token"
                                                        value={copiedToken || tokencopy}
                                                        fullWidth
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                    />
                                                    <IconButton
                                                        onClick={copyTokenToClipboard}
                                                        aria-label="Copy Token"
                                                        color="inherit"
                                                        onMouseEnter={() => setIsHovered(true)}
                                                        onMouseLeave={() => setIsHovered(false)}
                                                        style={{ cursor: "pointer", backgroundColor: isHovered ? "lightgrey" : "transparent" }}
                                                    >
                                                        <FileCopyIcon />
                                                    </IconButton>
                                                </Box>
                                                <Typography padding={2} variant="h6">If you want to delete the device with its resources,</Typography>
                                                <Typography paddingLeft={2} paddingRight={2} paddingBottom={2} variant="h6"><i>Copy the above token and Paste it below to make the final delete for this device</i></Typography>
                                                <Box mt={2} justifyContent="center" display="flex" flexDirection="column" alignItems="center">
                                                    <Typography variant="h6" align="center" gutterBottom>
                                                        Enter the token to override the deletion:
                                                    </Typography>
                                                    <Box width="100%" mb={2} paddingBottom={2}>
                                                        <TextField
                                                            label="Enter Token"
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            value={token}
                                                            onChange={handleTokenChange}
                                                        />
                                                    </Box>
                                                    {deleteInProgress ? (
                                                        <CircularProgress /> // Show loading indicator
                                                    ) : (
                                                        <Box width="100%" display="flex" justifyContent="space-between">
                                                            <Button
                                                                onClick={closeDeleteDialog}
                                                                variant="contained"
                                                                color={theme.nextbuttoncolor}
                                                            >
                                                                Cancel
                                                            </Button>
                                                            <Button
                                                                onClick={handleFinalDelete}
                                                                color={theme.backbuttoncolor}
                                                                variant="contained"
                                                            >
                                                                Final Delete
                                                            </Button>
                                                        </Box>
                                                    )}
                                                </Box>
                                            </div>
                                        ) : (
                                            <Typography padding={1} fontSize={20} color={"red"}>{deleteResult}</Typography>
                                        )}
                                    </>
                                )}
                        </div>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
}
