import { Alert, AlertTitle, Backdrop, Box, Divider } from "@mui/material";

function NoAccess() {
  return (
    <>
      <Box
        sx={{ flexGrow: 1, marginLeft: "5%", marginRight: "5%", marginTop: 10 }}
      >
        <Backdrop open={true}>
          <Box sx={{ marginLeft: 40 }}>
            <Alert severity="error">
              <AlertTitle sx={{ fontSize: "1.5rem" }}>
                You do not have Permission to view this page
              </AlertTitle>
            </Alert>
          </Box>
        </Backdrop>
      </Box>
    </>
  );
}

export default NoAccess;
