import { Auth } from "aws-amplify";
import { buildBaseUrl } from "../build-base-url";

export const getVehicles = async (
) => {
	const url = buildBaseUrl()
	let baseurl = `vehicles`;
	const authData = await Auth.currentAuthenticatedUser();
	const response = await fetch(`${url}${baseurl}`, {
		headers: {
			Authorization: `Bearer ${authData.signInUserSession.accessToken.jwtToken}`,
		},
	});
	var data = await response.json();
	return data;
};