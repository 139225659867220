import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  LinearProgress,
  Skeleton,
  Slide,
  Stack,
  Typography,
} from "@mui/material";
import { AppContext } from "../../utils/themes/theme-context";
import Actions from "./product-details-elements/actions";
import Details from "./product-details-elements/details";
import AlertsAndFaults from "./product-details-elements/alerts-faults";
import RecentFirmwareVersions from "./product-details-elements/recent-firmware-versions";
import { fetchSingleProductData } from "../../utils/requests/products/get-single-product";
import {
  ProductListData,
} from "../../utils/interfaces/products/products-list-interface";
import ProductPrecalsCard from "./product-details-elements/products-precals-card";
import DevicesTablList from "./device-table-tab-products/devices-tab-list";
import ActiveDevicesProducts from "./product-details-elements/active-devices";
import { getProducts } from "../../utils/requests/products/get-products";
import ProductPageStats from "./product-details-elements/stats";

export function SingleProductDetailsPage() {
  var { theme } = useContext(AppContext);
  const { id } = useParams<{ id?: string }>();
  let userNavigate = useNavigate();
  const [transition, setTransition] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const [activeButton, setActiveButton] = useState<string>("Home");
  const [displayDetailsTable, setDisplayDetailsTable] = useState<boolean>(true);
  const [displayDevices, setDisplayDevices] = useState<boolean>(false);
  const [displaysetDisplayFirmwareVersions, setDisplayFirmwareVersions] =
    useState<boolean>(false);
  const [singleProductDetailsData, setsingleProductDetailsData] =
    useState<ProductListData | null>(null);
  const [isValidId, setIsValidId] = useState<boolean>(true);
  const [products, setProducts] = useState<ProductListData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isFirmareUpdated, setIsFirmareUpdated] = useState(false);

  useEffect(() => {
    // Set loading state to true when starting to fetch products
    setLoading(true);
    //Fetch Client list data and update the state
    getProducts()
      .then((response: { data: ProductListData[] }) => {
        //the response object data has a property type Client[]
        const data = response.data; //data property is assigened to variable data
        setProducts(data);
        // Set loading state to false when products are fetched
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        // Set loading state to false in case of an error
        setLoading(false);
      });
  }, []);

  // Fetch data from the API on component mount
  useEffect(() => {
    if (id) {
      // Check if the id is valid (you can customize this validation)
      const isValid = products.some((product) => product.id === id);
      setIsValidId(isValid);

      if (isValid) {
        fetchSingleProductData(id)
          .then((data: ProductListData) => {
            setsingleProductDetailsData(data);
          })
          .catch((error) => {
            console.error("Error fetching product data:", error);
            setErrorMessage(true);
          });
      }
    }
  }, [id, products]);

  function handleBack(): void {
    setTransition(false);
    setTimeout(() => userNavigate(`/products`), 400);
  }

  const loadingDisplay = () => {
    if (!singleProductDetailsData) {
      return (
        <>
          <LinearProgress />
          <Stack direction="row" spacing={3} marginTop={5}>
            <Stack direction="column" spacing={3}>
              <Skeleton variant="rectangular" width={275} height={500} />
              <Skeleton variant="rectangular" width={275} height={200} />
            </Stack>
            <Divider flexItem orientation="vertical" />
            <Stack direction="column" spacing={3}>
              <Skeleton variant="rectangular" width={700} height={200} />
              <Skeleton variant="rectangular" width={700} height={400} />
            </Stack>
            <Stack direction="column" spacing={3}>
              <Skeleton variant="rectangular" width={275} height={150} />
              <Skeleton variant="rectangular" width={275} height={100} />
            </Stack>
          </Stack>
        </>
      );
    }
    return null;
  };

  const handleButtonClick = async (buttonName: string) => {
    setActiveButton(buttonName);
    setDisplayDetailsTable(buttonName === "Home");
    setDisplayDevices(buttonName === "Devices");
    setDisplayFirmwareVersions(buttonName === "Firmware Versions");
  };

   // Define a function to update the state
   const updateFirmwareStatus = (status: boolean | ((prevState: boolean) => boolean)) => {
    setIsFirmareUpdated(status);
  };

  return (
    <>
      <Slide direction="left" in={transition} timeout={400}>
        <Box
          flexGrow={1}
          marginLeft={"2%"}
          marginRight={"2%"}
          marginTop={8}
          marginBottom={10}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <h2>Product Details</h2>
            </Grid>
            <Grid item xs={6}>
              <Box
                display="flex"
                justifyContent="flex-end"
                marginTop={3}
                marginRight={2}
              >
                <Button
                  color={theme.backbuttoncolor}
                  onClick={handleBack}
                  variant={"contained"}
                >
                  Back
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Divider />

          <>
            <Grid>
              <Box
                display="flex"
                justifyContent="flex-start"
                marginTop={1}
                marginBottom={1}
              >
                <Button
                  variant="contained"
                  style={{
                    marginRight: "10px",
                    background:
                      activeButton === "Home"
                        ? theme.selectedbuttoncolor
                        : theme.nonactivebuttoncolor,
                  }}
                  onClick={() => handleButtonClick("Home")}
                >
                  Home
                </Button>
                <Button
                  variant="contained"
                  style={{
                    marginRight: "10px",
                    background:
                      activeButton === "Devices"
                        ? theme.selectedbuttoncolor
                        : theme.nonactivebuttoncolor,
                  }}
                  onClick={() => handleButtonClick("Devices")}
                >
                  Devices
                </Button>
                <Button
                  variant="contained"
                  style={{
                    marginRight: "10px",
                    background:
                      activeButton === "Firmware Versions"
                        ? theme.selectedbuttoncolor
                        : theme.nonactivebuttoncolor,
                  }}
                  onClick={() => handleButtonClick("Firmware Versions")}
                >
                  Firmware Versions
                </Button>
              </Box>
            </Grid>
            <Divider />

            {loading ? (
              <>
                <LinearProgress />
                <Stack direction="row" spacing={3} marginTop={5}>
                  <Stack direction="column" spacing={3}>
                    <Skeleton variant="rectangular" width={275} height={500} />
                    <Skeleton variant="rectangular" width={275} height={200} />
                  </Stack>
                  <Divider flexItem orientation="vertical" />
                  <Stack direction="column" spacing={3}>
                    <Skeleton variant="rectangular" width={700} height={200} />
                    <Skeleton variant="rectangular" width={700} height={400} />
                  </Stack>
                  <Stack direction="column" spacing={3}>
                    <Skeleton variant="rectangular" width={275} height={150} />
                    <Skeleton variant="rectangular" width={275} height={100} />
                  </Stack>
                </Stack>
              </>
            ) : isValidId ? (
              <>
                {displayDevices ? (
                  <Box padding={2}>
                    <DevicesTablList />
                  </Box>
                ) : displaysetDisplayFirmwareVersions ? (
                  <Box padding={2}>
                    <Typography variant="h4">
                      Firmware Versions Data Display
                    </Typography>
                  </Box>
                ) : errorMessage ? (
                  <Box paddingTop={2} color="red">
                    <Alert severity="error">Invalid Product</Alert>
                  </Box>
                ) : displayDetailsTable && singleProductDetailsData ? (
                  <>
                    <Stack
                      direction="row"
                      spacing={5}
                      marginTop={5}
                      marginBottom={10}
                    >
                      <Stack direction="column" spacing={2} flex={1}>
                        <Stack direction="column" spacing={2}>
                          <Details products={singleProductDetailsData} allProducts={products} />
                          <Actions products={singleProductDetailsData} updateFirmwareStatus={updateFirmwareStatus}/>
                          <ProductPageStats product_id={singleProductDetailsData.id}/>
                        </Stack>
                      </Stack>

                      <Divider flexItem orientation="vertical" />

                      <Stack direction="column" spacing={2} flex={2}>
                        <Stack direction="column" spacing={2}>
                          <RecentFirmwareVersions
                            products={singleProductDetailsData} isFirmareUpdated={isFirmareUpdated}
                          />
                        </Stack>
                      </Stack>

                      <Divider flexItem orientation="vertical" />

                      <Stack direction="column" spacing={2} flex={2}>
                        <Stack direction="column" spacing={2}>
                          <ProductPrecalsCard
                            products={singleProductDetailsData}
                          />
                          <ActiveDevicesProducts />
                          <AlertsAndFaults />
                        </Stack>
                      </Stack>
                    </Stack>
                  </>
                ) : (
                  loadingDisplay()
                )}
              </>
            ) : (
              <Box paddingTop={2} color="red">
                <Alert severity="error">Invalid Product ID</Alert>
              </Box>
            )}
          </>
        </Box>
      </Slide>
    </>
  );
}
