import { useState, useContext, useEffect, useRef } from "react";
import {
    Button,
    Typography,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    Stepper,
    Step,
    StepLabel,
    Box,
    IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { AppContext } from "../../../../utils/themes/theme-context";
import { DevicesDetails } from "../../../../utils/interfaces/vehicles/devices-details-interface";
import { UnregisterClientfromDevice } from "../../../../utils/requests/devices/unregister-client-from-device";
import { fetchLatestClientData } from "../../../../utils/requests/clients/get-single-client";

interface UnregisterClientActionProps {
    device: DevicesDetails;
    open: boolean;
    onClose: () => void;
    onDeleteClientName: (newClientName: string) => void;
}

export default function UnregisterClientAction(props: UnregisterClientActionProps) {
    const { theme } = useContext(AppContext);
    const [unregisterActiveStep, setUnregisterActiveStep] = useState(0);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true); //initial stage of loading is true
    const [clientName, setClientName] = useState("");
    const [unregisterResult, setUnregisterResult] = useState("");
    const [unregisterResultColor, setunregisterResultColor] = useState("");

    const handleUnregisterNext = async () => {
        try {
            const response = await UnregisterClientfromDevice(props.device.id);

            if (response === "success") {
                // Move to next step if deletion was successful
                setUnregisterResult("Successfully unregistered the client from the device.")
                setunregisterResultColor("green")
                // Call the callback function to update the client name in parent component
                props.onDeleteClientName(clientName);
            } else {
                // Handle error if deletion failed
                setUnregisterResult("Failed to delete client registration.")
                setunregisterResultColor("red")
            }
        } catch (error) {
            console.error("Error deleting client registration:", error);
            // Handle error if API call fails
            setErrorMessage("Error deleting client registration. Please try again.");
            setLoading(false);
        }
        setUnregisterActiveStep(1);
    };

    const closeUnregisterDialog = () => {
        props.onClose();
        setUnregisterActiveStep(0);
    };

    useEffect(() => {
        fetchLatestClientData(props.device.client_id)
            .then((name) => {
                setClientName(name.client);
            })
            .catch((error) => {
                console.error("Error fetching client name: ", error);
                // Handle errors as needed
            });
    }, [props.device.client_id])

    return (
        <>
            <Dialog open={props.open} onClose={closeUnregisterDialog}>
                <DialogTitle variant="h4" align="center">Delete Client Registration
                    <IconButton
                        aria-label="close"
                        onClick={closeUnregisterDialog}
                        sx={{ position: "absolute", right: 0, top: 0 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <Stepper activeStep={unregisterActiveStep} alternativeLabel>
                    <Step>
                        <StepLabel>Unregister Client</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Confirmation</StepLabel>
                    </Step>
                </Stepper>
                <Divider style={{ marginTop: "15px" }}/>
                <DialogContent>
                    {unregisterActiveStep === 0 && (
                        // Step 1: Unregister client
                        <>
                            <Box sx={{ paddingY: 3, paddingX: 5, borderRadius: 8, textAlign: 'center' }}>
                                <Typography fontSize={20} paddingBottom={3} color={"red"}>This action will unregister the client currently associated with this device.</Typography>
                                <Typography fontSize={20}>The Client Registered with this device is :
                                    <Box fontWeight="bold" fontSize={24} marginY={2}>
                                        {clientName}
                                    </Box>
                                </Typography>
                                <Box mt={2} paddingTop={5} justifyContent="space-between" display="flex">
                                    <Button
                                        onClick={closeUnregisterDialog} // Close the dialog on cancel
                                        variant="contained"
                                        color={theme.backbuttoncolor} // Adjust color based on your theme
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={handleUnregisterNext} // Move to next step on click
                                        variant="contained"
                                        color={theme.nextbuttoncolor} // Adjust color based on your theme
                                    >
                                        Unregister
                                    </Button>
                                </Box>
                            </Box>
                        </>
                    )}
                    {unregisterActiveStep === 1 && (
                        // Step 2: Confirmation
                        <>
                            <Typography padding={5} fontSize={24}>
                                <span style={{ color: unregisterResultColor }}>
                                    {unregisterResult}
                                </span>
                            </Typography>
                            <Box
                                mt={2}
                                display="flex"
                                justifyContent="center"
                                style={{ padding: 40 }}
                            >
                                <Button
                                    onClick={closeUnregisterDialog} // Move to next step on click
                                    variant="contained"
                                    color={theme.nextbuttoncolor} // Adjust color based on your theme
                                >
                                    Done
                                </Button>
                            </Box>
                        </>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
}
