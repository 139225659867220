import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  LinearProgress,
  Skeleton,
  Slide,
  Stack,
} from "@mui/material";
import Details from "./user-details-elements/details";
import Actions from "./user-details-elements/actions";
import Permissions from "./user-details-elements/permissions";
import Status from "./user-details-elements/status";
import { useNavigate, useParams } from "react-router-dom";
import { getUserFromUsername } from "../../utils/requests/user-details/get-user-from-username";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../utils/themes/theme-context";
import { getUser } from "../../utils/interfaces/users/user-details-interfaces";
import SuspendDelete from "./user-details-elements/suspend-delete";

export default function UserDetails() {
  var { theme } = useContext(AppContext);
  let { id } = useParams();
  const [user, setUser] = useState<getUser>();
  const [transition, setTransition] = useState<boolean>(true);
  let userNavigate = useNavigate();
  const [updatedat, setUpdatedAt] = useState<string>("");
  const [invalidUser, setInvalidUser] = useState<boolean>(false);
  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const [fetchUpdatedUser, setFetchUpdatedUser] = useState<boolean>(false);
  const [suspending, setSuspending] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);

  useEffect(() => {
    fetchUser()
      .then((response: getUser) => {
        setUser(response);
        setUpdatedAt(response.data.updated_at);
      })
      .catch(() => {
        setInvalidUser(true);
      });
  }, [fetchUpdatedUser]);

  const fetchUser = async () => {
    try {
      const response = await getUserFromUsername(id);
      return response;
    } catch (err) {
      console.log(err);
      setInvalidUser(true);
    }
  };

  const loadingDisplay = () => {
    return (
      <>
        <LinearProgress />
        <Stack direction={"row"} spacing={3} marginTop={5}>
          <Stack direction={"column"} spacing={3}>
            <Skeleton variant="rectangular" width={275} height={275} />
            <Skeleton variant="rectangular" width={275} height={150} />
            <Skeleton variant="rectangular" width={275} height={100} />
          </Stack>
          <Divider flexItem orientation="vertical" />
          <Skeleton variant="rectangular" width={700} height={500} />
        </Stack>
      </>
    );
  };

  const handleBack = () => {
    setTransition(false);
    setTimeout(() => userNavigate(`/users`), 400);
  };

  return (
    <>
      <Slide direction="left" in={transition} timeout={400}>
        <Box flexGrow={1} margin={"5%"} marginTop={10}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <h2>User Profile</h2>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  color={theme.backbuttoncolor}
                  onClick={handleBack}
                  variant={"contained"}
                >
                  Back
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Divider />
          {invalidUser ? (
            <Box padding={2}>
              <Alert severity="error">This User Does Not Exist</Alert>
            </Box>
          ) : user ? (
            <>
              <Stack direction={"row"} spacing={3} marginTop={5}>
                <Stack direction={"column"} spacing={3} width="30%">
                  <Details user={user} updatedat={updatedat} />
                  <Actions
                    user={user}
                    setDisplayModal={setDisplayModal}
                    setSuspending={setSuspending}
                    setDeleting={setDeleting}
                  />
                  <Status user={user} />
                </Stack>
                <Divider flexItem orientation="vertical" />
                <Stack width="70%">
                  <Permissions user={user} updatedat={updatedat} />
                </Stack>
              </Stack>
            </>
          ) : (
            loadingDisplay()
          )}
        </Box>
      </Slide>

      {user ? (
        <SuspendDelete
          user={user}
          suspending={suspending}
          deleting={deleting}
          setDisplayModal={setDisplayModal}
          setFetchUpdatedUser={setFetchUpdatedUser}
          fetchUpdatedUser={fetchUpdatedUser}
          displayModal={displayModal}
          setSuspending={setSuspending}
          setDeleting={setDeleting}
        />
      ) : null}
    </>
  );
}
