import { Auth } from "aws-amplify";
import { buildBaseUrl } from "../build-base-url";

export const deleteClient = async (clientId: string | undefined) => {
    const url = buildBaseUrl()
    const authData = await Auth.currentAuthenticatedUser();
    const response = await fetch(`${url}clients/${clientId}`, {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${authData.signInUserSession.accessToken.jwtToken}`,
        },
    }
    );
    return response;
};