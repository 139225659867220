import { Step, StepLabel, Stepper } from '@mui/material';

const AllStepsDisplay = ({ perms, calcActiveStep }: { perms: any, calcActiveStep: () => number }) => {
  const step1display = () => {
    if (perms.user_type === "aw") {
      return (
        <Step>
          <StepLabel>Select Client</StepLabel>
        </Step>
      );
    }
  };

  return (
    <Stepper activeStep={calcActiveStep()} alternativeLabel>
      {step1display()}
      <Step>
        <StepLabel>Download CSV File Template</StepLabel>
      </Step>
      <Step>
        <StepLabel>Upload CSV File</StepLabel>
      </Step>
      <Step>
        <StepLabel>Check Data Entry</StepLabel>
      </Step>
      <Step>
        <StepLabel>Review</StepLabel>
      </Step>
      <Step>
        <StepLabel>Account Creation Status</StepLabel>
      </Step>
    </Stepper>
  );
};

export default AllStepsDisplay;
