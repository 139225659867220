/*CONFLUENCE DOCUMENTATION: https://airweigh.atlassian.net/l/cp/Kc3A9urW */

import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Chip,
	Divider,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@mui/material";
import {
	corporate_permissions,
	aw_permissions,
	newuser,
	iweigh_permissions,
} from "../../../utils/interfaces/new-user/new-user-interface";
import {
	aw_perms_descriptions,
	corp_perms_descriptions,
	aw_perms_name,
	corp_perms_name,
	iweigh_perms_name,
	iweigh_perms_descriptions,
	PermsType,
} from "../../../utils/interfaces/new-user/permissions";
import { Check } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";

export function ReviewSubmit(props: {
	newUser: newuser;
	nextButtonCallback: Function;
	newActiveStep: Function;
	curclientname: string;
}) {

	const { newUser } = props;
	var permsname =
		props.newUser.user_type === "aw" ? aw_perms_name : corp_perms_name;

		var iweighpermanmes =
		iweigh_perms_name;

		const awPermissions = { ...newUser?.aw_permissions }; // Create a copy of AW permissions
		const corpPermissions = { ...newUser?.corporate_permissions }; // Create a copy of corporate permissions
		const iweighPermissions = { ...newUser?.iweigh_permissions }; // Create a copy of iWeigh permissions
	  
		

	//enables next button no matter what because user is only reviewing info,
	// no choices to check or make
	useEffect(() => {
		props.nextButtonCallback(false);
	}, []);

	const columns = ["Name", "Description", "Choice"];

	const permissionsHelper = (permstype: PermsType) => {
		return (
			<>
				<Box flexGrow={1}>
					<Table>
						<TableHead>
							<TableRow>
								{Object.values(columns).map((column: string) => {
									return <TableCell key={column}>{column}</TableCell>;
								})}
							</TableRow>
						</TableHead>
						<TableBody>
							{Object.values(permstype).map((perm: string, index: number) => {
								return (
									<TableRow key={perm}>
										<TableCell>{perm}</TableCell>
										{permsDescriptions(index, permstype)}
										{permsChoices(index, permstype)}
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</Box>
			</>
		);
	};

	// Modify permsChoices to handle both iWeigh and non-iWeigh permissions
	const permsChoices = (index: number, permstype: PermsType) => {
		const permLabel = Object.keys(permstype)[index];
		const isIWeigh = permstype === iweigh_perms_name;
		const isSelected = isIWeigh
		  ? iweighPermissions[permLabel]
		  : props.newUser.user_type === "aw"
		  ? awPermissions[permLabel] // Use the 'perms' prop for non-iWeigh permissions
		  : corpPermissions[permLabel]; // Use the 'perms' prop for non-iWeigh permissions
	
		return (
		  <>
			<TableCell>
			  {isSelected ? (
				<Chip icon={<Check />} label="True" color="success" />
			  ) : (
				<Chip icon={<CloseIcon />} label="False" color="error" />
			  )}
			</TableCell>
		  </>
		);
	  };

	  
	//helper to set perms descriptions
	const permsDescriptions = (index: number, permstype: PermsType) => {
		let permname = Object.keys(permstype)[index];
		let descriptionText = "";
		if (props.newUser.user_type === "aw") {
			descriptionText = aw_perms_descriptions[permname] || iweigh_perms_descriptions[permname];
		} else if (props.newUser.user_type === "corp") {
			descriptionText = corp_perms_descriptions[permname] || iweigh_perms_descriptions[permname];
		} else {
			descriptionText = iweigh_perms_descriptions[permname];
		}
	
		return (
			<TableCell>
				{descriptionText}
			</TableCell>
		);
	};

	return (
		<>
			<Card>
				<CardHeader title="Review and Submit Information" />
				<CardContent>
					<Card>
						<CardHeader
							subheader={
								props.newUser.user_type === "corp"
									? "Select User Type & Client"
									: "Select User Type"
							}
							action={
								<Button
									onClick={() => props.newActiveStep(0)}
									variant="contained"
								>
									Edit
								</Button>
							}
						/>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Divider light={true}></Divider>
							</Grid>
							<Grid item xs={6}>
								<Box padding={2}>User Type: {props.newUser.user_type}</Box>
							</Grid>
							<Grid item xs={6}>
								{props.newUser.user_type === "corp" ? (
									<Box padding={2}>Client: {props.curclientname}</Box>
								) : null}
							</Grid>
						</Grid>
					</Card>
					<Divider></Divider>
					<Card>
						<CardHeader
							subheader="Enter Information"
							action={
								<Button
									onClick={() => props.newActiveStep(1)}
									variant="contained"
								>
									Edit
								</Button>
							}
						/>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Divider light={true}></Divider>
							</Grid>
							<Grid item xs={6}>
								<Box padding={2}>Username: {props.newUser.username}</Box>
							</Grid>
							<Grid item xs={6}>
								<Box padding={2}>
									Full Name:{" "}
									{props.newUser.user_name
										? props.newUser.user_name
										: "(Not Specified)"}
								</Box>
							</Grid>
							<Grid item xs={6}>
								<Box padding={2}>Email: {props.newUser.user_email}</Box>
							</Grid>
						</Grid>
					</Card>
					<Divider></Divider>
					<Card>
						<CardHeader
							subheader="Set User Permissions"
							action={
								<Button
									onClick={() => props.newActiveStep(2)}
									variant="contained"
								>
									Edit
								</Button>
							}
						/>
						<CardContent>
						<Box>
						{props.newUser.user_type === "corp"
							? permissionsHelper(corp_perms_name)
							: permissionsHelper(aw_perms_name)}
					</Box>
						</CardContent>
					</Card>
					<Divider></Divider>
					<Card>
						<CardHeader
							subheader="iWeigh Permissions"
							action={
								<Button
									onClick={() => props.newActiveStep(2)}
									variant="contained"
								>
									Edit
								</Button>
							}
						/>
						<CardContent>
							<Box flexGrow={1} marginTop={3}>
							{permissionsHelper(iweigh_perms_name)}
							</Box>
						</CardContent>
					</Card>
				</CardContent>
			</Card>
		</>
	);
}
