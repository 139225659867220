import React, { useState } from "react";
import {
  Button,
  TextField,
  CircularProgress,
  Snackbar,
  Stack,
  Card,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Heading, View } from "@aws-amplify/ui-react";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface NewPasswordFormProps {
  onSubmit: (newPassword: string) => void; // Define the type of onSubmit
}

const NewPasswordForm: React.FC<NewPasswordFormProps> = ({ onSubmit }) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const isPasswordValid = () => {
    //password validation logic
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return passwordRegex.test(newPassword);
  };

  const handleSubmit = async () => {
    if (!newPassword || !confirmPassword) {
      return;
    }

    if (newPassword !== confirmPassword) {
      setNewPassword("");
      setConfirmPassword("");
      return;
    }

    if (!isPasswordValid()) {
      setNewPassword("");
      setConfirmPassword("");
      return;
    }

    setLoading(true);

    await onSubmit(newPassword);

    setLoading(false);
  };

  const handleToggleNewPasswordVisibility = () => {
    setShowNewPassword((prevShowNewPassword) => !prevShowNewPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevShowConfirmPassword) => !prevShowConfirmPassword);
  };

  return (
    <div>
      <div className="card-container">
        <Card
          className="sign-in-card"
          style={{ maxWidth: "500px", width: "90%", margin: "0px auto" }}
        >
          <div className="sign-in-card-inner">
            <Stack spacing={3}>
              <View textAlign="center">
                <Heading level={3} style={{ textAlign: "center" }}>
                  <img
                    alt="Air-Weigh logo"
                    src="/images/color-Air-Weigh_sans_subtext-web.png"
                    width={300}
                    height={80}
                  />
                </Heading>
              </View>
              <Stack direction="column" spacing={1} justifyContent="center">
                <Typography variant="subtitle1" gutterBottom>
                  New Password
                </Typography>
                <TextField
                  type={showNewPassword ? "text" : "password"}
                  variant="outlined"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  fullWidth
                  margin="normal"
                  error={newPassword !== confirmPassword || !isPasswordValid()}
                  helperText={
                    newPassword !== confirmPassword
                      ? "Passwords do not match"
                      : !isPasswordValid()
                      ? "Password must be at least 8 characters, including 1 uppercase and 1 lowercase letter"
                      : ""
                  }
                  sx={{
                    "& fieldset": {
                      borderColor:
                        newPassword !== confirmPassword || !isPasswordValid()
                          ? "red"
                          : "",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleToggleNewPasswordVisibility}
                          edge="end"
                        >
                          {showNewPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Typography variant="subtitle1" gutterBottom>
                  Confirm Password
                </Typography>
                <TextField
                  type={showConfirmPassword ? "text" : "password"}
                  variant="outlined"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  fullWidth
                  margin="normal"
                  error={newPassword !== confirmPassword || !isPasswordValid()}
                  helperText={
                    newPassword !== confirmPassword
                      ? "Passwords do not match"
                      : !isPasswordValid()
                      ? "Password must be at least 8 characters, including 1 uppercase and 1 lowercase letter"
                      : ""
                  }
                  sx={{
                    "& fieldset": {
                      borderColor:
                        newPassword !== confirmPassword || !isPasswordValid()
                          ? "red"
                          : "",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleToggleConfirmPasswordVisibility}
                          edge="end"
                        >
                          {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <View textAlign="center" width="100%" marginTop={20}>
                  <Button
                    className="navigation-button"
                    variant="contained"
                    fullWidth
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    {loading ? <CircularProgress size={24} /> : "Submit"}
                  </Button>
                </View>
              </Stack>
            </Stack>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default NewPasswordForm;
