import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { AppContext } from "../../../utils/themes/theme-context";
import { useContext, useEffect, useRef, useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { SingleProductFirmwareGET } from "../../../utils/interfaces/products/single-product-details-interface";
import { fetchSingleProductFirmwareData } from "../../../utils/requests/products/get-singleproduct-firmware-data";
import { useNavigate, useParams } from "react-router-dom";
import { Columns } from "../../../utils/interfaces/table/table-interfaces";
import GeneralTable from "../../../utils/generic-components/table/table";
import { fetchSingleFirmwareData } from "../../../utils/requests/products/get-single-firmware-data";
import { ProductListData } from "../../../utils/interfaces/products/products-list-interface";
import CloseIcon from "@mui/icons-material/Close";
import { fetchLatestClientData } from "../../../utils/requests/clients/get-single-client";
import { Client } from "../../../utils/interfaces/clients/clients-list-interface";
import { getSingleServicePlans } from "../../../utils/requests/products/get-single-service-plan";

let columns: Columns = {
  Version: {
    type: "text",
    display: "Version",
    data: "firmware_version",
    sort: "Version",
  },
  Date: {
    type: "time",
    display: "Date",
    data: "date_time",
    sort: "Date",
  },
  Link: {
    type: "link",
    display: "Link",
    sort: "Version",
  },
};

export default function RecentFirmwareVersions(props: {
  products: ProductListData;
  isFirmareUpdated: boolean;
}) {
  var { theme } = useContext(AppContext);
  const { id, version } = useParams<{ id?: string; version?: string }>();
  const [loading, setLoading] = useState<boolean>(true);
  const [singleProductFirmwareData, setsingleProductFirmwareData] = useState<
    SingleProductFirmwareGET[]
  >([]);
  const [selectedFirmware, setSelectedFirmware] =
    useState<SingleProductFirmwareGET | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [errorAlert, setErrorAlert] = useState<string | null>(null);
  const [clientsData, setClientsData] = useState<Client[]>([]);
  let userNavigate = useNavigate();
  const [activeTab, setActiveTab] = useState("firmwareDetailsTable"); // Default active tab is firmwareDetailsTable
  const [servicePlanNames, setServicePlanNames] = useState<string[]>([]);

  // Fetch data from the API on component mount
  useEffect(() => {
    if (id || props.isFirmareUpdated) {
      setLoading(true);
      fetchSingleProductFirmwareData(id)
        .then((data) => {
          setsingleProductFirmwareData(data.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching product data:", error);
          setLoading(false);
        });
    }
  }, [id, props.isFirmareUpdated]); //Refresh the table when new firmware is added

  const fetchClientData = async () => {
    try {
      if (selectedFirmware) {
        const clientIds = selectedFirmware.client_restrictions || [];
        const clientsDataArray = await Promise.all(
          clientIds.map(async (clientId) => {
            try {
              const clientData = await fetchLatestClientData(clientId);
              return clientData; // Assuming the API response structure includes the entire client data
            } catch (error) {
              console.error("Error fetching client data:", error);
              return null;
            }
          })
        );
        // Filter out null values and set non-null values to clientsData
        setClientsData(
          clientsDataArray.filter(
            (clientData) => clientData !== null
          ) as Client[]
        );
      }
    } catch (error) {
      console.error("Error fetching clients data:", error);
    }
  };

  // Function to fetch service plan names based on IDs
  const fetchServicePlanNames = async (servicePlanIds: string[]) => {
    try {
      const planDetailsPromise = servicePlanIds.map(async (id) => {
        try {
          const response = await getSingleServicePlans(id);
          const planName = response.name;
          return planName;
        } catch (error) {
          console.error(`Error fetching service plan for ID ${id}:`, error);
          return "N/A"; // Return 'N/A' for IDs that couldn't be fetched
        }
      });
      const names = await Promise.all(planDetailsPromise);
      setServicePlanNames(names);
    } catch (error) {
      console.error("Error fetching service plans:", error);
    }
  };

  useEffect(() => {
    fetchClientData();
  }, [selectedFirmware]);

  const handleRowClick = (selectedClient: Client) => {
    setTimeout(() => userNavigate(`/clients/${selectedClient}`), 400);
  };

  const handleServicePlanRowClick = () => {
    console.log("Service Plan clicked");
  };

  const handleDialogOpen = async (product_id: any, firmware_version: any) => {
    try {
      setDialogLoading(true); // Set the loading state when opening the dialog
      const data = await fetchSingleFirmwareData(product_id, firmware_version);
      setSelectedFirmware(data.Firmware);
      fetchServicePlanNames(data.Firmware.service_plan_restrictions || []);
      setDialogLoading(false); // Clear the loading state when data is fetched
      setDialogOpen(true);
    } catch (error) {
      console.error("Error fetching firmware data:", error);
    }
  };

  const handleDialogClose = () => {
    setSelectedFirmware(null);
    setDialogOpen(false);
    setErrorAlert(null);
  };

  // Calculate the SHA-256 hash of the file content using window.crypto.subtle
  async function calculateSHA256Hash(buffer: ArrayBuffer): Promise<string> {
    try {
      const hashBuffer = await crypto.subtle.digest("SHA-256", buffer);
      const hashArray = new Uint8Array(hashBuffer);
      const hashHex = Array.from(hashArray)
        .map((byte) => byte.toString(16).padStart(2, "0"))
        .join("");

      return hashHex;
    } catch (error) {
      console.error("Error calculating SHA-256 hash:", error);
      throw error;
    }
  }

  const handleDownloadFile = async () => {
    if (selectedFirmware) {
      try {
        // Convert Base64 encoded string to ArrayBuffer
        const binaryString = window.atob(selectedFirmware.file); //converts the Base64 string into a binary string
        const arrayBuffer = new ArrayBuffer(binaryString.length); //An ArrayBuffer is created with a length equal to the length of the decoded binary string.
        const uint8Array = new Uint8Array(arrayBuffer); //Uint8Array is created from the ArrayBuffer

        // iterates over each character in the binary string and converts its Unicode value to a byte value, storing it in the Uint8Array.
        for (let i = 0; i < binaryString.length; i++) {
          uint8Array[i] = binaryString.charCodeAt(i);
        }

        // Calculate the SHA-256 hash of the downloading content
        const downloadedHash = await calculateSHA256Hash(arrayBuffer);

        if (downloadedHash === selectedFirmware.file_hash) {
          // Create a Blob with the ArrayBuffer data
          const blob = new Blob([arrayBuffer], {
            type: "application/octet-stream",
          }); //The type is set to 'application/octet-stream' to indicate that this is a generic binary data.

          // Create a download URL for the Blob
          const blobUrl = URL.createObjectURL(blob); //. This URL points to the data in the Blob.

          // Create a temporary link element
          const link = document.createElement("a"); //An anchor (<a>) element is created dynamically.
          link.href = blobUrl; //Its href attribute is set to the URL of the Blob
          link.download = `${props.products.name}_${selectedFirmware.firmware_version}.gbl`;

          // Programmatically click the link to initiate the download
          link.click();

          // Clean up
          URL.revokeObjectURL(blobUrl);
          setErrorAlert(null); // Clear error alert on successful download
        } else {
          setErrorAlert("File corruption detected, Please download again");
        }
      } catch (error) {
        setErrorAlert("Error handling file download");
      }
    }
  };

  let columns2: Columns = {
    Name: {
      type: "link",
      display: "Client Name",
      data: "name",
      sort: "Name",
    },
  };

  let columns3: Columns = {
    Name: {
      type: "link",
      display: "Service Plan",
      data: "name",
      sort: "Name",
    },
  };

  const linkContent = dialogLoading ? (
    <CircularProgress size={24} color="primary" />
  ) : (
    "GO"
  );

  const loadingDisplay = () => {
    return (
      <>
        <LinearProgress />
        <Stack spacing={2}>
          <Skeleton variant="rectangular" height={50} animation="wave" />
          <Skeleton variant="rectangular" height={50} animation="wave" />
          <Skeleton variant="rectangular" height={50} animation="wave" />
        </Stack>
      </>
    );
    return null;
  };

  const transformedData = singleProductFirmwareData.map((productFirmware) => ({
    Version: productFirmware.firmware_version,
    Date: productFirmware.date_time,
    Link: linkContent,
    Product_Id: productFirmware.product_id,
    Release_Notes: productFirmware.release_notes,
    Comments: productFirmware.comments,
    FileData: productFirmware.file,
    File_Hash: productFirmware.file_hash,
  }));
  
  return (
    <>
      <Card elevation={8} sx={{ borderRadius: 3 }}>
        <CardHeader
          title="Recent Firmware Versions"
          action={
            <IconButton aria-label="info">
              <InfoOutlinedIcon />
            </IconButton>
          }
        />
        <CardContent>
          <Divider />
          <>
            {loading ? ( // Show skeleton when loading is true
              loadingDisplay()
            ) : singleProductFirmwareData.length === 0 ? (
              <Alert variant="filled" severity="warning">
                <AlertTitle>No Recent Firmware Versions to fetch</AlertTitle>
              </Alert>
            ) : (
              <GeneralTable
                data={transformedData}
                columns={columns}
                loading={loading}
                noDataMessage={"No Recent Firmware Versions to fetch "}
                rowClickCallback={(rowData: any) =>
                  handleDialogOpen(rowData.Product_Id, rowData.Version)
                }
                rows={10}
                rowsoptions={[10, 25, 50, 100]}
              />
            )}

            <Dialog open={dialogOpen} onClose={handleDialogClose}>
              <DialogTitle variant="h4" align="center">
                Firmware Details
                <IconButton
                  aria-label="close"
                  onClick={handleDialogClose}
                  sx={{
                    position: "absolute",
                    right: 2,
                    top: 2,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <Divider />
              <DialogContent sx={{ margin: "16px" }}>
                <Box display="flex" justifyContent="space-between">
                  <Button
                    variant="contained"
                    style={{
                      marginRight: "10px",
                      background:
                        activeTab === "firmwareDetailsTable"
                          ? theme.selectedbuttoncolor
                          : theme.nonactivebuttoncolor,
                    }}
                    onClick={() => setActiveTab("firmwareDetailsTable")}
                  >
                    Firmware Details Table
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      marginRight: "10px",
                      background:
                        activeTab === "clientRestrictions"
                          ? theme.selectedbuttoncolor
                          : theme.nonactivebuttoncolor,
                    }}
                    onClick={() => setActiveTab("clientRestrictions")}
                  >
                    Client Restrictions
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      marginRight: "10px",
                      background:
                        activeTab === "servicePlanRestrictions"
                          ? theme.selectedbuttoncolor
                          : theme.nonactivebuttoncolor,
                    }}
                    onClick={() => setActiveTab("servicePlanRestrictions")}
                  >
                    Service Plan Restrictions
                  </Button>
                </Box>
                <br />
                <Divider />
                {dialogLoading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100px",
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : (
                  selectedFirmware && (
                    <>
                      {activeTab === "firmwareDetailsTable" && (
                        <>
                          <Table>
                            <TableBody>
                              <TableRow>
                                <TableCell>Version:</TableCell>
                                <TableCell>
                                  {selectedFirmware.firmware_version}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Date:</TableCell>
                                <TableCell>
                                  {" "}
                                  {new Date(
                                    selectedFirmware.date_time
                                  ).toLocaleString()}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Release Notes:</TableCell>
                                <TableCell>
                                  {selectedFirmware.release_notes}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Comments:</TableCell>
                                <TableCell>
                                  {selectedFirmware.comments}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                          <Box textAlign="center" marginTop={4}>
                            {errorAlert && (
                              <Alert severity="error">{errorAlert}</Alert>
                            )}
                          </Box>
                          <Box textAlign="center" marginTop={2}>
                            <Button
                              variant="contained"
                              onClick={handleDownloadFile}
                            >
                              Download File
                            </Button>
                          </Box>
                        </>
                      )}
                      {activeTab === "clientRestrictions" && (
                        <GeneralTable
                          data={clientsData.map((clientData) => ({
                            Id: clientData.id,
                            Name: clientData.client,
                          }))}
                          columns={columns2}
                          // toolbar={true}
                          // timeCallback={timeCallback}
                          // loading={loading} //passing the loading state
                          noDataMessage={"Unable to fetch Clients "}
                          rowClickCallback={(rowData: any) =>
                            handleRowClick(rowData.Id)
                          }
                          rows={10}
                          rowsoptions={[10, 25, 50, 100]}
                        />
                      )}
                      {activeTab === "servicePlanRestrictions" && (
                        <GeneralTable
                          data={servicePlanNames.map((name, index) => ({
                            Id: index,
                            Name: name,
                          }))}
                          columns={columns3}
                          noDataMessage={"No Service Plans Available"}
                          rowClickCallback={(rowData: any) =>
                            handleServicePlanRowClick()
                          }
                          rows={10}
                          rowsoptions={[10, 25, 50, 100]}
                        />
                      )}
                    </>
                  )
                )}
              </DialogContent>
            </Dialog>
          </>
        </CardContent>
      </Card>
    </>
  );
}
