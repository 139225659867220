//get-users request
export interface defaultGetUserReturnType {
	data: User[];
	next_page: string;
}

//form of data got from userdetails
export interface getUser {
	data: User;
}

export interface User {
	username: string;
	user_email: string;
	user_domain: string;
	user_type: string;
	user_name: string;
	client_id: string;
	updated_at: string;
	root_user: boolean;
	status: string;
	enabled: boolean;
	client_info: {
		client: string;
		contact: string;
		contact_email: string;
		contact_phone: string;
		comments: string;
	};
	aw_permissions: {
		clients_read: boolean;
		clients_create: boolean;
		clients_edit: boolean;
		clients_delete: boolean;
		dashboard_read: boolean;
		device_create: boolean;
		device_edit: boolean;
		device_delete: boolean;
		firmware_create: boolean;
		firmware_edit: boolean;
		firmware_read: boolean;
		integrations_read: boolean;
		integrations_edit: boolean;
		integrations_trigger_update: boolean;
		report_read: boolean;
		service_plan_create: boolean;
		service_plan_edit: boolean;
		service_plan_delete: boolean;
		users_read: boolean;
		user_create: boolean;
		user_edit: boolean;
		vehicle_create: boolean;
		vehicle_edit: boolean;
		vehicle_delete: boolean;
		vehicle_tickets_create: boolean;
		vehicle_tickets_edit: boolean;
		vehicle_tickets_delete: boolean;
		weight_record_edit: boolean;
		weight_record_delete: boolean;
		weight_record_create: boolean;
	};
	corporate_permissions: {
		users_create: boolean;
		users_edit: boolean;
		users_read: boolean;
	};
	iweigh_permissions: {
		calibration_read: boolean;
		calibration_edit: boolean;
		configuration_profiles_read: boolean;
		configuration_profiles_edit: boolean;
		device_registration: boolean;
		device_unregister: boolean;
		linked_assets_read: boolean;
		linked_assets_edit: boolean;
		preferences_read: boolean;
		preferences_edit: boolean;
		sensor_setup_read: boolean;
		sensor_setup_edit: boolean;
		update_firmware: boolean;
	};
}

export interface putUser {
	data: any;
	updated_at: string;
}

export function CreateputUser() {
	const newputUser: putUser = {
		data: {
			username: "",
			user_email: "",
			user_domain: "",
			user_type: "",
			user_name: "",
			client_id: "",
			root_user: false,
			updated_at: "",
			client_info: {
				client: "",
				contact: "",
				contact_email: "",
				contact_phone: "",
				comments: "",
			},
			aw_permissions: {
				clients_read: false,
				clients_create: false,
				clients_edit: false,
				clients_delete: false,
				dashboard_read: false,
				device_create: false,
				device_edit: false,
				device_delete: false,
				firmware_create: false,
				firmware_edit: false,
				firmware_read: false,
				integrations_read: false,
				integrations_edit: false,
				integrations_trigger_update: false,
				report_read: false,
				service_plan_create: false,
				service_plan_edit: false,
				service_plan_delete: false,
				users_read: false,
				user_create: false,
				user_edit: false,
				vehicle_create: false,
				vehicle_edit: false,
				vehicle_delete: false,
				vehicle_tickets_create: false,
				vehicle_tickets_edit: false,
				vehicle_tickets_delete: false,
				weight_record_edit: false,
				weight_record_delete: false,
				weight_record_create: false,
			},
			corporate_permissions: {
				users_create: false,
				users_edit: false,
				users_read: false,
			},
			iweigh_permissions: {
				calibration_read: false,
				calibration_edit: false,
				configuration_profiles_read: false,
				configuration_profiles_edit: false,
				device_registration: false,
				device_unregister: false,
				linked_assets_read: false,
				linked_assets_edit: false,
				preferences_read: false,
				preferences_edit: false,
				sensor_setup_read: false,
				sensor_setup_edit: false,
				update_firmware: false,
			},
		},
		updated_at: "",
	};

	return {
		...newputUser,
	};
}
