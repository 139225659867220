// A successful response indicates the client’s details have been returned.

import { Auth } from "aws-amplify";
import { Client, getClient } from '../../interfaces/clients/clients-list-interface';
import { buildBaseUrl } from "../build-base-url";

export async function getSingleClient(clientId: string): Promise<getClient> {
  const url = buildBaseUrl()
  try {
    const authData = await Auth.currentAuthenticatedUser();
    const response = await fetch(`${url}clients/${clientId}`, {
      headers: {
        Authorization: `Bearer ${authData.signInUserSession.accessToken.jwtToken}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const fetchLatestClientData = async (clientId: string) => {
  try {
    const response = await getSingleClient(clientId);
    if (response && response.data) {
      return response.data;
    } else {
      throw new Error("Failed to fetch the latest client data");
    }
  } catch (error) {
    throw new Error("Failed to fetch the latest client data");
  }
};

