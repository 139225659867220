import React, { ReactNode } from "react";
import "../../../index.css";

interface BackgroundWrapperProps {
  children: ReactNode;
}

const BackgroundWrapper: React.FC<BackgroundWrapperProps> = ({ children }) => {
  return <div className="blur-background">{children}</div>;
};

export default BackgroundWrapper;
