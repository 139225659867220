import { Auth } from "aws-amplify";
import { buildBaseUrl } from "../build-base-url";
import { DeleteDeviceInterface } from "../../interfaces/devices/registration-device-interface";

export const deleteDevice = async (device_id: string) => {
	const url = buildBaseUrl()
	try {
		const authData = await Auth.currentAuthenticatedUser();
        const response = await fetch(`${url}device/${device_id}`, {
				method: "DELETE",
				headers: {
					Authorization: `Bearer ${authData.signInUserSession.accessToken.jwtToken}`,
				},
			}
		);
		if (response.status === 200) {
			return "success";
		} else {
			let data = await response.json();
			return data.errors;
		}
	} catch (err) {
		console.log(err);
	}
};

export const deleteDeviceToken = async (device_id: string, overwrite: string) => {
	const url = buildBaseUrl()
	try {
		const authData = await Auth.currentAuthenticatedUser();
        const response = await fetch(`${url}device/${device_id}?overwrite=${overwrite}`, {
				method: "DELETE",
				headers: {
					Authorization: `Bearer ${authData.signInUserSession.accessToken.jwtToken}`,
				},
			}
		);
		if (response.status === 200) {
			return "success";
		} else {
			let data = await response.json();
			return data.errors;
		}
	} catch (err) {
		console.log(err);
	}
};