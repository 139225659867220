//DOCUMENTATION: https://airweigh.atlassian.net/l/cp/q1RVuwmF

import { createMap } from "maplibre-gl-js-amplify";
import "maplibre-gl/dist/maplibre-gl.css";
import "maplibre-gl-js-amplify/dist/public/amplify-map.css";
import { NavigationControl } from "maplibre-gl";
import "@maplibre/maplibre-gl-geocoder/dist/maplibre-gl-geocoder.css";
import { Theme } from "../../themes/theme-context";
import "./initialize-map.css";
import 'maplibre-gl-js-amplify/dist/public/amplify-geocoder.css'; // Optional CSS for Amplify recommended styling
import GeoSearchControl from "../../generic-components/map/geo-search-control"; // Import your custom control
import SwitchMapStyleControl from "../../generic-components/map/map-style-control";
import CloseModalControl from "../../generic-components/map/close-modal-control";
import OpenModalControl from "../../generic-components/map/open-modal-control";

export const initializeMap = async (
	containerId: string,
	mapCallback: Function,
	theme: Theme, //theme must be passed bc of error thrown when trying to access it not in a component
	longlat: [number, number],
	zoom: number,
	fullscreenCallback: Function,
	fullscreen: boolean,
	searchBar?: boolean,
) => {
	let map = await createMap({
		container: containerId,
		center: longlat,
		zoom: zoom,
		minZoom: 3, //set to 3 to fix blank map on too far zoom out. also fixes
		//"Canvas2D: Multiple readback operations using getImageData are faster with the willReadFrequently attribute set to true."
	});

	// adds searchbar if passed
	if (searchBar) {
		const geoSearchControl = new GeoSearchControl((result) => {
			mapCallback(result);
			map.flyTo({ center: result.coordinates, zoom: 10 });
		})

		map.addControl(geoSearchControl, "top-left");
	}

	// Custom map style control
	const switchMapStyleControl = new SwitchMapStyleControl();
	map.addControl(switchMapStyleControl, 'top-right');

	// Custom fullscreen controls
	if (fullscreen) {
		const closeModalControl = new CloseModalControl(() => { fullscreenCallback(map.getCenter(), map.getZoom()) });
		map.addControl(closeModalControl, "top-right");
	} else {
		const openModalControl = new OpenModalControl(() => { fullscreenCallback() });
		map.addControl(openModalControl, "top-right");
	}

	//adds nav controls
	map.addControl(new NavigationControl({ showZoom: true }), "bottom-right");
	return map;
};
