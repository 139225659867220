import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { AppContext } from "../../../utils/themes/theme-context";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { getClient } from "../../../utils/interfaces/clients/clients-list-interface";
import { deleteClient } from "../../../utils/requests/clients/delete-client";

export default function Actions(props: { client: getClient }) {
  var { theme } = useContext(AppContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [deleteColor, setDeleteColor] = useState("");
  const [deleteResult, setDeleteResult] = useState("");

  const handleDeleteClient = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  //This is yet to be implemented on the backend
  const handleConfirmDelete = async () => {
    const response = await deleteClient(props.client.data.id);
    if (response.status === 200) {
      setDeleteResult("Client sucessfully deleted");
      setDeleteColor("green")
    } else if (response.status === 401 || response.status === 500) {
      setDeleteResult("Failed ot delete the client");
      setDeleteColor("red")
    } else {
      setDeleteResult("Unknown error occurred. Failed to delete the client");
      setDeleteColor("red")
    }
    setActiveStep(1);
  };

  const handleReset = () => {
    setOpenDialog(false);
    setActiveStep(0);
    setDeleteResult("");
    setDeleteColor("");
  };

  const steps = ["Confirm Deletion", "Delete Status"];

  return (
    <>
      <Card elevation={8} sx={{ borderRadius: 3 }}>
        <CardHeader
          title="Actions"
          action={
            <IconButton aria-label="info">
              <InfoOutlinedIcon />
            </IconButton>
          }
        />
        <CardContent>
          <Divider />
          <Box paddingTop={2}>
            <Stack direction={"column"} spacing={2}>
              <Button
                color={theme.backbuttoncolor}
                variant="contained"
                onClick={() => handleDeleteClient()}
              >
                Delete Client
              </Button>
            </Stack>
          </Box>
        </CardContent>
      </Card>

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDialog} onClose={handleDialogClose} PaperProps={{
        style: {
          minWidth: '30%',
          minHeight: '30%',
        },
      }}>
        <DialogTitle variant="h4" justifyContent="center" display="flex">Delete Client</DialogTitle>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Divider />
        <DialogContent>

          <Box mt={2}>
            {activeStep === 0 && (
              <><Typography variant="h5" justifyContent="center" display="flex">
                Are you sure you want to delete the client?
              </Typography><DialogActions>
                  <Box display="flex" justifyContent="space-between" width="100%" paddingTop={3} paddingLeft={2} paddingRight={2}>
                    <Button onClick={handleDialogClose} variant="contained" color={theme.backbuttoncolor}>
                      Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} variant="contained" color={theme.nextbuttoncolor}>
                      Yes
                    </Button>
                  </Box>
                </DialogActions></>
            )}
            {activeStep === 1 && (
              <DialogContent>
                <Typography variant="h5" justifyContent="center">Result: </Typography>
                <Typography padding={5} fontSize={24}>
                  <span style={{ color: deleteColor }}>
                    {deleteResult}
                  </span>
                </Typography>
                <DialogActions>
                  <Box display="flex" justifyContent="center" width="100%" >
                    <Button onClick={handleReset} variant="contained" color={theme.nextbuttoncolor}>
                      Done
                    </Button>
                  </Box>
                </DialogActions>
              </DialogContent>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
