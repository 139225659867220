import React from 'react';
import ReactDOM from 'react-dom/client'; // Import createRoot from React 18
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { RoundedIconButton } from "../../themes/rounded-icon-button";

class CloseModalControl implements maplibregl.IControl {
    private container: HTMLElement;
    private closeModal: () => void;
    private root: ReactDOM.Root | null = null; // Store a reference to the React root

    constructor(closeModal: () => void) {
        this.closeModal = closeModal;
        this.container = document.createElement('div');
        this.container.className = 'maplibregl-ctrl';
    }

    onAdd(map: maplibregl.Map): HTMLElement {
        // Create a React root and render the button component
        this.root = ReactDOM.createRoot(this.container);
        this.root.render(
            <RoundedIconButton
                onClick={this.closeModal}
                variant="contained"
                color="error"
                // Override default Maplibre control button style
                className='custom-map-control-button-back'
            >
                <FullscreenExitIcon />
            </RoundedIconButton>
        );

        return this.container;
    }

    onRemove() {
        setTimeout(() => {}, 1000); // This is a workaround to avoid an unmount race condition
        if (this.root) {
            setTimeout(() => {
                this.root!.unmount();
                if (this.container.parentNode) {
                    this.container.parentNode.removeChild(this.container);
                }
            }, 0);
        } else {
            if (this.container.parentNode) {
                this.container.parentNode.removeChild(this.container);
            }
        }
    }
}

export default CloseModalControl;
