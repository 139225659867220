/*CONFLUENCE DOCUMENTATION: https://airweigh.atlassian.net/l/cp/Kc3A9urW */

import {
	Box,
	Card,
	CardContent,
	CardHeader,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
} from "@mui/material";
import { useEffect, useState } from "react";
import { ClientSelectionCreation } from "./client-selection-creation";
import { newuser } from "../../../utils/interfaces/new-user/new-user-interface";

export const SelectUserType = (props: {
	SelectUserTypeCallback: Function;
	newUser: newuser;
	nextButtonCallback: Function;
	setClientCallback: Function;
	enterInformationDomain: Function;
	setClientId: Function;
	validateAndSetError: Function;
	setCurClientName: Function;
	handleNext: Function;
	isDisabled?: boolean; // Add the isDisabled prop
}) => {
	const [usertype, setUserType] = useState<string>(
		props.newUser.user_type ? props.newUser.user_type : ""
	);
	const [isclients, setIsClients] = useState<boolean>(false);

	//on component reload, check if usertype is already chosen and if
	// dropdown/clientselectioncreation should be displayed
	useEffect(() => {
		isButtonDisabled();
		isDropdown();
	}, []);

	const handleChange = (event: SelectChangeEvent) => {
		setUserType(event.target.value);
		props.SelectUserTypeCallback(event.target.value);
		props.nextButtonCallback(false);
		if (event.target.value === "corp") {
			setIsClients(true);
		} else {
			setIsClients(false);
		}
	};

	//check if next button is disabled or not and set
	function isButtonDisabled() {
		if (props.newUser.user_type) {
			return props.nextButtonCallback(false);
		} else {
			return props.nextButtonCallback(true);
		}
	}

	//check if client selection creation is available or not
	// based on aw or corp user choice
	function isDropdown() {
		if (props.newUser.user_type === "corp") {
			setIsClients(true);
		}
	}

	return (
		<>
			<Card>
				<CardHeader title="Select User Type" />
				<CardContent>
					<Box minWidth={120} paddingTop={2} paddingBottom={2}>
						<FormControl fullWidth>
							<InputLabel id="usertype">User Type</InputLabel>
							<Select
								labelId="usertype"
								id="simple-select"
								value={usertype}
								label="User Type"
								onChange={handleChange}
								disabled={props.isDisabled} // Use the isDisabled prop here
							>
								<MenuItem value={"aw"}>Air-Weigh</MenuItem>
								<MenuItem value={"corp"}>Corporate</MenuItem>
							</Select>
						</FormControl>
					</Box>
					{isclients ? (
						<ClientSelectionCreation
							nextButtonCallback={props.nextButtonCallback}
							setClientCallback={props.setClientCallback}
							enterInformationDomain={props.enterInformationDomain}
							setClientId={props.setClientId}
							validateAndSetError={props.validateAndSetError}
							newUser={props.newUser}
							setCurClientName={props.setCurClientName}
							handleNext={props.handleNext}
						/>
					) : null}
				</CardContent>
			</Card>
		</>
	);
};
