import styled from "@emotion/styled";
import { Button, ButtonProps, keyframes } from "@mui/material";
import { green } from "@mui/material/colors";

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const ColorButton = styled(Button)<ButtonProps>(() => ({
  "&:hover": {
    backgroundColor: green[300],
    animation: `${fadeIn} 0.3s ease`,
    padding: "6px 16px",
    position: "relative",
  },
  "&:not(:hover)": {
    position: "static",
  },
}));