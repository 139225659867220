//fetch if the user can register or not from route <BASE>/users/available

import { Auth } from 'aws-amplify';
import { buildBaseUrl } from "../build-base-url";

// const API_BASE_URL = 'http://localhost:8080/users/available';

export const checkAvailableUsers = async (
  email: string | undefined,
  username: string | undefined
) => {
  try {

    const baseApiUrl = buildBaseUrl();

    if (!email && !username) {
      throw new Error('Please provide either username or email or both to check if you are eligible ot create an account, API fetch error');
    }

    // const url = new URL(API_BASE_URL);
    // const authData = await Auth.currentAuthenticatedUser();

    const url = new URL(`${baseApiUrl}users/available`); // the / after baseurl is included in baseurl

    if (email) {
      url.searchParams.append('email', email);
    }

    if (username) {
      url.searchParams.append('username', username);
    }

    const response = await fetch(url.toString(),
    {
      method: 'GET',
      // headers: {
      //   Authorization: `Bearer ${authData.signInUserSession.accessToken.jwtToken}`,
      // },
    },
    );
    
    if (!response.ok) {
      throw new Error('Failed to fetch user availability');
    }

    var responsedata = await response.json();
    return responsedata;
  } catch(err){
    console.log(err);
    }
};