/*CONFLUENCE DOCUMENTATION: https://airweigh.atlassian.net/l/cp/Kc3A9urW */

import { Box, Divider } from "@mui/material";
import { NewUserStepper } from "./stepper";
import { useContext } from "react";
import { AppContext } from "../../utils/themes/theme-context";

export default function NewUser() {
	var { perms } = useContext(AppContext);

	return (
		<>
			<Box flexGrow={1} marginLeft={"5%"} marginRight={"5%"} marginTop={10}>
				<h2>New User</h2>
				<Divider />
				{perms ? (
					<NewUserStepper
						curusertype={perms.user_type}
						curclientid={perms.user_id}
					/>
				) : null}
			</Box>
		</>
	);
}
