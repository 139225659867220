import { Auth } from "aws-amplify";
import { buildBaseUrl } from "../build-base-url";

export const checkClientAvailability = async (client: string) => {
	const url = buildBaseUrl()
	if (!client) {
		console.log("no input");
		return;
	}
	try {
		const authData = await Auth.currentAuthenticatedUser();
		const response = await fetch(
			`${url}clients/available?client=${client}`,
			{
				headers: {
					Authorization: `Bearer ${authData.signInUserSession.accessToken.jwtToken}`,
				},
			}
		);
		return response.json();
	} catch (err) {
		console.log(err);
	}
};
