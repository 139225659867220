export type PermsType = {
	[key: string]: string;
  };

export let aw_perms_name: PermsType = {
	clients_read: "Read Clients",
	clients_create: "Create Clients",
	clients_edit: "Edit Clients",
	clients_delete: "Delete Clients",
	dashboard_read: "Read Dashboard",
	device_create: "Create Device",
	device_edit: "Edit Device",
	device_delete: "Delete Device",
	firmware_create: "Create Firmware",
	firmware_edit: "Edit Firmware",
	firmware_read: "Read Firmware",
	integrations_read: "Read Integrations",
	integrations_edit: "Edit Integrations",
	integrations_trigger_update: "Trigger Integrations Update",
	report_read: "Read Report",
	service_plan_create: "Create Service Plans",
	service_plan_edit: "Edit Service Plans",
	service_plan_delete: "Delete Service Plans",
	users_read: "Read Users",
	user_create: "Create Users",
	user_edit: "Edit Users",
	vehicle_create: "Create Vehicles",
	vehicle_edit: "Edit Vehicles",
	vehicle_delete: "Delete Vehicles",
	vehicle_tickets_create: "Create Vehicle Tickets",
	vehicle_tickets_edit: "Edit Vehicle Tickets",
	vehicle_tickets_delete: "Delete Vehicle Tickets",
	weight_record_edit: "Edit Weight Records",
	weight_record_delete: "Delete Weight Records",
	weight_record_create: "Create Weight Records",
};  

export let corp_perms_name: PermsType = {
	users_create: "Create New Users",
	users_edit: "Edit Users",
	users_read: "Read Users",
};

export let iweigh_perms_name: PermsType = {
	calibration_read : "Read Calibration",
	calibration_edit: "Edit Calibration",
	configuration_profiles_read: "Read Configuration Profiles",
	configuration_profiles_edit : "Edit Configuration Profiles",
	device_registration: "Register a New Device",
	device_unregister: "Unregister a Device",
	linked_assets_read : "Read Devices Linked Assets",
	linked_assets_edit: "Edit current Linked Assets",
	preferences_read: "Read Device Preferences",
	preferences_edit : "Edit Device Preferences",
	sensor_setup_read: "Read Current Sensor Setup Configuration",
	sensor_setup_edit: "Edit Current Sensor Setup Configuration",
	update_firmware: "Update Device Firmware",
};

export let aw_perms_descriptions: PermsType = {
	clients_read: "Whether or not the user can access and read the clients page",
	clients_create: "Whether or not the user can create a new client",
	clients_edit: "Whether or not the user can edit a client",
	clients_delete: "Whether or not the user can delete a client",
	dashboard_read: "Whether or not the user can access the dashboard",
	device_create: "Whether or not the user can create a new device",
	device_edit: "Whether or not the user can edit a device",
	device_delete: "Whether or not the user can delete a device",
	firmware_create: "Whether or not the user can create a new firmware version",
	firmware_edit: "Whether or not the user can edit a firmware version",
	firmware_read: "Whether or not the user can access the firmware page",
	integrations_read: "Whether or not the user can access the integrations page",
	integrations_edit: "Whether or not the user can edit an integration partner detail",
	integrations_trigger_update: "Whether or not the user can trigger an integration partner update",
	report_read: "Whether or not the user can generate reports",
	service_plan_create: "Whether or not the user can create a new service plan",
	service_plan_edit: "Whether or not the user can edit the details of a service plan",
	service_plan_delete: "Whether or not the user can delete a service plan",
	users_read: "Whether or not the user can access the users page",
	user_create: "Whether or not the user can create other users",
	user_edit: "Whether or not the user can edit/manage other users",
	vehicle_create: "Whether or not the user can create a new vehicle",
	vehicle_edit: "Whether or not the user can edit a vehicle record",
	vehicle_delete: "Whether or not the user can delete a vehicle record",
	vehicle_tickets_create: "Whether or not the user can access the support tickets page",
	vehicle_tickets_edit: "Whether or not the user can edit support tickets",
	vehicle_tickets_delete: "Whether or not the user can delete support tickets",
	weight_record_edit: "Whether or not the user can edit weight records",
	weight_record_delete: "Whether or not the user can delete weight records",
	weight_record_create: "Whether or not the user can create weight records",
};

export let corp_perms_descriptions: PermsType = {
	users_create: "Whether or not the user can create other users",
	users_edit: "Whether or not the user can edit users",
	users_read: "Whether or not the user can access the users page",
};

export let iweigh_perms_descriptions: PermsType = {
	calibration_read : "Whether or not the user has permission to read the current calibration                                                                       ",
	calibration_edit: "Whether or not the user has permission to edit the current calibration",
	configuration_profiles_read: "Whether or not the user has permission to read and use the available configuration profiles",
	configuration_profiles_edit : "Whether or not the user has permission to create and edit configuration profiles",
	device_registration: "Whether or not the user has permission to register a device",
	device_unregister: "Whether or not the user has permission to unregister a device",
	linked_assets_read : "Whether or not the user has permission to read a devices linked assets",
	linked_assets_edit: "Whether or not the user has permission to edit the current linked assets",
	preferences_read: "Whether or not the user has the ability to read the devices preferences",
	preferences_edit : "Whether or not the user has the ability to edit the device preferences",
	sensor_setup_read: "Whether or not the user has the ability to read the current sensor setup configuration",
	sensor_setup_edit: "Whether or not the user has the ability to edit the current sensor setup configuration",
	update_firmware: "Whether or not the user has the ability to update the device firmware",
};