//Custom SignUp Page Stepper for User Sign Up

import { View, Heading, Button } from "@aws-amplify/ui-react";
import {
  Card,
  Stack,
  TextField,
  Stepper,
  Step,
  StepLabel,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { checkAvailableUsers } from "./utils/requests/new-user-registration/get-new-user-registration";
import "./global.css";
import {
  validateDomain,
  validateEmail,
  validateName,
  validatePhoneNumber,
  validateUsername,
} from "./utils/regex/forms";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { PostNewUserRegistration } from "./utils/interfaces/new-user-registration/new-user-registration-post-interface";
import { postNewUserRegistration } from "./utils/requests/new-user-registration/post-new-user-registration";

const initialFormState = {
  username: "",
  email: "",
  domain: "",
  fleetName: "", //clientName changed to fleetName
  contactName: "",
  contactPhone: "",
};

type CustomSignUpProps = {
  goToLogin: () => void;
  setCurrentPage: (page: string) => void;
};

const CustomSignUp: React.FC<CustomSignUpProps> = ({
  goToLogin,
  setCurrentPage,
}) => {
  const [registrarEmails, setRegistrarEmails] = useState([]);
  const [isEligible, setIsEligible] = useState<boolean | null>(null);
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const [emailError, setEmailError] = useState("");
  const [userNameError, setUsernameError] = useState("");
  const [fleetNameError, setFleetNameError] = useState("");
  const [contactNameError, setContactNameError] = useState("");
  const [contactPhoneError, setcontactPhoneError] = useState("");
  const [isAccountCreated, setIsAccountCreated] = useState(false);

  const userName = useRef("");
  const email = useRef("");
  const fleetName = useRef("");
  const contactName = useRef("");
  const contactPhone = useRef("");
  const domainName = useRef("");

  const handleNextStep = () => {
    let hasError = false; //for simultanoeus error checks

    // Validate contact name
    if (contactName.current === "") {
      setContactNameError("Contact Name cannot be blank");
      contactName.current = "";
      hasError = true;
    } else if (contactName.current.length < 3) {
      setContactNameError("Must have at least 3 alphanumeric characters");
      contactName.current = "";
      hasError = true;
    }

    // Validate contact phone
    if (contactPhone.current === "") {
      setcontactPhoneError("Contact Phone cannot be blank");
      contactPhone.current = "";
      hasError = true;
    }

    // Validate domain
    if (domainName.current === "") {
      domainName.current = "";
    }

    // Validate fleet name
    if (fleetName.current === "") {
      setFleetNameError("Fleet Name cannot be blank");
      fleetName.current = "";
      hasError = true;
    }

    // Check if any errors occurred
    if (
      hasError ||
      userNameError ||
      emailError ||
      contactNameError ||
      contactPhoneError
    ) {
      return;
    }

    setIsNextButtonEnabled(true);
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handlePreviousStep = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleFormChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;

    // Validate email and set error message

    if (name === "username") {
      userName.current = value;
      const errorMessageUsername = validateUsername(
        value,
        "Invalid Username. Must have atleast 3 alphanumeric characters. Username must start with a letter and can only contain letters, numbers, and underscores."
      );
      setUsernameError(errorMessageUsername);
    } else if (name === "email") {
      email.current = value;
      const errorMessageEmail = validateEmail(value, "Invalid email address");
      setEmailError(errorMessageEmail);
      setIsNextButtonEnabled(false);
    } else if (name === "contactName") {
      contactName.current = value;
      const errorMessageContactName = validateName(
        value,
        "Invalid Contact Name"
      );
      setContactNameError(errorMessageContactName);
      setIsNextButtonEnabled(false);
    } else if (name === "contactPhone") {
      contactPhone.current = value;
      const errorMessageContactPhone = validatePhoneNumber(
        value,
        "Invalid Contact Phone"
      );
      setcontactPhoneError(errorMessageContactPhone);
      setIsNextButtonEnabled(false);
    } else if (name === "domain") {
      domainName.current = value;
    } else if (name === "fleetName") {
      fleetName.current = value;
      const errorMessageFleetName = validateName(value, "Invalid Fleet Name");
      setFleetNameError(errorMessageFleetName);
      setIsNextButtonEnabled(false);
    }

    setIsNextButtonEnabled(true);

    // Extract string after @ for domain name
    if (name === "email") {
      const atIndex = value.indexOf("@");
      if (atIndex !== -1 && atIndex + 1) {
        const domain = value.substring(atIndex + 1);
        domainName.current = domain;
      } else {
        domainName.current = "";
      }
    }
  };

  const goToSignInPage = () => {
    goToLogin();
    setCurrentPage("login");
  };

  const handleSubmit = async () => {
    let hasError = false; //This ensures that both conditions are checked simultaneously, and errors for both fields are handled.

    if (userName.current === "") {
      setUsernameError("Username cannot be blank");
      userName.current = "";
      hasError = true;
    } else if (userName.current.length < 3) {
      setUsernameError("Username must have at least 3 alphanumeric characters");
      userName.current = "";
      hasError = true;
    }

    if (email.current === "") {
      setEmailError("Email cannot be blank");
      email.current = "";
      hasError = true;
    }

    if (hasError || userNameError) {
      return;
    }

    if (userName || email) {
      try {
        // Check if user is eligible to register using API call
        const response = await checkAvailableUsers(
          email.current,
          userName.current
        );
        const data = await response;

        if (data.self_registration_available) {
          //check if the user is eligibile to create an account
          if (data.username_available) {
            //check username availability
          } else {
            setUsernameError("Username is not Available");
            userName.current = "";
            return;
          }
          if (data.email_available) {
            //check email availability
            setIsNextButtonEnabled(true);
            setIsEligible(true);
            setActiveStep((prevStep) => prevStep + 1);
          } else {
            setEmailError("Email is not available");
            email.current = "";
            setIsNextButtonEnabled(false);
            return;
          }
        } else {
          // User is not eligibble to create an account
          setIsEligible(false);
          setIsNextButtonEnabled(false);
          setRegistrarEmails(data.registrar_email);
        }
      } catch (error) {
        // API call errors here
        console.error("Error while checking user availability:", error);
        alert(
          "An error occurred while checking user availability. Please try again later."
        );
      }
    } else {
      setIsEligible(null); // Reset the eligibility message
    }
  };

  const handleCreateAccount = async () => {
    const username = userName.current;

    const clientInfo = {
      client: fleetName.current, //clientName changed to fleetName in frontend
      contact: contactName.current,
      contact_email: email.current,
      contact_phone: contactPhone.current,
      comments: "",
    };

    const newUser: PostNewUserRegistration = {
      username,
      user_email: email.current,
      user_domain: domainName.current,
      user_type: "corp",
      user_name: contactName.current,
      client_id: "",
      client_info: clientInfo,
    };

    try {
      await postNewUserRegistration(newUser);
      setIsAccountCreated(true);

      setActiveStep((prevStep) => prevStep + 1);
    } catch (error) {
      console.error("Error creating user:", error);
      setIsAccountCreated(false);
    }
  };

  const steps = [
    "Eligibility Check",
    "Account Information",
    "Review",
    "Confirmation",
  ];

  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <View textAlign="center">
              <h3>Check if you are eligible to create an account</h3>
            </View>
            <Stack direction="column" spacing={2} justifyContent="center">
              <TextField
                label="Username"
                id="username"
                name="username"
                onChange={handleFormChange}
                placeholder="Enter Username"
                fullWidth
                defaultValue={userName.current}
                error={!!userNameError}
                helperText={userNameError}
              />

              <TextField
                label="Email"
                name="email"
                onChange={handleFormChange}
                placeholder="Enter Email"
                fullWidth
                defaultValue={email.current}
                error={!!emailError}
                helperText={emailError}
              />
            </Stack>
            {isEligible === true && (
              <p style={{ color: "green" }}>
                You are eligible to create an account
              </p>
            )}
            {isEligible === false && (
              <div style={{ color: "red" }}>
                <p>
                  You are not eligible to create an account as your domain has
                  already been registered with Air-Weigh.
                  <br /> Please contact the registrar(s) at:
                </p>
                <ul>
                  {registrarEmails.map((email) => (
                    <li key={email}>
                      <a href={`mailto:${email}`}>{email}</a>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {isEligible === null && <br />}
            <View textAlign="center">
              <Button
                className="navigation-button"
                onClick={handleSubmit}
                isFullWidth
              >
                Check Eligibility
              </Button>
            </View>
          </>
        );
      case 1:
        return (
          <>
            <View textAlign="center">
              <h3>Enter Account Information</h3>
            </View>
            <Stack direction="column" spacing={2} justifyContent="center">
              <Stack direction="row" spacing={2} justifyContent="center">
                <TextField
                  label="Username"
                  id="username"
                  name="username"
                  onChange={handleFormChange}
                  placeholder="Enter Username"
                  fullWidth
                  disabled
                  value={userName.current}
                />

                <TextField
                  label="Email"
                  name="email"
                  onChange={handleFormChange}
                  placeholder="Enter Email"
                  fullWidth
                  disabled
                  value={email.current}
                />
              </Stack>
              <Stack direction="row" spacing={2} justifyContent="center">
                <TextField
                  label="Fleet Name" //Client Name changed to Fleet Name as per meeting
                  name="fleetName"
                  onChange={handleFormChange}
                  placeholder="Enter Fleet Name"
                  fullWidth
                  defaultValue={fleetName.current}
                  error={!!fleetNameError}
                  helperText={fleetNameError}
                />
              </Stack>
              <Stack direction="row" spacing={2} justifyContent="center">
                <TextField
                  label="Contact Name"
                  name="contactName"
                  onChange={handleFormChange}
                  placeholder="Enter Contact Name"
                  fullWidth
                  defaultValue={contactName.current}
                  error={!!contactNameError}
                  helperText={contactNameError}
                />

                <TextField
                  label="Contact Number"
                  name="contactPhone"
                  onChange={handleFormChange}
                  placeholder="Enter Contact Number"
                  fullWidth
                  defaultValue={contactPhone.current}
                  error={!!contactPhoneError}
                  helperText={contactPhoneError}
                />
              </Stack>
            </Stack>
          </>
        );

      case 2:
        return (
          <>
            <View textAlign="center">
              <h3>Review Account Information</h3>
              <hr />
            </View>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <strong>Username:</strong>
                    </TableCell>
                    <TableCell>{userName.current}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Email:</strong>
                    </TableCell>
                    <TableCell>{email.current}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Fleet Name:</strong>
                    </TableCell>
                    <TableCell>{fleetName.current}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Contact Name:</strong>
                    </TableCell>
                    <TableCell>{contactName.current}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Contact Phone:</strong>
                    </TableCell>
                    <TableCell>{contactPhone.current}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        );

      case 3:
        return (
          <>
            <View textAlign="center">
              <h3>Account Creation Status</h3>
            </View>
            {isAccountCreated ? (
              <>
                <p style={{ color: "green", fontWeight: "bold" }}>
                  <span
                    style={{ display: "block" }}
                  >{`Account Created Successfully`}</span>
                </p>
                <p style={{ color: "black" }}>
                  As the first person to create an account with the Domain Name
                  "{domainName.current}" you will be the point of contact for
                  all the others who need to create an account with this domain
                  name
                  <br />
                  <p style={{ color: "black" }}>
                    You will be recieving an email at{" "}
                    <a href={`mailto:${email.current}`}>{email.current}</a> with
                    a temporary password for the first time you sign into your
                    account.
                  </p>
                </p>
                <View textAlign="center">
                  <Button
                    className="navigation-button"
                    color="primary"
                    onClick={goToSignInPage}
                    style={{ marginTop: "16px" }}
                  >
                    Sign In
                  </Button>
                </View>
              </>
            ) : (
              <p style={{ color: "red" }}>{"Error Creating Account"}</p>
            )}
          </>
        );

      default:
        return null;
    }
  };

  return (
    <div>
      <div className="card-container">
        <Card
          className="sign-in-card"
          style={{ maxWidth: "500px", width: "90%", margin: "0px auto" }}
        >
          <div className="sign-in-card-inner">
            <Stack spacing={3}>
              <View textAlign="center">
                <Heading level={3} style={{ textAlign: "center" }}>
                  <img
                    alt="Air-Weigh logo"
                    src="/images/color-Air-Weigh_sans_subtext-web.png"
                    width={300}
                    height={80}
                  />
                </Heading>
              </View>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel
                      StepIconProps={{
                        classes: {
                          active: "custom-step-icon",
                          completed: "completed-step-icon",
                          error: "error-step-icon",
                        },
                      }}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
              <form onSubmit={handleSubmit}>
                {getStepContent(activeStep)}
                <View
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {activeStep !== 0 && activeStep !== 3 && (
                    <Button
                      className="navigation-button"
                      color="secondary"
                      onClick={handlePreviousStep}
                      style={{ marginTop: "16px" }}
                    >
                      Back
                    </Button>
                  )}
                  {activeStep !== steps.length - 1 &&
                    activeStep !== 0 &&
                    activeStep !== 2 &&
                    activeStep !== 3 && (
                      <Button
                        className="navigation-button"
                        color="primary"
                        onClick={handleNextStep}
                        disabled={!isNextButtonEnabled}
                        style={{ marginTop: "16px" }}
                      >
                        Next
                      </Button>
                    )}
                  {activeStep === steps.length - 2 && (
                    <Button
                      className="navigation-button"
                      color="primary"
                      style={{ marginTop: "16px" }}
                      onClick={handleCreateAccount}
                    >
                      Create Account
                    </Button>
                  )}
                  {activeStep === steps.length - 3 && activeStep !== 1 && (
                    <Button
                      className="navigation-button"
                      color="primary"
                      type="submit"
                      style={{ marginTop: "16px" }}
                      onClick={goToSignInPage}
                    >
                      Sign In
                    </Button>
                  )}
                </View>
              </form>
              <hr />
              <Stack direction="column" spacing={2} justifyContent="center">
                <View textAlign="center">
                  <img
                    alt="Air-Weigh logo"
                    src="/images/loadinglogo.png"
                    width={80}
                    height={80}
                  />
                </View>
                <View textAlign="center">Already Have an Account?</View>
                <Button
                  fontWeight="normal"
                  size="small"
                  variation="link"
                  onClick={goToSignInPage}
                >
                  Sign In
                </Button>
                {/* state change */}
              </Stack>
            </Stack>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default CustomSignUp;
