import { Auth } from "aws-amplify";
import { newuser } from "../../interfaces/new-user/new-user-interface";
import { buildBaseUrl } from "../build-base-url";

export const postNewUser = async (newUser: newuser) => {
	const url = buildBaseUrl()
	const authData = await Auth.currentAuthenticatedUser();
	const response = await fetch(`${url}users/new`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authData.signInUserSession.accessToken.jwtToken}`,
		},
		body: JSON.stringify(newUser),
	});

	let data = await response.json();
	return data;
};
