import { Auth } from "aws-amplify";
import { buildBaseUrl } from "../build-base-url";

export const getClients = async () => {
	const url = buildBaseUrl()
	try {
		const authData = await Auth.currentAuthenticatedUser();
		const response = await fetch(`${url}clients`, {
			headers: {
				Authorization: `Bearer ${authData.signInUserSession.accessToken.jwtToken}`,
			},
		});
		const data = await response.json();
		return data;
	} catch (err) {
		console.log(err);
	}
};