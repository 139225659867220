import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Stack,
} from "@mui/material";
import { useContext } from "react";
import { AppContext } from "../../../utils/themes/theme-context";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export default function Actions() {

  return (
    <>
      <Card elevation={8} sx={{ borderRadius: 3 }}>
        <CardHeader
          title="Actions"
          action={
            <IconButton aria-label="info">
              <InfoOutlinedIcon />
            </IconButton>
          }
        />
        <CardContent>
          <Divider />
          <Box paddingTop={2}>
            <Stack direction={"column"} spacing={2}>
              <Button variant="contained">Manual Update</Button>
            </Stack>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}
