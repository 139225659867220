import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import {
  Button,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Slide,
  TextField,
} from "@mui/material";
import GeneralTable from "../../utils/generic-components/table/table";
import { getUsers } from "../../utils/requests/users/get-users";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../utils/themes/theme-context";
import { Columns } from "../../utils/interfaces/table/table-interfaces";
import React from "react";
import { getUserSearch } from "../../utils/requests/users/get-user-search";
import ClearIcon from "@mui/icons-material/Clear";
// import LoadMoreButton from "../../utils/generic-components/Load More Button/loadmore";

interface selecteduser {
  Email: string;
  Name: string;
  UserName: string;
}

function UsersHeader() {
  var { perms, theme } = useContext(AppContext);
  const [value, setValue] = useState(0); //tabs
  const [awusers, setAwUsers] = useState<{ Name: string; UserName: string; Email: string; UserType: string }[]>([]);
  const [corpusers, setCorpUsers] = useState<{ Name: string; UserName: string; Email: string; UserType: string }[]>([]);
  const [navigation, setNavigation] = useState<string>("aw");
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingCorp, setLoadingCorp] = useState<boolean>(true);
  const [transitionout, setTransitionOut] = useState<boolean>(true);
  const nextAw = useRef<string | null>(null); // useRef for next page of aw-users
  const nextCorp = useRef<string | null>(null); // useRef for corp users
  const [originalAwUsers, setOriginalAwUsers] = useState([]);
  const [originalCorpUsers, setOriginalCorpUsers] = useState([]);
  const [AWhasMore, setAWHasMore] = useState<boolean>(false); //state that indicates whether there are additional pages of data available to fetch.
  const [CorphasMore, setCorpHasMore] = useState<boolean>(false); //state that indicates whether there are additional pages of data available to fetch.
  const [hasAWMoreSearch, setAWHasMoreSearch] = useState<boolean>(false); //state that indicates whether there are additional pages of data available to fetch.
  const [hasCorpMoreSearch, setCorpHasMoreSearch] = useState<boolean>(false); //state that indicates whether there are additional pages of data available to fetch.
  const [AWsearching, setAWSearching] = useState<boolean>(false); // To track if a search is active
  const [Corpsearching, setCorpSearching] = useState<boolean>(false); // To track if a search is active
  const nextPageAwKeyRef = useRef<string | null>(null); //holds the token for the next page of data.
  const nextPageCorpKeyRef = useRef<string | null>(null); //holds the token for the next page of data.
  const nextPageAWKeySearchRef = useRef<string | null>(null); //holds the token for the next page of data.
  const nextPageCorpKeySearchRef = useRef<string | null>(null); //holds the token for the next page of data.
  const [errorMessage, setErrorMessage] = useState<string>("");

  let userNavigate = useNavigate();
  var rowsPerPage: number = 25;
  var toolbar: boolean = true;
  var newUserButton = (
    <Button
      key={"new user"}
      onClick={() => userNavigate(`/newuser`)}
      color={theme.nextbuttoncolor}
      variant="contained"
    >
      Create New User
    </Button>
  );
  var noDataMessage: string = "No Data Found";
  let rowsoptions = [10, 25];
  const [searchCriterion, setSearchCriterion] = useState("username");
  // State and handlers for AW user search
  const [awSearchTerm, setAwSearchTerm] = useState("");
  const [awResetVisible, setAwResetVisible] = useState(false);

  // State and handlers for Corporate user search
  const [corpSearchTerm, setCorpSearchTerm] = useState("");
  const [corpResetVisible, setCorpResetVisible] = useState(false);

  const createCsvUsersButton = (
    <Button
      key={"create new users via csv"}
      onClick={() => userNavigate("/newusercsv")} // Implement this function
      color={theme.nextbuttoncolor}
      variant="contained"
    >
      Create users via CSV
    </Button>
  );

  const handleAwSearchCriterionChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSearchCriterion(event.target.value);
  };

  const handleCorpSearchCriterionChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSearchCriterion(event.target.value);
  };

  const handleAwSearchTermChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setAwSearchTerm(event.target.value);
  };

  const handleCorpSearchTermChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setCorpSearchTerm(event.target.value);
  };

  const handleAwSearch = async (nextPageKeySearch?: string) => {
    try {
      // Clear previous search results
      setAwUsers([]);
      setLoading(true);
      const searchResponse = await getUserSearch(searchCriterion, awSearchTerm, nextPageKeySearch);
      // Separate users into AW and corporate based on user_type
      const awUsers = searchResponse.data.filter(
        (userData: { user_type: string }) => userData.user_type === "aw"
      );

      //Match the format of the Original Data 
      const transformedAwUsers = awUsers.map((user: any) => {
        return {
          Name: user.username || user.Name,
          UserName: user.user_name || user.UserName,
          Email: user.user_email || user.Email,
          UserType: user.user_type,
        };
      });
      if (nextPageAWKeySearchRef) {
        setAwUsers((prevDevices) => [...prevDevices, ...transformedAwUsers]);
      } else {
        setAwUsers(transformedAwUsers);
      }

      setAWHasMoreSearch(!!searchResponse.next_page);
      nextPageAWKeySearchRef!.current = searchResponse.next_page || null;
      setAWSearching(!!searchResponse.next_page);
      // Update the state with the search results
      // setAwUsers(transformedAwUsers);

      // Update the reset button visibility
      setAwResetVisible(true);
      setLoading(false);
    } catch (error) {
      console.error("Error while searching AW users:", error);
      // Handle errors as needed
    }
  };

  const handleCorpSearch = async (nextPageKeySearch?: string) => {
    try {
      // Clear previous search results
      setCorpUsers([]);
      setLoadingCorp(true);
      const searchResponse = await getUserSearch(
        searchCriterion,
        corpSearchTerm, nextPageKeySearch
      );

      // Separate users into AW and corporate based on user_type
      const corpUsers = searchResponse.data.filter(
        (userData: { user_type: string }) => userData.user_type === "corp"
      );

      //Match the format of the Original Data 
      const transformedCorpUsers = corpUsers.map((user: any) => {
        return {
          Name: user.username || user.Name,
          UserName: user.user_name || user.UserName,
          Email: user.user_email || user.Email,
          UserType: user.user_type,
        };
      });

      if (nextPageAWKeySearchRef) {
        setCorpUsers((prevDevices) => [...prevDevices, ...transformedCorpUsers]);
      } else {
        setCorpUsers(transformedCorpUsers);
      }

      setCorpHasMoreSearch(!!searchResponse.next_page);
      nextPageCorpKeySearchRef!.current = searchResponse.next_page || null;
      setCorpSearching(!!searchResponse.next_page);

      // Update the state with the search results
      // setCorpUsers(transformedCorpUsers);

      // Update the reset button visibility
      setCorpResetVisible(true);
      setLoadingCorp(false);
    } catch (error) {
      console.error("Error while searching Corporate users:", error);
      // Handle errors as needed
    }
  };

  const handleAwReset = () => {
    setAwSearchTerm("");
    // Reset the data to the original table
    setAwUsers(originalAwUsers);
    setAwResetVisible(false);
  };

  const handleCorpReset = () => {
    setCorpSearchTerm("");
    // Reset the data to the original table
    setCorpUsers(originalCorpUsers);
    setCorpResetVisible(false);
  };

  const handleAwSearchKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleAwSearch();
    }
  };

  const handleCorpSearchKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleCorpSearch();
    }
  };

  const awSearchBar = (
    <Box display="flex" alignItems="center">
      <FormControl variant="outlined">
        <Select
          value={searchCriterion}
          onChange={handleAwSearchCriterionChange}
        >
          <MenuItem value="username">User Name</MenuItem>
          <MenuItem value="user_name">User's Full Name</MenuItem>
          <MenuItem value="email">User Email</MenuItem>
          {/* <MenuItem value="email">Client Name</MenuItem> */}
        </Select>
      </FormControl>
      <TextField
        label="Search AW Users"
        variant="outlined"
        value={awSearchTerm}
        onChange={handleAwSearchTermChange}
        onKeyDown={handleAwSearchKeyDown}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {awResetVisible && (
                <IconButton onClick={handleAwReset} edge="end">
                  <ClearIcon />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
      <Button
        variant="contained"
        color="primary"
        style={{ height: "100%" }}
        onClick={() => handleAwSearch()}
      >
        Search
      </Button>
    </Box>
  );

  const corpSearchBar = (
    <Box display="flex" alignItems="center">
      <FormControl variant="outlined">
        <Select
          value={searchCriterion}
          onChange={handleCorpSearchCriterionChange}
        >
          <MenuItem value="username">User Name</MenuItem>
          <MenuItem value="user_name">User's Full Name</MenuItem>
          <MenuItem value="email">User Email</MenuItem>
          {/* <MenuItem value="email">Client Name</MenuItem> */}
        </Select>
      </FormControl>
      <TextField
        label="Search Corp Users"
        variant="outlined"
        value={corpSearchTerm}
        onChange={handleCorpSearchTermChange}
        onKeyDown={handleCorpSearchKeyDown}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {corpResetVisible && (
                <IconButton onClick={handleCorpReset} edge="end">
                  <ClearIcon />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
      <Button
        variant="contained"
        color="primary"
        style={{ height: "100%" }}
        onClick={() => handleCorpSearch()}
      >
        Search
      </Button>
    </Box>
  );

  let columns: Columns = {
    Name: {
      type: perms?.corporate_permissions.users_edit ? "link" : "text", // Use "text" when userEditCorpPerms is false
      display: "Username",
      data: "",
      sort: "Name",
    },
    UserName: "User's Full Name",
    Email: "User's Email",
    ClientName: {
      type: "button",
      data: "",
      sort: "ClientName",
      display: "Client Name",
      onClick: (rowData: { ClientId: any; }) => {
        const clientId = rowData.ClientId;
        userNavigate(`/clients/${clientId}`);
      },
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData("aw", nextAw.current)
      .then((responseaw: any) => {
        const awUsersResponse = responseaw[0].filter(
          (user: any) => user.UserType === "aw"
        );
        setAwUsers(awUsersResponse);
        setOriginalAwUsers(awUsersResponse); // Store the original data
        nextAw.current = responseaw[1];
        nextPageAwKeyRef.current = awUsersResponse.next_page || null;
        setAWHasMore(!!awUsersResponse.next_page);
        setLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoadingCorp(true);
    fetchData("corp", nextCorp.current)
      .then((responsecorp: any) => {
        const corpUsersResponse = responsecorp[0].filter(
          (user: any) => user.UserType === "corp"
        );
        setCorpUsers(corpUsersResponse);
        setOriginalCorpUsers(corpUsersResponse); // Store the original data
        nextCorp.current = responsecorp[1];
        nextPageCorpKeyRef.current = corpUsersResponse.next_page || null;
        setCorpHasMore(!!corpUsersResponse.next_page);
        setLoadingCorp(false);
      })
      .catch((err: any) => {
        console.log(err);
        setLoadingCorp(false);
      });
  }, []);

  const fetchData = async (tab: string, next_page: any) => {
    try {
      setLoading(true);
      const response = await getUsers(tab, next_page);
      return response;
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const tabclickHandler = (choice: string) => {
    setNavigation(choice);
  };

  const handleLoadMore = () => {

    if (AWsearching) {
      // If searching for AW users
      if (nextPageAWKeySearchRef.current) {
        handleAwSearch(nextPageAWKeySearchRef.current);
      }
    } else if (Corpsearching) {
      // If searching for corporate users
      if (nextPageCorpKeySearchRef.current) {
        handleCorpSearch(nextPageCorpKeySearchRef.current);
      }
    } else {
      if (nextPageAwKeyRef.current) {
        fetchData("aw", nextPageAwKeyRef.current);
      } else if (nextPageCorpKeyRef.current) {
        fetchData("corp", nextPageCorpKeyRef.current);
      }
    }
  };

  const getMoreDataCallback = (tab: string) => {
    if (tab === "aw" ? nextAw : nextCorp.current !== "" || null) {
      fetchData(tab, tab === "aw" ? nextAw : nextCorp).then((response: any) => {
        if (tab === "aw") {
          setAwUsers({ ...awusers, ...response[0] });
          nextAw.current = response[1];
        } else {
          setCorpUsers({ ...corpusers, ...response[0] });
          nextAw.current = response[1];
        }
      });
    }
  };

  function handleRowClick(selecteduser: selecteduser) {
    const username = selecteduser.Name;
    setTransitionOut(false);
    setTimeout(() => userNavigate(`/users/${username}`), 400);
  }

  function handleNewTabRowClick(selecteduser: selecteduser) {
    const username = selecteduser.Name;
    // setTransitionOut(false);
    window.open(`/users/${username}`, "_blank");
  }

  return (
    <>
      <Slide direction="right" in={transitionout} timeout={400}>
        <Box flexGrow={1} marginLeft={"5%"} marginRight={"5%"} marginTop={10} marginBottom={10}>
          <h2>User Dashboard</h2>
          <Divider />
          <Box>
            {perms?.user_type === "aw" && (
              <Tabs value={value} onChange={handleTabChange}>
                <Tab onClick={() => tabclickHandler("aw")} label="AW" />
                <Tab
                  onClick={() => tabclickHandler("Corporate")}
                  label="Corporate"
                />
              </Tabs>
            )}
            {/* {perms?.user_type === "corp" && (
						<Tabs value={value} onChange={handleTabChange}>
							<Tab onClick={() => tabclickHandler("Corporate")} label="Corporate" />
						</Tabs>
						)} */}
          </Box>
          {navigation === "aw" && perms?.user_type !== "corp" ? (
            <>
              <GeneralTable
                data={awusers}
                columns={columns}
                toolbar={toolbar}
                toolbarActions={[
                  awSearchBar,
                  newUserButton,
                  createCsvUsersButton,
                ]}
                loading={loading}
                noDataMessage={noDataMessage}
                // additionalPageResultsCallback={getMoreDataCallback}
                rowClickCallback={handleRowClick}
                rows={rowsPerPage}
                rowsoptions={rowsoptions}
                rowNewTabClickCallback={handleNewTabRowClick} />
              {/* <LoadMoreButton
                hasMore={AWsearching ? hasAWMoreSearch : AWhasMore}
                loading={loading}
                onLoadMore={handleLoadMore}
                errorMessage={errorMessage} /> */}
            </>
          ) : (
            //need to disable the button for the corp users with no permission to add new user.
            <>
              <GeneralTable
                data={corpusers}
                columns={columns}
                toolbar={toolbar}
                toolbarActions={[
                  corpSearchBar,
                  perms?.corporate_permissions.users_create ? (
                    <React.Fragment key="newUserAndCsvButtons">
                      {" "}
                      {/* to resolve the waring each child needs to have a unique key. */}
                      {newUserButton}
                      {createCsvUsersButton}
                    </React.Fragment>
                  ) : (
                    <Button key={"new user"} variant="contained" disabled>
                      Create New User
                    </Button>
                  ),
                ]}
                loading={loadingCorp}
                noDataMessage={noDataMessage}
                // additionalPageResultsCallback={getMoreDataCallback}
                rowClickCallback={handleRowClick}
                rows={rowsPerPage}
                rowsoptions={rowsoptions}
                rowNewTabClickCallback={handleNewTabRowClick} />
              {/* <LoadMoreButton
                hasMore={Corpsearching ? hasCorpMoreSearch : CorphasMore}
                loading={loadingCorp}
                onLoadMore={handleLoadMore}
                errorMessage={errorMessage} /> */}
            </>
          )}
        </Box>
      </Slide>
    </>
  );
}

export default UsersHeader;
