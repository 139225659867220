import DetailsTable from "../../../utils/generic-components/Details Table/details-table";
import { DetailTableData } from "../../../utils/interfaces/details-table/details-table-interfaces";
import { rfcToDateAndTime } from "../../../utils/custom-functions/rfc3339-conversions";
import { Columns } from "../../../utils/interfaces/table/table-interfaces";
import { Integration } from "../../../utils/interfaces/integration/integration-interface";


export default function Details(props: { integrator: Integration }) {
	let DetailTableData: DetailTableData = {
		data: [
			{
				label: "Name",
				content: props.integrator.name,
				editable: true,
				error: "",
			},
            {
				label: "Integration Type",
				content: props.integrator.integration_type,
				editable: true,
				error: "",
			},
            {
				label: "Contact",
				content: props.integrator.contact,
				editable: true,
				error: "",
			},
            {
				label: "Contact Email",
				content: props.integrator.contact_email,
				editable: true,
				error: "",
			},
            {
				label: "Contact Phone",
				content: props.integrator.contact_phone,
				editable: true,
				error: "",
			},
            {
				label: "Comments",
				content: props.integrator.comments,
				editable: true,
				error: "",
			},
			{
				label: "Updated At",
				content: rfcToDateAndTime(props.integrator.updated_at),
				editable: false,
				error: "",
			},
		],
	};

    let columns: Columns = {
        Name: {
          type: "link",
          display: "Name",
          data: "",
          sort: "Name",
        },
        IntegrationType: "Integration Type",
        IntegrationInterval: "Integration Interval",
        Contact: {
          type: "text",
          display: "Contact",
          data: "contact",
          sort: "Contact",
        },
        ContactEmail: {
          type: "text",
          display: "Contact Email",
          data: "contact_email",
          sort: "ContactEmail",
        },
        ContactPhone: {
          type: "text",
          display: "Contact Phone",
          data: "contact_phone",
          sort: "ContactPhone",
        },
        Comments: {
          type: "text",
          display: "Comments",
          data: "comments",
          sort: "Comments",
        },
        UpdatedAt: {
          type: "time",
          display: "Updated At",
          data: "updated_at",
          sort: "UpdatedAt",
        },
        Intervals: {
          type: "subhead",
          display: "Intervals",
          data: "Intervals",
          subColumns: {
            UpdatedAt: {
              type: "time",
              display: "Updated At",
              data: "updated_at", // Access nested data
            },
            DateTime: {
              type: "time",
              display: "Date Time",
              data: "date_time",
              sort: "DateTime",
            },
            IntegratorId: {
              type: "text",
              display: "Integrator Id",
              data: "integrator_id",
              sort: "IntegratorId",
            },
            ClientId: {
              type: "text",
              display: "Client Id",
              data: "client_id",
              sort: "ClientId",
            },
            Success: {
              type: "text",
              display: "Success",
              data: "success",
              sort: "Success",
            },
            ErrorMessage: {
              type: "text",
              display: "Error Message",
              data: "error_message",
              sort: "ErrorMessage",
            },
            TotalUnits: {
              type: "number",
              display: "Total Units",
              data: "total_units",
              sort: "TotalUnits",
            },
            UpdatedUnits: {
              type: "number",
              display: "Updated Units",
              data: "updated_units",
              sort: "UpdatedUnits",
            },
            NewUnits: {
              type: "number",
              display: "New Units",
              data: "new_units",
              sort: "NewUnits",
            },
            StaleUnits: {
              type: "number",
              display: "Stale Units",
              data: "stale_units",
              sort: "StaleUnits",
            },
            geotab_interval: {
              type: "subhead",
              display: "GeoTab Intervals",
              data: "geotab_interval",
              subColumns: {
                GrossFeed: {
                  type: "text",
                  display: "Gross Feed",
                  data: "gross_feed",
                  sort: "GrossFeed",
                },
                GrossQualityFeed: {
                  type: "text",
                  display: "Gross Quality Feed",
                  data: "gross_quality_feed",
                  sort: "GrossQualityFeed",
                },
                NetFeed: {
                  type: "text",
                  display: "Net Feed",
                  data: "net_feed",
                  sort: "NetFeed",
                },
                SteerFeed: {
                  type: "text",
                  display: "Steer Feed",
                  data: "steer_feed",
                  sort: "SteerFeed",
                },
                DriveFeed: {
                  type: "text",
                  display: "Drive Feed",
                  data: "drive_feed",
                  sort: "DriveFeed",
                },
                TrailerFeed: {
                  type: "text",
                  display: "Trailer Feed",
                  data: "trailer_feed",
                  sort: "TrailerFeed",
                },
                DriveSteerQualityFeed: {
                  type: "text",
                  display: "Drive Steer Quality Feed",
                  data: "drive_steer_quality_feed",
                  sort: "DriveSteerQualityFeed",
                },
                TrailerQualityFeed: {
                  type: "text",
                  display: "Trailer Quality Feed",
                  data: "trailer_quality_feed",
                  sort: "TrailerQualityFeed",
                },
              },
            },
          },
        },
      };

	//extend functionality in the future
	const putCallback = async (
		data: DetailTableData,
		setAlert: Function,
		setEditingMode: Function,
		setLoadingButton: Function
	) => {
		// try{
		// 	const latestProductsData = await fetchSingleProductData(props.products.id);

		// 	const updatedProductDetails: ProductListData = {
		// 		...latestProductsData,
		// 		//updating all the data 
		// 		name: data.data[0].content !== null ? data.data[0].content : '',
		// 		release_date: data.data[1].content !== null ? data.data[1].content : '',
		// 		comments: data.data[2].content !== null ? data.data[2].content : '',
		// 		updated_at: props.products.updated_at, // Include the updated_at value passed from the prop
		// 	};

		// 	// Include the updated_at value from the latest client data
		// 	updatedProductDetails.updated_at = latestProductsData.updated_at;
	

		// 	try {
		// 		const response = await putUpdatedProductDetails(updatedProductDetails);

		// 		if (response && response.data) {
		// 			const fetchedUpdatedProducts = response.data;

		// 			const isUpdated =
		// 			fetchedUpdatedProducts.name === updatedProductDetails.name &&
		// 				rfcToDate(fetchedUpdatedProducts.registration_date) === updatedProductDetails.release_date &&
		// 				fetchedUpdatedProducts.comments === updatedProductDetails.comments;

		// 			if (isUpdated) {
		// 				console.log("Data is updated");
		// 			} else {
		// 				console.log("Data is not updated");
		// 			}
		// 		} else {
		// 			console.log("Error updating product data");
		// 		}
		// 	} catch (error) {
		// 		console.log("Error updating product data:", error);
		// 		setAlert("Unable to save, Try Again");
		// 	}
		// } catch (error) {
		// 	console.log("Error fetching latest product data:", error);
		// 	setAlert("Unable to save, Try Again");
		// }

		setEditingMode(false);
		setLoadingButton(false);
	};

	return (
		<>
			<DetailsTable
				data={DetailTableData}
				putCallback={putCallback}
				editing={true}
				close={false}
			/>
		</>
	);
}
