//SNI - Serial Number Identification
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { Columns } from "../../utils/interfaces/table/table-interfaces";
import GeneralTable from "../../utils/generic-components/table/table";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../utils/themes/theme-context";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { ProductListData, getProductListData } from "../../utils/interfaces/products/products-list-interface";
import { getProducts } from "../../utils/requests/products/get-products";
import { Stepper, Step, StepLabel, StepContent } from "@mui/material";
import { createNewProduct } from "../../utils/requests/products/create-new-product";
import {
  validateProductName,
  validateProductSNI,
} from "../../utils/regex/forms";
import { putUpdatedProductDetails } from "../../utils/requests/products/put-updated-product-details";
import ProductSearchBar from "./product-search/product-search";
import CloseIcon from "@mui/icons-material/Close";
import LoadMoreButton from "../../utils/generic-components/Load More Button/loadmore";

export default function ProductList() {
  var { theme } = useContext(AppContext);
  const [transitionout, setTransitionOut] = useState<boolean>(true);
  let userNavigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true); //initial stage of loading is true
  const [searchTerm, setSearchTerm] = useState<string>(""); // State for search term
  const [products, setProducts] = useState<ProductListData[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [filteredProducts, setFilteredProducts] = useState<ProductListData[]>([]);
  const [anchorEls, setAnchorEls] = useState<Array<null | HTMLElement>>([]); //controls the position of the actions menu
  const [isNewProductDialogOpen, setNewProductDialogOpen] = useState(false);
  const [newProductName, setNewProductName] = useState("");
  const [newProductSNI, setNewProductSNI] = useState("");
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setEditDialogOpen] = useState(false);
  const [editedProduct, setEditedProduct] = useState<ProductListData | null>(
    null
  );
  const editedProductNameRef = useRef<string>("");
  const editedCommentsRef = useRef<string>("");
  const editDialogRef = useRef<HTMLDivElement | null>(null); //editDialogRef is used to access the content of the edit dialog. This allows the edited values to be retrieved and used when saving changes to the product data.
  // The editDialogRef itself is not used directly in this function, but it's part of the context that allows the dialog's content to be manipulated.
  const [activeStep, setActiveStep] = useState(0);
  const [productNameError, setProductNameError] = useState<string>("");
  const [editedProductNameError, setEditedProductNameError] =
    useState<string>("");
  const [productSNIError, setProductSNIError] = useState<string>("");
  const [success, setSuccess] = useState(false);
  const [originalProducts, setOriginalProducts] = useState<ProductListData[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false); //state that indicates whether there are additional pages of data available to fetch.
  const [hasMoreSearch, setHasMoreSearch] = useState<boolean>(false); //state that indicates whether there are additional pages of data available to fetch.
  const [searching, setSearching] = useState<boolean>(false); // To track if a search is active
  const nextPageKeyRef = useRef<string | null>(null); //holds the token for the next page of data.
  const nextPageKeySearchRef = useRef<string | null>(null); //holds the token for the next page of data.

  useEffect(() => {
    const fetchProducts = async () => {
      //Fetch Client list data and update the state
      try {
        const response: getProductListData = await getProducts();
        const data = response.data; //data property is assigened to variable data
        setLoading(false);
        setProducts(data);
        setOriginalProducts(data);
        nextPageKeyRef.current = response.next_page || null;
        setHasMore(!!response.next_page);
      }
      catch (error: any) {
        if (error.response) {
          if (error.response.status === 401) {
            setErrorMessage("Parameter mismatch");
          } else if (error.response.status === 500) {
            setErrorMessage("Internal Server Error");
          } else {
            // other errors
            setErrorMessage(`Error: ${error.message}`);
          }
        } else if (error.request) {
          // The request was made but no response was received
          setErrorMessage("No response received");
        } else {
          // Something happened in setting up the request that triggered an error
          setErrorMessage(`Error: ${error.message}`);
        }
      }
    };
    fetchProducts();
  }, []); //[] ->  that this effect runs only once after the initial render.

  useEffect(() => {
    setAnchorEls(products.map(() => null));
  }, [products]); // It sets initial values for the anchor elements based on the number of products, and it re-runs whenever the products state changes.

  const checkProductNameUnique = (name: string) => {
    // Check if the name exists in the products list
    const isUnique = !products.some((product) => product.name.toLowerCase() === name.toLowerCase());
    return isUnique;
  };

  // THE PLACEMENTS OF THE BELOW FUNCTIONS SHOULD BE IN THIS ORDER ONLY

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    // index of the product for which the actions menu is being opened.
    const updatedAnchorEls = [...anchorEls]; //... spread operator creates a shallow copy of the anchorEls array. So that the state is not mutated directly, which is important in React to maintain proper state management
    updatedAnchorEls[index] = event.currentTarget; //updates the copied array to set the element at the specified index to the currentTarget of the event -> button that was clicked.
    setAnchorEls(updatedAnchorEls);
  };

  const handleMenuClose = (index: number) => {
    const updatedAnchorEls = [...anchorEls];
    updatedAnchorEls[index] = null; //the actions menu for that product should be closed
    setAnchorEls(updatedAnchorEls);
  };

  // Edit Start
  const handleEdit = (rowData: ProductListData) => {
    editedProductNameRef.current = rowData.name;
    editedCommentsRef.current = rowData.comments;
    setEditedProduct(rowData);
    setEditDialogOpen(true);
    handleMenuClose(products.findIndex((product) => product.id === rowData.id));
  };

  const handleCloseEditDialog = () => {
    setEditDialogOpen(false);
    setEditedProduct(null);
    setEditedProductNameError("");
    editedProductNameRef.current = ""; // Reset the stored name
    editedCommentsRef.current = "";
  };

  const handleSaveEdit = async () => {
    if (editedProduct) {
      const editedProductNameError = validateProductName(
        editedProductNameRef.current,
        "Invalid product name"
      );
      if (editedProductNameError) {
        setEditedProductNameError(editedProductNameError);
        return;
      }

      // Check if the edited product name is unique
      const isProductNameUnique = checkProductNameUnique(editedProductNameRef.current);
      //user might not change the product name and is only updating other details.
      if (!isProductNameUnique && editedProductNameRef.current !== editedProduct.name) {
        setEditedProductNameError("Product name must be unique.");
        return;
      }

      // Update the product name on the backend
      const updatedProduct = {
        ...editedProduct,
        name: editedProductNameRef.current,
        comments: editedCommentsRef.current,
        // RegistrationDate: new Date().toISOString(),
      };

      try {
        const updatedProductResponse = await putUpdatedProductDetails(
          updatedProduct, editedProduct.id
        );

        if (updatedProductResponse) {
          // Update the product name in the frontend
          const updatedProducts = products.map((product) =>
            product.id === editedProduct.id
              ? {
                ...product,
                name: editedProductNameRef.current,
                comments: editedCommentsRef.current,
              }
              : product
          );

          setProducts(updatedProducts);
          handleCloseEditDialog();
        } else {
          // Handle error when updating the product on the backend
          console.error("Error updating product on the backend.");
        }
      } catch (error) {
        // Handle    error or any other error
        console.error("Error updating product:", error);
      }
    }
  };

  // Edit ends

  //Delete Start
  const handleDelete = (rowData: ProductListData) => {
    setDeleteDialogOpen(true); // Open the delete dialog
    handleMenuClose(products.findIndex((product) => product.id === rowData.id));
    //  It finds the index of the product in the products array based on its id property and then passes
    // this index to handleMenuClose to close the actions menu associated with that product.
    //show a dialog you cannot delete
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  //Delete End

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(18),
      border: "1px solid #dadde9",
    },
  }));

  //Action cell Icon and options
  const renderActionsCell = (rowData: ProductListData, index: number) => {
    return (
      <>
        <IconButton
          aria-label="actions"
          aria-controls={`actions-menu-${rowData.id}`}
          aria-haspopup="true"
          onClick={(event) => handleMenuOpen(event, index)}
        >
          <MoreHorizIcon />
        </IconButton>
        <Menu
          id={`actions-menu-${rowData.id}`}
          anchorEl={anchorEls[index]} //This attribute specifies the element that the menu should be anchored to. It uses the anchorEls state array to control the position of the menu.
          open={Boolean(anchorEls[index])} //This attribute determines whether the menu should be open or closed based on the value in the anchorEls array.
          //anchorEls[index] is null/not null. Boolean(someValue) returns true if someValue is truthy (not null, undefined, false, 0, or an empty string), and false otherwise.
          onClose={() => handleMenuClose(index)}
        >
          <MenuItem onClick={() => handleEdit(rowData)}>
            <EditIcon /> Edit
          </MenuItem>
          <MenuItem onClick={() => handleDelete(rowData)}>
            <DeleteIcon /> Delete
          </MenuItem>
        </Menu>
      </>
    );
  };

  //Add new product Start
  const handleNewProduct = () => {
    setNewProductDialogOpen(true);
  };

  const handleCloseNewProductDialog = () => {
    setNewProductDialogOpen(false);
    setNewProductName("");
    setNewProductSNI("");
    setProductNameError("");
    setProductSNIError("");
    setActiveStep(0); //resetting the stepper's active step to 0 and clearing the form field values when the dialog is closed
  };

  let columns: Columns = {
    Name: {
      type: "link",
      display: "Product Name",
      data: "name",
      sort: "Name",
    },
    ProductSerialNI: {
      type: "text",
      display: "Product Serial Number Identification",
      data: "serial_identifier",
      sort: "ProductSerialNI",
    },
    // ActiveUnits: {
    //   type: "text",
    //   display: "Active Units",
    //   render: renderActiveUnitsCell,
    // },
    // ActiveTickets: {
    //   type: "text",
    //   display: "Active Tickets",
    //   data: "active_tickets",
    //   sort: "ActiveTickets",
    // },
    Comments: {
      type: "text",
      display: "Comments",
      data: "comments",
      sort: "Comments",
    },
    LatestFirmwareUpdate: {
      type: "time",
      display: "Latest Firmware Update",
      data: "last_firmware_update",
      sort: "LatestFirmwareUpdate",
    },
    MostRecentFirmwareUpdate: {
      type: "text",
      display: "Most Recent Firmware Update",
      data: "most_recent_firmware",
      sort: "MostRecentFirmwareUpdate",
    },
    ReleaseDate: {
      type: "time",
      display: "Release Date",
      data: "release_date",
      sort: "ReleaseDate",
    },

    Actions: {
      type: "actions",
      display: "Actions",
      render: renderActionsCell,
    },
  };

  //handle induividual product click
  function handleRowClick(seletedProduct: ProductListData) {
    setTransitionOut(false);
    setTimeout(() => userNavigate(`/products/${seletedProduct.id}`), 400);
  }

  function handleNewTabRowClick(seletedProduct: ProductListData) {
    // setTransitionOut(false);
    window.open(`/products/${seletedProduct.id}`, "_blank");
  }

  // Function to handle search
  // const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setSearchTerm(event.target.value);
  // };

  const handleLoadMore = () => {

    if (searching) {
      //TODO
      //call handleSearh form the ProductSearch component
    } else {
      if (nextPageKeyRef.current) {
        getProducts(nextPageKeyRef.current); // Load more general devices
      }
    }
  };

  // Function to filter clients based on search term
  useEffect(() => {
    const filtered = products.filter((product) =>
      Object.values(product).some(
        (
          value // convert each client object into an array of its values
        ) => value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredProducts(filtered);
  }, [searchTerm, products]);

  //Stepper
  const handleNextStep = () => {
    if (activeStep === 0) {
      const productNameError = validateProductName(
        newProductName,
        "Invalid product name"
      );
      if (productNameError) {
        setProductNameError(productNameError);
        return;
      }

      // Check if the product name is unique
      if (!checkProductNameUnique(newProductName)) {
        setProductNameError("Product name must be unique.");
        return;
      }

      const productSNIError = validateProductSNI(
        newProductSNI,
        "Product Serial Number Identification must be in the range 000-159"
      );
      if (productSNIError) {
        setProductSNIError(productSNIError);
        return;
      }

      // Check if the serial number already exists in the fetched products
      const serialNumberExists = products.some(
        (product) => product.serial_identifier === newProductSNI
      ); //'some' method checks if any product in the products array has a serial_identifier property matching the newProductSNI (the entered serial number).
      if (serialNumberExists) {
        setProductSNIError(
          "Product Serial Number Identification already exists"
        );
        return;
      }

      setProductNameError("");
      setProductSNIError("");
    }

    setActiveStep(activeStep + 1);
  };

  const handlePrevStep = () => {
    setActiveStep(activeStep - 1);
  };

  const handleCreate = async () => {
    const isSuccess = await createNewProduct(newProductName, newProductSNI);
    if (isSuccess) {
      // Fetch the updated product list from the backend
      try {
        const updatedProductList = await getProducts();
        setProducts(updatedProductList.data);
        setSuccess(true);
        setActiveStep(activeStep + 1);
      } catch (error) {
        // Handle error
        setSuccess(false);
      }
    }
  };

  // Search
  const handleUpdateData = (updatedData: ProductListData[]) => {
    setProducts(updatedData);
  };

  const handleError = (error: any) => {
    setErrorMessage(error);
  };

  const handleProductNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    setNewProductName(name);
    // Check if the product name is unique
    if (checkProductNameUnique(name)) {
      setProductNameError(""); // Clear error if unique
    } else {
      setProductNameError("Product name must be unique.");
    }
  };

  const handleProductNameEdit = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    editedProductNameRef.current = name;

    // Check if the product name is unique
    if (!checkProductNameUnique(name)) {
      setEditedProductNameError("Product name must be unique.");
    } else {
      setEditedProductNameError("");
    }
  };

  <TextField
    label="Product Name"
    defaultValue={editedProductNameRef.current}
    onChange={handleProductNameEdit}
    fullWidth
    margin="normal"
    error={!!editedProductNameError}
    helperText={editedProductNameError}
  />


  const steps = ["Enter Product Details", "Review", "Result"];
  return (
    <>
      <Slide direction="right" in={transitionout} timeout={400}>
        <Box
          flexGrow={1}
          marginLeft={"5%"}
          marginRight={"5%"}
          marginTop={10}
          marginBottom={10}
        >
          <h2>Product List</h2>
          <Divider />
          {/* Search box and buttton container*/}
          <Box display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            marginBottom={2}
            marginTop={2}
            flexWrap="wrap" // Ensure items wrap on smaller screens
          >
            <ProductSearchBar onUpdateData={handleUpdateData} onError={handleError} originalProducts={originalProducts} />
            <Button
              variant="contained"
              color={theme.nextbuttoncolor}
              onClick={() => handleNewProduct()}
              style={{ height: 55, minWidth: 150 }}
            >
              New Product
            </Button>
          </Box>

          {/* Dialog for Adding New Product */}
          <Dialog
            open={isNewProductDialogOpen}
            onClose={handleCloseNewProductDialog}
          >
            <DialogTitle variant="h4" align="center">
              Add New Product
              <IconButton
                aria-label="close"
                onClick={handleCloseNewProductDialog}
                sx={{ position: "absolute", right: 0, top: 0 }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>
              <Box display="flex" flexDirection="column" alignItems="center">
                {/* Stepper */}
                <Stepper
                  activeStep={activeStep}
                  alternativeLabel
                  style={{ wordSpacing: "80px" }}
                >
                  {steps.map((label, index) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                {/* Step content */}
                {activeStep === 0 && (
                  /* Step 1: Enter Product Information */
                  <div>
                    <Typography
                      variant="h6"
                      align="center"
                      style={{ fontWeight: "bold" }}
                    >
                      Enter Product Details
                    </Typography>
                    <TextField
                      label="Product Name"
                      value={newProductName}
                      onChange={handleProductNameChange}
                      fullWidth
                      margin="normal"
                      error={!!productNameError}
                      helperText={productNameError}
                    />
                    <TextField
                      label="Product Serial Number identification"
                      value={newProductSNI}
                      onChange={(e) => {
                        setNewProductSNI(e.target.value);
                        setProductSNIError("");
                      }}
                      fullWidth
                      margin="normal"
                      error={productSNIError !== ""}
                      helperText={productSNIError}
                    />
                  </div>
                )}
                {activeStep === 1 && (
                  /* Step 2: Review */
                  <div>
                    <Typography
                      variant="h6"
                      align="center"
                      style={{ fontWeight: "bold" }}
                    >
                      Review Your Entry
                    </Typography>
                    <TableContainer style={{ marginTop: "16px" }}>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell>Product Name</TableCell>
                            <TableCell>{newProductName}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              Product Serial Number identification
                            </TableCell>
                            <TableCell>{newProductSNI}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                )}
                {activeStep === 2 && (
                  /* Step 3: Result */
                  <div style={{ padding: "40px" }}>
                    <Typography
                      variant="h6"
                      align="center"
                      style={{ fontWeight: "bold" }}
                    >
                      Status
                    </Typography>
                    <Typography
                      variant="h6"
                      style={{ color: success ? "green" : "red" }}
                    >
                      {success
                        ? "Product Created Successfully"
                        : "Error Creating New Product"}
                    </Typography>
                  </div>
                )}
              </Box>
              {/* Buttons */}
              <DialogActions>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  width="100%"
                >
                  {activeStep === 0 && (
                    <>
                      <Button color={theme.backbuttoncolor} variant="contained" onClick={handleCloseNewProductDialog}>
                        Close
                      </Button>
                      <Button color={theme.nextbuttoncolor} variant="contained" onClick={handleNextStep}>
                        Next
                      </Button>
                    </>
                  )}
                  {activeStep === 1 && (
                    <>
                      <Button onClick={handlePrevStep} color={theme.backbuttoncolor} variant="contained">Back</Button>
                      <Button color={theme.nextbuttoncolor} variant="contained" onClick={handleCreate}>
                        Create
                      </Button>
                    </>
                  )}
                  {activeStep === 2 && (
                    <>
                      <Button onClick={handlePrevStep}>Back</Button>
                      <Button
                        color="primary"
                        onClick={handleCloseNewProductDialog}
                      >
                        Finish
                      </Button>
                    </>
                  )}
                </Box>
              </DialogActions>
            </DialogContent>
          </Dialog>

          {/* Do not allow delete */}
          <Dialog open={isDeleteDialogOpen} onClose={handleCloseDeleteDialog}>
            <DialogTitle>Delete Product</DialogTitle>
            <DialogContent>
              <Typography style={{ color: "red" }}>
                You are not Authorized to Delete Product
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
            </DialogActions>
          </Dialog>

          {/*  Dialog for Edit Product */}
          <Dialog open={isEditDialogOpen} onClose={handleCloseEditDialog}>
            <DialogTitle variant="h4" align="center">
              Edit Product Details
            </DialogTitle>
            <DialogContent ref={editDialogRef}>
              <TextField
                label="Product Name"
                defaultValue={editedProductNameRef.current}
                onChange={handleProductNameEdit}
                fullWidth
                margin="normal"
                error={!!editedProductNameError}
                helperText={editedProductNameError}
              />
              <TextField
                label="Comments"
                defaultValue={editedCommentsRef.current}
                onChange={(e) =>
                  (editedCommentsRef.current = e.target.value)
                }
                fullWidth
                margin="normal"
              />
            </DialogContent>
            <DialogActions>
              <Box display="flex" justifyContent="space-between" width="100%">
                <Button onClick={handleCloseEditDialog}>Cancel</Button>
                <Button onClick={handleSaveEdit} color="primary">
                  Save
                </Button>
              </Box>
            </DialogActions>
          </Dialog>


          {errorMessage && (
            <Box paddingTop={2} color="red">
              <Alert severity="error">Invalid Client</Alert>
            </Box>
          )}

          <GeneralTable
            data={products.map((product, index) => ({
              id: product.id,
              Name: product.name,
              ProductSerialNI: product.serial_identifier,
              // ActiveUnits: product.active_units.count,
              // ActiveTickets: renderActiveUnitsCell(product, index),
              Comments: product.comments,
              LatestFirmwareUpdate: product.last_firmware_update,
              MostRecentFirmwareUpdate: product.most_recent_firmware,
              ReleaseDate: product.release_date,
              Actions: renderActionsCell(product, index),
            }))}
            columns={columns}
            rowClickCallback={handleRowClick}
            loading={loading}
            noDataMessage={"Unable To Products Clients List"}
            rows={10}
            rowsoptions={[10, 25, 50, 100]}
            rowNewTabClickCallback={handleNewTabRowClick}
          />
          <LoadMoreButton
            hasMore={searching ? hasMoreSearch : hasMore}
            loading={loading}
            onLoadMore={handleLoadMore}
            errorMessage={errorMessage}
          />
        </Box>
      </Slide>
    </>
  );
}
