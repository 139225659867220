//DOCUMENTATION: https://airweigh.atlassian.net/l/cp/q1RVuwmF

import { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { initializeMap } from "../../requests/initialize-map/initialize-map";
import {
	Alert,
	Backdrop,
	Box,
	Card,
	LinearProgress,
	Typography,
} from "@mui/material";
import { LngLatBoundsLike, Marker, Popup } from "maplibre-gl";
import { isVehicleWeightPoint } from "../../interfaces/map/vehicle-map-interface";
import { AppContext } from "../../themes/theme-context";
import { renderToString } from "react-dom/server";
import { rfcToDateAndTime } from "../../custom-functions/rfc3339-conversions";
import { FormattedWeight, WeightsCallbackResponse } from "../../interfaces/vehicles/vehicles-interfaces";
import Supercluster from "supercluster";
import { Address } from "./search-box";
import { red } from "@mui/material/colors";
import { MapPoint } from "../../interfaces/map/generic-interface";

const HOT_AREA_COUNT = 750; // The number of points at which a cluster is considered a "hot area"

export const MyMap = (props: {
	title: string;
	longlat: [number, number];
	recurse: boolean;
	dataPoints: MapPoint[];
	searchBar?: boolean;
	mapSearchCallback: Function;
	count: number; //accounts for naming if someone wanted to change the program to recurse more than one layer deep
	zoom: number;
	fitBounds: LngLatBoundsLike | undefined;
	popupClickCallback: Function;
	currentSnapshot: WeightsCallbackResponse | undefined;
	closeModalParentCallback?: Function;
}) => {
	var { theme } = useContext(AppContext);
	const [loading, setLoading] = useState<boolean>(true); //true=loading, false=not
	const [alert, setAlert] = useState<string>("");
	const [fullScreen, setFullScreen] = useState<boolean>(false); //false=not full, true=full
	const markers: Marker[] = []; // Array to store the markers that are added to the map
	const [spiderfiedMarkers, setSpiderfiedMarkers] = useState<Marker[]>([]);
	const currentSpiderfiedCluster = useRef<number | null>(null);
	// Maintain marker starte in a ref
	const searchMarker = useRef<Marker | null>(null);
	const mapRef = useRef<maplibregl.Map | null>(null);
	
	const spiderZoomLevel = 18;

	const clusterIndex = new Supercluster({
		radius: 40,
		maxZoom: 20,
	});

	const calculateSpiderPositions = (center: [number, number], count: number, zoom: number): [number, number][] => {
		const positions: [number, number][] = [];

		if (count <= 10) {
			// Create a circle
			const angleStep = (2 * Math.PI) / count;

			// Adjust radius based on zoom level
			var radius
			switch (true) {
				case (zoom >= 20.75):
					radius = 0.0001 / (zoom / 3);
					break;
				case (zoom >= 20.5):
					radius = 0.0001 / (zoom / 5);
					break;
				case (zoom >= 20.25):
					radius = 0.0001 / (zoom / 6);
					break;
				case (zoom >= 20):
					radius = 0.0001 / (zoom / 7);
					break;
				case (zoom >= 19):
					radius = 0.0001 / (zoom / 10);
					break;
				default:
					radius = 0.0001 / (zoom / 15);
			}


			for (let i = 0; i < count; i++) {
				const angle = i * angleStep;
				const x = center[0] + radius * Math.cos(angle);
				const y = center[1] + radius * Math.sin(angle);
				positions.push([x, y]);
			}
		} else {
			// Create a spiral
			const angleStep = 0.95; // Adjust angle step to control spiral tightness
			const baseRadius = 0.00001; // Base radius for the closest zoom level
			const radiusIncrement = baseRadius / (zoom / 20); // Adjust radius based on zoom level

			for (let i = 0; i < count; i++) {
				const radius = radiusIncrement * (i + 3.5); // Ensure first point is not at the center
				const angle = angleStep * i;
				const x = center[0] + radius * Math.cos(angle);
				const y = center[1] + radius * Math.sin(angle);
				positions.push([x, y]);
			}
		}

		return positions;
	};

	const spiderfyCluster = (cluster: any, coordinates: [number, number]): void => {
		if (!mapRef.current) return;
		if (currentSpiderfiedCluster.current != null)
			clearSpiderCluster();

		currentSpiderfiedCluster.current = cluster.id;

		const clusterLeaves = clusterIndex.getLeaves(cluster.id, Infinity);
		const zoom = mapRef.current.getZoom();
		const positions = calculateSpiderPositions(coordinates, clusterLeaves.length, zoom);

		const lineCoordinates: [number, number][][] = [];

		clusterLeaves.forEach((leaf: any, i: number) => {
			if (!mapRef.current) return;
			const datapoint = leaf.properties as FormattedWeight;
			const popup = new Popup({
				closeButton: false,
				offset: 40,
			})
				.setLngLat(positions[i])
				.setDOMContent(createPopupDom(datapoint));

			const marker = new Marker()
				.setLngLat(positions[i])
				.setPopup(popup)
				.addTo(mapRef.current);

			// Add the line coordinates
			lineCoordinates.push([coordinates, positions[i]]);

			spiderfiedMarkers.push(marker);
		});

		// Add or update the line source
		if (mapRef.current.getSource('spiderfy-lines')) {
			(mapRef.current.getSource('spiderfy-lines') as maplibregl.GeoJSONSource).setData({
				type: 'FeatureCollection',
				features: lineCoordinates.map(coords => ({
					type: 'Feature',
					geometry: {
						type: 'LineString',
						coordinates: coords,
					},
					properties: {} // Add an empty properties object here
				})),
			});
		} else {
			mapRef.current.addSource('spiderfy-lines', {
				type: 'geojson',
				data: {
					type: 'FeatureCollection',
					features: lineCoordinates.map(coords => ({
						type: 'Feature',
						geometry: {
							type: 'LineString',
							coordinates: coords,
						},
						properties: {} // Add an empty properties object here
					})),
				},
			});

			mapRef.current.addLayer({
				id: 'spiderfy-lines',
				type: 'line',
				source: 'spiderfy-lines',
				layout: {
					'line-join': 'round',
					'line-cap': 'round',
				},
				paint: {
					'line-color': '#FF0000',
					'line-width': 2,
				},
			});
		}
	};

	const clearSpiderCluster = () => {
		if (!mapRef.current) return;
		if (currentSpiderfiedCluster.current == null) return;

		spiderfiedMarkers.forEach(marker => {
			marker.remove()
		});
		spiderfiedMarkers.length = 0;

		if (mapRef.current.getSource('spiderfy-lines')) {
			(mapRef.current.getSource('spiderfy-lines') as maplibregl.GeoJSONSource).setData({
				type: 'FeatureCollection',
				features: [],
			});
		}

		currentSpiderfiedCluster.current = null;
	};

	//flys to selected longlat and opens up a popup at the snapshot location
	useEffect(() => {
		if (mapRef.current) {
			mapRef.current.flyTo({ center: props.longlat, essential: true, zoom: 20 });
			if (props.currentSnapshot) {
				addPopup(props.currentSnapshot);
			}
		}
	}, [props.longlat]);

	//once map and markers loaded, fitbounds are passed to fit all markers in the visible window
	useEffect(() => {
		if (mapRef.current && props.fitBounds) {
			mapRef.current.fitBounds(props.fitBounds, {
				padding: { top: 45, bottom: 25, left: 15, right: 15 },
			});
		}
	}, [props.fitBounds]);

	useLayoutEffect(() => {
		if (mapRef.current) {
			mapRef.current.resize();
		}
	}, []);

	const onSearchResultClick = (result: Address) => {
		if (!mapRef.current) return;
		if (searchMarker.current) {
			searchMarker.current.remove();
		}

		searchMarker.current = new Marker(
			{ color: red[500] }
		)
			.setLngLat(result.coordinates)
			.setPopup(
				new Popup({
					closeButton: false,
					offset: 40,
				})
					.setLngLat(result.coordinates)
					.setHTML(`<h3>${result.title}</h3><p>${result.address}</p>`)
			)
			.addTo(mapRef.current);

		props.mapSearchCallback()
	}

	useEffect(() => {
		initializeMap(
			props.title,
			onSearchResultClick,
			theme,
			props.longlat,
			props.zoom,
			(!props.recurse && props.closeModalParentCallback) ? props.closeModalParentCallback : openModal,
			!props.recurse,
			props.searchBar,
		)
			.then((response: maplibregl.Map) => {
				mapRef.current = response;
				currentSpiderfiedCluster.current = null;
				setAlert("");
				setLoading(false);
			})
			.catch((err: string) => {
				setAlert(err);
				console.log(err);
			});
		return () => {
			//map is removed when component is gone, https://maplibre.org/maplibre-gl-js-docs/api/map/#map#remove
			if (mapRef.current) {
				mapRef.current.remove();
				mapRef.current = null;
			}
		};
	}, []);

	//add markers and listeners to map after it is loaded
	useEffect(() => {
		console.log("in use effect");
		if (mapRef.current) {
			// index the data points for clustering
			if (isVehicleWeightPoint(props.dataPoints[0])) {
				var vehicleData = props.dataPoints as FormattedWeight[];
				console.log("loading data");

				clusterIndex.load(
					vehicleData.map((datapoint: FormattedWeight) => ({
						type: "Feature",
						geometry: {
							type: "Point",
							coordinates: [datapoint.longitude, datapoint.latitude],
						},
						properties: datapoint,
					}))
				);
			} else
				return;

			const updateClusters = () => {
				if (!mapRef.current) return;
				if (clusterIndex.getClusters === undefined) return;
				clearSpiderCluster();
				// Remove existing markers
				markers.forEach((marker, i) => {
					const markerElement = marker.getElement();
					markerElement.removeEventListener("mouseenter", () => { });
					markerElement.removeEventListener("mouseleave", () => { });
					markerElement.removeEventListener("click", () => { });
					marker.remove();
				});
				markers.length = 0;

				// Get the current map bounds and zoom level to determine which clusters to show
				const zoom = mapRef.current.getZoom();
				const bounds = mapRef.current.getBounds().toArray().flat() as [number, number, number, number, number, number];
				const clusters = clusterIndex.getClusters(bounds, Math.floor(zoom)); // Get the clusters that are visible in the current map view

				// Add markers for each cluster
				clusters.forEach((cluster) => {
					if (!mapRef.current) return;
					// Add cluster marker
					if (cluster.properties.cluster) {
						const coordinates = cluster.geometry.coordinates as [number, number];
						const pointCount = cluster.properties.point_count;

						// Create a cluster marker with a size that represents the number of points in the cluster
						const marker = new Marker(createHeatmapMarker(pointCount))
							.setLngLat(coordinates as [number, number])
							.addTo(mapRef.current);

						// Add event listeners to the cluster marker
						marker.getElement().addEventListener('mouseenter', () => {
							marker.getElement().style.cursor = 'pointer';
						});

						marker.getElement().addEventListener('mouseleave', () => {
							marker.getElement().style.cursor = '';
						});

						marker.getElement().addEventListener('click', () => {
							if (!mapRef.current) return;
							if (mapRef.current.getZoom() < spiderZoomLevel) {
								mapRef.current.flyTo({
									center: coordinates as [number, number],
									zoom: Math.min(mapRef.current.getZoom() + 2, mapRef.current.getMaxZoom())
								});
							} else {
								if (currentSpiderfiedCluster.current == cluster.id) {
									clearSpiderCluster();
								} else {
									spiderfyCluster(cluster, coordinates);
								}
							}
						});

						markers.push(marker); // Add the cluster marker to the array of markers
					} else {
						// Add individual marker
						const datapoint = cluster.properties as FormattedWeight;
						const popup = new Popup({
							closeButton: false,
							offset: 40,
						})
							.setLngLat([datapoint.longitude, datapoint.latitude])
							.setDOMContent(createPopupDom(datapoint));

						// Add the marker to the map
						const marker = new Marker()
							.setLngLat([datapoint.longitude, datapoint.latitude])
							.setPopup(popup)
							.addTo(mapRef.current);

						// Add event listeners to the marker
						marker.getElement().addEventListener('mouseenter', () => {
							marker.getElement().style.cursor = 'pointer';
						});

						marker.getElement().addEventListener('mouseleave', () => {
							marker.getElement().style.cursor = '';
						});

						markers.push(marker); // Add the marker to the array of markers
					}
				});
			};

			mapRef.current.on("moveend", updateClusters);
			updateClusters();
		}
	}, [mapRef.current]);

	// Function to format the text displayed in the cluster markers
	const formatCount = (count: number) => {
		return count > 1000 ? (count / 1000).toFixed(1) + 'k' : count.toString();
	};

	// Function to determine the color of the cluster marker based on the number of points it represents
	const getColor = (pointCount: number) => {
		const ratio = Math.min(Math.max((pointCount - 1) / (HOT_AREA_COUNT - 1), 0), 1);
		const r = ratio <= 0.5 ? Math.floor(255 * (ratio * 2)) : 255;
		const g = ratio <= 0.5 ? 255 : Math.floor(255 * ((1 - ratio) * 2));
		return `rgb(${r},${g},0)`;
	};

	// Function to create the cluster marker element
	const createHeatmapMarker = (pointCount: number) => {
		const color = getColor(pointCount);
		const size = 50 + (pointCount / HOT_AREA_COUNT) * 50; // Size range between 50 and 100
		const markerElement = document.createElement('div');
		markerElement.style.width = `${size}px`;
		markerElement.style.height = `${size}px`;
		markerElement.style.background = `radial-gradient(circle, ${color}, transparent)`;
		markerElement.style.borderRadius = '50%';
		markerElement.style.opacity = '0.8';
		markerElement.style.display = 'flex';
		markerElement.style.justifyContent = 'center';
		markerElement.style.alignItems = 'center';
		markerElement.style.fontWeight = '900';
		markerElement.innerHTML = formatCount(pointCount);
		return markerElement;
	};

	const createPopupDom = (datapoint: FormattedWeight) => {
		// Define a conversion factor from kilograms to pounds
		const kgToPounds = 2.20462;

		// Convert the weight from grams to pounds
		// const weightInPounds = (datapoint.weight * gramsToPounds).toFixed(2);
		const weightInPounds = datapoint.weight * kgToPounds;
		const roundedWeightInPounds = Math.round(weightInPounds / 10) * 10;
		//first divides the value pounds by 10, rounds it to the nearest integer, and then multiplies it by 10 to get the rounded value to the nearest 10 without the decimal part.

		const selectedPopupHTML = renderToString(
			<Box>
				<Box
					style={{
						color: theme.secondary,
						background: theme.tertiary,
						padding: 10,
						margin: -10,
						fontWeight: 700,
						textAlign: "center",
					}}
				>
					<Typography>{rfcToDateAndTime(datapoint.date_time)}</Typography>
				</Box>
				<Box
					style={{
						color: theme.primary,
						padding: 10,
						paddingBottom: 0,
						margin: -10,
						fontWeight: 700,
						textAlign: "center",
					}}
				>
					{/* <Typography>Weight: {datapoint.weight}</Typography> */}
					{/* Display the converted weight with the unit "lbs" */}
					<Typography>Weight: {roundedWeightInPounds} lbs</Typography>

				</Box>
			</Box>
		);
		const popupButtonHTML = renderToString(
			<Box
				style={{
					color: theme.primary,
					padding: 10,
					paddingBottom: 0,
					margin: -10,
					fontWeight: 700,
					textAlign: "center",
				}}
			>
				<button>More Details</button>
			</Box>
		);
		//button stuff
		const assignBtn = document.createElement("div");
		assignBtn.innerHTML = popupButtonHTML;
		//popup
		const popupNode = document.createElement("div");
		popupNode.innerHTML = selectedPopupHTML;
		//adding button to popup + adding event listener
		popupNode.appendChild(assignBtn);
		assignBtn.addEventListener("click", (e) => {
			props.popupClickCallback(datapoint);
		});
		return popupNode;
	};

	const addPopup = (datapoint: any) => {
		if (mapRef.current) {
			new Popup({
				closeButton: false,
				offset: 40,
			})
				.setLngLat([datapoint.longitude, datapoint.latitude])
				.setDOMContent(createPopupDom(datapoint))
				.addTo(mapRef.current);
		}
	};

	const openModal = () => {
		setFullScreen(true);
	};

	const closeModal = () => {
		setFullScreen(false);
	};

	return (
		<>
			{props.recurse ? (
				<>
					{loading ? <LinearProgress /> : null}
					{alert ? <Alert severity="error">{alert}</Alert> : null}
					<Box display={"flex"} height={"100%"} width={"100%"} minHeight={400}>
						<Card sx={{ flexGrow: 1 }}>
							<Box
								position="relative"
								height="100%"
								width="100%"
								flexGrow={1}
								zIndex={0}
							>
								<Box
									id={props.title}
									width={"100%"}
									height={"100%"}
									position={"absolute"}
									flexGrow={1}
								></Box>
							</Box>
						</Card>
					</Box>
				</>
			) : (
				<Box
					position="relative"
					height="100%"
					width="100%"
					flexGrow={1}
					paddingBottom={2}
					marginBottom={2}
				>
					{loading ? <LinearProgress /> : null}
					{alert ? <Alert severity="error">{alert}</Alert> : null}
					<Box
						id={props.title}
						width={"100%"}
						height={"100%"}
						position={"absolute"}
						flexGrow={1}
					></Box>
				</Box>
			)}
			{props.recurse ? (
				// sx necessary here to prevent other button/pagination etc elements on the page when the component is used to cover the backdrop
				<Backdrop open={fullScreen} sx={{ zIndex: 500 }}>
					<Box
						display={"flex"}
						marginLeft={40}
						marginRight={10}
						height={"80%"}
						width={"100%"}
					>
						<Card sx={{ flexGrow: 1 }}>
							<>
								<MyMap
									title={`AW-Cloud-Portal-Recursed-Map-${props.count}`}
									longlat={props.longlat}
									recurse={false}
									dataPoints={props.dataPoints}
									searchBar={true}
									mapSearchCallback={props.mapSearchCallback}
									count={props.count + 1}
									zoom={props.zoom}
									fitBounds={props.fitBounds}
									popupClickCallback={props.popupClickCallback}
									currentSnapshot={props.currentSnapshot}
									closeModalParentCallback={(cords: maplibregl.LngLat, zoom: number) => {
										mapRef.current?.setCenter(cords);
										mapRef.current?.setZoom(zoom);
										closeModal()
									}}
								/>
							</>
						</Card>
					</Box>
				</Backdrop>
			) : null}
		</>
	);
};





// ============================== MyMapV2 ===================================
// The code below is started code for a MyMapV2 which implements the map using
// features from a newer version of the maplibre-gl-js library. This code is
// not completed and is not currently being used in the application.
//
// Known issues:
// - The Spiderfy package does not appear to be compatible with the older version
// - Labels for the clusters are not displaying correctly
// - Switching between map styles works, but the map does not reload the data
// ==========================================================================


//DOCUMENTATION: https://airweigh.atlassian.net/l/cp/q1RVuwmF

// import { useContext, useEffect, useLayoutEffect, useState } from "react";
// import { initializeMap } from "../../requests/initialize-map/initialize-map";
// import {
//     Alert,
//     Backdrop,
//     Box,
//     Card,
//     LinearProgress,
//     Stack,
//     Typography,
// } from "@mui/material";
// import maplibregl, { LngLatBoundsLike, MapMouseEvent, Marker, Popup } from "maplibre-gl";
// import {
//     VehicleData,
//     VehicleDataPoint,
// } from "../../interfaces/map/vehicle-map-interface";
// import { AppContext } from "../../themes/theme-context";
// import LayersIcon from "@mui/icons-material/Layers";
// import FullscreenIcon from "@mui/icons-material/Fullscreen";
// import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
// import { RoundedIconButton } from "../../themes/rounded-icon-button";
// import { renderToString } from "react-dom/server";
// import { rfcToDateAndTime } from "../../custom-functions/rfc3339-conversions";
// import { WeightsCallbackResponse } from "../../interfaces/vehicles/vehicles-interfaces";
// // import Spiderfy from '@nazka/map-gl-js-spiderfy';

// const HOT_AREA_COUNT = 750; // The number of points at which a cluster is considered a "hot area"

// export const MyMapV2 = (props: {
//     title: string;
//     longlat: [number, number];
//     recurse: boolean;
//     vehicleData: VehicleData;
//     searchBar?: boolean;
//     mapSearchCallback: Function;
//     count: number; //accounts for naming if someone wanted to change the program to recurse more than one layer deep
//     zoom: number;
//     fitBounds: LngLatBoundsLike | undefined;
//     popupClickCallback: Function;
//     currentSnapshot: WeightsCallbackResponse | undefined;
// }) => {
//     var { theme } = useContext(AppContext);
//     const [map, setMap] = useState<maplibregl.Map>();
//     const [mapSwitch, setMapSwitch] = useState<boolean>(false);
//     const [loading, setLoading] = useState<boolean>(true); //true=loading, false=not
//     const [alert, setAlert] = useState<string>("");
//     const [fullScreen, setFullScreen] = useState<boolean>(false); //false=not full, true=full


//     const geojsonData: GeoJSON.FeatureCollection = {
//         type: "FeatureCollection",
//         features: props.vehicleData.data.map((datapoint: VehicleDataPoint) => ({
//             type: "Feature",
//             geometry: {
//                 type: "Point",
//                 coordinates: [datapoint.longitude, datapoint.latitude],
//             },
//             properties: datapoint,
//         })),
//     };



//     const getColor = (pointCount: number): string => {
//         const ratio = Math.min(Math.max((pointCount - 1) / (HOT_AREA_COUNT - 1), 0), 1);
//         const r = ratio <= 0.5 ? Math.floor(255 * (ratio * 2)) : 255;
//         const g = ratio <= 0.5 ? 255 : Math.floor(255 * ((1 - ratio) * 2));
//         return `rgb(${r},${g},0)`;
//     };

//     const generateColorSteps = () => {
//         // Accept numbers, strings, or string[] as array items
//         const colorSteps: any[] = ["step", ["get", "point_count"], getColor(1)];
//         for (let i = 50; i <= HOT_AREA_COUNT; i += 50) {
//             colorSteps.push(i);
//             colorSteps.push(getColor(i));
//         }
//         return colorSteps;
//     };

//     const colorSteps = generateColorSteps() as maplibregl.Expression

//     useEffect(() => {
//         initializeMap(
//             props.title,
//             props.mapSearchCallback,
//             theme,
//             props.longlat,
//             props.zoom,
//             props.searchBar
//         )
//             .then((response: maplibregl.Map) => {
//                 setMap(response);
//                 setAlert("");
//                 setLoading(false);
//             })
//             .catch((err: string) => {
//                 setAlert(err);
//                 console.log(err);
//             });
//         return () => {
//             //map is removed when component is gone, https://maplibre.org/maplibre-gl-js-docs/api/map/#map#remove
//             if (map) {
//                 map.remove();
//             }
//         };
//     }, []);

//     useEffect(() => {
//         if (!map) return;
//         console.log("map loaded");

//         map.on("load", () => {
//             map.addSource("weights", {
//                 type: "geojson",
//                 data: geojsonData,
//                 cluster: true,
//                 clusterMaxZoom: 14,
//                 clusterRadius: 50,
//             });

//             // const iconUrl = require("./location.png");
//             const iconUrl = "https://images.ctfassets.net/3prze68gbwl1/assetglossary-17su9wok1ui0z7w/c4c4bdcdf0d0f86447d3efc450d1d081/map-marker.png"

//             map.loadImage(iconUrl, (error: any, image: any) => {
//                 if (error) throw error;
//                 // Set size to 38x38 pixels and add image to map

//                 map.addImage("custom-marker", image);
//             });

//             map.addLayer({
//                 id: "clusters",
//                 type: "circle",
//                 source: "weights",
//                 filter: ["has", "point_count"],
//                 paint: {
//                     'circle-color': colorSteps,
//                     "circle-blur": .5,
//                     "circle-radius": [
//                         "step",
//                         ["get", "point_count"],
//                         20,
//                         100,
//                         25,
//                         750,
//                         30,
//                     ],
//                 },
//             });

//             map.addLayer({
//                 id: "cluster-count",
//                 type: "symbol",
//                 source: "weights",
//                 filter: ["has", "point_count"],
//                 layout: {
//                     // "text-field": ["get", "point_count"],
//                     // "text-field": "1",
//                     "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
//                     "text-size": 12,
//                 },
//             });

//             map.addLayer({
//                 id: "unclustered-point",
//                 type: "symbol",
//                 source: "weights",
//                 filter: ["!", ["has", "point_count"]],
//                 layout: {
//                     "icon-image": "custom-marker",
//                     "icon-size": 0.07,
//                     "icon-allow-overlap": true,
//                 }
//             });

//             // const spiderfy = new Spiderfy(map, {
//             //     onLeafClick: (f: any) => console.log(f),
//             //     minZoomLevel: 12,
//             //     zoomIncrement: 2,
//             //     closeOnLeafClick: true,
//             //     circleSpiralSwitchover: 10,
//             //     circleOptions: {
//             //         leavesSeparation: 50,
//             //         leavesOffset: [0, 0],
//             //     },
//             //     spiralOptions: {
//             //         legLengthStart: 25,
//             //         legLengthFactor: 2.2,
//             //         leavesSeparation: 30,
//             //         leavesOffset: [0, 0],
//             //     },
//             //     spiderLegsAreHidden: false,
//             //     spiderLegsWidth: 2,
//             //     spiderLegsColor: 'black',
//             //     maxLeaves: 255,
//             //     renderMethod: 'flat',
//             // }); // create a new spiderfy object

//             // spiderfy.applyTo('unclustered-point');

//             map.on("click", "clusters", async (e: MapMouseEvent) => {
//                 const features = map.queryRenderedFeatures(e.point, {
//                     layers: ["clusters"],
//                 });
//                 if (!features[0].properties) {
//                     return;
//                 }
//                 const clusterId = features[0].properties.cluster_id;
//                 (map.getSource("weights") as maplibregl.GeoJSONSource).getClusterExpansionZoom(clusterId, (err, zoom) => {
//                     if (err) return;
//                     if (features[0].geometry.type == "GeometryCollection") return;
//                     map.easeTo({
//                         center: features[0].geometry.coordinates as [number, number],
//                         zoom,
//                     });
//                 });
//             });

//             map.on("click", "unclustered-point", (e) => {
//                 if (!e || !e.features || !e.features[0] || !e.features[0].properties) return;
//                 if (e.features[0].geometry.type == "GeometryCollection") return;


//                 const features = map.queryRenderedFeatures(e.point, {
//                     layers: ['unclustered-point']
//                 });

//                 if (!(features.length > 1)) {
//                 //     spiderfy.spiderfy(features, e.point);
//                 // } else {
//                 const coordinates = e.features[0].geometry.coordinates.slice();
//                 new Popup()
//                     .setLngLat(coordinates as [number, number])
//                     .setHTML(
//                         `<h3>${e.features[0].properties.date_time}</h3><p>Weight: ${e.features[0].properties.weight} kg</p>`
//                     )
//                     .addTo(map);
//                 }
//             });

//             map.on("mouseenter", "clusters", () => {
//                 map.getCanvas().style.cursor = "pointer";
//             });

//             map.on("mouseleave", "clusters", () => {
//                 map.getCanvas().style.cursor = "";
//             });

//             map.on("mouseenter", "unclustered-point", () => {
//                 map.getCanvas().style.cursor = "pointer";
//             });

//             map.on("mouseleave", "unclustered-point", () => {
//                 map.getCanvas().style.cursor = "";
//             });
//         });
//     }, [map]);

//     useEffect(() => {
//         if (map) {
//             map.flyTo({ center: props.longlat, essential: true, zoom: 20 });
//             if (props.currentSnapshot) {
//                 addPopup(props.currentSnapshot);
//             }
//         }
//     }, [props.longlat]);

//     useEffect(() => {
//         if (map && props.fitBounds) {
//             map.fitBounds(props.fitBounds, {
//                 padding: { top: 45, bottom: 25, left: 15, right: 15 },
//             });
//         }
//     }, [props.fitBounds]);

//     useLayoutEffect(() => {
//         if (map) {
//             map.resize();
//         }
//     }, []);

//     const createPopupDom = (datapoint: VehicleDataPoint) => {
//         const kgToPounds = 2.20462;
//         const weightInPounds = datapoint.weight * kgToPounds;
//         const roundedWeightInPounds = Math.round(weightInPounds / 10) * 10;

//         const selectedPopupHTML = renderToString(
//             <Box>
//                 <Box
//                     style={{
//                         color: theme.secondary,
//                         background: theme.tertiary,
//                         padding: 10,
//                         margin: -10,
//                         fontWeight: 700,
//                         textAlign: "center",
//                     }}
//                 >
//                     <Typography>{rfcToDateAndTime(datapoint.date_time)}</Typography>
//                 </Box>
//                 <Box
//                     style={{
//                         color: theme.primary,
//                         padding: 10,
//                         paddingBottom: 0,
//                         margin: -10,
//                         fontWeight: 700,
//                         textAlign: "center",
//                     }}
//                 >
//                     <Typography>Weight: {roundedWeightInPounds} lbs</Typography>
//                 </Box>
//             </Box>
//         );
//         const popupButtonHTML = renderToString(
//             <Box
//                 style={{
//                     color: theme.primary,
//                     padding: 10,
//                     paddingBottom: 0,
//                     margin: -10,
//                     fontWeight: 700,
//                     textAlign: "center",
//                 }}
//             >
//                 <button>More Details</button>
//             </Box>
//         );
//         const assignBtn = document.createElement("div");
//         assignBtn.innerHTML = popupButtonHTML;

//         const popupNode = document.createElement("div");
//         popupNode.innerHTML = selectedPopupHTML;
//         popupNode.appendChild(assignBtn);
//         assignBtn.addEventListener("click", () => {
//             props.popupClickCallback(datapoint);
//         });
//         return popupNode;
//     };

//     const addPopup = (datapoint: any) => {
//         if (map) {
//             new Popup({
//                 closeButton: false,
//                 offset: 40,
//             })
//                 .setLngLat([datapoint.longitude, datapoint.latitude])
//                 .setDOMContent(createPopupDom(datapoint))
//                 .addTo(map);
//         }
//     };

//     const switchMapStyle = () => {
//         if (map) {
//             setLoading(true);
//             map.setStyle(mapSwitch ? "Aw-Cloud-Portal-Map-Road" : "Aw-Cloud-Portal-Map-Satellite");
//             map.resize();
//             setMapSwitch(!mapSwitch);
//             setLoading(false);
//         }
//     };

//     const openModal = () => {
//         setFullScreen(true);
//     };

//     const closeModal = () => {
//         setFullScreen(false);
//     };

//     const customMapButtons = () => {
//         return (
//             <Box position={"absolute"} zIndex={1} marginTop={2} right={30}>
//                 <Stack direction="row" spacing={2}>
//                     <RoundedIconButton
//                         onClick={switchMapStyle}
//                         disabled={loading}
//                         variant="contained"
//                         color={theme.basicbuttoncolor}
//                     >
//                         <LayersIcon />
//                     </RoundedIconButton>
//                     <RoundedIconButton
//                         onClick={openModal}
//                         disabled={loading}
//                         variant="contained"
//                         color={theme.basicbuttoncolor}
//                     >
//                         <FullscreenIcon />
//                     </RoundedIconButton>
//                 </Stack>
//             </Box>
//         );
//     };

//     const modalCloseMapButton = () => {
//         return (
//             <Box position={"absolute"} zIndex={1} marginTop={2} right={110}>
//                 <Stack direction="row" spacing={2}>
//                     <RoundedIconButton
//                         onClick={closeModal}
//                         variant={"contained"}
//                         color={theme.backbuttoncolor}
//                     >
//                         <FullscreenExitIcon />
//                     </RoundedIconButton>
//                 </Stack>
//             </Box>
//         );
//     };

//     const recursiveModalSwitchStyles = () => {
//         return (
//             <Box position={"absolute"} zIndex={1} marginTop={2} right={110}>
//                 <RoundedIconButton
//                     onClick={switchMapStyle}
//                     disabled={loading}
//                     variant="contained"
//                     color={theme.basicbuttoncolor}
//                 >
//                     <LayersIcon />
//                 </RoundedIconButton>
//             </Box>
//         );
//     };


//     return (
//         <>
//             {props.recurse ? (
//                 <>
//                     {loading ? <LinearProgress /> : null}
//                     {alert ? <Alert severity="error">{alert}</Alert> : null}
//                     <Box display={"flex"} height={"100%"} width={"100%"} minHeight={400}>
//                         <Card sx={{ flexGrow: 1 }}>
//                             <Box
//                                 position="relative"
//                                 height="100%"
//                                 width="100%"
//                                 flexGrow={1}
//                                 zIndex={0}
//                             >
//                                 {customMapButtons()}
//                                 <Box
//                                     id={props.title}
//                                     width={"100%"}
//                                     height={"100%"}
//                                     position={"absolute"}
//                                     flexGrow={1}
//                                 ></Box>
//                             </Box>
//                         </Card>
//                     </Box>
//                 </>
//             ) : (
//                 <Box
//                     position="relative"
//                     height="100%"
//                     width="100%"
//                     flexGrow={1}
//                     paddingBottom={2}
//                     marginBottom={2}
//                 >
//                     {loading ? <LinearProgress /> : null}
//                     {alert ? <Alert severity="error">{alert}</Alert> : null}
//                     {recursiveModalSwitchStyles()}
//                     <Box
//                         id={props.title}
//                         width={"100%"}
//                         height={"100%"}
//                         position={"absolute"}
//                         flexGrow={1}
//                     ></Box>
//                 </Box>
//             )}
//             {props.recurse ? (
//                 <Backdrop open={fullScreen} sx={{ zIndex: 500 }}>
//                     <Box
//                         display={"flex"}
//                         marginLeft={40}
//                         marginRight={10}
//                         height={"80%"}
//                         width={"100%"}
//                     >
//                         <Card sx={{ flexGrow: 1 }}>
//                             <>
//                                 {modalCloseMapButton()}
//                                 <MyMapV2
//                                     title={`AW-Cloud-Portal-Recursed-Map-${props.count}`}
//                                     longlat={props.longlat}
//                                     recurse={false}
//                                     vehicleData={props.vehicleData}
//                                     searchBar={true}
//                                     mapSearchCallback={props.mapSearchCallback}
//                                     count={props.count + 1}
//                                     zoom={props.zoom}
//                                     fitBounds={props.fitBounds}
//                                     popupClickCallback={props.popupClickCallback}
//                                     currentSnapshot={props.currentSnapshot}
//                                 />
//                             </>
//                         </Card>
//                     </Box>
//                 </Backdrop>
//             ) : null}
//         </>
//     );
// };
