import {
	User,
	getUser,
} from "../../../utils/interfaces/users/user-details-interfaces";
import { validateName } from "../../../utils/regex/forms";
import { putUserPermissions } from "../../../utils/requests/user-details/put-user-permissions";
import DetailsTable from "../../../utils/generic-components/Details Table/details-table";
import { errorResponse } from "../../../utils/interfaces/error-interface";
import { DetailTableData } from "../../../utils/interfaces/details-table/details-table-interfaces";

export default function Details(props: { user: getUser; updatedat: string }) {
	let DetailTableData: DetailTableData = {
		data: [
			{
				label: "Username",
				content: props.user.data.username,
				editable: false,
				error: "",
			},
			{
				label: "Name",
				content: props.user.data.user_name,
				editable: true,
				error: "",
				regex: validateName,
			},
			{
				label: "Email",
				content: props.user.data.user_email,
				editable: false,
				error: "",
			},
		],
	};

	const putCallback = (
		data: DetailTableData,
		setAlert: Function,
		setEditingMode: Function,
		setLoadingButton: Function
	) => {
		let updatedUser = props.user.data;
		updatedUser.user_name = data.data[1].content ? data.data[1].content : "";
		putNewUser(updatedUser)
			.then((response: getUser) => {
				setEditingMode(false);
				setLoadingButton(false);
			})
			.catch((err: errorResponse) => {
				console.log(err);
				setAlert("Unable To Save, Try Again");
				setLoadingButton(false);
			});
	};

	const putNewUser = async (updatedUser: User) => {
		try {
			const response = await putUserPermissions(
				props.user.data.username,
				updatedUser,
				props.updatedat
			);
			return response;
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<>
			<DetailsTable
				data={DetailTableData}
				putCallback={putCallback}
				editing={true}
				close={false}
			/>
		</>
	);
}
