import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Stack,
} from "@mui/material";
import { useContext, useState } from "react";
import { AppContext } from "../../../utils/themes/theme-context";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ServicePlansInterface } from "../../../utils/interfaces/service/service-list-interface";
import DeleteServicePlanDialog from "./delete-service-plan";
import { useNavigate } from "react-router-dom";

interface DeleteServicePlanDialogProps {
  deleteServicePlan: ServicePlansInterface | null;
}

const Actions: React.FC<DeleteServicePlanDialogProps> = ({
  deleteServicePlan,
}) => {
  var { theme } = useContext(AppContext);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  let userNavigate = useNavigate();
  
  const handleDelete = () => {
    setDeleteDialogOpen(true); // Open the delete dialog
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setTimeout(() => userNavigate(`/service`), 400);
  };

  return (
    <>
      <>
        <Card elevation={8} sx={{ borderRadius: 3 }}>
          <CardHeader
            title="Actions"
            action={
              <IconButton aria-label="info">
                <InfoOutlinedIcon />
              </IconButton>
            }
          />
          <CardContent>
            <Divider />
            <Box paddingTop={2}>
              <Stack direction={"column"} spacing={2}>
                <Button
                  color={theme.backbuttoncolor}
                  variant="contained"
                  onClick={() => handleDelete()}
                >
                  Delete Service Plan
                </Button>
              </Stack>
            </Box>
          </CardContent>
        </Card>
      </>
      <DeleteServicePlanDialog
        open={isDeleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        deleteServicePlan={deleteServicePlan}
      />
    </>
  );
};

export default Actions;
