import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../utils/themes/theme-context";
import { Columns } from "../../../utils/interfaces/table/table-interfaces";
import { Client } from "../../../utils/interfaces/clients/clients-list-interface";
import { getClientDetails } from "../../../utils/requests/clients/get-clients-list";
import GeneralTable from "../../../utils/generic-components/table/table";
import { validateVersionNumber } from "../../../utils/regex/forms";
import { updateFirmware } from "../../../utils/requests/products/post-firmware-update";
import {
  FirmwareUpdatePOST,
  ProductListData,
} from "../../../utils/interfaces/products/products-list-interface";
import crypto from "crypto";
import { Buffer } from "buffer";
import CloseIcon from "@mui/icons-material/Close";
import { ServicePlan } from "../../../utils/interfaces/products/service-plan-inetrface";
import { getServicePlans } from "../../../utils/requests/products/get-service-plans";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

let columns: Columns = {
  Name: {
    type: "text",
    display: "Client Name",
    data: "client",
    sort: "Name",
  },
};

let columns1: Columns = {
  Name: {
    type: "text",
    display: "Service Plan",
    data: "client",
    sort: "Name",
  },
};

export default function Actions(props: { products: ProductListData, updateFirmwareStatus: (status: boolean) => void }) {
  var { theme } = useContext(AppContext);
  const [isUpdateFirmwareDialogOpen, seUpdateFirmwareDialogOpen] =
    useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [clients, setClients] = useState<Client[]>([]);
  const [servicePlan, setServicePlan] = useState<ServicePlan[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [selectedClients, setSelectedClients] = useState<Client[]>([]);
  const [selectedServicePlans, setSelectedServicePlans] = useState<
    ServicePlan[]
  >([]);
  const versionNumberRef = useRef<HTMLInputElement | null>(null);
  const releaseNotesRef = useRef<HTMLInputElement | null>(null);
  const commentsRef = useRef<HTMLInputElement | null>(null);
  const [selectedFirmwarewUpdateDetails, setSelectedFirmwarewUpdateDetails] =
    useState({
      versionNumber: "",
      releaseNotes: "",
      comments: "",
    });
  const [versionNumberError, setVersionNumberError] = useState<string>("");
  const [fileError, setFileError] = useState<string>("");
  const [step1Data, setStep1Data] = useState<{
    //to retain the data and selections when we go back in stepper
    step1: Client[];
  }>({
    step1: [],
  });
  const [step2Data, setStep2Data] = useState<{
    //to retain the data and selections when we go back in stepper
    step2: ServicePlan[];
  }>({
    step2: [],
  });
  const [base64Content, setBase64Content] = useState<string>("");
  const fileHashRef = useRef<string>("");
  const [isFirmareUpdated, setisFirmareUpdated] = useState<boolean>(false);

  const updateClick = () => {
    seUpdateFirmwareDialogOpen(true);
  };
  const dismissClick = () => {
    console.log("dismiss button clicked");
  };
  const disableClick = () => {
    console.log("disable button clicked");
  };

  async function handleUpdateFirmwarePOST(
    event: React.MouseEvent<HTMLButtonElement> | null
  ) {
    try {
      // Prepare the data for the API request
      const firmwareData: FirmwareUpdatePOST = {
        firmware_version: selectedFirmwarewUpdateDetails.versionNumber,
        file: base64Content, // Base64 content from the handleFileUpload
        file_hash: fileHashRef.current, // hash from the handleFileUpload
        client_restrictions: selectedClients.map((client) => client.id),
        service_plan_restrictions: selectedServicePlans.map((plan) => plan.id),
        release_notes: selectedFirmwarewUpdateDetails.releaseNotes,
        comments: selectedFirmwarewUpdateDetails.comments,
      };

      // Call the API to send the data to the backend
      const response = await updateFirmware(props.products.id, firmwareData);

      if (response.ok) {
        // Firmware update successful
        setisFirmareUpdated(true);
        props.updateFirmwareStatus(true); // Update parent component state
        setActiveStep((prevStep) => prevStep + 1);
      } else {
        // Handle error response from the server
        setisFirmareUpdated(false);
        props.updateFirmwareStatus(false); // Update parent component state
        setActiveStep((prevStep) => prevStep + 1);
        console.error("Error updating firmware:", response.statusText);
      }
    } catch (error) {
      // Handle other errors
      setisFirmareUpdated(false);
      setActiveStep((prevStep) => prevStep + 1);
      console.error("Error:", error);
    }
  }

  function handleCloseUpdateFirmware(): void {
    seUpdateFirmwareDialogOpen(false);
    setActiveStep(0);
    setVersionNumberError("");
    setFileError("");
    setSelectedFileName("");
    setSelectedFirmwarewUpdateDetails({
      // Reset to empty state
      versionNumber: "",
      releaseNotes: "",
      comments: "",
    });
    setSelectedClients([]); // Reset selected clients
    setSelectedServicePlans([]); // Reset selected service plan
    setStep1Data({
      step1: [], // Reset step1 data
    });
    setStep2Data({
      step2: [], // Reset step2 data
    });
  }

  const handleNextStep = () => {
    if (activeStep === 0) {
      const versionNumber = versionNumberRef.current?.value || "";
      const file = selectedFileName;

      let valid = true;

      const versionNumberError = validateVersionNumber(
        versionNumber,
        "Invalid Version Number. Version Number should be in Format: X.XX.X"
      );
      if (!versionNumber) {
        setVersionNumberError("Version Number is required");
        valid = false;
      } else if (versionNumberError) {
        setVersionNumberError(versionNumberError);
        valid = false;
      } else {
        setVersionNumberError("");
      }

      if (!file) {
        setFileError("File is required");
        valid = false;
      } else {
        setFileError("");
      }

      if (!valid) {
        return; // Don't proceed to the next step if validation fails
      }

      const updatedDetails = {
        versionNumber,
        releaseNotes: releaseNotesRef.current?.value || "",
        comments: commentsRef.current?.value || "",
      };

      setSelectedFirmwarewUpdateDetails(updatedDetails);
    }

    if (activeStep === 1) {
      // Store selected clients in stepData
      setStep1Data({
        ...step1Data,
        step1: selectedClients,
      });
    }

    if (activeStep === 2) {
      // Store selected service plan in stepData
      setStep2Data({
        ...step2Data,
        step2: selectedServicePlans,
      });
    }

    setActiveStep((prevStep) => prevStep + 1);
  };

  const handlePrevStep = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  // 	hashBuffer is the raw binary output of the hash calculation.
  // hashArray converts the binary representation into a JavaScript array for easier manipulation.
  // hashHex creates a human-readable hexadecimal representation of the hash.

  // Calculate the SHA-256 hash of the file content using window.crypto.subtle
  async function calculateSHA256Hash(buffer: ArrayBuffer): Promise<string> {
    //representing the hexadecimal representation of the SHA-256 hash.
    //conversion is necessary because the window.crypto.subtle.digest method expects data in the form of a Uint8Array.
    const data = new Uint8Array(buffer); // Convert ArrayBuffer to Uint8Array

    // crypto.subtle API is available in browsers, not in Node.js. Since we are using the browser, we can directly use window.crypto.subtle to access the cryptographic operations.
    //The window.crypto.subtle.digest method is used to calculate the hash.  It takes two arguments: the hash algorithm ("SHA-256" in this case) and the data (data in this case).
    // result is a hash value stored in a binary ArrayBuffer named hashBuffer.
    const hashBuffer = await window.crypto.subtle.digest("SHA-256", data);
    // The hashBuffer is then converted into a regular JavaScript array (hashArray) using the Array.from method.
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    // The hashArray is transformed into a hexadecimal representation (hashHex) by iterating through each byte in the array and converting it to a two-character hexadecimal string using byte.toString(16).padStart(2, '0').
    // The padStart function is used to ensure that each byte's hexadecimal representation is at least two characters long, adding leading zeroes if needed.
    const hashHex = hashArray
      .map((byte) => byte.toString(16).padStart(2, "0"))
      .join(""); //hexadecimal hash representation

    return hashHex;
  }

  async function encodeArrayBufferToBase64(
    arrayBuffer: ArrayBuffer
  ): Promise<string> {
    //encodes the array buffer into base65 string
    const data = new Uint8Array(arrayBuffer); //Uint8Array is used to convert the ArrayBuffer to a more manageable format.

    // const encoder = new TextEncoder();
    const base64 = Buffer.from(data).toString("base64"); // converts the data to a Base64 string.
    return base64;
  }

  //conversion to base64
  const handleFileUpload = (selectedFiles: FileList | null) => {
    if (selectedFiles && selectedFiles.length > 0) {
      const file = selectedFiles[0]; // Get the first selected file
      setSelectedFileName(selectedFiles[0].name);

      if (file.name.endsWith(".gbl")) {
        const reader = new FileReader(); // If the file's extension is ".gbl", it initializes a FileReader to read the file content as an ArrayBuffer.
        reader.onload = async (e) => {
          //Once the reader loads the content, an async callback is invoked
          if (e.target) {
            const fileContent = e.target.result as ArrayBuffer; // the file content is extracted from the FileReader result.

            try {
              const encodedBase64Content = await encodeArrayBufferToBase64(
                fileContent
              );
              setBase64Content(encodedBase64Content); // Update the state

              // Calculate the SHA-256 hash of the file content
              const hash = await calculateSHA256Hash(fileContent);
              fileHashRef.current = hash; // Update the hash using useRef
            } catch (error) {
              console.error("Error encoding file content:", error);
            }
          } else {
            console.error("Error reading file content: e.target is null");
          }
        };
        reader.readAsArrayBuffer(file);
      } else {
        // Display an error message or handle invalid file type
        console.log("Invalid file type. Only .gbl files are allowed.");
      }
    } else {
      setSelectedFileName("");
    }
  };

  const handleMultiSelectCallback = (selectedRows: string[]) => {
    //Function takes an array of selectedRows, which contains the IDs of the selected rows.
    const selectedClients = clients.filter((client) =>
      selectedRows.includes(client.id)
    ); //The function uses the filter method to create a new array selectedClients by filtering the clients array based on the selected row IDs
    setSelectedClients(selectedClients); // Store selected clients' data
    // Update stepData with selected clients
  };

  const handleMultiSelectCallbackSP = (selectedRows: string[]) => {
    //Function takes an array of selectedRows, which contains the IDs of the selected rows.
    const selectedServicePlans = servicePlan.filter((plan) =>
      selectedRows.includes(plan.id)
    ); //The function uses the filter method to create a new array selectedClients by filtering the clients array based on the selected row IDs
    setSelectedServicePlans(selectedServicePlans); // Store selected clients' data
    // Update stepData with selected clients
  };

  const steps = [
    "Enter Details",
    "Select Client",
    "Select Service Plan",
    "Review",
    "Confirmation",
  ];

  useEffect(() => {
    //Fetch Client list data and update the state
    getClientDetails()
      .then((response: { data: Client[] }) => {
        //the response object data has a property type Client[]
        //Successful response
        const data = response.data; //data property is assigened to variable data
        setClients(data);
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          if (error.response.status === 401) {
            // 401 Unauthorized error
            setErrorMessage("Parameter mismatch");
          } else if (error.response.status === 500) {
            // 500 Internal Server Error
            setErrorMessage("Internal Server Error");
          } else {
            // other errors
            setErrorMessage(`Error: ${error.message}`);
          }
        } else if (error.request) {
          // The request was made but no response was received
          setErrorMessage("No response received");
        } else {
          // Something happened in setting up the request that triggered an error
          setErrorMessage(`Error: ${error.message}`);
        }
      });
  }, []);

  useEffect(() => {
    //Fetch service plan data and update the state
    getServicePlans()
      .then((response: { data: ServicePlan[] }) => {
        //the response object data has a property type ServicePlan[]
        //Successful response
        const data = response.data; //data property is assigened to variable data
        setServicePlan(data);
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          if (error.response.status === 401) {
            setErrorMessage("Parameter mismatch");
          } else if (error.response.status === 500) {
            setErrorMessage("Internal Server Error");
          } else {
            setErrorMessage(`Error: ${error.message}`);
          }
        } else if (error.request) {
          setErrorMessage("No response received");
        } else {
          setErrorMessage(`Error: ${error.message}`);
        }
      });
  }, []);

  return (
    <>
      <Card elevation={8} sx={{ borderRadius: 3 }}>
        <CardHeader
          title="Actions"
          action={
            <IconButton aria-label="info">
              <InfoOutlinedIcon />
            </IconButton>
          }
        />
        <CardContent>
          <Divider />
          <Box paddingTop={2}>
            <Stack direction={"column"} spacing={2}>
              <Button variant="contained" onClick={() => updateClick()}>
                Upload Firmware
              </Button>
            </Stack>
          </Box>
          {/* Dialog for Upload Firmware Button */}
          <Dialog
            open={isUpdateFirmwareDialogOpen}
            onClose={handleCloseUpdateFirmware}
          >
            <DialogTitle variant="h5" align="center">
              Upload Firmware Version
              <IconButton
                aria-label="close"
                onClick={handleCloseUpdateFirmware}
                sx={{
                  position: "absolute",
                  right: 2,
                  top: 2,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Box
                flexGrow={1}
                marginLeft={"2%"}
                marginRight={"2%"}
                marginTop={8}
                marginBottom={10}
              >
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                {/* Step content */}
                {activeStep === 0 && (
                  /* Step 1: Enter Information */
                  <div>
                    <Typography
                      variant="h6"
                      align="center"
                      style={{ fontWeight: "bold" }}
                    >
                      Enter Details
                    </Typography>
                    <TextField
                      label="Version Number"
                      fullWidth
                      margin="normal"
                      inputRef={versionNumberRef}
                      error={!!versionNumberError}
                      helperText={versionNumberError}
                      value={selectedFirmwarewUpdateDetails.versionNumber}
                      onChange={(e) => {
                        setSelectedFirmwarewUpdateDetails({
                          ...selectedFirmwarewUpdateDetails,
                          versionNumber: e.target.value,
                        });
                      }}
                    />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <label
                        htmlFor="fileInput"
                        style={{
                          backgroundColor: theme.selectedbuttoncolor,
                          color: "white", // Text color
                          padding: "10px 15px",
                          borderRadius: "5px",
                          border: "none",
                          cursor: "pointer",
                          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Add a subtle shadow
                          transition: "background-color 0.3s, transform 0.3s", // Smooth transition effects
                          display: "inline-block",
                        }}
                      >
                        Choose File
                      </label>
                      <input
                        id="fileInput"
                        type="file"
                        accept=".gbl"
                        onChange={(e) => handleFileUpload(e.target.files)}
                        style={{
                          display: "none", // Hide the default input
                        }}
                      />

                      {selectedFileName && (
                        <p
                          style={{
                            marginLeft: "15px",
                            marginTop: "10px",
                            fontSize: "14px",
                          }}
                        >
                          {selectedFileName}
                        </p>
                      )}
                      {!selectedFileName && (
                        <p
                          style={{
                            marginLeft: "15px",
                            marginTop: "10px",
                            fontSize: "14px",
                          }}
                        >
                          No file chosen
                        </p>
                      )}

                      {fileError && (
                        <p
                          style={{
                            marginLeft: "15px",
                            marginTop: "10px",
                            fontSize: "14px",
                            color: "red",
                          }}
                        >
                          {fileError}
                        </p>
                      )}
                    </div>
                    <TextField
                      label="Release Notes"
                      fullWidth
                      margin="normal"
                      inputRef={releaseNotesRef} // Attach the ref to the TextField
                      value={selectedFirmwarewUpdateDetails.releaseNotes}
                      onChange={(e) => {
                        setSelectedFirmwarewUpdateDetails({
                          ...selectedFirmwarewUpdateDetails,
                          releaseNotes: e.target.value,
                        });
                      }}
                    />
                    <TextField
                      label="Comments"
                      fullWidth
                      margin="normal"
                      inputRef={commentsRef} // Attach the ref to the TextField
                      value={selectedFirmwarewUpdateDetails.comments}
                      onChange={(e) => {
                        setSelectedFirmwarewUpdateDetails({
                          ...selectedFirmwarewUpdateDetails,
                          comments: e.target.value,
                        });
                      }}
                    />
                  </div>
                )}
                {activeStep === 1 && (
                  /* Step 2: Select Client */
                  <div>
                    <Typography
                      variant="h6"
                      align="center"
                      style={{ fontWeight: "bold" }}
                    >
                      Select Client
                    </Typography>
                    <GeneralTable
                      data={clients.map((client) => ({
                        id: client.id,
                        Name: client.client,
                      }))}
                      columns={columns}
                      multiselect={true}
                      multiselectCallback={handleMultiSelectCallback}
                      selectedRows={step1Data.step1.map((client) => client.id)} //to provide the array of string we used map
                      noDataMessage={"Unable To Firmware Upload"}
                      rows={10}
                      rowsoptions={[10, 25, 50, 100]}
                    />
                  </div>
                )}
                {activeStep === 2 && (
                  /* Step 3: Select Service Plan */
                  <div>
                    <Typography
                      variant="h6"
                      align="center"
                      style={{ fontWeight: "bold" }}
                    >
                      Select Service Plan
                    </Typography>
                    <GeneralTable
                      data={servicePlan.map((plan) => ({
                        id: plan.id,
                        Name: plan.name,
                      }))}
                      columns={columns1}
                      multiselect={true}
                      multiselectCallback={handleMultiSelectCallbackSP}
                      selectedRows={step2Data.step2.map((plan) => plan.id)} //to provide the array of string we used map
                      noDataMessage={"Unable To Firmware Upload"}
                      rows={10}
                      rowsoptions={[10, 25, 50, 100]}
                    />
                  </div>
                )}
                {activeStep === 3 && (
                  /* Step 3: Review */
                  <div>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={2}>
                            <Typography
                              variant="h6"
                              align="center"
                              style={{ fontWeight: "bold" }}
                            >
                              Review
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            colSpan={2}
                            style={{ background: "#f0f0f0" }}
                          >
                            <Typography
                              variant="body1"
                              style={{ fontWeight: "bold" }}
                            >
                              Selected Product Details:
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Version Number:</TableCell>
                          <TableCell>
                            {selectedFirmwarewUpdateDetails.versionNumber}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>File Name:</TableCell>
                          <TableCell>{selectedFileName}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Release Notes:</TableCell>
                          <TableCell>
                            {selectedFirmwarewUpdateDetails.releaseNotes}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Comments:</TableCell>
                          <TableCell>
                            {selectedFirmwarewUpdateDetails.comments}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>

                    <Table style={{ marginTop: "16px" }}>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={2}
                            style={{ background: "#f0f0f0" }}
                          >
                            <Typography
                              variant="body1"
                              style={{ fontWeight: "bold" }}
                            >
                              Selected Clients:
                            </Typography>
                          </TableCell>
                        </TableRow>
                        {selectedClients.map((client) => (
                          <TableRow key={client.id}>
                            <TableCell>{client.client}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>

                    <Table style={{ marginTop: "16px" }}>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={2}
                            style={{ background: "#f0f0f0" }}
                          >
                            <Typography
                              variant="body1"
                              style={{ fontWeight: "bold" }}
                            >
                              Selected Service Plan:
                            </Typography>
                          </TableCell>
                        </TableRow>
                        {selectedServicePlans.map((plan) => (
                          <TableRow key={plan.id}>
                            <TableCell>{plan.name}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                )}
                {activeStep === 4 && (
                  /* Step 3: Result */
                  <div style={{ padding: "40px" }}>
                    {isFirmareUpdated ? (
                      <Typography variant="h6" style={{ color: "green" }}>
                        Firmware Updated Successful
                      </Typography>
                    ) : (
                      <Typography variant="h6" style={{ color: "red" }}>
                        Firmware Update Failed
                      </Typography>
                    )}
                  </div>
                )}
              </Box>
              {/* Buttons */}
              <DialogActions>
                <Box display="flex" justifyContent="space-between" width="100%">
                  {activeStep === 0 && (
                    <Button
                      variant="contained"
                      onClick={handleCloseUpdateFirmware}
                    >
                      Close
                    </Button>
                  )}
                  {activeStep > 0 && (
                    <Button variant="contained" onClick={handlePrevStep}>
                      Back
                    </Button>
                  )}
                  {activeStep === 3 ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleUpdateFirmwarePOST}
                    >
                      Update
                    </Button>
                  ) : activeStep < steps.length - 1 ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNextStep}
                    >
                      Next
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCloseUpdateFirmware}
                    >
                      Finish
                    </Button>
                  )}
                </Box>
              </DialogActions>
            </DialogContent>
          </Dialog>
        </CardContent>
      </Card>
    </>
  );
}
