export interface DeviceFirmwareVersion {
    client_restrictions: [];
    comments: string;
    date_time: string;
    file_hash: string;
    firmware_version: string;
    id: string;
    product_id: string;
    release_notes: string;
    service_plan_restrictions: [];
    updated_at: string;
  }

  export function getEmptyFirmwareVersion (): DeviceFirmwareVersion {
	return {
        client_restrictions: [],
        comments: "",
        date_time: "",
        file_hash: "",
        firmware_version: "",
        id: "",
        product_id: "",
        release_notes: "",
        service_plan_restrictions: [],
        updated_at: "",
	};
}
  