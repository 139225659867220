import {
	Box,
	Card,
	CardActionArea,
	CardContent,
	CardHeader,
	CardMedia,
	Divider,
	Grid,
	Typography,
} from "@mui/material";

//NOTE: this page is designed for use outside of the router
//thus is should be understood that no user details, theme, or other context is available

export default function QrScanPage() {
	return (
		//center in page horizonally and vertically
		<Grid
			container
			justifyContent="center"
			alignItems="center"
			sx={{ height: "100vh" }}
		>
			<Grid item>
				<Card sx={{ width: "50vw", maxWidth: "600px" }}>
					<CardActionArea sx={{ padding: 5 }}>
						<CardMedia
							component="img"
							image="/images/color-Air-Weigh_sans_subtext-web.png"
							alt="Air-Weigh logo"
						/>
					</CardActionArea>
					<Divider />

					<CardContent>
						<Grid container>
							<Grid item xs={12}>
								<Typography variant="h5" align="center">
									Thank you for choosing iWeigh
								</Typography>
							</Grid>
							<Grid item xs={12} md={6} sx={{ paddingTop: 1 }}>
								<Typography
									align="center"
									justifyContent="center"
									style={{ fontSize: "1.2rem" }}
									sx={{ paddingTop: 2 }}
								>
									iWeigh leads the industry with with time
									weight measurements, accurancy, integrations
									and ease of use! Download the app to get
									started.
								</Typography>
							</Grid>
							<Grid item xs={12} md={6} sx={{ paddingTop: 1 }}>
								<Box
									display="flex"
									justifyContent="center" // Horizontally center the content
									alignItems="center" // Vertically center the content
									height="100%"
								>
									<img
										//dynamically size
										width="70%"
										src="/images/iweigh-render2.png"
										alt="qr code"
									/>
								</Box>
							</Grid>

							<Grid item xs={12} paddingTop={5}>
								<Box
									display="flex"
									justifyContent="center" // Horizontally center the content
									alignItems="center" // Vertically center the content
								>
									<img
										//dynamically size
										width="75%"
										src="/images/google-play-badge.png"
										alt="qr code"
										onClick={() => {
											//link to google play store
											window.open(
												"https://play.google.com/store/apps/",
												"_blank"
											);
										}}
									/>
								</Box>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
}
