import React, { useState, useContext, useEffect, useRef } from "react";
import {
    Button,
    Typography,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    Stepper,
    Step,
    StepLabel,
    Box,
    IconButton,
    Autocomplete,
    InputAdornment,
    CircularProgress,
} from "@mui/material";
import { DevicesDetails, RegisterClient } from "../../../../utils/interfaces/vehicles/devices-details-interface";
import { AppContext } from "../../../../utils/themes/theme-context";
import { RegisterClienttoDevice } from "../../../../utils/requests/devices/register-client-to-device";
import { getClientSearch } from "../../../../utils/requests/clients/get-clients-search";
import { getClientDetails } from "../../../../utils/requests/clients/get-clients-list";
import ClearIcon from "@mui/icons-material/Clear";
import { Client } from "../../../../utils/interfaces/clients/clients-list-interface";
import CloseIcon from "@mui/icons-material/Close";

interface RegisterClientActionProps {
    device: DevicesDetails;
    open: boolean;
    onClose: () => void;
    onUpdateClientName: (newClientName: string) => void;
}

export default function RegisterClientAction(props: RegisterClientActionProps) {
    const { theme } = useContext(AppContext);
    const [registerResult, setRegisterResult] = useState("");
    const [registerResultColor, setRegisterResultColor] = useState("");
    const [updateAssignActiveStep, setUpdateAssignActiveStep] = useState(0);
    const [AllClientNames, setAllClientNames] = useState<string[]>([]);
    const [allclients, setAllClients] = useState<Client[]>([]);
    const [searchTerm, setSearchTerm] = useState<string | null>(""); // State for search term
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [Clientloading, setClientLoading] = useState<boolean>(false);
    const [resetVisible, setResetVisible] = useState(false); // Define resetVisible state
    const [selectSuggestions, setSelectSuggestions] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true); //initial stage of loading is true
    const [searchResponse, setSearchResponse] = useState<any[]>([]);
    const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);
    const [selectedClientName, setSelectedClientName] = useState<string | null>(null);
    const [selectedClientId, setSelectedClientId] = useState<string>("");

    const handleUpdateAssignNext = () => {
        setUpdateAssignActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleRegister = async () => {
        if (selectedClientName !== null) {
            // Prepare the data to send to the API
            const data: RegisterClient = {
                client_id: selectedClientId,
            };
            // Call the API to register the client to the device
            const response = await RegisterClienttoDevice(props.device.id, data);
            // Handle response if necessary
            if (response.status === 204) {
                setRegisterResult("Client Registered to Device successfully");
                setRegisterResultColor("green");
                // Call the callback function to update the client name in parent component
                props.onUpdateClientName(selectedClientName);
            } else {
                setRegisterResult("Client Registration to Device unsuccessful");
                setRegisterResultColor("red");
            }
            setUpdateAssignActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
            console.error("Selected client name is null.");
        }
    };

    const handleUpdateAssignBack = () => {
        setUpdateAssignActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const closeUpdateAssignDialog = () => {
        props.onClose();
        setUpdateAssignActiveStep(0);
        setErrorMessage("");
        setSearchTerm("");
        setSelectSuggestions(false);
        setResetVisible(false);
    };

    const handleSuggestionSelect = async (selectedItem: string | null) => {
        setSearchTerm(selectedItem);
        setSelectSuggestions(true);

        if (selectedItem) {
            try {
                setLoading(true);

                if (searchTerm && searchTerm.trim() !== "") {
                    const selectedItemData = searchResponse.find(client => client.client === selectedItem);
                    if (selectedItemData) {
                        const clientId = selectedItemData.id; // Extract the client ID
                        const clientName = selectedItemData.client;
                        setSelectedClientName(clientName);
                        setSelectedClientId(clientId);
                    } else {
                        console.error("Selected client not found in search response.");
                    }
                } else {
                    // No search term, directly select the client from the list of all clients
                    const selectedClientData = allclients.find(client => client.client === selectedItem);
                    if (selectedClientData) {
                        setSelectedClientName(selectedClientData.client);
                        setSelectedClientId(selectedClientData.id);
                    } else {
                        console.error("Selected client not found in the list of all clients.");
                    }
                }

                setLoading(false);
            } catch (error) {
                console.error("Error fetching devices:", error);

                setLoading(false);
            }
        }
    };

    const fetchSuggestions = async (query: string) => {
        if (timeoutIdRef.current !== null) {
            clearTimeout(timeoutIdRef.current);
        }

        timeoutIdRef.current = setTimeout(async () => {
            try {
                setClientLoading(true);

                // If there's a query, fetch suggestions based on the query
                const searchResponse = await getClientSearch("client", query);
                setSearchResponse(searchResponse.data);
                const clientNames = searchResponse.data.map((client: { client: string }) => client.client);
                setSuggestions(clientNames);
                setClientLoading(false);
            } catch (error) {
                console.error("Error fetching suggestions:", error);
                setClientLoading(false);
            }
        }, 1000);
    };


    const handleSearchTermChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const newSearchTerm = event.target.value;


        if (newSearchTerm.trim() === "") {
            // it means the user is actively typing 

            setSearchTerm("");
            setSelectSuggestions(false);
            setResetVisible(false);
        } else {
            // it indicates that the user has deleted the search term or hasn't typed anything yet
            // we don't want to immediately trigger a search request because the user may continue typing shortly. 
            // Instead, we want to wait for a brief pause in typing before initiating the search.
            setSuggestions([])
            setSearchTerm(newSearchTerm);
            setResetVisible(true);
            fetchSuggestions(newSearchTerm);
            if (selectSuggestions && suggestions.includes(newSearchTerm)) {
                setSelectSuggestions(true); // Set selectSuggestions to true if a suggestion is selected
            } else {
                setSelectSuggestions(false); // Set selectSuggestions to false when search term changes
            }
        }

        // Update the Autocomplete value to reflect the current search term
        setSearchTerm(newSearchTerm);
    };


    const handleReset = () => {
        // Clear the search term and restore the original table data
        setSearchTerm("");
        setSuggestions(AllClientNames);
        setResetVisible(false); // Hide the Reset button
    };

    useEffect(() => {
        if (!searchTerm || searchTerm.trim() === "") {
            setClientLoading(true);
            getClientDetails()
                .then((allClientsResponse) => {
                    const allClientNames = allClientsResponse.data.map((client: { client: string }) => client.client);
                    const allClientsData = allClientsResponse.data;
                    setAllClientNames(allClientNames);
                    setAllClients(allClientsData)
                    setSuggestions(allClientNames);
                    setClientLoading(false);
                })
                .catch((error) => {
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        if (error.response.status === 401) {
                            // 401 Unauthorized error
                            setErrorMessage("Parameter mismatch");
                        } else if (error.response.status === 500) {
                            // 500 Internal Server Error
                            setErrorMessage("Internal Server Error");
                        } else {
                            // other errors
                            setErrorMessage(`Error: ${error.message}`);
                        }
                    } else if (error.request) {
                        // The request was made but no response was received
                        setErrorMessage("No response received");
                    } else {
                        // Something happened in setting up the request that triggered an error
                        setErrorMessage(`Error: ${error.message}`);
                    }
                    setClientLoading(false);
                });
        } else {
            // Fetch client suggestions based on the search term
            fetchSuggestions(searchTerm);
        }
    }, [searchTerm]);

    return (
        <>
            <Dialog open={props.open} onClose={closeUpdateAssignDialog}>
                <DialogTitle variant="h4" align="center">Update/Assign Client Registration
                    <IconButton
                        aria-label="close"
                        onClick={closeUpdateAssignDialog}
                        sx={{ position: "absolute", right: 0, top: 0 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <Stepper activeStep={updateAssignActiveStep} alternativeLabel>
                    <Step>
                        <StepLabel>Select a client</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Review</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Confirmation</StepLabel>
                    </Step>
                </Stepper>
                <Divider style={{ marginTop: "15px" }} />
                <DialogContent>
                    {updateAssignActiveStep === 0 && (
                        // Step 1: Select a client
                        <Box sx={{ paddingY: 3, paddingX: 5, borderRadius: 8, textAlign: 'center' }}>
                            <Typography fontSize={20} paddingBottom={3} color={"green"}>This action will register the selected client with this device.</Typography>
                            <Typography variant="h5">Select a Client:</Typography>
                            <Box sx={{ paddingY: 2 }}>
                                <Autocomplete
                                    // fullWidth
                                    style={{ width: "100%" }}
                                    options={suggestions}
                                    loading={Clientloading}
                                    value={searchTerm}
                                    onChange={(event, value) => handleSuggestionSelect(value)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Search"
                                            variant="outlined"
                                            // fullWidth
                                            onChange={handleSearchTermChange}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {Clientloading ? (
                                                            <CircularProgress color="inherit" size={20} />
                                                        ) : (
                                                            resetVisible && (
                                                                <IconButton onClick={handleReset} size="small">
                                                                    <ClearIcon />
                                                                </IconButton>
                                                            )
                                                        )}
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    )} />
                            </Box>
                            <Box mt={2} justifyContent="space-between" display="flex">
                                <Button
                                    onClick={closeUpdateAssignDialog} // Close the dialog on cancel
                                    variant="contained"
                                    color={theme.backbuttoncolor} // Adjust color based on your theme
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={handleUpdateAssignNext} // Move to next step on click
                                    variant="contained"
                                    color={theme.nextbuttoncolor} // Adjust color based on your theme
                                >
                                    Next
                                </Button>
                            </Box>
                        </Box>
                    )}
                    {updateAssignActiveStep === 1 && (
                        // Step 2: Review the selection
                        <>
                            <Box sx={{ paddingY: 3, paddingX: 5, borderRadius: 8, textAlign: 'center' }}>
                                <Typography fontSize={20}>The selected client name is :
                                    <Box fontWeight="bold" fontSize={24} marginY={2}>
                                        {selectedClientName}
                                    </Box>
                                </Typography>
                                <Box mt={2} justifyContent="space-between" display="flex">
                                    <Button
                                        onClick={handleUpdateAssignBack} // Close the dialog on cancel
                                        variant="contained"
                                        color={theme.backbuttoncolor} // Adjust color based on your theme
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        onClick={handleRegister} // Move to next step on click
                                        variant="contained"
                                        color={theme.nextbuttoncolor} // Adjust color based on your theme
                                    >
                                        Register
                                    </Button>
                                </Box>
                            </Box>
                        </>
                    )}
                    {updateAssignActiveStep === 2 && (
                        // Step 3: Confirmation
                        <div>
                            <Typography padding={5} fontSize={24}>
                                <span style={{ color: registerResultColor }}>
                                    {registerResult}
                                </span>
                            </Typography>
                            <Box
                                mt={2}
                                display="flex"
                                justifyContent="center"
                                style={{ padding: 40 }}
                            >
                                <Button
                                    onClick={closeUpdateAssignDialog}
                                    variant="contained"
                                    color={theme.nextbuttoncolor}
                                >
                                    Done
                                </Button>
                            </Box>
                        </div>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
}
