//get /products from backend

import { Auth } from "aws-amplify";
import { buildBaseUrl } from "../build-base-url";

export const getServicePlans = async () => {
	const url = buildBaseUrl()
	try {
		const authData = await Auth.currentAuthenticatedUser();
		const response = await fetch(`${url}service_plans`, {
			headers: {
				Authorization: `Bearer ${authData.signInUserSession.accessToken.jwtToken}`,
			},
		});
		const data  = await response.json();
		return data;
	} catch (err) {
		console.log(err);
	}
};