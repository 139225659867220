import { Auth } from "aws-amplify";
import { buildBaseUrl } from "../build-base-url";

export const UnregisterClientfromDevice = async (device_id: string) => {
	const url = buildBaseUrl()
	try {
		const authData = await Auth.currentAuthenticatedUser();
        const response = await fetch(`${url}device/${device_id}/register`, {
				method: "DELETE",
				headers: {
					Authorization: `Bearer ${authData.signInUserSession.accessToken.jwtToken}`,
				},
			}
		);
		if (response.status === 204) {
			return "success";
		} else {
			let data = await response.json();
			return data.errors;
		}
	} catch (err) {
		console.log(err);
	}
};