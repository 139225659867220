import { buildBaseUrl } from "../build-base-url";

interface ResetPasswordRequest {
  code: string;
  password: string;
  email: string;
}

export const PatchForgotPassword = async (
  code: string,
  newPassword: string,
  email: string
): Promise<Response> => {
  const url = `${buildBaseUrl()}users/forgot_password`;

  const requestData: ResetPasswordRequest = {
    code: code,
    password: newPassword,
    email: email,
  };

  const response = await fetch(url, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestData),
  });

  return response;
};
