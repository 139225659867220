import { Auth } from "aws-amplify";
import { buildBaseUrl } from "../build-base-url";

export const getUserFromUsername = async (username: string | undefined) => {
	const url = buildBaseUrl()
	if (!username) {
		return;
	}
	try {
		const authData = await Auth.currentAuthenticatedUser();
		const response = await fetch(`${url}users/${username}`, {
			headers: {
				Authorization: `Bearer ${authData.signInUserSession.accessToken.jwtToken}`,
			},
		});
		return response.json();
	} catch (err) {
		console.log(err);
	}
};
