import {
  Alert,
  AlertTitle,
  LinearProgress,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { AppContext } from "../../../utils/themes/theme-context";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Columns,
} from "../../../utils/interfaces/table/table-interfaces";
import GeneralTable from "../../../utils/generic-components/table/table";
import { getDevicesforProducts } from "../../../utils/requests/products/get-devices-for-product";
import { ProductsDevicesTabList } from "../../../utils/interfaces/products/products-devicestab-list";

export default function DevicesTablList() {
  var { theme } = useContext(AppContext);
  const { id, version } = useParams<{ id?: string; version?: string }>();
  const [devicesTabData, setDevicesTabData] = useState<
    ProductsDevicesTabList[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  let userNavigate = useNavigate();

  let columns: Columns = {
    ID: {
      type: "button",
      display: "Device ID",
      data: "id",
      sort: "ID",
      onClick: (rowData: { ID: any }) => {
        const deviceId = rowData.ID;
        userNavigate(`/device/${deviceId}`);
      },
    },
    InstallationDate: {
      type: "time",
      display: "Installation Date",
      data: "installation_date",
      sort: "InstallationDate",
    },
    RegistrationDate: {
      type: "time",
      display: "Registration Date",
      data: "registration_date",
      sort: "RegistrationDate",
    },
    VehicleId: {
      type: "button",
      display: "Vehicle Id",
      data: "vehicle_id",
      sort: "VehicleId",
      onClick: (rowData: { VehicleId: any }) => {
        const vehicleId = rowData.VehicleId;
        userNavigate(`/vehicles/${vehicleId}`);
      },
    },
    ClientId: {
      type: "button",
      display: "Client Id",
      data: "client_id",
      sort: "ClientId",
      onClick: (rowData: { ClientId: any }) => {
        const clientId = rowData.ClientId;
        userNavigate(`/clients/${clientId}`);
      },
    },
    Serial: {
      type: "text",
      display: "Serial",
      data: "serial",
      sort: "Serial",
    },
    ProductId: {
      type: "text",
      display: "Product Id",
      data: "product_id",
      sort: "ProductId",
    },
    FirmwareId: {
      type: "text",
      display: "Firmware Id",
      data: "firmware_id",
      sort: "FirmwareId",
    },
    UpdatedAt: {
      type: "time",
      display: "Updated At",
      data: "updated_at",
      sort: "UpdatedAt",
    },
  };

  useEffect(() => {
    const fetchDevicesTabData = async () => {
      try {
        setLoading(true);
        const responseData = await getDevicesforProducts(id, "");
        setDevicesTabData(responseData || []); // Ensure responseData.data is not undefined
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false); // Set loading to false after receiving the response
      }
    };
    fetchDevicesTabData();
  }, [id]);

  const loadingDisplay = () => {
    return (
      <>
        <LinearProgress />
        <Stack spacing={2}>
          <Skeleton variant="rectangular" height={50} animation="wave" />
          <Skeleton variant="rectangular" height={50} animation="wave" />
          <Skeleton variant="rectangular" height={50} animation="wave" />
        </Stack>
      </>
    );
    return null;
  };

  return (
    <>
      <Typography variant="h5">Devices</Typography>
      {loading ? ( // Show skeleton when loading is true
        loadingDisplay()
      ) : devicesTabData && devicesTabData.length === 0 ? (
        <Alert severity="error">
          <AlertTitle>No devices available for the Product</AlertTitle>
        </Alert>
      ) : (
        <GeneralTable
          data={devicesTabData.map((deviceData) => ({
            ID: deviceData.id,
            InstallationDate: deviceData.installation_date,
            RegistrationDate: deviceData.registration_date,
            VehicleId: deviceData.vehicle_id,
            ClientId: deviceData.client_id,
            Serial: deviceData.serial,
            ProductId: deviceData.product_id,
            FirmwareId: deviceData.firmware_id,
            UpdatedAt: deviceData.updated_at,
          }))}
          columns={columns}
          noDataMessage={"No Devices to fetch "}
          rows={10}
          rowsoptions={[10, 25, 50, 100]}
        />
      )}
    </>
  );
}
