import { Auth } from "aws-amplify";
import { buildBaseUrl } from "../build-base-url";

// export const getAllDeviceConfiguration = async (serialnumber: string | undefined) => {
export const getAllDeviceConfiguration = async (deviceId: string | undefined) => {
    const url = buildBaseUrl()
    if (!deviceId) {
        return;
    }
    try {
        const authData = await Auth.currentAuthenticatedUser();
        const response = await fetch(`${url}device/${deviceId}/configuration`, {
            headers: {
                Authorization: `Bearer ${authData.signInUserSession.accessToken.jwtToken}`,
            },
        });

        const data = await response.json();
        return data;
       
    } catch (err) {
        console.log("ERROR from getDeviceConfiguration error", err);
    }
};