import Box from "@mui/material/Box";
import {
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Slide,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../utils/themes/theme-context";
import { parse } from "papaparse";
import {
  client_info,
  newuser,
} from "../../utils/interfaces/new-user/new-user-interface";
import {
  CreateUserInterface,
} from "../../utils/interfaces/new-user/create-user-interface";
import { ClientSelectionCreation } from "../new-user/forms/client-selection-creation";
import { EnterInformationErrors } from "../../utils/interfaces/new-user/enter-information-interfaces";
import {
  corp_perms_descriptions,
  corp_perms_name,
  iweigh_perms_name,
  iweigh_perms_descriptions,
} from "../../utils/interfaces/new-user/permissions";
import CloseIcon from "@mui/icons-material/Close";
import { SetUserPermsV2 } from "../new-user/forms/set-user-perms-v2";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import { checkAvailableUsers } from "../../utils/requests/new-user-registration/get-new-user-registration";
import { PostNewUserRegistrationViaCSV } from "../../utils/interfaces/new-user-registration/new-user-registration-post-interface";
import { postNewUserRegistrationViaCSV } from "../../utils/requests/new-user-registration-via-csv/post-new-user-via-csv";
import AccountCreationStatus from "./new-user-csv-elements/account-creation-status";
import DownloadCSVTemplate from "./new-user-csv-elements/csv-template-download";
import {
  handleScroll,
  scrollToBottom,
  scrollToTop,
} from "./new-user-csv-elements/scrollarrows";
import AllStepsDisplay from "./new-user-csv-elements/stepper";
import ReviewTable from "./new-user-csv-elements/review";

function NewUserCSV() {
  var { perms, theme } = useContext(AppContext);
  const [transitionout, setTransitionOut] = useState<boolean>(true);
  const [activeStep, setActiveStep] = useState(0); // State to manage the active step
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [fileRequiredError, setFileRequiredError] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState<string | null>(null);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [selectedDeleteIndex, setSelectedDeleteIndex] = useState<number | null>(
    null
  );
  let userNavigate = useNavigate();
  const [nextbutton, setNextButton] = useState<boolean>(true); //true means disabled, false means not disabled
  const [curclientname, setCurClientName] = useState<string>(""); //used to avoid a call to the backend in review and submit
  const newUser = useRef<newuser>(CreateUserInterface());
  const [permsData, setPermsData] = useState<newuser[]>([
    CreateUserInterface(),
  ]);
  const [open, setOpen] = useState(false);
  const isAlphanumeric = (value: string) => /^[a-zA-Z0-9]+$/.test(value);
  const isValidEmail = (value: string) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
  const [showScrollTopButton, setShowScrollTopButton] = useState(false);
  const [showScrollBottomButton, setShowScrollBottomButton] = useState(false);
  const [missingColumnsError, setMissingColumnsError] = useState(false);
  const [missingColumns, setMissingColumns] = useState<string[]>([]);
  const [missingDialogOpen, setMissingDialogOpen] = useState(false);
  // const [unexpectedColumnErrorMessage, setUnexpectedColumnErrorMessage] = useState<string | null>(null);
  const [unexpectedColumnsError, setUnexpectedColumnsError] = useState(false);
  const [unexpectedColumns, setUnexpectedColumns] = useState<string[]>([]);
  const [unexpectedDialogOpen, setUnexpectedDialogOpen] = useState(false);
  const [missingunexpectedDialogOpen, setMissingUnexpectedDialogOpen] =
    useState(false);
  const [isAccountCreated, setIsAccountCreated] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      handleScroll(setShowScrollTopButton, setShowScrollBottomButton);
    });

    return () => {
      window.removeEventListener("scroll", () => {
        handleScroll(setShowScrollTopButton, setShowScrollBottomButton);
      });
    };
  }, []);

  const handleEditClick = (index: number) => {
    setActiveStep(3);
  };

  const handleNext = () => {
    if (activeStep === 0) {
      setActiveStep(1);
      setFileRequiredError(false); // Clear the error when proceeding

      // Check if a user type is selected (Future Scope when AW users can be created using csv upload too)
      // if (newUser.current.user_type) {
      //     setActiveStep(1);
      //     setFileRequiredError(false); // Clear the error when proceeding
      // } else {
      //     // Show an error message because no user type is selected
      //     // You can set an error state here if needed
      //     console.log("Please select a user type before proceeding.");
      //     return;
      // }
    } else if (activeStep === 1) {
      // For all other steps (step 2, 3, etc.), allow the user to proceed
      setActiveStep(2);
    } else if (activeStep === 2) {
      if (isFileUploaded) {
        // Check if there are missing columns
        if (!missingColumnsError && !unexpectedColumnsError) {
          setActiveStep(3);
          setFileRequiredError(false); // Clear the error when proceeding
        } else {
          // Handle the case where either missing or unexpected columns are present
          if (missingColumnsError && unexpectedColumnsError) {
            setMissingUnexpectedDialogOpen(true);
          } else if (missingColumnsError) {
            // Display the missing columns dialog
            setMissingDialogOpen(true);
          } else if (unexpectedColumnsError) {
            // Display the unexpected columns dialog
            setUnexpectedDialogOpen(true);
          }
        }
      } else {
        setFileRequiredError(true); // Set the error when no file is uploaded
      }
    } else if (activeStep === 3) {
      // For all other steps (step 2, 3, etc.), allow the user to proceed
      // setActiveStep(4);
      // Check if there are any errors in the fieldErrors
      const hasErrors = fieldErrors.some((errors) => {
        return Object.values(errors).some((error) => error !== null);
      });

      if (!hasErrors) {
        setActiveStep(4);
      } else {
        // Display a confirmation dialog
        setOpen(true);
      }
    } else if (activeStep === 4) {
      setActiveStep(5);
    }
  };

  const handleCloseMissingUnexpectedDialog = () => {
    setMissingUnexpectedDialogOpen(false);
  };

  const handleCloseUnexpectedDialog = () => {
    setUnexpectedDialogOpen(false);
  };

  //Dialog close for Missing columns
  const handleCloseMissingDialog = () => {
    setMissingDialogOpen(false);
  };

  //alert dialog for errors
  const handleDialogClose = () => {
    setOpen(false);
  };

  //alert dialog for errors
  const handleConfirm = () => {
    // Handle the user's confirmation action
    setOpen(false);
  };

  // Function to handle row deletion
  const handleConfirmDelete = () => {
    if (selectedDeleteIndex !== null) {
      const newData = [...permsData];
      newData.splice(selectedDeleteIndex, 1);
      setPermsData(newData);
      closeDeleteConfirmationDialog();
    }
  };

  //  Function to open the delete confirmation dialog
  const openDeleteConfirmationDialog = (index: number) => {
    setSelectedDeleteIndex(index);
    setDeleteConfirmationOpen(true);
  };

  //  Function to close the delete confirmation dialog
  const closeDeleteConfirmationDialog = () => {
    setSelectedDeleteIndex(null);
    setDeleteConfirmationOpen(false);
  };

  const permsDataCallback = (
    awpermissions: {
      [key: string]: boolean;
    },
    corporatepermissions: {
      [key: string]: boolean;
    },
    iweighpermissions: {
      [key: string]: boolean;
    },
    index: number
  ) => {
    let tempNewUser = { ...permsData[index] };
    tempNewUser.aw_permissions = {
      ...tempNewUser.aw_permissions,
      ...awpermissions,
    };
    tempNewUser.corporate_permissions = {
      ...tempNewUser.corporate_permissions,
      ...corporatepermissions,
    };
    tempNewUser.iweigh_permissions = {
      ...tempNewUser.iweigh_permissions,
      ...iweighpermissions,
    };

    setPermsData((prevPermsData) => {
      const updatedPermsData = [...prevPermsData];
      updatedPermsData[index] = tempNewUser;
      return updatedPermsData;
    });
  };

  function setClient(newclient: client_info) {
    newUser.current.client_info.client = newclient.client;
    newUser.current.client_info.comments = newclient.comments;
    newUser.current.client_info.contact = newclient.contact;
    newUser.current.client_info.contact_email = newclient.contact_email;
    newUser.current.client_info.contact_phone = newclient.contact_phone;
  }

  function enterInformationDomain(string: string) {
    newUser.current.user_domain = string;
  }

  //called by textfield verification, regex verification
  function validateInput(input: string, regex: Function, errorMessage: string) {
    return regex(input, errorMessage);
  }

  //sets errors and calls validate input to process whenever a textfield is changed
  function validateAndSetError(
    input: string,
    regex: Function,
    errorMessage: string,
    key: string,
    setErrors: Function
  ) {
    const error = input ? validateInput(input, regex, errorMessage) : "";
    setErrors((prevState: EnterInformationErrors) => ({
      ...prevState,
      [key]: error,
    }));
    return error === "";
  }

  function setClientId(string: string) {
    newUser.current.client_id = string;
  }

  // Add this code within the component function
  useEffect(() => {
    // Check if the user type is "corp"
    if (perms.user_type !== "aw") {
      // Set activeStep to 2 to skip directly to Step 2 (CSV file upload)
      setActiveStep(1);
      setCurClientName(perms.client_name);
      setClientId(perms.client_id);
    }
  }, []); // This effect runs once when the component mounts

  //Use when we need to create AW users via CSV future scop
  //when usertype changes, permissions reset to avoid security issues
  // function resetPermissions() {
  //     const tempcorpperms = CreateCorpPerms();
  //     const tempawperms = CreateAwPerms();
  //     setCorpPerms(tempcorpperms);
  //     setAwPerms(tempawperms);
  //     newUser.current.corporate_permissions = corpperms;
  //     newUser.current.aw_permissions = awperms;
  // }

  //Use when we need to create AW users via CSV future scope
  // function selectUserTypeCallback(usertype: string) {
  //     newUser.current.user_type = usertype;
  //     resetPermissions();
  // }

  function updateNextButton(bool: boolean) {
    setNextButton(bool);
  }

  const getStepContent = (step: any) => {
    switch (step) {
      case 0:
        return (
          <>
            <ClientSelectionCreation
              nextButtonCallback={setNextButton}
              setClientCallback={setClient}
              enterInformationDomain={enterInformationDomain}
              setClientId={setClientId}
              validateAndSetError={validateAndSetError}
              newUser={newUser.current}
              setCurClientName={setCurClientName}
              handleNext={handleNext}
            />
          </>
        );

      //Use this code for future scope when AW users can use this section for selecting AW user_type for creating accounts with csv

      // return perms.user_type === "corp" ? (
      // 	<>
      // 		<ClientSelectionCreation
      // 			nextButtonCallback={setNextButton}
      // 			setClientCallback={setClient}
      // 			enterInformationDomain={enterInformationDomain}
      // 			setClientId={setClientId}
      // 			validateAndSetError={validateAndSetError}
      // 			newUser={newUser.current}
      // 			setCurClientName={setCurClientName}
      // 			handleNext={handleNext}
      // 		/>
      // 	</>
      // ) : (
      // 	<SelectUserType
      // 		SelectUserTypeCallback={selectUserTypeCallback}
      // 		newUser={{ ...newUser.current, user_type: "corp" }} // Set user_type to "corp" by default
      // 		nextButtonCallback={updateNextButton}
      // 		setClientCallback={setClient}
      // 		enterInformationDomain={enterInformationDomain}
      // 		setClientId={setClientId}
      // 		validateAndSetError={validateAndSetError}
      // 		setCurClientName={setCurClientName}
      // 		handleNext={handleNext}
      // 		isDisabled={true} // Set isDisabled to true to disable the dropdown
      // 	/>
      // );

      case 1:
        return <DownloadCSVTemplate theme={theme} />;
      case 2:
        return (
          <div>
            <Typography
              paddingTop={5}
              variant="h5"
              align="center"
              style={{
                marginBottom: "16px",
                borderBottom: "2px solid #304D6D",
                paddingBottom: "8px",
                color: theme.selectedbuttoncolor,
                fontWeight: "bold",
              }}
            >
              Upload CSV file
            </Typography>
            <Typography paddingBottom={5} variant="subtitle1" align="center">
              Instructions: Please include the username, name, email, create,
              edit and read permissions.
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h6"
                align="center"
                style={{ margin: "8px", fontWeight: "bold" }}
              >
                Upload File:{" "}
              </Typography>
              <label
                htmlFor="fileInput"
                style={{
                  backgroundColor: theme.selectedbuttoncolor,
                  color: "white",
                  padding: "10px 15px",
                  borderRadius: "5px",
                  border: "none",
                  cursor: "pointer",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  transition: "background-color 0.3s, transform 0.3s",
                  display: "inline-block",
                }}
              >
                Choose File
              </label>
              <input
                id="fileInput"
                type="file"
                accept=".csv"
                style={{
                  display: "none", // Hide the default input
                }}
                onChange={(e) => {
                  const selectedFile = e.target.files?.[0];
                  if (selectedFile) {
                    setSelectedFileName(selectedFile.name);
                    setIsFileUploaded(true);
                    handleCsvFileUpload(selectedFile);
                    setFileRequiredError(false); // Clear the error when a file is selected
                  } else {
                    setSelectedFileName(null);
                    setIsFileUploaded(false);
                    setFileRequiredError(true); // Set the error state when no file is selected
                  }
                }}
              />

              {/* Display selected file name */}
              {selectedFileName && (
                <Typography
                  variant="body2"
                  style={{
                    marginTop: "12px",
                    marginLeft: "8px",
                    display: "inline-block",
                    verticalAlign: "middle",
                  }}
                >
                  {selectedFileName}
                </Typography>
              )}

              {/* File error message */}
              {activeStep === 2 && fileRequiredError && (
                <Typography
                  variant="body2"
                  color="error"
                  style={{
                    marginTop: "12px",
                    marginLeft: "8px",
                    display: "inline-block",
                    verticalAlign: "middle",
                  }}
                >
                  File is required.
                </Typography>
              )}

              {activeStep === 0 && !newUser.current.user_type && (
                <Typography variant="body2" color="error">
                  Please select a user type before proceeding.
                </Typography>
              )}
            </div>
          </div>
        );
      case 3:
        return (
          <div>
            <Typography
              paddingTop={5}
              variant="h5"
              align="center"
              style={{
                borderBottom: "2px solid #304D6D",
                paddingBottom: "8px",
                color: theme.selectedbuttoncolor,
                fontWeight: "bold",
              }}
            >
              Check User Account Details
            </Typography>
            {permsData.map((rowData, index) => (
              <div key={index}>
                <Typography fontWeight="bold" padding={1} variant="h6">
                  User {index + 1} : {rowData.username}
                </Typography>
                {/* <Stack direction="row" spacing={2}> */}
                <Card
                  elevation={8}
                  sx={{ borderRadius: 3 }}
                  variant="elevation"
                  style={{ flex: "1", marginRight: "16px" }}
                >
                  <CardContent>
                    <Typography variant="h5">Check User Details</Typography>
                    <Stack paddingTop={5} direction="row" spacing={5}>
                      {/* Render user-entered data dynamically */} 
                      {Object.keys(rowData).map((columnHeader, columnIndex) => {
                        // Check if the column header contains any of the specified keywords
                        const skipKeywords = [
                          "read",
                          "write",
                          "device",
                          "firmware",
                          "create",
                          "edit",
                          "domain",
                          "type",
                          "client",
                          "aw",
                          "corporate",
                          "iweigh",
                        ];
                        if (
                          skipKeywords.some((keyword) =>
                            columnHeader.toLowerCase().includes(keyword)
                          )
                        ) {
                          // Don't create a TextField for columns with these keywords
                          return null;
                        }

                        const fieldError =
                          fieldErrors[index] &&
                          fieldErrors[index][columnHeader];

                        return (
                          <TextField
                            key={columnIndex}
                            label={columnHeader.toUpperCase()}
                            value={
                              rowData[columnHeader as keyof typeof rowData] ||
                              ""
                            }
                            onChange={(e) =>
                              handleCsvDataChange(
                                index,
                                columnHeader as keyof typeof rowData,
                                e.target.value
                              )
                            }
                            error={fieldError !== null}
                            helperText={fieldError ? fieldError : ""}
                            style={{
                              width: "100%",
                            }}
                            margin="dense"
                          />
                        );
                      })}
                    </Stack>
                  </CardContent>
                </Card>
                <Divider />
                {/* Display SetUserPerms component */}
                {/* <div style={{ flex: '1' }}> */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      marginRight: "16px",
                    }}
                  >
                    <Card
                      variant="elevation"
                      sx={{ borderRadius: 3 }}
                      elevation={8}
                    >
                      {/* <SetUserPermsV2 
									user={rowData} // Pass the user object
									usertype={"corp"} //check condition in future to see if thats a corp or aw user usertype={newUser.current.user_type}
									permissionscallback={(bool: boolean, label: string, permissiontype: string) => {
										permsDataCallback(bool, label, permissiontype, index);
										}}
									/> */}
                      <SetUserPermsV2
                        user={rowData} // Pass the user object
                        usertype={"corp"} //check condition in future to see if thats a corp or aw user usertype={newUser.current.user_type}
                        permissionscallback={(user: newuser) => {
                          permsDataCallback(
                            user.aw_permissions,
                            user.corporate_permissions,
                            user.iweigh_permissions,
                            index
                          );
                        }}
                      />
                    </Card>
                  </div>
                </div>
                {/* </Stack> */}
                <Divider
                  style={{
                    height: "1px",
                    paddingTop: "10px",
                    borderBottom: "2px solid #304D6D",
                    margin: "16px 0",
                  }}
                />

                {showScrollBottomButton && (
                  <Tooltip title="Scroll to Bottom" placement="left" arrow>
                    <ArrowCircleDownIcon
                      onClick={scrollToBottom}
                      color="primary"
                      style={{
                        position: "fixed",
                        bottom: "70px",
                        right: "20px",
                        zIndex: 1000,
                        cursor: "pointer",
                        fontSize: "4rem",
                      }}
                    />
                  </Tooltip>
                )}

                {showScrollTopButton && (
                  <Tooltip title="Scroll to Top" placement="left" arrow>
                    <ArrowCircleUpIcon
                      onClick={scrollToTop}
                      color="primary"
                      style={{
                        position: "fixed",
                        bottom: "150px",
                        right: "20px",
                        zIndex: 1000,
                        cursor: "pointer",
                        fontSize: "4rem",
                      }}
                    />
                  </Tooltip>
                )}
              </div>
            ))}
          </div>
        );

      case 4:
        return (
          <ReviewTable
            permsData={permsData}
            corp_perms_name={corp_perms_name}
            corp_perms_descriptions={corp_perms_descriptions}
            iweigh_perms_name={iweigh_perms_name}
            iweigh_perms_descriptions={iweigh_perms_descriptions}
            handleEditClick={handleEditClick}
            openDeleteConfirmationDialog={openDeleteConfirmationDialog}
          />
        );

      case 5:
        return <AccountCreationStatus isAccountCreated={isAccountCreated} />;

      default:
        return "Unknown step";
    }
  };

  const handleCsvFileUpload = (file: File) => {
    //FileReader to read the selected CSV file
    const reader = new FileReader();
    //FileReader() - a way to read the contents of a file asynchronously.
    // Set up an event handler for when the FileReader finishes reading the file
    // onload: Event that fires when reading is successful.
    // onerror: Event that fires when an error occurs during reading.

    reader.onload = (event) => {
      if (event.target) {
        // Extract the CSV text from the event result
        const csvText = event.target.result as string;

        //Note: FileReader uses event handlers to notify you when file reading is complete or if an error occurs during the process.
        // Then we define callback functions to respond to these events.

        // Parse the CSV data using Papaparse
        parse(csvText, {
          header: true, // Treat the first row as headers
          dynamicTyping: true, //Automatically converts recognized numeric values to numbers.
          skipEmptyLines: true, //Ignores empty lines in the CSV data.
          complete: (result) => {
            // Callback function called when parsing is complete
            //type assertion -  It tells the compiler that you are confident that result.data will conform to the specified type, and it prevents type-checking errors or warnings that might occur without the assertion.
            // const parsedData = result.data as Record<
            // 	string,
            // 	string
            // >[]; //an array of objects, where each object has string keys and string values. [] indicates that the result should be treated as an array.

            const parsedData = result.data as Record<string, any>[];

            if (parsedData.length > 0) {
              // Check if the CSV header contains the expected column names
              const expectedColumnNames = [
                "username",
                "name",
                "email",
                "users_create",
                "users_edit",
                "users_read",
                "calibration_read",
                "calibration_edit",
                "configuration_profiles_read",
                "configuration_profiles_edit",
                "device_registration",
                "device_unregister",
                "linked_assets_read",
                "linked_assets_edit",
                "preferences_read",
                "preferences_edit",
                "sensor_setup_read",
                "sensor_setup_edit",
                "update_firmware",
              ];

              const csvHeader = Object.keys(parsedData[0]);
              const missingColumns = expectedColumnNames.filter(
                (columnName) => !csvHeader.includes(columnName)
              );

              if (missingColumns.length === 0) {
                // All expected columns are present in the header
                // Continue processing the data
                // No missing columns, so set the error state to false
                setMissingColumnsError(false);
              } else {
                // Handle the case where some columns are missing
                setMissingColumns(missingColumns);
                setMissingColumnsError(true);
              }

              // Split the header row by commas
              const csvHeader2 = csvText.split("\n")[0].split(",");

              // Check for unexpected columns, including empty headers
              const unexpectedColumns = csvHeader2.filter(
                (columnName) =>
                  !expectedColumnNames.includes(columnName.trim()) && // Trim to handle any leading/trailing spaces
                  !missingColumns.includes(columnName.trim()) && // Exclude missing columns
                  columnName.trim().length > 0 // Exclude columns with blank headers
              );

              if (unexpectedColumns.length > 0) {
                // Show an error message for each unexpected non-blank column

                // setUnexpectedColumnErrorMessage(`Unsupported columns in your uploaded CSV file: ${unexpectedColumns.join(", ")}, please check the template for the valid column names and upload a corrected csv file`);
                setUnexpectedColumns(unexpectedColumns);
                setUnexpectedColumnsError(true);
              } else {
                setUnexpectedColumnsError(false);
              }

              // Update the 'csvData' state with the parsed CSV data
              // setCsvData(parsedData);
              // setActiveStep(2); // Reset the stepper to the first step

              // Replace "yes",  "YES" and "true" "TRUE" with true, and "no" "NO" and "false" "FALSE" with false
              for (const row of parsedData) {
                for (const key in row) {
                  if (typeof row[key] === "string") {
                    const lowerCaseValue = row[key].toLowerCase().trim();
                    if (lowerCaseValue === "yes" || lowerCaseValue === "true") {
                      row[key] = true;
                    } else if (
                      lowerCaseValue === "no" ||
                      lowerCaseValue === "false"
                    ) {
                      row[key] = false;
                    }
                  }
                }
              }

              const parsedpermissionsData = parsedData.map((row, index) => {
                let test = CreateUserInterface();
                test.username = row["username"];
                test.user_name = row["name"];
                test.user_email = row["email"];

                handleCsvDataChange(index, "username", test.username);
                handleCsvDataChange(index, "user_name", test.user_name);
                handleCsvDataChange(index, "user_email", test.user_email);

                // Corporate Permissions
                test.corporate_permissions.users_create = row["users_create"];
                test.corporate_permissions.users_edit = row["users_edit"];
                test.corporate_permissions.users_read = row["users_read"];

                // iWeigh Permissions
                test.iweigh_permissions.calibration_read =
                  row["calibration_read"];
                test.iweigh_permissions.calibration_edit =
                  row["calibration_edit"];
                test.iweigh_permissions.configuration_profiles_read =
                  row["configuration_profiles_read"];
                test.iweigh_permissions.configuration_profiles_edit =
                  row["configuration_profiles_edit"];
                test.iweigh_permissions.device_registration =
                  row["device_registration"];
                test.iweigh_permissions.device_unregister =
                  row["device_unregister"];
                test.iweigh_permissions.linked_assets_read =
                  row["linked_assets_read"];
                test.iweigh_permissions.linked_assets_edit =
                  row["linked_assets_edit"];
                test.iweigh_permissions.preferences_read =
                  row["preferences_read"];
                test.iweigh_permissions.preferences_edit =
                  row["preferences_edit"];
                test.iweigh_permissions.sensor_setup_read =
                  row["sensor_setup_read"];
                test.iweigh_permissions.sensor_setup_edit =
                  row["sensor_setup_edit"];
                test.iweigh_permissions.update_firmware =
                  row["update_firmware"];

                return test;
              });

              setPermsData(parsedpermissionsData);
              setActiveStep(2);
              // setPermissionsData(parsedpermissionsData);
            } else {
              // Handle the case where the CSV file is empty
              console.error("CSV file is empty");
            }
          },
          error: (error: { message: any }) => {
            // Handle any errors that occur during CSV parsing
            console.error("CSV parsing error:", error.message);
          },
        });
        // Set the selected file name
        setSelectedFileName(file.name);
        setIsFileUploaded(true);
        setFileRequiredError(false);
      }
    };
    // Read the selected file as text
    reader.readAsText(file);
  };

  const [fieldErrors, setFieldErrors] = useState<
    Array<Record<string, string | null>>
  >([]);

  const handleCsvDataChange = async (
    index: number,
    field: keyof newuser,
    value: string
  ) => {
    // Update the field value
    setPermsData((prevData) => {
      const newData = [...prevData];
      newData[index] = { ...newData[index], [field]: value };
      return newData;
    });

    // Validate the field and update the error state
    const updatedErrors = { ...fieldErrors };
    let fieldError: string | null = null;

    if (value === "" || value === null) {
      fieldError = "Field cannot be empty";
    } else {
      switch (field) {
        case "username":
          //check username availability
          const usernameAvailability = await checkAvailableUsers("", value);
          if (!usernameAvailability.username_available) {
            fieldError = "Username is already in use";
          }
          //validate username textfield
          else if (!isAlphanumeric(value) || value.length < 3) {
            fieldError = "Username must be at least 3 alphanumeric characters";
          }
          break;
        case "user_email":
          if (!isValidEmail(value)) {
            fieldError = "Invalid email";
          } else {
            const emailAvailability = await checkAvailableUsers(value, "");
            if (!emailAvailability.email_available) {
              fieldError = "Email is already in use";
            }
          }
          break;
        case "user_name":
          //validate user_name text field

          if (!isAlphanumeric(value) || value.length < 3) {
            fieldError =
              "User's Name must be at least 3 alphanumeric characters";
          }
          break;
        default:
          break;
      }
    }

    // Update the errors for the specified field
    updatedErrors[index] = { ...updatedErrors[index], [field]: fieldError };

    // Update the error state
    setFieldErrors((prevErrors) => {
      const mergedErrors = [...prevErrors];
      mergedErrors[index] = { ...mergedErrors[index], [field]: fieldError };
      return mergedErrors;
    });
  };

  const handleSubmit = async () => {
    //  permsData is  array of user data objects
    const bulkUserData: PostNewUserRegistrationViaCSV = {
      client_id: newUser.current.client_id, // Provide the client ID
      users: permsData.map((user) => ({
        username: user.username,
        user_email: user.user_email,
        user_name: user.user_name,
        user_domain: user.user_domain || "",
        user_type: "corp",
        user_id: "",
        root_user: false, // Set as needed
        client_info: {
          client: user.client_info.client || "",
          contact: user.client_info.contact || "",
          contact_email: user.client_info.contact_email || "",
          contact_phone: user.client_info.contact_phone || "",
          comments: user.client_info.comments || "",
        },
        aw_permissions: { ...user.aw_permissions }, // Copy permissions
        corporate_permissions: { ...user.corporate_permissions }, // Copy permissions
        iweigh_permissions: { ...user.iweigh_permissions }, // Copy permissions
      })),
    };

    try {
      // Send the bulk user data to the backend
      const response = await postNewUserRegistrationViaCSV(bulkUserData);
      // Handle success for bulk user creation here
      setIsAccountCreated(true);
    } catch (error) {
      console.error("Error creating bulk users:", error);
      setIsAccountCreated(false);
      // Handle errors for bulk user creation here
    }

    setActiveStep(5);
  };

  const calcActiveStep = (): number => {
    if (perms.user_type !== "aw") {
      return activeStep - 1;
    }
    return activeStep;
  };

  return (
    <>
      <Slide direction="right" in={transitionout} timeout={400}>
        <Box
          flexGrow={1}
          marginLeft={"5%"}
          marginRight={"5%"}
          marginTop={10}
          marginBottom={10}
        >
          <h2>Create New User via CSV file upload</h2>
          <Divider />
          <Grid container spacing={3}>
            <Grid item xs={12} marginTop={3}>
              <AllStepsDisplay perms={perms} calcActiveStep={calcActiveStep} />
              {getStepContent(activeStep)}
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction="row"
                spacing={2}
                display={"flex"}
                justifyContent={"center"}
                alignContent={"center"}
              >
                {activeStep === 0 && (
                  <>
                    <Button
                      color={theme.backbuttoncolor}
                      variant="contained"
                      onClick={() => userNavigate(`/users`)}
                    >
                      Back
                    </Button>
                    <Button
                      color={theme.nextbuttoncolor}
                      variant="contained"
                      disabled={nextbutton}
                      onClick={handleNext}
                    >
                      Next
                    </Button>
                  </>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction="row"
                spacing={2}
                display={"flex"}
                justifyContent={"center"}
                alignContent={"center"}
              >
                {activeStep === 1 && (
                  <>
                    <Button
                      color={theme.backbuttoncolor}
                      variant="contained"
                      onClick={() => setActiveStep(0)}
                      disabled={perms.user_type === "corp"}
                    >
                      Back
                    </Button>
                    <Button
                      color={theme.nextbuttoncolor}
                      variant="contained"
                      onClick={handleNext}
                    >
                      Next
                    </Button>
                  </>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction="row"
                spacing={2}
                display={"flex"}
                justifyContent={"center"}
                alignContent={"center"}
              >
                {activeStep === 2 && (
                  <>
                    <Button
                      color={theme.backbuttoncolor}
                      variant="contained"
                      onClick={() => setActiveStep(1)}
                    >
                      Back
                    </Button>
                    <Button
                      color={theme.nextbuttoncolor}
                      variant="contained"
                      onClick={handleNext}
                    >
                      Next
                    </Button>
                  </>
                )}
              </Stack>
            </Grid>
            {/* Dialog for unexpedted column error message */}

            <Dialog
              open={unexpectedDialogOpen}
              onClose={handleCloseUnexpectedDialog}
            >
              <DialogTitle
                style={{ color: "red" }}
                variant="h5"
                id="missing-columns-dialog-title"
              >
                Error
              </DialogTitle>
              <DialogContent>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={handleCloseUnexpectedDialog}
                  style={{
                    position: "absolute",
                    top: "5px",
                    right: "15px",
                    color: "black",
                  }}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>

                <DialogContentText variant="h6" style={{ color: "black" }}>
                  Unexpected Columns
                </DialogContentText>
                <DialogContentText>
                  The following columns are unexpected in your uploaded csv
                  file:
                </DialogContentText>
                <DialogContentText style={{ color: "red" }}>
                  {unexpectedColumns.join(", ")}
                </DialogContentText>
                <DialogContentText>
                  Please correct these columns and upload a corrected csv file
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseUnexpectedDialog} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={missingDialogOpen}
              onClose={handleCloseMissingDialog}
              aria-labelledby="missing-columns-dialog-title"
            >
              <DialogTitle
                style={{ color: "red" }}
                variant="h5"
                id="missing-columns-dialog-title"
              >
                Error
              </DialogTitle>
              <DialogContent>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={handleCloseMissingDialog}
                  aria-label="close"
                  style={{ position: "absolute", top: 5, right: 15 }}
                >
                  <CloseIcon />
                </IconButton>
                <DialogContentText variant="h6" style={{ color: "black" }}>
                  Missing Columns
                </DialogContentText>
                <DialogContentText>
                  The following columns are missing in your uploaded csv file:
                </DialogContentText>
                <DialogContentText style={{ color: "red" }}>
                  {missingColumns.join(", ")}
                </DialogContentText>
                <DialogContentText>
                  Please fill these columns and upload a corrected csv file
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseMissingDialog} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>

            {/* Dialog for missing and unexpected */}

            <Dialog
              open={missingunexpectedDialogOpen}
              onClose={handleCloseMissingUnexpectedDialog}
            >
              <DialogTitle
                style={{ color: "red" }}
                variant="h5"
                id="missing-columns-dialog-title"
              >
                Error
              </DialogTitle>
              <DialogContent>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={handleCloseMissingUnexpectedDialog}
                  style={{
                    position: "absolute",
                    top: "5px",
                    right: "15px",
                    color: "black",
                  }}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>

                <DialogContentText variant="h6" style={{ color: "black" }}>
                  Missing and Unexpected Columns
                </DialogContentText>
                <DialogContentText>
                  The following columns are missing in your uploaded csv file:
                </DialogContentText>
                <DialogContentText style={{ color: "red" }}>
                  {missingColumns.join(", ")}
                </DialogContentText>
                <DialogContentText>
                  The following columns are unexpected in your uploaded csv
                  file:
                </DialogContentText>
                <DialogContentText style={{ color: "red" }}>
                  {unexpectedColumns.join(", ")}
                </DialogContentText>
                <DialogContentText>
                  Please correct these columns and upload a corrected csv file
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleCloseMissingUnexpectedDialog}
                  color="primary"
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>

            <Grid item xs={12}>
              <Stack
                direction="row"
                spacing={2}
                display={"flex"}
                justifyContent={"center"}
                alignContent={"center"}
              >
                {activeStep === 3 && (
                  <>
                    <Button
                      color={theme.backbuttoncolor}
                      variant="contained"
                      onClick={() => setActiveStep(2)}
                    >
                      Back
                    </Button>
                    <Button
                      color={theme.nextbuttoncolor}
                      variant="contained"
                      onClick={handleNext}
                    >
                      Next
                    </Button>
                  </>
                )}
              </Stack>
            </Grid>

            <div>
              {/* Dialog component */}
              <Dialog
                open={open}
                onClose={handleDialogClose}
                fullWidth
                maxWidth="xs"
              >
                <DialogContent
                  style={{ overflow: "hidden", position: "relative" }}
                >
                  <IconButton
                    edge="end"
                    color="inherit"
                    onClick={handleDialogClose}
                    aria-label="close"
                    style={{ position: "absolute", top: 5, right: 15 }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <Box p={2}>
                    <Typography variant="h5" style={{ color: "red" }}>
                      Error
                    </Typography>
                    <Typography variant="body1" style={{ marginTop: "10px" }}>
                      Please correct the form field with errors.
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="flex-end" p={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleConfirm}
                    >
                      OK
                    </Button>
                  </Box>
                </DialogContent>
              </Dialog>
            </div>

            <Grid item xs={12}>
              <Stack
                direction="row"
                spacing={2}
                display={"flex"}
                justifyContent={"center"}
                alignContent={"center"}
              >
                {activeStep === 4 && (
                  <>
                    <Button
                      color={theme.backbuttoncolor}
                      variant="contained"
                      onClick={() => setActiveStep(3)}
                    >
                      Back
                    </Button>
                    <Button
                      color={theme.nextbuttoncolor}
                      variant="contained"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  </>
                )}
              </Stack>
            </Grid>
          </Grid>
          {/* // Render the delete confirmation dialog */}
          <Dialog
            open={deleteConfirmationOpen}
            onClose={closeDeleteConfirmationDialog}
          >
            <DialogTitle>Delete Entry</DialogTitle>
            <DialogContent>
              <Typography>
                Are you sure you want to delete this user entry?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDeleteConfirmationDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={handleConfirmDelete} color="primary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>

          <Grid item xs={12}>
            <Stack
              direction="row"
              spacing={2}
              display={"flex"}
              justifyContent={"center"}
              alignContent={"center"}
            >
              {activeStep === 5 && (
                <>
                  <Button
                    color={theme.backbuttoncolor}
                    variant="contained"
                    onClick={() => setActiveStep(4)}
                  >
                    Back
                  </Button>
                  <Button
                    color={theme.nextbuttoncolor}
                    variant="contained"
                    onClick={() => userNavigate(`/users`)}
                  >
                    Done
                  </Button>
                </>
              )}
            </Stack>
          </Grid>
        </Box>
      </Slide>
    </>
  );
}

export default NewUserCSV;
