import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  LinearProgress,
  Skeleton,
  Stack,
} from "@mui/material";
import { AppContext } from "../../../utils/themes/theme-context";
import { useContext, useEffect, useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Columns } from "../../../utils/interfaces/table/table-interfaces";
import GeneralTable from "../../../utils/generic-components/table/table";
import { Client } from "../../../utils/interfaces/clients/clients-list-interface";
import { fetchClientsServicePlan } from "../../../utils/requests/serviceplans/get-clients-serviceplan";
import { useNavigate, useParams } from "react-router-dom";

export default function ClientsCard() {
  var { theme } = useContext(AppContext);
  const { id } = useParams<{ id?: string }>();
  const [clients, setClients] = useState<Client[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [transitionout, setTransitionOut] = useState<boolean>(true);
  let userNavigate = useNavigate();

  let columns: Columns = {
    Client: {
      type: "link",
      display: "Client",
      data: "client",
      sort: "Client",
    },
  };

  useEffect(() => {
    if (id) {
      setLoading(true);
      //Fetch Client list data and update the state
      fetchClientsServicePlan(id)
        .then((response) => {
          setLoading(false);
          setClients(response.clients);
          setLoading(false);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              setErrorMessage("Parameter mismatch");
            } else if (error.response.status === 500) {
              setErrorMessage("Internal Server Error");
            } else {
              // other errors
              setErrorMessage(`Error: ${error.message}`);
            }
          } else if (error.request) {
            // The request was made but no response was received
            setErrorMessage("No response received");
          } else {
            // Something happened in setting up the request that triggered an error
            setErrorMessage(`Error: ${error.message}`);
          }
          setLoading(false);
        });
    }
  }, [id]);

  function handleRowClick(selectedClient: Client) {
    setTransitionOut(false);
    setTimeout(() => userNavigate(`/clients/${selectedClient.id}`), 400);
  }

  return (
    <>
      <Card elevation={8} sx={{ borderRadius: 3 }}>
        <CardHeader
          title="Clients"
          action={
            <IconButton aria-label="info">
              <InfoOutlinedIcon />
            </IconButton>
          }
        />
        <CardContent>
          <Divider />
          <Box paddingTop={2}>
            <GeneralTable
              data={clients.map((item, index) => ({
                id: item.id,
                Client: item.client,
              }))}
              columns={columns}
              loading={loading} //passing the loading state
              noDataMessage={"No Clients found for this Service Plan"}
              rowClickCallback={handleRowClick}
              rows={10}
              rowsoptions={[10, 25, 50, 100]}
            />
          </Box>
        </CardContent>
      </Card>
    </>
  );
}
