import { Auth } from "aws-amplify";
import { buildBaseUrl } from "../build-base-url";

export const getDeviceFirmware = async (serialnumber: string | undefined, firmwareVersion: string | undefined) => {
  const url = buildBaseUrl();
  if (!serialnumber) {
    return;
  }
  try {
    const authData = await Auth.currentAuthenticatedUser();
    const response = await fetch(`${url}device/${serialnumber}/firmware?current=${firmwareVersion}`, {
      headers: {
        Authorization: `Bearer ${authData.signInUserSession.accessToken.jwtToken}`,
      },
    });

    // Check the response status code
    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else if (response.status === 401) {
      throw new Error("Unauthorized access to device firmware.");
    } else if (response.status === 500) {
      throw new Error("Server error while fetching device firmware.");
    } else {
      throw new Error(`Unexpected response status code: ${response.status}`);
    }
  } catch (err) {
    console.error("Error from getDeviceFirmware:", err);
    throw err; // Rethrow the error to handle it in the caller function
  }
};
