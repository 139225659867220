import { Auth } from "aws-amplify";
import { buildBaseUrl } from "../build-base-url";
import { ServicePlansInterface } from "../../interfaces/service/service-list-interface";

export async function fetchSingleServicePlan(id: string | undefined): Promise<ServicePlansInterface> {
  const url = buildBaseUrl()
  try {
    const authData = await Auth.currentAuthenticatedUser();
    const response = await fetch(`${url}service_plans/${id}`, {
      headers: {
        Authorization: `Bearer ${authData.signInUserSession.accessToken.jwtToken}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
