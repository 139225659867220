import {
	aw_permissions,
	client_info,
	corporate_permissions,
	iweigh_permissions,
	newuser,
} from "./new-user-interface";

export function CreateUserInterface() {
	const newUser: newuser = {
		username: "",
		user_email: "",
		user_domain: "",
		user_type: "", //setting default to corp so corp users, when skipping the first step, will have this value filled
		user_name: "",
		client_id: "",
		client_info: {
			client: "",
			contact: "",
			contact_email: "",
			contact_phone: "",
			comments: "",
		},
		aw_permissions: {
			clients_read: false,
			clients_create: false,
			clients_edit: false,
			clients_delete: false,
			dashboard_read: false,
			device_create: false,
			device_edit: false,
			device_delete: false,
			firmware_create: false,
			firmware_edit: false,
			firmware_read: false,
			integrations_read: false,
			integrations_edit: false,
			integrations_trigger_update: false,
			report_read: false,
			service_plan_create: false,
			service_plan_edit: false,
			service_plan_delete: false,
			users_read: false,
			user_create: false,
			user_edit: false,
			vehicle_create: false,
			vehicle_edit: false,
			vehicle_delete: false,
			vehicle_tickets_create: false,
			vehicle_tickets_edit: false,
			vehicle_tickets_delete: false,
			weight_record_edit: false,
			weight_record_delete: false,
			weight_record_create: false,
		},
		corporate_permissions: {
			users_create: false,
			users_edit: false,
			users_read: false,
		},
		iweigh_permissions: {
			calibration_read: false,
			calibration_edit: false,
			configuration_profiles_read: false,
			configuration_profiles_edit: false,
			device_registration: false,
			device_unregister: false,
			linked_assets_read: false,
			linked_assets_edit: false,
			preferences_read: false,
			preferences_edit: false,
			sensor_setup_read: false,
			sensor_setup_edit: false,
			update_firmware: false,
		},
	};

	return {
		...newUser,
	};
}

export function CreateAwPerms() {
	const newAwPerms: aw_permissions = {
		clients_read: false,
		clients_create: false,
		clients_edit: false,
		clients_delete: false,
		dashboard_read: false,
		device_create: false,
		device_edit: false,
		device_delete: false,
		firmware_create: false,
		firmware_edit: false,
		firmware_read: false,
		integrations_read: false,
		integrations_edit: false,
		integrations_trigger_update: false,
		report_read: false,
		service_plan_create: false,
		service_plan_edit: false,
		service_plan_delete: false,
		users_read: false,
		user_create: false,
		user_edit: false,
		vehicle_create: false,
		vehicle_edit: false,
		vehicle_delete: false,
		vehicle_tickets_create: false,
		vehicle_tickets_edit: false,
		vehicle_tickets_delete: false,
		weight_record_edit: false,
		weight_record_delete: false,
		weight_record_create: false,
	};

	return {
		...newAwPerms,
	};
}

export function CreateCorpPerms() {
	const newCorpPerms: corporate_permissions = {
		users_create: false,
		users_edit: false,
		users_read: false,
	};

	return {
		...newCorpPerms,
	};
}

export function CreateClientInfo() {
	const newClientInfo: client_info = {
		client: "",
		contact: "",
		contact_email: "",
		contact_phone: "",
		comments: "",
	};

	return {
		...newClientInfo,
	};
}


export function CreateiWeighPerms() {
	const newiWeighPerms: iweigh_permissions = {
		calibration_read: false,
		calibration_edit: false,
		configuration_profiles_read: false,
		configuration_profiles_edit: false,
		device_registration: false,
		device_unregister: false,
		linked_assets_read: false,
		linked_assets_edit: false,
		preferences_read: false,
		preferences_edit: false,
		sensor_setup_read: false,
		sensor_setup_edit: false,
		update_firmware: false,
	};

	return {
		...newiWeighPerms,
	};
}
