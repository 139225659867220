import DetailsTable from "../../../utils/generic-components/Details Table/details-table";
import { DetailTableData } from "../../../utils/interfaces/details-table/details-table-interfaces";
import {
  rfcToDateAndTime,
} from "../../../utils/custom-functions/rfc3339-conversions";
import { ServicePlansInterface } from "../../../utils/interfaces/service/service-list-interface";
import { fetchSingleServicePlan } from "../../../utils/requests/serviceplans/get-single-serviceplan";
import { putUpdatedServicePlans } from "../../../utils/requests/serviceplans/put-service-plan";

export default function Details(
  props: { servicePlan: ServicePlansInterface | null }
) {
  let DetailTableData: DetailTableData = {
    data: [
      {
        label: "Service Name",
        content: props.servicePlan!.name,
        editable: true,
        error: "",
      },
      {
        label: "Creation Date",
        content: rfcToDateAndTime(props.servicePlan!.creation_date!),
        editable: false,
        error: "",
      },
      {
        label: "Updated At",
        content: rfcToDateAndTime(props.servicePlan!.updated_at!),
        editable: false,
        error: "",
      },
      {
        label: "Comments",
        content: props.servicePlan!.comments,
        editable: true,
        error: "",
      },
    ],
  };

  const putCallback = async (
    data: DetailTableData,
    setAlert: Function,
    setEditingMode: Function,
    setLoadingButton: Function
  ) => {
    try {
      const latestServicePlanData = await fetchSingleServicePlan(props.servicePlan?.id);

      const editedServicePlan: ServicePlansInterface = {
        ...latestServicePlanData,
        // updating all the data
        name: data.data[0].content !== null ? data.data[0].content : "",
        comments: data.data[3].content !== null ? data.data[3].content : "",
        service_plan_features: props.servicePlan?.service_plan_features || {
          fault_alerts: false,
          firmware_updates: false,
          heartbeat_alerts: false,
        },
        updated_at: props.servicePlan?.updated_at || "", // Provide a default value for updated_at
      };
      

      try {
        const response = await putUpdatedServicePlans(editedServicePlan);

        if (response && response.data) {
          const fetchedUpdatedServicePlan = response.data;

          const isUpdated =
          fetchedUpdatedServicePlan.name === editedServicePlan.name &&
              fetchedUpdatedServicePlan.comments === editedServicePlan.comments;

          if (isUpdated) {
            console.log("Data is updated");
          } else {
            console.log("Data is not updated");
          }
        } else {
          console.log("Error updating product data");
        }
      } catch (error) {
        console.log("Error updating product data:", error);
        setAlert("Unable to save, Try Again");
      }
    } catch (error) {
      console.log("Error fetching latest product data:", error);
      setAlert("Unable to save, Try Again");
    }

    setEditingMode(false);
    setLoadingButton(false);
  };

  return (
    <>
      <DetailsTable
        data={DetailTableData}
        putCallback={putCallback}
        editing={true}
        close={false}
      />
    </>
  );
}
