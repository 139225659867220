//DOCUMENTATION: https://airweigh.atlassian.net/l/cp/q1RVuwmF

import { FormattedWeight } from "../vehicles/vehicles-interfaces";
import { MapPoint } from "./generic-interface";

export const VEHICLE_WEIGHT_POINT = "VehicleWeightPoint";

/**
 * isVehicleWeightPoint is a type guard for VehicleWeightPoint
 * @param {MapPoint} point - The point to check
 * @returns {point is FormattedWeight} - Whether the point is a VehicleWeightPoint
 */
export function isVehicleWeightPoint(point: MapPoint): point is FormattedWeight {
	return point.objectType === VEHICLE_WEIGHT_POINT;
}
