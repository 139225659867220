import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Typography,
  } from "@mui/material";
import { AppContext } from "../../../utils/themes/theme-context";
import { useContext, useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useParams } from "react-router-dom";
import {
  Columns,
  Data,
} from "../../../utils/interfaces/table/table-interfaces";
import GeneralTable from "../../../utils/generic-components/table/table";
import {
  DynamicIntervals,
} from "../../../utils/interfaces/integration/integration-interface";
import NestedTable from "../../../utils/generic-components/table/nested-table";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

const nestedcustomtablestyle = {
  width: "auto", // Set your desired width here
};

interface DynamicColumns {
  [key: string]: {
    type: string;
    display: string;
    data: string;
    sort: string;
    subColumns?: DynamicColumns; // Allow subColumns to be recursively defined
  };
}

let columns: Columns = {
  UpdatedAt: {
    type: "time",
    display: "Updated At",
    data: "updated_at", // Access nested data
    sort: "UpdatedAt",
  },
  DateTime: {
    type: "time",
    display: "Date Time",
    data: "date_time",
    sort: "DateTime",
  },
  IntegratorId: {
    type: "text",
    display: "Integrator Id",
    data: "integrator_id",
    sort: "IntegratorId",
  },
  ClientId: {
    type: "text",
    display: "Client Id",
    data: "client_id",
    sort: "ClientId",
  },
  Success: {
    type: "text",
    display: "Success",
    data: "success",
    sort: "Success",
  },
  ErrorMessage: {
    type: "text",
    display: "Error Message",
    data: "error_message",
    sort: "ErrorMessage",
  },
  TotalUnits: {
    type: "number",
    display: "Total Units",
    data: "total_units",
    sort: "TotalUnits",
  },
  UpdatedUnits: {
    type: "number",
    display: "Updated Units",
    data: "updated_units",
    sort: "UpdatedUnits",
  },
  NewUnits: {
    type: "number",
    display: "New Units",
    data: "new_units",
    sort: "NewUnits",
  },
  StaleUnits: {
    type: "number",
    display: "Stale Units",
    data: "stale_units",
    sort: "StaleUnits",
  },
  //   geotab_interval: {
  //     type: "subhead",
  //     display: "GeoTab Intervals",
  //     data: "geotab_interval",
  //     subColumns: {
  //       GrossFeed: {
  //         type: "text",
  //         display: "Gross Feed",
  //         data: "gross_feed",
  //         sort: "GrossFeed",
  //       },
  //       GrossQualityFeed: {
  //         type: "text",
  //         display: "Gross Quality Feed",
  //         data: "gross_quality_feed",
  //         sort: "GrossQualityFeed",
  //       },
  //       NetFeed: {
  //         type: "text",
  //         display: "Net Feed",
  //         data: "net_feed",
  //         sort: "NetFeed",
  //       },
  //       SteerFeed: {
  //         type: "text",
  //         display: "Steer Feed",
  //         data: "steer_feed",
  //         sort: "SteerFeed",
  //       },
  //       DriveFeed: {
  //         type: "text",
  //         display: "Drive Feed",
  //         data: "drive_feed",
  //         sort: "DriveFeed",
  //       },
  //       TrailerFeed: {
  //         type: "text",
  //         display: "Trailer Feed",
  //         data: "trailer_feed",
  //         sort: "TrailerFeed",
  //       },
  //       DriveSteerQualityFeed: {
  //         type: "text",
  //         display: "Drive Steer Quality Feed",
  //         data: "drive_steer_quality_feed",
  //         sort: "DriveSteerQualityFeed",
  //       },
  //       TrailerQualityFeed: {
  //         type: "text",
  //         display: "Trailer Quality Feed",
  //         data: "trailer_quality_feed",
  //         sort: "TrailerQualityFeed",
  //       },
  //     },
  //   },
  //   DynamicSubcolumn: {
  //     type: "subhead",
  //     display: "Dynamic Subcolumn",
  //     data: "dynamic_subcolumn", // This can be any name since it's dynamically detected
  //   },

  // Actions: {
  //   type: "actions",
  //   display: "Actions",
  //   render: renderActionsCell,
  // },
};

// export default function Status(props: { productstatus: ProductData }) {
export default function IntegrationIntervals(props: {
  intervals: DynamicIntervals[] | null;
}) {

  const renderSuccessColumn = (success: boolean | undefined) => {
    if (success === undefined) {
      return <Typography>No data</Typography>;
    }
  
    return success ? (
      <CheckCircleIcon fontSize="large" style={{ color: "green" }} />
    ) : (
      <CancelIcon fontSize="large" style={{ color: "red" }} />
    );
  };
  

  const getNestedTableColumns = (data: Record<string, any>) => {
    return Object.keys(data).map((key) => ({
      type: "text",
      display: key,
      data: key,
      sort: key,
    }));
  };

  // Utility function to transform column names
  const transformColumnName = (name: string): string => {
    return name
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  

  return (
    <>
      <Card elevation={8} sx={{ borderRadius: 3 }}>
        <CardHeader
          title="Integrator Intervals"
          action={
            <IconButton aria-label="info">
              <InfoOutlinedIcon />
            </IconButton>
          }
        />
        <CardContent>
          <Divider />
          <>
            {/* <GeneralTable
                data={props.intervals!.map((interval) => ({
                    UpdatedAt: interval.updated_at,
                    DateTime: interval.date_time,
                    ClientId:interval.client_id,
                    Success: renderSuccessColumn(interval.success),
                    ErrorMessage: interval.error_message,
                    TotalUnits: interval.total_units,
                    UpdatedUnits: interval.updated_units,
                    NewUnits: interval.new_units,
                    StaleUnits: interval.stale_units,
                    geotab_interval: (
                    <NestedTable
                      data={props.intervals![0].geotab_interval}
                      columns={columns.geotab_interval.subColumns}
                    />
                  ),
                }))}
                columns={columns}
                noDataMessage={"No Integrator Intervals to fetch"}
                rows={10}
                rowsoptions={[10, 25, 50, 100]}
                customTableStyle={nestedcustomtablestyle}
              /> */}

            <GeneralTable
              data={props.intervals!.map((interval) => {
                const rowData: Data = {
                  UpdatedAt: interval.updated_at,
                  DateTime: interval.date_time,
                  ClientId: interval.client_id,
                  Success: renderSuccessColumn(interval.success),
                  ErrorMessage: interval.error_message,
                  TotalUnits: interval.total_units,
                  UpdatedUnits: interval.updated_units,
                  NewUnits: interval.new_units,
                  StaleUnits: interval.stale_units,
                };

                // Dynamically detect and add subcolumns
                Object.keys(interval).forEach((key) => {
                  if (
                    typeof interval[key] === "object" &&
                    interval[key] !== null &&
                    !Array.isArray(interval[key])
                  ) {
                    const subcolumnData = interval[key];
                    const subcolumnColumns =
                      getNestedTableColumns(subcolumnData);

                    // Update the columns with dynamic subcolumns
                    const transformedKey = transformColumnName(key);
                    columns[transformedKey] = {
                      type: "subhead",
                      display: transformedKey,
                      data: key,
                      sort: key, // Assuming the sort is the key itself for subcolumns
                      subColumns: subcolumnColumns,
                    };

                    // Update the rowData with dynamic subcolumns
                    rowData[transformedKey] = (
                      <NestedTable
                        data={subcolumnData}
                        columns={subcolumnColumns}
                      />
                    );
                  }
                });

                return rowData;
              })}
              columns={columns}
              noDataMessage={"No Integrator Intervals to fetch"}
              rows={10}
              rowsoptions={[10, 25, 50, 100]}
              customTableStyle={nestedcustomtablestyle}
            />
          </>
        </CardContent>
      </Card>
    </>
  );
}
