import { Auth } from "aws-amplify";
import { buildBaseUrl } from '../build-base-url';
import { RegisterClient } from "../../interfaces/vehicles/devices-details-interface";

export const RegisterClienttoDevice = async (id: string, data: RegisterClient): Promise<Response> => {
    const url = buildBaseUrl()
    const authData = await Auth.currentAuthenticatedUser();
    const response = await fetch(`${url}device/${id}/register`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authData.signInUserSession.accessToken.jwtToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return response;
  };