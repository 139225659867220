export interface Client {
    id: string;
    client: string;
    service_plan_id: string;
    registration_date: string;
    contact: string;
    contact_email: string;
    contact_phone: string;
    comments: string;
    updated_at: string;
    status: string;
	enabled: true;
}

//get single client details
export interface getClient {
    data: Client;
}

export interface UpdateClientData {
    contact: string;
    contact_email: string;
    contact_phone: string;
    comments: string;
    updated_at: string;
  }
  
  export interface clientResponse {
    data: Client[];
    next_page: string;
}

export interface clientAvailablility {
    client_name_available: boolean;
}

export function getEmptyClient (): Client {
	return {
        id: "",
        client: "",
        service_plan_id: "",
        registration_date: "",
        contact: "",
        contact_email: "",
        contact_phone: "",
        comments: "",
        updated_at: "",
        status: "",
        enabled: true,
	};
}