import React from 'react';
import ReactDOM from 'react-dom/client'; // Import createRoot from React 18

import GeoSearchBox, { Address } from "./search-box";

class GeoSearchControl implements maplibregl.IControl {
    private container: HTMLElement;
    private onResultClickCallback: (result: Address) => void;
    private root: ReactDOM.Root | null = null; // Store a reference to the React root

    constructor(onResultClickCallback: (result: Address) => void) {
        this.onResultClickCallback = onResultClickCallback;
        this.container = document.createElement('div');
        this.container.className = 'maplibregl-ctrl';
    }

    onAdd(map: maplibregl.Map): HTMLElement {
        // Create a React root and render the GeoSearchBox component
        this.root = ReactDOM.createRoot(this.container); // Initialize the root
        this.root.render(
            <GeoSearchBox onResultClickCallback={this.onResultClickCallback} />
        );

        return this.container;
    }

    onRemove() {
        if (this.root) {
            // Use a setTimeout to delay unmounting until after the current render cycle
            setTimeout(() => {
                this.root!.unmount();
                if (this.container.parentNode) {
                    this.container.parentNode.removeChild(this.container);
                }
            }, 0);
        } else {
            if (this.container.parentNode) {
                this.container.parentNode.removeChild(this.container);
            }
        }
    }
}

export default GeoSearchControl;
