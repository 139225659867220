import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { deleteSingleServicePlan } from "../../../utils/requests/serviceplans/delete-single-service-plan";
import { AppContext } from "../../../utils/themes/theme-context";
import { ServicePlansInterface } from "../../../utils/interfaces/service/service-list-interface";

interface DeleteServicePlanDialogProps {
  open: boolean;
  onClose: () => void;
  deleteServicePlan: ServicePlansInterface | null;
}

const DeleteServicePlanDialog: React.FC<DeleteServicePlanDialogProps> = ({
  open,
  onClose,
  deleteServicePlan,
}) => {
  var { theme } = useContext(AppContext);
  const [activeStep, setActiveStep] = useState(0);
  const [deleteResult, setDeleteResult] = useState("");
  const [success, setSuccess] = useState(false);
  const [deleteResultColor, setDeleteResultColor] = useState("");

  const handleDeleteServicePlan = async () => {
    try {
      // Make API call to delete the service plan
      const response = await deleteSingleServicePlan(deleteServicePlan!.id);

      if (response === "success") {
        // Log success
        setSuccess(true);
        setDeleteResult("Service Plan was successfully deleted");
        setDeleteResultColor("green");
      } else {
        // Log failure
        console.error("Service Plan Deletion Failed");
        setDeleteResult("Error deleting Service Plan: ${errorMessages}");
        setDeleteResultColor("red");
        // Log the error message if available
        response &&
          console.error("Error deleting Service Plan:", response.statusText);
      }
    } catch (error) {
      // Handle other errors
      console.error("Error deleting Service Plan:", error);
      setDeleteResult("An error occurred while making the request");
      setDeleteResultColor("red");
    }

    setActiveStep(1);
  };

  const handleCloseEditDialog = () => {
    setActiveStep(0);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle variant="h4" align="center">
        Delete Service Plan
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stepper activeStep={activeStep} alternativeLabel>
          <Step>
            <StepLabel>Confirmation</StepLabel>
          </Step>
          <Step>
            <StepLabel>Result</StepLabel>
          </Step>
        </Stepper>
        {activeStep === 0 && (
          <>
            <div
              style={{
                marginLeft: "20px",
                marginRight: "20px",
                marginBottom: "50px",
              }}
            >
              <Typography paddingTop={5}>
                This is an irreversible action. We cannot retrieve the service
                plan details after it is deleted.
              </Typography>
              <Typography>
                This action can cause serious consequences like deletion of
                other important details linked to this service plan data
              </Typography>
              <Typography color="red">
                Are you sure you want to delete this service plan?
              </Typography>
            </div>
            <div>
              <Box mt={2} display="flex" justifyContent="space-between">
                <Button
                  onClick={onClose}
                  variant="contained"
                  color={theme.nextbuttoncolor}
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  color={theme.backbuttoncolor}
                  onClick={handleDeleteServicePlan}
                >
                  Yes
                </Button>
              </Box>
            </div>
          </>
        )}
        {activeStep === 1 && (
          <div>
            <Typography padding={5} fontSize={24}>
              <span style={{ color: deleteResultColor }}>{deleteResult}</span>
            </Typography>
            <Box
              mt={2}
              display="flex"
              justifyContent="center"
              style={{ padding: 40 }}
            >
              <Button
                onClick={handleCloseEditDialog}
                variant="contained"
                color={theme.nextbuttoncolor}
              >
                Done
              </Button>
            </Box>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DeleteServicePlanDialog;
