import {
	Button,
	Card,
	CardContent,
	CardHeader,
	Divider,
	Stack,
	Tooltip,
} from "@mui/material";
import { useContext } from "react";
import { AppContext } from "../../../utils/themes/theme-context";
import { getUser } from "../../../utils/interfaces/users/user-details-interfaces";

export default function Actions(props: {
	user: getUser;
	setDisplayModal: Function;
	setSuspending: Function;
	setDeleting: Function;
}) {
	var { perms, theme } = useContext(AppContext);

	const suspendClick = () => {
		props.setDeleting(false);
		props.setSuspending(true);
		props.setDisplayModal(true);
	};
	const deleteClick = () => {
		props.setDeleting(true);
		props.setSuspending(false);
		props.setDisplayModal(true);
	};

	return (
		<>
			<Card elevation={8} sx={{ borderRadius: 3 }}>
				<CardHeader title="Actions" />
				<CardContent>
					<Divider />
					<Stack direction={"column"} spacing={2}>
						<Button
							disabled={
								perms.username === props.user.data.username ? true : false
							}
							color={
								props.user.data.enabled
									? theme.backbuttoncolor
									: theme.nextbuttoncolor
							}
							variant="contained"
							onClick={() => suspendClick()}
						>
							{props.user.data.enabled === true
								? "Suspend Account"
								: "Enable Account"}
						</Button>
						<Button
							color={theme.backbuttoncolor}
							variant="contained"
							onClick={() => deleteClick()}
						>
							Delete Account
						</Button>
					</Stack>
				</CardContent>
			</Card>
		</>
	);
}
