export const validEmail = new RegExp(
  "^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$"
);

export const validUsername = new RegExp("^[A-Za-z][A-Za-z0-9_]{2,127}$");

export const validName = new RegExp("^[a-zA-Z ]{3,127}$");

export const validPhoneNumber = new RegExp(
  "^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$"
);

export const validDomain = new RegExp(
  "^(?!-)[A-Za-z0-9-]+([-.]{1}[a-z0-9]+)*.[A-Za-z]{2,6}$"
);

export const validProductName = new RegExp("^[a-zA-Z0-9-]{1,128}$");

export const validProductSNI = /^(0\d{2}|1[0-4]\d|159)$/; 

export const validProductSerialNumber = new RegExp("^(0\\d{2}|1[0-4]\\d|159)\\d{5}$");

export const validVersionNumber = /^\d\.\d{2}\.\d$/;

export function validateWeight(weight: number, errormessage: string) {
  if (!Number.isInteger(weight) || weight <= 0) {
    return errormessage;
  }
  return "";
}

export function validateVersionNumber(
  versionNumber: string,
  errormessage: string
) {
  if (!validVersionNumber.test(versionNumber)) {
    return errormessage;
  }
  return "";
}

export function validateEmail(email: string, errormessage: string) {
  if (!validEmail.test(email)) {
    return errormessage;
  }
  return "";
}

export function validateUsername(username: string, errormessage: string) {
  if (!validUsername.test(username)) {
    return errormessage;
  }
  return "";
}

export function validateName(name: string, errormessage: string) {
  if (!validName.test(name)) {
    return errormessage;
  }
  return "";
}

export function validatePhoneNumber(phoneNumber: string, errormessage: string) {
  if (!validPhoneNumber.test(phoneNumber)) {
    return errormessage;
  }
  return "";
}

export function validateDomain(domain: string, errormessage: string) {
  if (!validDomain.test(domain)) {
    return errormessage;
  }
  return "";
}

export function validateProductName(productName: string, errormessage: string) {
  if (!validProductName.test(productName)) {
    return errormessage;
  }
  return "";
}

export function validateProductSNI(productSNI: string, errormessage: string) {
  if (!validProductSNI.test(productSNI)) {
    return errormessage;
  }
  return "";
}

export function validateProductSerialNumber(serialNumber: string, errormessage: string) {
  if (!validProductSerialNumber.test(serialNumber)) {
    return errormessage;
  }
  return "";
}

export function validatePreCal(modelValue: string, errormessage: string) {
  if (!validProductName.test(modelValue)) {
    return errormessage;
  }
  return "";
}
