import { useEffect, useState } from "react";
import {
	Divider,
	IconButton,
	LinearProgress,
	Paper,
	TextField,
	Box,
	Typography,
} from "@mui/material";
import { Clear, Schedule, Search } from "@mui/icons-material";
import { Geo } from "aws-amplify";
import { countries } from "../../requests/initialize-map/constants";

const geoSettings = {
	/* 
	  settings applied to all AWS Location services Geocode Requests
  */
	maxResults: 9, //limit the maximum number of results to 9. Default is 50 and maximum value is 50 per AWS documentation
	countries: countries, //the countries for which potential addresses will be returned
};

const LOCAL_STORAGE_KEY = "awGeoSearchHistory";

export interface Address {
	title: string;
	address: string;
	coordinates: { lat: number; lng: number };
}

const filterAddresses = (addresses: Address[], searchText: string) => {
	const searchLower = searchText.toLowerCase();
	return addresses.filter(
		(address) =>
			address.title.toLowerCase().includes(searchLower) ||
			address.address.toLowerCase().includes(searchLower)
	);
};

const geoSearch = async (searchText: string): Promise<Address[]> => {
	var addresses: Address[] = [];
	await Geo.searchByText(searchText, geoSettings)
		.then((results) => {
			var filteredResults = results
				.filter((result) => {
					if (!result.addressNumber) return false;
					if (
						!result.geometry ||
						!result.geometry.point ||
						result.geometry.point.length < 2
					)
						return false;
					return true;
				})
				.map((result) => {
					if (
						!result.label ||
						!result.addressNumber ||
						!result.street ||
						!result.municipality ||
						!result.region ||
						!result.postalCode ||
						!result.country ||
						!result.geometry ||
						!result.geometry.point ||
						result.geometry.point.length < 2
					)
						return null;
					return {
						// Map as Address
						title: formatTitle(result),
						address:
							result.addressNumber +
							" " +
							result.street +
							", " +
							result.municipality +
							", " +
							result.region +
							" " +
							result.postalCode +
							", " +
							result.country,
						coordinates: {
							lat: result.geometry?.point[1],
							lng: result.geometry?.point[0],
						},
					};
				})
				.filter((result): result is Address => result !== null); // Filter out nulls

			addresses = filteredResults;
		})
		.catch((error) => {
			console.error("error:", error);
			addresses = []; // Handle errors by assigning an empty array
		});

	await new Promise((resolve) => setTimeout(resolve, 200));
	return addresses;
};

// Function to format the title based on label or address details
const formatTitle = (result: any): string => {
	const { label, addressNumber, street } = result;
  
	// Split the label by commas to check if there's a name followed by an address
	const labelParts = label.split(",");
  
	// Check if the first part of the label (before the first comma) is different from the address
	if (labelParts.length > 1 && !labelParts[0].includes(addressNumber) && !labelParts[0].includes(street)) {
	  return labelParts[0].trim(); // Return the name part
	}
  
	// If no name is detected, return the formatted address using addressNumber and street
	return `${addressNumber} ${street}`;
  };


const ResultItem = (props: {
	address: Address;
	leadingIcon?: React.ReactNode;
	trailingIcon?: React.ReactNode;
	onClose?: () => void;
	onClick: () => void;
}) => {
	const { address, onClick, onClose } = props;

	return (
		<Box
			className="result-item" // Add a class to help with event handling
			onMouseDown={() => {
				onClick();
			}}
			sx={{
				padding: 1,
				cursor: "pointer",
				"&:hover": {
					backgroundColor: "#f0f0f0",
				},
				display: "flex",
				flexDirection: "row",
				alignItems: "center",

				gap: 1,
			}}
		>
			{props.leadingIcon}
			<Typography variant="body2" fontWeight="bold">
				{address.title}
			</Typography>
			<Typography variant="body2" color="textSecondary">
				{address.address}
			</Typography>
			{props.trailingIcon}
			{onClose && (
				<ClearIcon
					className="clear-hostory-item"
					onClick={() => {
						onClose();
					}}
				/>
			)}
		</Box>
	);
};

const SearchResults = (props: {
	searchText: string;
	results: Address[];
	history: Address[];
	onResultClick: (result: Address) => void;
	onClearHistoryItem: (item: Address) => void;
}) => {
	if (props.results.length === 0 && props.history.length === 0) {
		return (
			<>
				<Divider />
				<Box
					sx={{
						padding: 1,
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Typography variant="body1" color="textSecondary">
						No results found
					</Typography>
				</Box>
			</>
		);
	}

	return (
		<>
			<Divider />
			{props.history?.map((item, index) => (
				<ResultItem
					key={index}
					address={item}
					leadingIcon={
						<Schedule sx={{ fontSize: "18px", color: "gray" }} />
					}
					onClick={() => {
						props.onResultClick(item);
					}}
					onClose={() => {
						props.onClearHistoryItem(item);
					}}
				/>
			))}
			{props.results
				?.filter(
					(result) =>
						!props.history.some(
							(item) => item.address === result.address
						)
				)
				.map((result, index) => (
					<ResultItem
						key={index}
						address={result}
						onClick={() => {
							props.onResultClick(result);
						}}
					/>
				))}
		</>
	);
};

const ClearIcon = ({
	onClick,
	className,
}: {
	onClick: () => void;
	className: string;
}) => (
	<IconButton
		onMouseDown={(event) => {
			event.stopPropagation();
			onClick();
		}}
		size="small"
		className={className}
		sx={{ fontSize: "18px", marginLeft: "auto" }}
	>
		<Clear fontSize="inherit" />
	</IconButton>
);

const GeoSearchBox = ({
	onResultClickCallback,
}: {
	onResultClickCallback: (result: Address) => void;
}) => {
	const [loading, setLoading] = useState(false);
	const [searchText, setSearchText] = useState<string>("");
	const [searchResults, setSearchResults] = useState<Address[]>([]);
	const [history, setHistory] = useState<Address[]>([]); // State to track history
	const [showResults, setShowResults] = useState(false);

	const filteredHistory = filterAddresses(history, searchText);
	// const filteredResults = filterAddresses(searchResults, searchText);

	// Load history from localStorage on component mount
	useEffect(() => {
		const storedHistory = localStorage.getItem(LOCAL_STORAGE_KEY);
		if (storedHistory) {
			setHistory(JSON.parse(storedHistory));
		}
	}, []);

	// Save history to localStorage whenever it changes
	useEffect(() => {
		localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(history));
	}, [history]);

	// Function to handle changes in the search text
	const handleSearch = async () => {
		if (searchText.trim() === "") {
			setSearchResults([]);
			return;
		}

		if (searchText.length < 5) {
			setShowResults(false);
			return;
		}

		setLoading(true);
		setSearchResults(await geoSearch(searchText));
		setShowResults(true);
		setLoading(false);
	};

	const handleResultClick = (result: Address) => {
		setHistory((prevHistory) => {
			return [
				result,
				...prevHistory.filter(
					(item) => item.address !== result.address
				),
			];
		});
		setSearchResults(
			searchResults.filter((item) => item.title !== result.title)
		);
		setShowResults(false);
		setSearchText(result.title);
		onResultClickCallback(result);
	};

	const clearHistoryItem = (item: Address) => {
		setHistory((prevHistory) => {
			return prevHistory.filter(
				(historyItem) => historyItem.title !== item.title
			);
		});
		setShowResults(true);
	};

	const handleBlur = (event: React.FocusEvent) => {
		// Check if the blur event is related to a click on the result item
		const relatedTarget = event.relatedTarget as HTMLElement;
		if (relatedTarget && relatedTarget.classList.contains("result-item")) {
			return;
		}
		// Don't hide results if it was a remove item from history click
		// if (relatedTarget && relatedTarget.classList.contains('clear-history-item')) {
		//   return;
		// }
		setShowResults(false);
	};

	const handleFocus = () => {
		// if (searchText.length > 4)
		setShowResults(true);
	};

	// Debounce the search function to avoid making too many requests
	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			handleSearch();
		}, 500);

		return () => clearTimeout(delayDebounceFn);
	}, [searchText]);

	return (
		<Paper
			square={false}
			id="geo-search-box"
			sx={{
				borderRadius: 6,
				overflow: "hidden",
			}}
		>
			<TextField
				fullWidth
				size="small"
				variant="standard"
				placeholder="Search for an address"
				value={searchText}
				onChange={(e) => setSearchText(e.target.value)}
				onBlur={handleBlur}
				onFocus={handleFocus}
				InputProps={{
					startAdornment: <Search />,
					endAdornment: searchText.length > 0 && (
						<ClearIcon
							onClick={() => setSearchText("")}
							className="clear-search"
						/>
					),
					disableUnderline: true,
				}}
				sx={{
					marginX: 1,
					marginY: 1,
					paddingRight: 2,
				}}
			/>
			{loading && <LinearProgress color="primary" />}
			{showResults && searchText.length === 0 && history.length > 0 && (
				<SearchResults
					results={[]}
					history={history}
					onResultClick={handleResultClick}
					onClearHistoryItem={clearHistoryItem}
					searchText={searchText}
				/>
			)}
			{showResults && searchText.length > 4 && (
				<SearchResults
					// results={searchResults}
					// history={history}
					results={searchResults}
					history={filteredHistory}
					onResultClick={handleResultClick}
					onClearHistoryItem={clearHistoryItem}
					searchText={searchText}
				/>
			)}
		</Paper>
	);
};

export default GeoSearchBox;
