import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  aw_perms_descriptions,
  corp_perms_descriptions,
  aw_perms_name,
  corp_perms_name,
  PermsType,
  iweigh_perms_name,
  iweigh_perms_descriptions,
} from "../../../utils/interfaces/new-user/permissions";
import {
  aw_permissions,
  corporate_permissions,
  iweigh_permissions,
  newuser,
} from "../../../utils/interfaces/new-user/new-user-interface";

const columns = ["Name", "Description", "Choice"];

export const SetUserPermsV2 = (props: {
  usertype: string;
  user?: newuser;
  permissionscallback: Function;
  nextButtonCallback?: Function;
}) => {
  const { user } = props;
  const userType = user?.user_type;

  const awPermissions = { ...user?.aw_permissions }; // Create a copy of AW permissions
  const corpPermissions = { ...user?.corporate_permissions }; // Create a copy of corporate permissions
  const iweighPermissions = { ...user?.iweigh_permissions }; // Create a copy of iWeigh permissions

  // interaction to move on to final step
  useEffect(() => {
    if (props.nextButtonCallback) {
      props.nextButtonCallback(false);
    }
  }, [awPermissions, corpPermissions, iweighPermissions]);

  //   const [selectAll, setSelectAll] = useState<boolean | null>(null);
  const [selectAll, setSelectAll] = useState<boolean | null>(
    user ? null : false
  );

  const handleIWeighSelectAll = (value: boolean) => {
    setSelectAll(value);
    // Iterate over permissions and set all of them to the selected value
    Object.keys(iweighPermissions).forEach((perm) => {
      clickHandler(value, perm, "iweigh");
    });
  };

  const permissionsHelper = (permstype: PermsType) => {
    return (
      <>
        <Box flexGrow={1}>
          <Table>
            <TableHead>
              <TableRow>
                {Object.values(columns).map((column: string) => {
                  return <TableCell key={column}>{column}</TableCell>;
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.values(permstype).map((perm: string, index: number) => {
                return (
                  <TableRow key={perm}>
                    <TableCell>{perm}</TableCell>
                    {description(index, permstype)}
                    {radioButtons(index, permstype)}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </>
    );
  };

  // Helper to fill each description
  const description = (index: number, permstype: PermsType) => {
    let permname = Object.keys(permstype)[index];
    let descriptionText = "";

    if (props.usertype === "aw") {
      descriptionText =
        aw_perms_descriptions[permname] || iweigh_perms_descriptions[permname];
    } else if (props.usertype === "corp") {
      descriptionText =
        corp_perms_descriptions[permname] ||
        iweigh_perms_descriptions[permname];
    } else {
      descriptionText = iweigh_perms_descriptions[permname];
    }

    return <TableCell>{descriptionText}</TableCell>;
  };

  //helper to fill each "choice" wtih functional radio buttons
  const radioButtons = (index: number, permstype: PermsType) => {
    const isIWeigh = permstype === iweigh_perms_name; //checks if the permstype variable is equal to the value of iweigh_perms_name.

    return (
      <>
        <TableCell>
          <FormControl>
            <Box>
              <RadioGroup row>
                <FormControlLabel
                  onChange={(event) =>
                    clickHandler(
                      true,
                      Object.keys(permstype)[index],
                      isIWeigh ? "iweigh" : props.usertype
                    )
                  }
                  value={true}
                  control={<Radio />}
                  label="True"
                  checked={
                    //If isIWeigh is true, it means that permstype corresponds to the permissions for iWeigh
                    isIWeigh
                      ? iweighPermissions[Object.keys(permstype)[index]] ===
                        true
                      : props.usertype === "corp"
                      ? corpPermissions[Object.keys(permstype)[index]] === true
                      : awPermissions[Object.keys(permstype)[index]] === true
                  }
                />
                <FormControlLabel
                  onChange={(event) =>
                    clickHandler(
                      false,
                      Object.keys(permstype)[index],
                      isIWeigh ? "iweigh" : props.usertype
                    )
                  }
                  value={false}
                  control={<Radio />}
                  label="False"
                  checked={
                    // if isIWeigh is false, it means that permstype corresponds to the permissions for AW or Corp
                    isIWeigh
                      ? iweighPermissions[Object.keys(permstype)[index]] ===
                        false
                      : props.usertype === "corp"
                      ? corpPermissions[Object.keys(permstype)[index]] === false
                      : awPermissions[Object.keys(permstype)[index]] === false
                  }
                />
              </RadioGroup>
            </Box>
          </FormControl>
        </TableCell>
      </>
    );
  };

  const clickHandler = (
    bool: boolean,
    label: string,
    permissiontype: string
  ) => {
    const updatedPermissions: newuser = { ...(user as newuser) };
    if (permissiontype === "aw") {
      updatedPermissions.aw_permissions[label] = bool;
    } else if (permissiontype === "corp") {
      updatedPermissions.corporate_permissions[label] = bool;
    } else {
      updatedPermissions.iweigh_permissions[label] = bool;
    }

    props.permissionscallback(bool, label, permissiontype);
  };

  return (
    <>
      <Card>
        <CardHeader title={`Set Permissions for ${user?.username}`} />
        <CardContent>
          <Box>
            {props.usertype === "corp"
              ? permissionsHelper(corp_perms_name)
              : permissionsHelper(aw_perms_name)}
          </Box>
        </CardContent>
      </Card>
      <Divider />
      <Box marginTop={5}>
        <Card>
          <CardHeader title="iWeigh Permissions" />
          <CardContent>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              paddingRight={45}
            >
              <FormControl>
                <RadioGroup row>
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Select All True"
                    checked={selectAll === true}
                    onChange={() => handleIWeighSelectAll(true)}
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="Select All False"
                    checked={selectAll === false}
                    onChange={() => handleIWeighSelectAll(false)}
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            {permissionsHelper(iweigh_perms_name)}
          </CardContent>
        </Card>
      </Box>
    </>
  );
};
