import { Auth } from 'aws-amplify';
import { buildBaseUrl } from '../build-base-url';

export async function getClientVehicles(clientId: string) {
  const url = buildBaseUrl()
  try {
    const authData = await Auth.currentAuthenticatedUser();
    const response = await fetch(`${url}clients/${clientId}/vehicles`, {
      headers: {
        Authorization: `Bearer ${authData.signInUserSession.accessToken.jwtToken}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch client vehicles');
    }

    const data = await response.json();
    return data.data;
    
  } catch (err) {
    console.log(err);
    throw err;
  }
}