import { Check } from "@mui/icons-material";
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
} from "@mui/material";
import { newuser } from "../../../utils/interfaces/new-user/new-user-interface";
import { useContext, useState } from "react";
import { AppContext } from "../../../utils/themes/theme-context";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { PermsType } from "../../../utils/interfaces/new-user/permissions";

const ReviewTable = ({
  permsData,
  corp_perms_name,
  corp_perms_descriptions,
  iweigh_perms_name,
  iweigh_perms_descriptions,
  handleEditClick,
  openDeleteConfirmationDialog,
}: {permsData: newuser[],corp_perms_name: PermsType,
    corp_perms_descriptions: PermsType,
    iweigh_perms_name: PermsType,
    iweigh_perms_descriptions: PermsType, handleEditClick: (index: number) => void,
    openDeleteConfirmationDialog: (index: number) => void}) => {
  var { theme } = useContext(AppContext);
  
  return (
    <div>
      <Typography
        paddingTop={5}
        variant="h5"
        align="center"
        style={{
          marginBottom: "16px",
          borderBottom: "2px solid #304D6D",
          paddingBottom: "8px",
          color: theme.selectedbuttoncolor,
          fontWeight: "bold",
        }}
      >
        Review
      </Typography>

      <Table
        style={{
          border: "1px solid #e0e0e0",
          tableLayout: "auto",
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell
              style={{
                border: "1px solid #e0e0e0",
                textAlign: "center",
              }}
            >
              <strong>User</strong>
            </TableCell>
            {Object.keys(permsData[0]).map((columnHeader, index) => {
              const skipKeywords = [
                "read",
                "write",
                "device",
                "firmware",
                "create",
                "edit",
                "type",
                "id",
                "client",
                "aw",
                "corporate",
                "iweigh",
                "domain",
              ];
              if (
                skipKeywords.some((keyword) =>
                  columnHeader.toLowerCase().includes(keyword)
                )
              ) {
                return null;
              }
              return (
                <TableCell
                  key={index}
                  style={{
                    border: "1px solid #e0e0e0",
                    textAlign: "center",
                  }}
                >
                  {columnHeader}
                </TableCell>
              );
            })}
            <TableCell
              style={{
                border: "1px solid #e0e0e0",
                textAlign: "center",
              }}
            >
              <strong>iWeigh Permissions</strong>
            </TableCell>
            <TableCell
              style={{
                border: "1px solid #e0e0e0",
                textAlign: "center",
              }}
            >
              <strong>User Permissions</strong>
            </TableCell>
            <TableCell
              style={{
                border: "1px solid #e0e0e0",
                textAlign: "center",
              }}
            >
              Actions
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {permsData.map((rowData, index) => (
            <TableRow key={index}>
              <TableCell
                style={{
                  border: "1px solid #e0e0e0",
                  textAlign: "center",
                }}
              >
                User {index + 1}
              </TableCell>
              {Object.keys(rowData).map((columnHeader, columnIndex) => {
                const skipKeywords = [
                  "read",
                  "write",
                  "device",
                  "firmware",
                  "create",
                  "edit",
                  "type",
                  "id",
                  "client",
                  "aw",
                  "corporate",
                  "iweigh",
                  "domain",
                ];
                if (
                  skipKeywords.some((keyword) =>
                    columnHeader.toLowerCase().includes(keyword)
                  )
                ) {
                  return null;
                }

                const cellContent = String(
                  rowData[columnHeader as keyof newuser]
                );
                return (
                  <TableCell
                    key={columnIndex}
                    style={{
                      border: "1px solid #e0e0e0",
                      textAlign: "center",
                    }}
                  >
                    {cellContent}
                  </TableCell>
                );
              })}
              <TableCell
                style={{
                  border: "1px solid #e0e0e0",
                  textAlign: "center",
                }}
              >
                {Object.keys(rowData.corporate_permissions).map(
                  (permissionKey) => (
                    <TableRow key={permissionKey}>
                      <TableCell>{corp_perms_name[permissionKey]}: </TableCell>
                      <TableCell>
                        {corp_perms_descriptions[permissionKey]}:{" "}
                      </TableCell>
                      <TableCell>
                        {(
                          rowData.corporate_permissions as Record<
                            string,
                            boolean
                          >
                        )[permissionKey] ? (
                          <Chip icon={<Check />} label="True" color="success" />
                        ) : (
                          <Chip
                            icon={<CloseIcon />}
                            label="False"
                            color="error"
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableCell>

              <TableCell
                style={{
                  border: "1px solid #e0e0e0",
                  textAlign: "center",
                }}
              >
                {Object.keys(rowData.iweigh_permissions).map(
                  (permissionKey) => (
                    <TableRow key={permissionKey}>
                      <TableCell>
                        {" "}
                        {iweigh_perms_name[permissionKey]}:{" "}
                      </TableCell>
                      <TableCell>
                        {iweigh_perms_descriptions[permissionKey]}:{" "}
                      </TableCell>
                      <TableCell>
                        {" "}
                        {(
                          rowData.iweigh_permissions as Record<string, boolean>
                        )[permissionKey] ? (
                          <Chip icon={<Check />} label="True" color="success" />
                        ) : (
                          <Chip
                            icon={<CloseIcon />}
                            label="False"
                            color="error"
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableCell>

              <TableCell
                style={{
                  border: "1px solid #e0e0e0",
                  textAlign: "center",
                }}
              >
                <EditIcon
                  onClick={() => handleEditClick(index)}
                  style={{
                    cursor: "pointer",
                    marginRight: "15px",
                  }}
                />
                <DeleteIcon
                  onClick={() => openDeleteConfirmationDialog(index)}
                  style={{ cursor: "pointer" }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default ReviewTable;
