import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  LinearProgress,
  Skeleton,
  Slide,
  Stack,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AppContext } from "../../utils/themes/theme-context";
import ClientsTabServicePlan from "./tab-pages/clients-tab-table";
import Details from "./service-elements/details";
import ClientsCard from "./service-elements/clientscard";
import { ServicePlansInterface } from "../../utils/interfaces/service/service-list-interface";
import { fetchSingleServicePlan } from "../../utils/requests/serviceplans/get-single-serviceplan";
import Features from "./service-elements/features";
import Actions from "./service-elements/actions";

export default function SingleService() {
  var { theme } = useContext(AppContext);
  const { id } = useParams<{ id?: string }>();
  let userNavigate = useNavigate();
  const [transition, setTransition] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const [activeButton, setActiveButton] = useState<string>("Home");
  const [displayDetailsTable, setDisplayDetailsTable] = useState<boolean>(true);
  const [displayClients, setDisplayClients] = useState<boolean>(false);
  const [singleServicePlan, setsingleServicePlan] =
    useState<ServicePlansInterface | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  // Fetch data from the API on component mount
  useEffect(() => {
    setLoading(true);
    if (id) {
      fetchSingleServicePlan(id)
        .then((data: ServicePlansInterface) => {
          setsingleServicePlan(data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching product data:", error);
          setErrorMessage(true);
          setLoading(false);
        });
    }
  }, [id]);

  function handleBack(): void {
    setTransition(false);
    setTimeout(() => userNavigate(`/service`), 400);
  }

  const handleButtonClick = async (buttonName: string) => {
    setActiveButton(buttonName);
    //condense in single state 0 is home 1 is client
    setDisplayDetailsTable(buttonName === "Home");
    setDisplayClients(buttonName === "Clients");
  };

  const loadingDisplay = () => {
    if (!singleServicePlan) {
      return (
        <>
          <LinearProgress />
          <Stack direction="row" spacing={3} marginTop={5}>
            <Stack direction="column" spacing={3}>
              <Skeleton variant="rectangular" width={275} height={500} />
              <Skeleton variant="rectangular" width={275} height={200} />
            </Stack>
            <Divider flexItem orientation="vertical" />
            <Stack direction="column" spacing={3}>
              <Skeleton variant="rectangular" width={700} height={200} />
              <Skeleton variant="rectangular" width={700} height={400} />
            </Stack>
            <Stack direction="column" spacing={3}>
              <Skeleton variant="rectangular" width={275} height={150} />
              <Skeleton variant="rectangular" width={275} height={100} />
            </Stack>
          </Stack>
        </>
      );
    }
    return null;
  };

  return (
    <>
      <Slide direction="left" in={transition} timeout={400}>
        <Box
          flexGrow={1}
          marginLeft={"2%"}
          marginRight={"2%"}
          marginTop={8}
          marginBottom={10}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <h2>Service Plan Details</h2>
            </Grid>
            <Grid item xs={6}>
              <Box
                display="flex"
                justifyContent="flex-end"
                marginTop={3}
                marginRight={2}
              >
                <Button
                  color={theme.backbuttoncolor}
                  onClick={handleBack}
                  variant={"contained"}
                >
                  Back
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Divider />

          <>
            <Grid>
              <Box
                display="flex"
                justifyContent="flex-start"
                marginTop={1}
                marginBottom={1}
              >
                <Button
                  variant="contained"
                  style={{
                    marginRight: "10px",
                    background:
                      activeButton === "Home"
                        ? theme.selectedbuttoncolor
                        : theme.nonactivebuttoncolor,
                  }}
                  onClick={() => handleButtonClick("Home")}
                >
                  Home
                </Button>
                <Button
                  variant="contained"
                  style={{
                    marginRight: "10px",
                    background:
                      activeButton === "Clients"
                        ? theme.selectedbuttoncolor
                        : theme.nonactivebuttoncolor,
                  }}
                  onClick={() => handleButtonClick("Clients")}
                >
                  Clients
                </Button>
              </Box>
            </Grid>
            <Divider />

            {loading ? (
              <>
                <LinearProgress />
                <Stack direction="row" spacing={3} marginTop={5}>
                  <Stack direction="column" spacing={3}>
                    <Skeleton variant="rectangular" width={275} height={500} />
                    <Skeleton variant="rectangular" width={275} height={200} />
                  </Stack>
                  <Divider flexItem orientation="vertical" />
                  <Stack direction="column" spacing={3}>
                    <Skeleton variant="rectangular" width={700} height={200} />
                    <Skeleton variant="rectangular" width={700} height={400} />
                  </Stack>
                  <Stack direction="column" spacing={3}>
                    <Skeleton variant="rectangular" width={275} height={150} />
                    <Skeleton variant="rectangular" width={275} height={100} />
                  </Stack>
                </Stack>
              </>
            ) : (
              <>
                {displayClients ? (
                  <Box padding={2}>
                    <ClientsTabServicePlan />
                  </Box>
                ) : errorMessage ? (
                  <Box paddingTop={2} color="red">
                    <Alert severity="error">Invalid Service Plan</Alert>
                  </Box>
                ) : displayDetailsTable && singleServicePlan ? (
                  <>
                    <Stack
                      direction="row"
                      spacing={4}
                      marginTop={5}
                      marginBottom={10}
                    >
                      <Stack direction="column" spacing={3} width="30%">
                        <Stack direction="column" spacing={2}>
                          <Details servicePlan={singleServicePlan} />
                          <Actions deleteServicePlan={singleServicePlan}
                          />
                        </Stack>
                      </Stack>
                      <Divider flexItem orientation="vertical" />
                      <Stack direction="column" spacing={2} width="70%">
                        <Stack direction="row" spacing={2}>
                          <Box display="inline-block">
                            <Features servicePlan={singleServicePlan} />
                          </Box>
                          <Box display="inline-block">
                            {/* For future use when statistical calculations are available */}
                            {/* <Status /> */}
                          </Box>
                        </Stack>
                        <ClientsCard />
                      </Stack>
                    </Stack>
                  </>
                ) : (
                  loadingDisplay()
                )}
              </>
            )}
          </>
        </Box>
      </Slide>
    </>
  );
}
