/*CONFLUENCE DOCUMENTATION: https://airweigh.atlassian.net/l/cp/Kc3A9urW */

import { useEffect, useState } from "react";
import {
	validateEmail,
	validateName,
	validateUsername,
} from "../../../utils/regex/forms";
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Grid,
	TextField,
} from "@mui/material";
import { checkUserAvailability } from "../../../utils/requests/new-user/get-users-available";
import { newuser } from "../../../utils/interfaces/new-user/new-user-interface";
import {
	EnterInformationAvailability,
	EnterInformationErrors,
	EnterInformationForm,
} from "../../../utils/interfaces/new-user/enter-information-interfaces";

export const EnterInformation = (props: {
	username: Function;
	name: Function;
	email: Function;
	newUser: newuser;
	nextButtonCallback: Function;
	processAvailabilityResponse: Function;
	validateAndSetError: Function;
}) => {
	const [errors, setErrors] = useState<EnterInformationErrors>({
		errortextUsername: "",
		errortextName: "",
		errortextEmail: "",
	});
	const [form, setForm] = useState<EnterInformationForm>({
		curusername: props.newUser.username ? props.newUser.username : "",
		curname: props.newUser.user_name ? props.newUser.user_name : "",
		curemail: props.newUser.user_email ? props.newUser.user_email : "",
	});
	const [availability, setAvailability] =
		useState<EnterInformationAvailability>({
			usernameavailability: false,
			emailavailability: false,
		}); //true = available, false = not
	const [triggercheck, setTriggerCheck] = useState<boolean>(true);
	const [usernametimer, setUsernameTimer] = useState<NodeJS.Timeout>();
	const [emailtimer, setEmailTimer] = useState<NodeJS.Timeout>();

	//checks fields on returning to the component to ensure buttons and errors
	// are properly displayed
	useEffect(() => {
		checkAllFields();
	}, []);

	//checks on change to availabilty or triggered after errors are set
	useEffect(() => {
		checkAllFields();
	}, [triggercheck, availability]);

	//uses validateandseterror to check all entries when at least one field
	// is filled... email and username set timers for calling the backend to avoid
	// excessive calls. These calls cancelled in textfield change funtion
	useEffect(() => {
		if (!form.curemail && !form.curname && !form.curusername) {
			return;
		}

		props.validateAndSetError(
			form.curname,
			validateName,
			"Invalid Name",
			"errortextName",
			setErrors
		);
		const isValidEmail = props.validateAndSetError(
			form.curemail,
			validateEmail,
			"Invalid Email",
			"errortextEmail",
			setErrors
		);
		const isValidUsername = props.validateAndSetError(
			form.curusername,
			validateUsername,
			"Invalid Username",
			"errortextUsername",
			setErrors
		);

		if (isValidEmail) {
			if (form.curemail) {
				setEmailTimer(setTimeout(checkAvailability, 1000));
			}
		}

		if (isValidUsername) {
			if (form.curusername) {
				setUsernameTimer(setTimeout(checkAvailability, 1000));
			}
		}

		setTriggerCheck(!triggercheck);
	}, [form]);

	//calls backend to check availability and returns the response json
	const checkAvailability = async () => {
		try {
			const response = await checkUserAvailability(
				form.curemail,
				form.curusername
			);
			props.processAvailabilityResponse(
				response,
				setAvailability,
				availability,
				setErrors
			);
		} catch (err) {
			console.log(err);
		}
	};

	//form set, timeouts cleared if applicable, using props to set variables
	// in newUser in stepper. Will only be submitted if next button enabled
	const handleTextfieldChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setForm({ ...form, [event.target.id]: event.target.value });
		if (event.target.id === "curusername") {
			clearTimeout(usernametimer);
			props.username(event.target.value);
		} else if (event.target.id === "curname") {
			props.name(event.target.value);
		} else {
			clearTimeout(emailtimer);
			props.email(event.target.value);
		}
	};

	//sets next disabled or not
	const checkAllFields = () => {
		if (
			form.curusername &&
			form.curemail &&
			availability.usernameavailability &&
			availability.emailavailability
		) {
			const hasErrors = Object.values(errors).some(
				(error: string) => error !== ""
			);
			props.nextButtonCallback(hasErrors);
		} else {
			props.nextButtonCallback(true);
		}
	};

	return (
		<>
			<Card>
				<CardHeader title="Enter New User Information" />
				<CardContent>
					<Box padding={3}>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<TextField
									fullWidth
									onChange={handleTextfieldChange}
									error={errors.errortextUsername === "" ? false : true}
									helperText={errors.errortextUsername}
									defaultValue={
										props.newUser.username ? props.newUser.username : ""
									}
									id="curusername"
									label="Username"
									variant="outlined"
									required={true}
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									fullWidth
									onChange={handleTextfieldChange}
									error={errors.errortextName === "" ? false : true}
									helperText={errors.errortextName}
									defaultValue={
										props.newUser.user_name ? props.newUser.user_name : ""
									}
									id="curname"
									label="Name"
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									onChange={handleTextfieldChange}
									error={errors.errortextEmail === "" ? false : true}
									helperText={errors.errortextEmail}
									defaultValue={
										props.newUser.user_email ? props.newUser.user_email : ""
									}
									id="curemail"
									label="Email"
									variant="outlined"
									required={true}
								/>
							</Grid>
						</Grid>
					</Box>
				</CardContent>
			</Card>
		</>
	);
};
