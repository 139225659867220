// NestedTable.tsx
import React from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { Columns } from "../../../utils/interfaces/table/table-interfaces";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

const cellStyle = {
  border: "1px solid #ddd",
  padding: "2px",
  maxWidth: "200px",
  overflow: "hidden",
};

interface NestedTableProps {
  data: any[] | Record<string, any> | null;
  columns: Columns;
  cellStyles?: Record<string, React.CSSProperties>;
}

const NestedTable: React.FC<NestedTableProps> = ({
  data,
  columns,
  cellStyles,
}) => {
  if (!data || (Array.isArray(data) && data.length === 0)) {
    return <p>No data available</p>;
  }

  return (
    <Table sx={{tableLayout: 'auto' }}>
      <TableHead>
        <TableRow>
          {Object.keys(columns).map((columnKey) => (
            <TableCell
              key={columnKey}
              style={{ ...cellStyle, ...(cellStyles?.[columnKey] || {}) }}
              align="center"
            >
              {columns[columnKey as keyof Columns].display}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {Array.isArray(data) ? (
          // If data is an array, proceed to map through it
          data.map((row: any, index: number) => (
            <TableRow key={index}>
              {Object.keys(columns).map((columnKey) => (
                <TableCell key={columnKey} style={cellStyle} align="center">
                  {columns[columnKey as keyof Columns].subColumns ? (
                    <NestedTable
                      data={row[columns[columnKey as keyof Columns].data]}
                      columns={columns[columnKey as keyof Columns].subColumns!}
                    />
                  ) : (
                    // : columns[columnKey as keyof Columns].data === "success" ? (
                    //   <TableCell
                    //     key={columnKey}
                    //     style={{
                    //       ...cellStyle,
                    //       border: "none",
                    //       ...(cellStyles?.[columnKey] || {}),
                    //       display: "flex", // Center the content horizontally
                    //       alignItems: "center", // Center the content vertically
                    //       justifyContent: "center", // Center the content horizontally
                    //     }}
                    //   >
                    //     {row.success ? (
                    //       <CheckCircleIcon
                    //         fontSize="large"
                    //         style={{ color: "green" }}
                    //       />
                    //     )
                    //     : (
                    //       <CancelIcon fontSize="large" style={{ color: "red" }} />
                    //     )}
                    //   </TableCell>
                    // )
                    // Render value dynamically for other cases
                    `${row[columns[columnKey as keyof Columns].data]}`
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          // If data is an object, render a single row
          <TableRow>
            {Object.keys(columns).map((columnKey) => (
              <TableCell key={columnKey} style={cellStyle} align="center">
                {columns[columnKey as keyof Columns].subColumns ? (
                  <NestedTable
                    data={data[columns[columnKey as keyof Columns].data]}
                    columns={columns[columnKey as keyof Columns].subColumns!}
                  />
                ) : columns[columnKey as keyof Columns].data === "success" ? (
                  <TableCell
                    key={columnKey}
                    style={{ ...cellStyle, border: "none" }}
                    align="center"
                  >
                    {data.success ? (
                      <CheckCircleIcon
                        fontSize="large"
                        style={{ color: "green" }}
                      />
                    ) : (
                      <CancelIcon fontSize="large" style={{ color: "red" }} />
                    )}
                  </TableCell>
                ) : (
                  // Render value dynamically for other cases
                  `${data[columns[columnKey as keyof Columns].data]}`
                )}
              </TableCell>
            ))}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default NestedTable;
