import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Stack,
} from "@mui/material";
import { useContext } from "react";
import { AppContext } from "../../../utils/themes/theme-context";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export default function Actions() {
  var { theme } = useContext(AppContext);
  const updateClick = () => {
    console.log("update button clicked");
  };
  const dismissClick = () => {
    console.log("dismiss button clicked");
  };
  const disableClick = () => {
    console.log("disable button clicked");
  };

  return (
    <>
      <Card elevation={8} sx={{ borderRadius: 3 }}>
        <CardHeader
          title="Actions"
          action={
            <IconButton aria-label="info">
              <InfoOutlinedIcon />
            </IconButton>
          }
        />
        <CardContent>
          <Divider />
          <Box paddingTop={2}>
            <Stack direction={"column"} spacing={2}>
              <Button
                color={theme.backbuttoncolor}
                variant="contained"
                onClick={() => updateClick()}
              >
                Manual Update
              </Button>
              <Button
                color={theme.backbuttoncolor}
                variant="contained"
                onClick={() => dismissClick()}
              >
                Dismiss Alerts
              </Button>
              <Button
                color={theme.backbuttoncolor}
                variant="contained"
                onClick={() => disableClick()}
              >
                Disable Alerts
              </Button>
            </Stack>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}
