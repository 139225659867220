import { Box, Divider } from "@mui/material";

function Nopage() {
	return (
		<>
			<Box
				sx={{ flexGrow: 1, marginLeft: "5%", marginRight: "5%", marginTop: 10 }}
			>
				<h2>Page Not Found</h2>
				<Divider />
			</Box>
		</>
	);
}

export default Nopage;
