import {
  Alert,
  Backdrop,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Skeleton,
} from "@mui/material";
import {
  useEffect,
  useState,
} from "react";
import { Columns } from "../../../utils/interfaces/table/table-interfaces";
import GeneralTable from "../../../utils/generic-components/table/table";
import { getDevices } from "../../../utils/requests/devices/get-devices-details";
import { DevicesDetails } from "../../../utils/interfaces/vehicles/devices-details-interface";
import { getDeviceConfiguration } from "../../../utils/requests/devices/get-device-configuration";
import {
  DeviceConfiguration,
} from "../../../utils/interfaces/devices/device-configuration-interface";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import NestedTable from "../../../utils/generic-components/table/nested-table";

const autoWidthTableStyle = {
  width: "auto", // Set your desired width here
};

let columns: Columns = {
  AssetId: {
    type: "text",
    display: "Asset Id",
    data: "asset_id",
    sort: "AssetId",
  },
  Baudrate: {
    type: "text",
    display: "Baudrate",
    data: "baudrate",
    sort: "Baudrate",
  },
  DateTime: {
    type: "time",
    display: "Date Time",
    data: "date_time",
    sort: "DateTime",
  },
  DeviceType: {
    type: "text",
    display: "Device Type",
    data: "device_type",
    sort: "DeviceType",
  },
  Vin: {
    type: "text",
    display: "VIN",
    data: "vin",
    sort: "Vin",
  },
  UpdatedAt: {
    type: "time",
    display: "Updated At",
    data: "updated_at",
    sort: "UpdatedAt",
  },
  Sensors: {
    type: "subhead",
    display: "Sensors",
    data: "sensors",
    subColumns: {
      SensorType: {
        type: "text",
        display: "Sensor Type",
        data: "type", // Access nested data
      },
      SensorPort: {
        type: "text",
        display: "Sensor Port",
        data: "port", // Access nested data
      },
    },
  },
  Groups: {
    type: "subhead",
    display: "Groups",
    data: "groups",
    subColumns: {
      GroupName: {
        type: "text",
        display: "Group Name",
        data: "group_name", // Access nested data
      },
      GroupNumber: {
        type: "text",
        display: "Group Number",
        data: "group_number", // Access nested data
      },
      SensorPorts: {
        type: "subhead",
        display: "Sensor Ports",
        data: "sensor_ports",
        subColumns: {
          SensorType: {
            type: "text",
            display: "Sensor Type",
            data: "type", // Access deeply nested data
          },
          SensorPort: {
            type: "text",
            display: "Sensor Port",
            data: "port", // Access deeply nested data
          },
        },
      },
    },
  },
};

export default function CurrentDeviceConfiguration(props: {
  device: DevicesDetails;
}) {
  const [serialNumber, setserialNumber] = useState<string>();
  const [deviceConfigurationData, setDeviceConfigurationData] =
    useState<DeviceConfiguration | null>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const deviceidget = props.device.id;

  useEffect(() => {
    // Fetch the serial number
    const fetchSerialNumber = async () => {
      try {
        const response = await getDevices(deviceidget);
        setserialNumber(response.serial);
      } catch (err) {
        console.log(err);
      }
    };

    fetchSerialNumber(); // Fetch the serial number when the component mounts or when deviceidget changes
  }, [deviceidget]);

  useEffect(() => {
    // Fetch device calibration data whenever the serial number changes
    const fetchDeviceConfigurationData = async () => {
      try {
        if (serialNumber) {
          const response = await getDeviceConfiguration(serialNumber);

          if (!response) {
            setErrorMessage("No Data Available");
            setDeviceConfigurationData(null);
          } else if (
            response.errors &&
            response.errors.length > 0 &&
            response.errors[0] === "unsupported product feature"
          ) {
            setErrorMessage("Unsupported Product Feature.");
            setDeviceConfigurationData(null);
          } else if (
            response.errors &&
            response.errors.length > 0 &&
            response.errors[0] === "database record not found"
          ) {
            setErrorMessage("Database Record Not Found");
            setDeviceConfigurationData(null);
          } else {
            setDeviceConfigurationData(response);
            setErrorMessage(null); // Clear any previous error message
          }
        }
      } catch (err) {
        setDeviceConfigurationData(null);
      }
    };

    fetchDeviceConfigurationData(); // Fetch device calibration data when the serial number changes
  }, [serialNumber]);

  return (
    <>
      <Card elevation={8} sx={{ borderRadius: 3 }}>
        <CardHeader
          title="Current Device Configuration"
          action={
            <IconButton aria-label="info">
              <InfoOutlinedIcon />
            </IconButton>
          }
        />
        <CardContent>
          <Divider />
          {errorMessage ? (
            <Box>
              <Backdrop
                open={true}
                sx={{
                  position: "relative",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              >
                <Alert variant="filled" severity="warning" style={{width: "100%"}}>
                  {errorMessage ? errorMessage : null}
                </Alert>
              </Backdrop>
            </Box>
          ) : deviceConfigurationData ? (
            <GeneralTable
              data={[
                {
                  DeviceType: deviceConfigurationData.device_type,
                  Vin: deviceConfigurationData.vin,
                  AssetId: deviceConfigurationData.asset_id,
                  Baudrate: deviceConfigurationData.baudrate,
                  WeightDisplay: deviceConfigurationData.weight_display,
                  DateTime: deviceConfigurationData.date_time,
                  UpdatedAt: deviceConfigurationData.updated_at,
                  Sensors: (
                    <NestedTable
                      data={deviceConfigurationData.sensors}
                      columns={columns.Sensors.subColumns}
                    />
                  ),
                  Groups: (
                    <NestedTable
                      data={deviceConfigurationData.groups}
                      columns={columns.Groups.subColumns}
                    />
                  ),
                },
              ]}
              customTableStyle={autoWidthTableStyle}
              columns={columns}
              loading={false}
              noDataMessage="No Configuration Data"
            />
          ) : (
            <Skeleton variant="rectangular" width={650} height={200} />
          )}
        </CardContent>
      </Card>
    </>
  );
}
