import { Auth } from 'aws-amplify';
import { DefaultCVWeightsReturnType, UnformattedCVWeightsDataPoint } from '../../interfaces/clients/client-vehicle-map-interface';
import { buildBaseUrl } from "../build-base-url";
import { FormattedWeight, FormattedWeightswithData } from '../../interfaces/vehicles/vehicles-interfaces';

export async function getClientVehiclesMap(clientId: string) {
	const url = buildBaseUrl()
	try {
		const authData = await Auth.currentAuthenticatedUser();
		const response = await fetch(`${url}clients/${clientId}/vehicles`, {
			headers: {
				Authorization: `Bearer ${authData.signInUserSession.accessToken.jwtToken}`,
			},
		});

		if (!response.ok) {
			throw new Error('Failed to fetch client vehicles');
		}

		// Cast res to UnformattedCVWeightsDataPoint[]
		const data = await response.json() as DefaultCVWeightsReturnType;

		return formatgetClientVehicleWeights(data);

		// return data.data;
	} catch (err) {
		console.log(err);
		throw err;
	}
};

//the latest_weights array could be empty or missing
//If any property, such as latest_weights or its first element, was missing or null, 
//the code tried to access nested properties (like latitude.value), 
//resulting in an error when the base object was null or undefined.
//before latest_weights[0] was access directly without checks so was causing the errors
//The original code didn’t have checks to handle cases where latest_weights was empty or latest_weights[0] was missing, causing the map function to break.
//Hence TypeError

export function formatgetClientVehicleWeights(data: DefaultCVWeightsReturnType): [FormattedWeightswithData, DefaultCVWeightsReturnType] {
	let unformattedCVdata = data.data

	var formattedCVWeights: FormattedWeight[] = unformattedCVdata.map((weight: UnformattedCVWeightsDataPoint, index: number) => {
		// Ensuring latest_weights is an array and has at least one element
		if (!weight.latest_weights || weight.latest_weights.length === 0)
			return undefined

		var latestWeight = weight.latest_weights[0]
		
		// Check if lat, lng, and weight are present
		if (!latestWeight.latitude.received || !latestWeight.longitude.received || !latestWeight.weight.value.received)
			return undefined

		return {
			number: index + 1,
			latitude: latestWeight.latitude?.value,
			longitude: latestWeight.longitude?.value,
			weight: latestWeight.weight.value.value,
			date_time: latestWeight.date_time ?? "Not Specified",
			snapshot: "go",
			more_details: "open",
		};
	}).filter((item): item is FormattedWeight => item !== undefined);

	let clientvehicleData: FormattedWeightswithData = {
		data: formattedCVWeights,
	};

	return [clientvehicleData, data];
}