/*CONFLUENCE DOCUMENTATION: https://airweigh.atlassian.net/l/cp/Kc3A9urW */

import {
	Alert,
	Backdrop,
	Button,
	Grid,
	Paper,
	Slide,
	Stack,
	Step,
	StepLabel,
	Stepper,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { ReviewSubmit } from "./forms/reviewsubmit";
import { EnterInformation } from "./forms/enter-information";
import {
	aw_permissions,
	client_info,
	corporate_permissions,
	iweigh_permissions,
	newuser,
} from "../../utils/interfaces/new-user/new-user-interface";
import {
	CreateAwPerms,
	CreateCorpPerms,
	CreateUserInterface,
	CreateiWeighPerms,
} from "../../utils/interfaces/new-user/create-user-interface";
import { SelectUserType } from "./forms/selectusertype";
import { checkUserAvailability } from "../../utils/requests/new-user/get-users-available";
import { SetUserPerms } from "./forms/set-user-perms";
import { ClientSelectionCreation } from "./forms/client-selection-creation";
import { PostAndError } from "./forms/post-and-error";
import { AppContext } from "../../utils/themes/theme-context";
import { userAvailability } from "../../utils/interfaces/new-user/user-availability-interface";
import {
	EnterInformationAvailability,
	EnterInformationErrors,
} from "../../utils/interfaces/new-user/enter-information-interfaces";
import { SetUserPermsV2 } from "./forms/set-user-perms-v2";

const aw_steps = [
	"Select User Type",
	"Enter Information",
	"Set User Permissions",
	"Review & Submit",
];

const corp_steps = [
	"Select Client",
	"Enter Information",
	"Set User Permissions",
	"Review & Submit",
];

export const NewUserStepper = (props: {
	curusertype: string;
	curclientid: string;
}) => {
	var { perms, theme } = useContext(AppContext);
	const newUser = useRef<newuser>(CreateUserInterface());

	const [activeStep, setActiveStep] = useState<number>(0);
	const [direction, setDirection] = useState<"left" | "right">("left");
	const [alert, setAlert] = useState<string>("");
	const [awperms, setAwPerms] = useState<aw_permissions>(CreateAwPerms());
	const [posting, setPosting] = useState<boolean>(false);
	const [corpperms, setCorpPerms] = useState<corporate_permissions>(
		CreateCorpPerms()
	);
	const [iweighperms, setiWeighPerms] = useState<iweigh_permissions>(
		CreateiWeighPerms()
	);
	const [nextbutton, setNextButton] = useState<boolean>(true); //true means disabled, false means not disabled
	const [curclientname, setCurClientName] = useState<string>(""); //used to avoid a call to the backend in review and submit
	const [slidein, setSlideIn] = useState<boolean>(true);

	// Add this code within the component function
	useEffect(() => {
		// Check if the user type is "corp"
		if ( perms.user_type === "corp") {
			// Set activeStep to 2 to skip directly to Step 2 
			setActiveStep(1);
		} 
	}, []); 

	//gets form based on activestep and usertype
	function getForm(activeStep: number, curusertype: string) {
		switch (activeStep) {
			case 0:
				return curusertype === "corp" ? (
					<>
						<ClientSelectionCreation
							nextButtonCallback={setNextButton}
							setClientCallback={setClient}
							enterInformationDomain={enterInformationDomain}
							setClientId={setClientId}
							validateAndSetError={validateAndSetError}
							newUser={newUser.current}
							setCurClientName={setCurClientName}
							handleNext={handleNext}
						/>
					</>
				) : (
					<SelectUserType
						SelectUserTypeCallback={selectUserTypeCallback}
						newUser={newUser.current}
						nextButtonCallback={updateNextButton}
						setClientCallback={setClient}
						enterInformationDomain={enterInformationDomain}
						setClientId={setClientId}
						validateAndSetError={validateAndSetError}
						setCurClientName={setCurClientName}
						handleNext={handleNext}
					/>
				);
			case 1:
				return (
					<EnterInformation
						username={enterInformationUsername}
						name={enterInformationName}
						email={enterInformationEmail}
						newUser={newUser.current}
						nextButtonCallback={updateNextButton}
						processAvailabilityResponse={processAvailabilityResponse}
						validateAndSetError={validateAndSetError}
					/>
				);
			case 2:
				return (
					<SetUserPermsV2 
					usertype={newUser.current.user_type}  
					user={newUser.current}
					permissionscallback={(user: newuser) => {
						permsDataCallback(user.aw_permissions, user.corporate_permissions, user.iweigh_permissions);
						}}
					nextButtonCallback={updateNextButton}
					/>
				);
			case 3:
				return (
					<ReviewSubmit
						newUser={newUser.current}
						nextButtonCallback={updateNextButton}
						newActiveStep={newActiveStep}
						curclientname={curclientname}
					/>
				);
			default:
				return null;
		}
	}

	//extra validation for enter info - prevents someone from spamming next button on incorrect
	// input before timeout verifies new input is incorrect
	const validateEnterInfo = async () => {
		if (newUser.current.user_email && newUser.current.username) {
			const response: userAvailability = await checkUserAvailability(
				newUser.current.user_email,
				newUser.current.username
			);
			if (
				response.email_available === false ||
				response.username_available === false
			) {
				setAlert("Username Or Email Unavailable");
				return false;
			}
			return true;
		}
		setAlert("Please Fill Out Form");
		return false;
	};

	const checkNext = async () => {
		let isValid = false;
		if (activeStep === 1) {
			isValid = await validateEnterInfo();
			return isValid;
		} else if (activeStep === 2) {
			// setUserPermsOnNext();
			return true;
		} else if (activeStep === 3) {
			setPosting(true);
			return true;
		} else {
			return true;
		}
	};

	const handleNext = async () => {
		setDirection("right");
		setSlideIn(false);

		const isValid = await checkNext();
		if (isValid) {
			setTimeout(() => {
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
				setAlert("");
				setNextButton(true);
				setDirection("left");
				setSlideIn(true);
			}, 400);
		} else {
			console.log("Invalid or blank entry");
		}
	};

	const handleBack = () => {
		setDirection("left");
		setSlideIn(false);

		setTimeout(() => {
			setActiveStep((prevActiveStep) => prevActiveStep - 1);
			setAlert("");
			setNextButton(true);
			setDirection("right");
			setSlideIn(true);
		}, 400);
	};

	//general alert for all errors in input
	const showAlert = () => {
		if (alert) {
			return <Alert severity="error">{alert}</Alert>;
		}
	};

	//when usertype changes, permissions reset to avoid security issues
	function resetPermissions() {
		const tempcorpperms = CreateCorpPerms();
		const tempawperms = CreateAwPerms();
		setCorpPerms(tempcorpperms);
		setAwPerms(tempawperms);
		newUser.current.corporate_permissions = corpperms;
		newUser.current.aw_permissions = awperms;
	}

	function selectUserTypeCallback(usertype: string) {
		newUser.current.user_type = usertype;
		resetPermissions();
	}

	function enterInformationUsername(string: string) {
		newUser.current.username = string;
	}

	function enterInformationName(string: string) {
		newUser.current.user_name = string;
	}

	function enterInformationEmail(string: string) {
		newUser.current.user_email = string;
	}

	function enterInformationDomain(string: string) {
		newUser.current.user_domain = string;
	}

	function updateNextButton(bool: boolean) {
		setNextButton(bool);
	}

	function setClientId(string: string) {
		newUser.current.client_id = string;
	}

	function newActiveStep(number: number) {
		setActiveStep(number);
	}

	function setClient(newclient: client_info) {
		newUser.current.client_info.client = newclient.client;
		newUser.current.client_info.comments = newclient.comments;
		newUser.current.client_info.contact = newclient.contact;
		newUser.current.client_info.contact_email = newclient.contact_email;
		newUser.current.client_info.contact_phone = newclient.contact_phone;
	}

	//called by textfield verification, regex verification
	function validateInput(input: string, regex: Function, errorMessage: string) {
		return regex(input, errorMessage);
	}

	//sets errors and calls validate input to process whenever a textfield is changed
	function validateAndSetError(
		input: string,
		regex: Function,
		errorMessage: string,
		key: string,
		setErrors: Function
	) {
		const error = input ? validateInput(input, regex, errorMessage) : "";
		setErrors((prevState: EnterInformationErrors) => ({
			...prevState,
			[key]: error,
		}));
		return error === "";
	}

	//callback for enter information
	function processAvailabilityResponse(
		response: userAvailability,
		setAvailability: Function,
		availability: EnterInformationAvailability,
		setErrors: Function
	) {
		if (
			response.email_processed === true &&
			response.email_available === true &&
			response.username_processed === true &&
			response.username_available === true
		) {
			setAlert("");
			setAvailability({
				...availability,
				usernameavailability: true,
				emailavailability: true,
			});
		} else if (
			response.email_processed === true &&
			response.email_available === false &&
			response.username_processed === true &&
			response.username_available === false
		) {
			setAlert("Username and Email Unavailable");
			setErrors((prevState: EnterInformationErrors) => ({
				...prevState,
				errortextUsername: "Username Unavailable",
				errortextEmail: "Email Unavailable",
			}));
			setAvailability({
				...availability,
				usernameavailability: false,
				emailavailability: false,
			});
		} else if (
			response.email_processed === true &&
			response.email_available === false
		) {
			setAlert("Email Unavailable");
			setErrors((prevState: EnterInformationErrors) => ({
				...prevState,
				errortextEmail: "Email Unavailable",
			}));
			setAvailability({ ...availability, emailavailability: false });
		} else if (
			response.username_processed === true &&
			response.username_available === false
		) {
			setAlert("Username Unavailable");
			setErrors((prevState: EnterInformationErrors) => ({
				...prevState,
				errortextUsername: "Username Unavailable",
			}));
			setAvailability({ ...availability, usernameavailability: false });
		} else if (
			response.email_processed === true &&
			response.email_available === true
		) {
			setAlert("");
			setAvailability({ ...availability, emailavailability: true });
		} else if (
			response.username_processed === true &&
			response.username_available === true
		) {
			setAlert("");
			setAvailability({ ...availability, usernameavailability: true });
		}
	}

	const [permsData, setPermsData] = useState<newuser>(CreateUserInterface());

	const permsDataCallback = (
	awpermissions: {
		[key: string]: boolean;
	},
	corporatepermissions: {
		[key: string]: boolean;
	},
	iweighpermissions: {
		[key: string]: boolean;
	}
	) => {

	setPermsData((prevPermsData) => {
		const tempNewUser = { ...prevPermsData };
		
		// Initialize permissions with values from CreateUserInterface()
		tempNewUser.aw_permissions = CreateUserInterface().aw_permissions;
		tempNewUser.corporate_permissions = CreateUserInterface().corporate_permissions;
		tempNewUser.iweigh_permissions = CreateUserInterface().iweigh_permissions;

		// Update with the provided values
		tempNewUser.aw_permissions = { ...tempNewUser.aw_permissions, ...awpermissions };
		tempNewUser.corporate_permissions = { ...tempNewUser.corporate_permissions, ...corporatepermissions };
		tempNewUser.iweigh_permissions = { ...tempNewUser.iweigh_permissions, ...iweighpermissions };
		return tempNewUser;
	});
	};

	return (
		<>
			<Grid container spacing={3}>
				<Grid item xs={12} marginTop={3}>
					<Stepper activeStep={activeStep}>
						{(props.curusertype === "aw" ? aw_steps : corp_steps).map(
							(label) => {
								return (
									<Step key={label}>
										<StepLabel>{label}</StepLabel>
									</Step>
								);
							}
						)}
					</Stepper>
				</Grid>
				<Grid item xs={12}>
					{showAlert()}
				</Grid>
				{activeStep === aw_steps.length ? null : (
					<>
						<Grid item xs={12}>
							<div style={{ position: "relative", paddingTop: 20 }}>
								<Slide
									key={activeStep}
									direction={direction}
									in={slidein}
									timeout={400}
									mountOnEnter
									unmountOnExit
								>
									<Paper elevation={4}>
										{getForm(activeStep, props.curusertype)}
									</Paper>
								</Slide>
							</div>
						</Grid>
						<Grid item xs={12}>
							<Stack
								direction="row"
								spacing={2}
								display={"flex"}
								justifyContent={"center"}
								alignContent={"center"}
								marginBottom={15}
							>
								<Button
									color={theme.backbuttoncolor}
									disabled={activeStep === 0 || activeStep === 1}
									onClick={handleBack}
									variant="contained"
								>
									Back
								</Button>
								<Button
									color={theme.nextbuttoncolor}
									disabled={nextbutton}
									onClick={
										activeStep === 3 ? () => setPosting(true) : handleNext
									}
									variant="contained"
								>
									Next
								</Button>
							</Stack>
						</Grid>
					</>
				)}
			</Grid>
			<Backdrop open={posting}>
				<PostAndError
					newActiveStep={newActiveStep}
					newUser={newUser.current}
					setPosting={setPosting}
				/>
			</Backdrop>
		</>
	);
};
