import { Auth } from "aws-amplify";
import { buildBaseUrl } from "../build-base-url";
import { PostNewClientData } from "../../interfaces/clients/post-new-client-interface";

export const postNewClient = async (data: PostNewClientData) => {
	const url = buildBaseUrl()
	const authData = await Auth.currentAuthenticatedUser();
	
	const response = await fetch(`${url}clients`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${authData.signInUserSession.accessToken.jwtToken}`,
		},
		body: JSON.stringify(data),
	});

	return response;
};
