import {
	Alert,
	AlertTitle,
	Box,
	Card,
	CardContent,
	CardHeader,
	Divider,
	IconButton,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useEffect, useState } from "react";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { rfcToDateAndTime } from "../../../utils/custom-functions/rfc3339-conversions";
import { IntegratorStatsInterface } from "../../../utils/interfaces/integration/integrator-stats-interface";
import { getIntergratorStats } from "../../../utils/requests/integraton/get-integrators-stats";

export default function IntegratorStats(props: { intergratorId: string }) {
	const [statsData, setStatsData] = useState<IntegratorStatsInterface | null>(null); // State to hold fetched data
	const [error, setError] = useState<string | null>(null);

	// Function to fetch client stats when component mounts
	const fetchStatsData = async () => {
		try {
			const intergratorId = props.intergratorId;
			const data = await getIntergratorStats(intergratorId);
			setStatsData(data); // Set the fetched data into state
			setError(null); // Clear any previous errors
		} catch (error: any) {
			if (error.response) {
				const { status, data } = error.response; // Destructure 'status' and 'data' from the 'response' property of the 'error' object.
				if (status === 400) {
					setError(`Bad Request: ${data.errors ? data.errors[0] : 'Invalid request'}`);
				} else if (status === 401) {
					setError(`Unauthorized: ${data.errors ? data.errors[0] : 'Unauthorized access'}`);
				} else if (status === 403) {
					setError(`Forbidden: ${data.errors ? data.errors[0] : 'Access denied'}`);
				} else if (status === 500) {
					setError(`Server Error: ${data.errors ? data.errors[0] : 'Internal server error'}`);
				} else {
					setError('An error occurred');
				}
			} else {
				setStatsData(null); // Reset statsData to null on error
				setError('Failed to fetch integrator stats'); // Generic error message
			}
		}
	};

	// Fetch data when component mounts
	useEffect(() => {
		fetchStatsData();
	}, [props.intergratorId]);

	return (
		<>
			<Card elevation={8} sx={{ borderRadius: 3 }}>
				<CardHeader title="Stats" action={
					<IconButton aria-label="info">
						<InfoOutlinedIcon />
					</IconButton>
				}
				/>
				<CardContent>
					{error ? (
						<Alert severity="error" sx={{ marginBottom: 2 }}>
							<AlertTitle>Error</AlertTitle>
							{error}
						</Alert>
					) : (
						<><Divider />
							<Stack direction={"row"} spacing={12} marginTop={2} marginLeft={8} marginRight={8}>
								<Stack direction={"column"} spacing={2} alignItems={"center"} flex={1}>
									<Typography>Total Devices</Typography>
									<Divider flexItem orientation="horizontal" />
									<Box sx={{ display: 'flex', alignItems: 'center' }}>
										<Box
											sx={{
												borderRadius: "20%",
												padding: "10px",
											}}
										>
											<Typography>{statsData?.total_devices.total}</Typography>
										</Box>
										<Tooltip title={statsData?.total_devices.date_time !== undefined
											? rfcToDateAndTime(statsData?.total_devices.date_time)
											: ''} placement="right">
											<CalendarTodayIcon color="inherit" fontSize="small" sx={{ marginLeft: 1 }} />
										</Tooltip>
									</Box>
								</Stack>
							</Stack></>
					)}
				</CardContent>
			</Card>
		</>
	);
}