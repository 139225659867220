import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  LinearProgress,
  Skeleton,
  Slide,
  Stack,
  Typography,
} from "@mui/material";
import { AppContext } from "../../utils/themes/theme-context";
import { getVehicleWeights } from "../../utils/requests/vehicles-routes/get-vehicle-weights";
import { ClientVehicleData } from "../../utils/interfaces/clients/clientvehicles-interface";
import { Columns } from "../../utils/interfaces/table/table-interfaces";
import GeneralTable from "../../utils/generic-components/table/table";
import { SingleVehicle } from "../../utils/interfaces/vehicles/vehicles-interfaces";
import { getClientVehiclesMap } from "../../utils/requests/clients/getclientvehicles-map";
import { getDevices } from "../../utils/requests/devices/get-devices-details";
import { DevicesDetails } from "../../utils/interfaces/vehicles/devices-details-interface";
import { getDeviceCalibration } from "../../utils/requests/devices/get-device-calibration";
import { DeviceCalibration } from "../../utils/interfaces/vehicles/device-calibration-interface";
import CurrentDeviceCalibration from "./device-page-elements/current-device-calibration";
import CurrentDeviceConfiguration from "./device-page-elements/current-device-configuration";
import Details from "./device-page-elements/details";
import RegistrationDevice from "./device-page-elements/registration-device";
import FirmwareDevice from "./device-page-elements/firmware-device";
import AllDeviceCalibration from "./device-page-elements/device-all-calibrations";
import AllDeviceConfiguration from "./device-page-elements/device-all-configuration";
import AllDeviceDiagnostics from "./device-page-elements/device-all-diagnostics";
import DeviceDiagnosticsMostRecent from "./device-page-elements/device-diagnostics-most-recent";
import DeviceAction from "./device-page-elements/device-action";

let columns: Columns = {
  FirmwareVersion: "Firmware Version",
  // OpenTickets: "Open Tickets",
  // Product: {
  //   type: "text",
  //   display: "Product",
  //   data: "",
  //   sort: "Product",
  // },
  UpdatedAt: {
    type: "time",
    display: "Updated At",
    data: "",
    sort: "UpdatedAt",
  },
  VehicleName: {
    type: "link",
    display: "Vehicle Name",
    data: "",
    sort: "VehicleName",
  },
  VehicleRegistrationDate: {
    type: "time",
    display: "Vehicle Registration Date",
    data: "",
    sort: "VehicleRegistrationDate",
  },
  VehicleUpdatedAt: {
    type: "time",
    display: "Vehicle Updated At",
    data: "",
    sort: "VehicleUpdatedAt",
  },
  LatestWeight: "Latest Weight",
  // YearTotalTickets: "Year Total Tickets",
};

export function SingleDeviceDetailsPage() {
  var { theme } = useContext(AppContext);
  const { id } = useParams<{ id?: string }>();
  const [devicesDetails, setDevicesDetails] = useState<DevicesDetails | null>(
    null
  );
  let userNavigate = useNavigate();
  const [transition, setTransition] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const [errorStringMessage, setErrorStringMessage] = useState<string | null>(
    null
  );
  const [updatedat, setUpdatedAt] = useState<string>("");
  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const [suspending, setSuspending] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [activeButton, setActiveButton] = useState<string>("Home");
  const [displayDetailsTable, setDisplayDetailsTable] = useState<boolean>(true);
  const [displayConfiguration, setDisplayConfiguration] =
    useState<boolean>(false);
  const [displayCalibration, setDisplayCalibration] = useState<boolean>(false);
  const [displayDiagnostics, setDisplayDiagnostics] = useState<boolean>(false);
  const [displayAlerts, setDisplayAlerts] = useState<boolean>(false);
  // const [displayTickets, setDisplayTickets] = useState<boolean>(false);
  const [displayLogs, setDisplayLogs] = useState<boolean>(false);
  const [vehicles, setVehicles] = useState<ClientVehicleData[]>([]); // Initialize as an empty array
  let { client_id, vehicle_id } = useParams<{
    client_id?: string;
    vehicle_id?: string;
  }>(); //change for alerts and faults its just a placeholder used from vehicles page
  const [serialNumber, setserialNumber] = useState<string>();
  const [deviceCalibrationData, setDeviceCalibrationData] = useState<
    DeviceCalibration[] | null
  >();
  const [registeredclientName, setRegisteredClientName] = useState<string>("");
  const [unregisteredclientName, setUnRegisteredClientName] = useState<string>("");

  useEffect(() => {
    if (id) {
      //check if id is defined
      getDevices(id)
        .then((data: DevicesDetails) => {
          setDevicesDetails(data);
          setserialNumber(data.serial);
          setUpdatedAt(data.updated_at); //updated time
        })
        .catch((error) => {
          setErrorMessage(true);
        });
    }
    //refresh data when id/ registeredclientName/ unregisteredclientName changes
  }, [id, registeredclientName, unregisteredclientName]);

  useEffect(() => {
    // Fetch device calibration data whenever the serial number changes
    const fetchDeviceCalibrationData = async () => {
      try {
        if (serialNumber) {
          const response = await getDeviceCalibration(serialNumber);
          if (
            response.errors &&
            response.errors.length > 0 &&
            response.errors[0] === "unsupported product feature"
          ) {
            setErrorStringMessage("Unsupported Product Feature.");
            setDeviceCalibrationData(null);
          } else {
            setDeviceCalibrationData(response.data);
            setErrorStringMessage(null); // Clear any previous error message
          }
        }
      } catch (err) {
        setDeviceCalibrationData(null);
      }
    };

    fetchDeviceCalibrationData(); // Fetch device calibration data when the serial number changes
  }, [serialNumber]);

  if (!devicesDetails) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  const fetchClientVehiclesWeights = async () => {
    if (id) {
      try {
        const response = await getClientVehiclesMap(id);
        return response;
      } catch (err) { }
    }
  };

  function handleBack(): void {
    setTransition(false);
    setTimeout(() => userNavigate(`/device`), 400);
  }

  const loadingDisplay = () => {
    if (!devicesDetails) {
      return (
        <>
          <LinearProgress />
          <Stack direction="row" spacing={3} marginTop={5}>
            <Stack direction="column" spacing={3}>
              <Skeleton variant="rectangular" width={275} height={500} />
              <Skeleton variant="rectangular" width={275} height={200} />
            </Stack>
            <Divider flexItem orientation="vertical" />
            <Stack direction="column" spacing={3}>
              <Skeleton variant="rectangular" width={700} height={200} />
              <Skeleton variant="rectangular" width={700} height={400} />
            </Stack>
            <Stack direction="column" spacing={3}>
              <Skeleton variant="rectangular" width={275} height={150} />
              <Skeleton variant="rectangular" width={275} height={100} />
            </Stack>
          </Stack>
        </>
      );
    }
    return null;
  };

  const handleButtonClick = async (buttonName: string) => {
    setActiveButton(buttonName);
    setDisplayDetailsTable(buttonName === "Home");
    setDisplayConfiguration(buttonName === "Configuration");
    setDisplayCalibration(buttonName === "Calibration");
    setDisplayDiagnostics(buttonName === "Diagnostics");
  };

  const fetchWeightsAlert = async () => {
    //change for alerts and faults its just a placeholder used from vehicles page
    try {
      const response = await getVehicleWeights(client_id, vehicle_id);
      return response;
    } catch (err) { }
  };

  function handleRowClick(selectedvehicle: SingleVehicle) {
    setTransition(false);
    setTimeout(
      () => userNavigate(`/vehicles/${selectedvehicle.VehicleId}`),
      400
    );
  }

  // Define a function to receive the registered client name
  const handleRegisteredClientName = (clientName: string) => {
    setRegisteredClientName(clientName);
  };

  const handleUnRegisterClientName = (clientName: string) => {
    setUnRegisteredClientName(clientName)
  }

  return (
    <>
      <Slide direction="left" in={transition} timeout={400}>
        <Box
          flexGrow={1}
          marginLeft={"2%"}
          marginRight={"2%"}
          marginTop={8}
          marginBottom={10}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <h2>Device Details</h2>
            </Grid>
            <Grid item xs={6}>
              <Box
                display="flex"
                justifyContent="flex-end"
                marginTop={3}
                marginRight={2}
              >
                <Button
                  color={theme.backbuttoncolor}
                  onClick={handleBack}
                  variant={"contained"}
                >
                  Back
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Divider />
          <Grid>
            <Box
              display="flex"
              justifyContent="flex-start"
              marginTop={1}
              marginBottom={1}
            >
              <Button
                variant="contained"
                style={{
                  marginRight: "10px",
                  background:
                    activeButton === "Home"
                      ? theme.selectedbuttoncolor
                      : theme.nonactivebuttoncolor,
                }}
                onClick={() => handleButtonClick("Home")}
              >
                Home
              </Button>
              <Button
                variant="contained"
                style={{
                  marginRight: "10px",
                  background:
                    activeButton === "Configuration"
                      ? theme.selectedbuttoncolor
                      : theme.nonactivebuttoncolor,
                }}
                onClick={() => handleButtonClick("Configuration")}
              >
                Configuration
              </Button>
              <Button
                variant="contained"
                style={{
                  marginRight: "10px",
                  background:
                    activeButton === "Calibration"
                      ? theme.selectedbuttoncolor
                      : theme.nonactivebuttoncolor,
                }}
                onClick={() => handleButtonClick("Calibration")}
              >
                Calibration
              </Button>
              <Button
                variant="contained"
                style={{
                  marginRight: "10px",
                  background:
                    activeButton === "Diagnostics"
                      ? theme.selectedbuttoncolor
                      : theme.nonactivebuttoncolor,
                }}
                onClick={() => handleButtonClick("Diagnostics")}
              >
                Diagnostics
              </Button>
            </Box>
          </Grid>
          <Divider />
          <>
            {displayConfiguration ? (
              <Box padding={2}>
                <Typography variant="h4">Configuration Data</Typography>
                <AllDeviceConfiguration device={devicesDetails} />
              </Box>
            ) : displayCalibration ? (
              <Box padding={2}>
                <Typography variant="h4">Calibration Data</Typography>
                <AllDeviceCalibration device={devicesDetails} />
              </Box>
            ) : displayDiagnostics ? (
              <Box padding={2}>
                <Typography variant="h4">Diagnostics Data</Typography>
                <AllDeviceDiagnostics device={devicesDetails} />
              </Box>
            ) : errorMessage ? (
              <Box paddingTop={2} color="red">
                <Alert severity="error">Invalid Client</Alert>
              </Box>
            ) : displayDetailsTable || devicesDetails ? (
              <>
                <Stack
                  direction="row"
                  spacing={3}
                  marginTop={5}
                  marginBottom={10}
                >
                  <Stack direction="column" spacing={3} width="30%">
                    <Details device={devicesDetails} registeredclientName={registeredclientName} unregisteredclientName={unregisteredclientName} />
                    <DeviceAction device={devicesDetails} onRegisteredClientName={handleRegisteredClientName} onUnRegisteredClientName={handleUnRegisterClientName} />
                  </Stack>
                  <Divider flexItem orientation="vertical" />
                  <Stack direction="column" spacing={3} width="70%">
                    <Stack direction="row" spacing={3}>
                      <RegistrationDevice device={devicesDetails} registeredclientName={registeredclientName} unregisteredclientName={unregisteredclientName} />
                      <FirmwareDevice device={devicesDetails} />
                    </Stack>
                    <Stack direction="row" spacing={3}>
                      <CurrentDeviceConfiguration device={devicesDetails} />
                    </Stack>
                    <Stack direction="row" spacing={3}>
                      <CurrentDeviceCalibration device={devicesDetails} />
                    </Stack>
                    <Stack direction="row" spacing={3}>
                      <DeviceDiagnosticsMostRecent device={devicesDetails} />
                    </Stack>
                  </Stack>
                </Stack>
              </>
            ) : (
              loadingDisplay()
            )}
          </>
        </Box>
      </Slide>
    </>
  );
}
