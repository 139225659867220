import { TableCell, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../themes/theme-context";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import LinkIcon from "@mui/icons-material/Link";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { ColumnObject } from "../../interfaces/table/table-interfaces";

export function ColumnCell(props: {
	column: string | ColumnObject;
	columnSortCallback: Function;
	currentSortedColumn: string; //if column and this match, arrow stays, if not it gets cleared
	sortOrder: number; // 0: no sort, 1: ascending, 2: descending
}) {
	var { theme } = useContext(AppContext);
	const [isHovered, setIsHovered] = useState(false);
	let columnValue: string =
		typeof props.column === "string" ? props.column : props.column.display;
	const [sort, setSort] = useState<number>(0); //0 is normal, 1 is alph up, 2 is alph down

	//resets sorting number when column isnt being sorted anymore
	useEffect(() => {
		if (columnValue !== props.currentSortedColumn) {
			setSort(0);
		}
		else {
			setSort(props.sortOrder);
		}
	}, [props.currentSortedColumn, props.sortOrder]);

	//calls sorting callback whenever sort changes, each case handled there
	useEffect(() => {
		props.columnSortCallback(sort, props.column);
	}, [sort]);

	const handleClick = () => {
		if (sort === 2) {
			setSort(0);
		} else {
			setSort(sort + 1);
		}
	};

	//can be added to in order to account for more .type options
	const displayIcon = () => {
		if (typeof props.column === "string") {
			return null;
		} else if (props.column.type === "link") {
			return <LinkIcon />;
		} else if (props.column.type === "date") {
			return <CalendarTodayIcon />;
		} else if (props.column.type === "time") {
			return <AccessTimeIcon />;
		}
	};

	//if not being sorted, return nothing. if not switch governs what is shown
	const displayArrow = () => {
		if (columnValue !== props.currentSortedColumn) {
			return <></>;
		}
		switch (sort) {
			case 0:
				return <></>;
			case 1:
				return <ArrowUpwardIcon />;
			case 2:
				return <ArrowDownwardIcon />;
			default:
				return <></>;
		}
	};

	return (
		<>
			<TableCell
				onClick={handleClick}
				key={columnValue}
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
				style={{ backgroundColor: isHovered ? theme.primary : "transparent" }}
			>
				<Stack direction={"row"} spacing={2} color={"#FFFFFF"}>
					<Typography>{columnValue}</Typography>
					{displayIcon()}
					{displayArrow()}
				</Stack>
			</TableCell>
		</>
	);
}
