import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import IconButton from "@mui/material/IconButton";
import { useContext } from "react";
import { AppContext } from "../../../utils/themes/theme-context";
import { DashboardStats, ProductList } from "../../../utils/interfaces/dashboard/dashboard-stats-interface";
import { rfcToDateAndTime } from "../../../utils/custom-functions/rfc3339-conversions";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import GeneralTable from "../../../utils/generic-components/table/table";
import { Columns } from "../../../utils/interfaces/table/table-interfaces";
import NestedTable from "../../../utils/generic-components/table/nested-table";

export default function ProductDashboardCard(props: { dashboardStats: DashboardStats }) {
    var { theme } = useContext(AppContext);

    let column: Columns = {
        ProductName: {
            type: "text",
            display: "Product Name",
            sort: "ProductName"
        },
        Stats: {
            type: "text",
            display: "Stats",
            data: "stat",
            subColumns: {
                DateTime: {
                    type: "time",
                    display: "Date and Time",
                    data: "date_time",
                },
                Total: {
                    type: "text",
                    display: "Total",
                    data: "total",
                }
            }
        }
    }

    return (
        <>
            <Card elevation={8} sx={{ borderRadius: 3 }}>
                <CardHeader
                    title="Product Stats"
                    action={
                        <IconButton aria-label="info">
                            <InfoOutlinedIcon />
                        </IconButton>
                    }
                />
                <CardContent>
                    <Divider />
                    <GeneralTable
                        data={props.dashboardStats.product_list.map((prodlist: ProductList) => ({
                            ProductName: prodlist.product_name,
                            Stats: (
                                <NestedTable
                                key={prodlist.product_id}
                                data={{
                                    ...prodlist.stat,
                                    date_time: rfcToDateAndTime(prodlist.stat.date_time) // Apply date formatting here
                                }}
                                columns={column.Stats.subColumns}
                                />
                            )
                        }))}
                        columns={column}
                        noDataMessage={"No Devices to fetch "}
                        rows={10}
                        rowsoptions={[10, 25, 50, 100]}
                    />
                </CardContent>
            </Card>
        </>
    );
}