  // Function to scroll to the top of the page
  export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

 // Function to scroll to the bottom of the page
export const scrollToBottom = () => {
  window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
};

// Event listener for showing/hiding scroll buttons
export const handleScroll = (
  setShowScrollTopButton: (value: boolean) => void,
  setShowScrollBottomButton: (value: boolean) => void
) => {
  if (window.scrollY > 100) {
    setShowScrollTopButton(true);
  } else {
    setShowScrollTopButton(false);
  }

  if (window.innerHeight + window.scrollY < document.body.offsetHeight - 100) {
    setShowScrollBottomButton(true);
  } else {
    setShowScrollBottomButton(false);
  }
};
