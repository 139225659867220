import { useContext, useState } from "react";
import { AppContext } from "../../themes/theme-context";
import {
  Typography,
  TableRow,
  TableCell,
  Menu,
  MenuItem,
  Checkbox,
  Button,
} from "@mui/material";
import {
  ColumnObject,
  Columns,
  Data,
  Row,
} from "../../interfaces/table/table-interfaces";
import { ColorButton } from "../../themes/color-button";
import { rfcToDateAndTime } from "../../custom-functions/rfc3339-conversions";

/* takes 1 row, columns, and index at row and returns a tablerow containing the contents of row, rowclickcallback functionality*/
export function RowsHelper(props: {
  rowelement: Row;
  columns: Columns;
  i: number;
  rowClickCallback: Function | undefined;
  rowNewTabClickCallback?: Function;
  data: Data;
  multiselect: boolean | undefined;
  handleRowSelect: (row: any) => void;
  isSelected: boolean;
  currentPage?: Function;
}) {
  var { theme } = useContext(AppContext);
  const [isHovered, setIsHovered] = useState(false);
  const [contextMenuAnchor, setContextMenuAnchor] =
    useState<null | HTMLElement>(null);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);

  const handleContextMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setContextMenuAnchor(event.currentTarget);
    setIsContextMenuOpen(true); // Set the flag to indicate that the context menu is open
  };

  const handleCloseContextMenu = () => {
    setContextMenuAnchor(null);
    setIsContextMenuOpen(false); // Reset the flag when the context menu is closed
  };

  const openLinkInNewTab = () => {
    handleCloseContextMenu();
    const column = Object.values(props.columns).find((col) => {
      return typeof col === "object" && col.type === "link";
    }) as ColumnObject;
    if (column) {
      if (props.rowNewTabClickCallback) {
        props.rowNewTabClickCallback(props.rowelement, column); // Pass the link URL to the callback
      }
    }
  };

  const displayCellWithType = (
    key: string | undefined,
    column: ColumnObject
  ) => {
    //more cases can be added to this for different types
    if (column.type === "link") {
      return (
        <div
          onClick={() => {
            if (!isContextMenuOpen && props.rowClickCallback) {
              props.rowClickCallback(props.rowelement, column);
            }
          }}
          onContextMenu={handleContextMenu}
          style={{ cursor: "context-menu" }}
        >
          <ColorButton>
            <Typography>{key ? props.rowelement[key] : ""}</Typography>
          </ColorButton>
          <Menu
            anchorEl={contextMenuAnchor}
            open={Boolean(contextMenuAnchor)}
            onClose={handleCloseContextMenu}
          >
            <MenuItem onClick={openLinkInNewTab}>Open in new tab</MenuItem>
          </Menu>
        </div>
      );
    } else if (column.type === "time") {
      return (
        <Typography>
          {key ? rfcToDateAndTime(props.rowelement[key]) : ""}
        </Typography>
      );
    } else if (column.type === "button") {
      return (
        <Button style={{ fontSize: "1.0rem" }}
          onClick={() => {
            if (column.onClick && typeof column.onClick === "function") {
              column.onClick(props.rowelement);
            }
          }}
        >
          {key ? props.rowelement[key] : ""}
        </Button>
      );
    } else {
      //catches if type is there but not implemented specifically yet
      return <Typography>{key ? props.rowelement[key] : ""}</Typography>;
    }
  };

  return (
    <TableRow
      key={props.i}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ backgroundColor: isHovered ? theme.secondary : "transparent" }}
    >
      {Object.values(props.columns).map(
        (column: string | ColumnObject, i: number) => {
          const key = Object.keys(props.columns).find(
            (value) => props.columns[value] === column
          );
          return (
            <TableCell key={`${props.i}-${key}`}
              style={{
                whiteSpace: 'nowrap', // Prevents text wrapping
                overflow: 'visible', // Hides overflow content
                textOverflow: 'ellipsis', // Add ellipsis if content overflows
              }}
            >
              {typeof column === "string" ? (
                <>
                  <Typography>{key ? props.rowelement[key] : ""}</Typography>
                </>
              ) : (
                displayCellWithType(key, column)
              )}
            </TableCell>
          );
        }
      )}

      {props.multiselect && ( // Render a checkbox cell for multi-select
        <TableCell padding="checkbox">
          <Checkbox
            checked={props.isSelected} // Is the current row selected?
            onChange={() => props.handleRowSelect(props.rowelement.id)} // Pass item ID to handleRowSelect
          />
        </TableCell>
      )}
    </TableRow>
  );
}
