import {
	Alert,
	AlertTitle,
	Backdrop,
	Box,
	CircularProgress,
} from "@mui/material";
import { useContext } from "react";
import { AppContext } from "../../utils/themes/theme-context";

export function determineContent(userdetails: any) {
	if (userdetails) {
		if (userdetails.data) {
			return true;
		} else if (userdetails.errors) {
			return false;
		}
	} else {
		return false;
	}
}

export const AccessDenied = () => {
	var { perms } = useContext(AppContext);

	const displayAlert = () => {
		return (
			<>
				<Alert severity="error">
					<AlertTitle>Unable to Fetch User Permissions</AlertTitle>
					Message Here.
				</Alert>
			</>
		);
	};

	return (
		<>
			<Box
				sx={{ flexGrow: 1, marginLeft: "5%", marginRight: "5%", marginTop: 10 }}
			>
				<Backdrop open={true}>
					<Box sx={{ marginLeft: 40 }}>
						{!perms ? <CircularProgress /> : displayAlert()}
					</Box>
				</Backdrop>
			</Box>
		</>
	);
};
