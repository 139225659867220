import {
  Alert,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  Skeleton,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../utils/themes/theme-context";
import { Columns } from "../../../utils/interfaces/table/table-interfaces";
import GeneralTable from "../../../utils/generic-components/table/table";
import { DevicesDetails } from "../../../utils/interfaces/vehicles/devices-details-interface";
import { getAllDeviceDiagnostics } from "../../../utils/requests/devices/get-all-device-diagnostics";
import { fetchSingleProductData } from "../../../utils/requests/products/get-single-product";
import {
  DiagnosticsRequest,
} from "../../../utils/interfaces/devices/device-diagnostics-interface";
import NestedTable from "../../../utils/generic-components/table/nested-table";

const autoWidthTableStyle = {
  width: "auto", // Set your desired width here
};

let columns: Columns = {
  FirmwareVersion: {
    type: "text",
    display: "Firmware Version",
    data: "firmware_version",
    sort: "FirmwareVersion",
  },
  SignalStrength: {
    type: "text",
    display: "Signal Strength",
    data: "signal_strength",
    sort: "SignalStrength",
  },
  DateAndTime: {
    type: "time",
    display: "Date and Time",
    data: "date_time",
    sort: "DateAndTime",
  },
  AxleGroups: {
    type: "subhead",
    display: "Axle Groups",
    data: "axle_groups",
    subColumns: {
      GroupName: {
        type: "text",
        display: "Group Name",
        data: "group_name",
        sort: "GroupName",
      },
      Weight: {
        type: "number",
        display: "Weight",
        data: "weight",
        sort: "Weight",
      },
      Ratio: {
        type: "number",
        display: "Ratio",
        data: "ratio",
        sort: "Ratio",
      },
      Offset: {
        type: "number",
        display: "Offset",
        data: "offset",
        sort: "Offset",
      },
      SensorReadings: {
        type: "subhead",
        display: "Sensor Readings",
        data: "sensor_readings",
        subColumns: {
          AdValue: {
            type: "number",
            display: "AD Value",
            data: "ad_value",
            sort: "AdValue",
          },
          EmptyAd: {
            type: "number",
            display: "Empty Ad",
            data: "empty_ad",
            sort: "EmptyAd",
          },
          EmptyCal: {
            type: "number",
            display: "Empty Calibration",
            data: "empty_cal",
            sort: "EmptyCal",
          },
          HeavyAd: {
            type: "number",
            display: "Heavy Ad",
            data: "heavy_ad",
            sort: "HeavyAd",
          },
          HeavyCal: {
            type: "number",
            display: "Heavy Calibration",
            data: "heavy_cal",
            sort: "HeavyCal",
          },
          SensorPort: {
            type: "number",
            display: "Sensor Port",
            data: "sensor_port",
            sort: "SensorPort",
          },
        },
      },
    },
  },
};

export default function AllDeviceDiagnostics(props: {
  device: DevicesDetails;
}) {
  var { theme } = useContext(AppContext);
  const [diagnosticSupport, setDiagnosticSupport] = useState<boolean>();
  const [deviceDiagnosticsData, setDeviceDiagnosticsData] = useState<
    DiagnosticsRequest[] | null
  >();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [refreshInterval, setRefreshInterval] = useState(30000); // 30 seconds in milliseconds
  const [lastRefreshed, setLastRefreshed] = useState<string | null>(null);
  var toolbar: boolean = true;

  const deviceid = props.device.id;
  const productidget = props.device.product_id;

  useEffect(() => {
    //Fetch the product id
    const fetchDiagnosticSupport = async () => {
      try {
        const response = await fetchSingleProductData(productidget);
        setDiagnosticSupport(response.diagnostic_support);
      } catch (err) {
        console.log(err);
      }
    };
    fetchDiagnosticSupport();
  }, [productidget]);

  const fetchDeviceDiagnosticsData = async () => {
    try {
      if (deviceid) {
        const response = await getAllDeviceDiagnostics(deviceid);

        if (!response) {
          setErrorMessage("No Data Available");
          setDeviceDiagnosticsData(null);
        } else if (
          response.errors &&
          response.errors.length > 0 &&
          response.errors[0] === "unsupported product feature"
        ) {
          setErrorMessage("Unsupported Product Feature.");
          setDeviceDiagnosticsData(null);
        } else if (
          response.errors &&
          response.errors.length > 0 &&
          response.errors[0] === "database record not found"
        ) {
          setErrorMessage("Database record not found.");
          setDeviceDiagnosticsData(null);
        } else {
          setDeviceDiagnosticsData(response.data);
          // Update the last_refreshed property for the entire table
          const lastRefreshed = new Date().toLocaleString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          });
          setLastRefreshed(lastRefreshed);
          setErrorMessage(null); // Clear any previous error message
        }
      }
    } catch (err) {
      setDeviceDiagnosticsData(null);
    }
  };

  useEffect(() => {
    if (diagnosticSupport === true) {
      // Fetch device Diagnostics data whenever the serial number changes
      fetchDeviceDiagnosticsData();

      // Clear the interval when the component unmounts
      return () => { };
    } else {
      setErrorMessage("Unsupported Product Feature.");
    }
  }, [deviceid, diagnosticSupport]);

  const handleRefreshClick = () => {
    // Manually trigger data refresh when the button is clicked
    fetchDeviceDiagnosticsData();
  };

  const toolbarActions = [
    <div
      style={{
        position: "relative",
        left: 0,
        top: 0,
        zIndex: 1,
        paddingTop: "10px",
        marginBottom: "20px",
      }}
    >
      <Card
        style={{
          background: theme.tertiary,
          color: "white",
        }}
      >
        <CardContent>
          <Typography style={{ fontSize: "16px" }}>
            <strong> Table Last Refresh Time: </strong> <i>{lastRefreshed}</i>
          </Typography>
          <Button
            variant="contained"
            color="success"
            onClick={handleRefreshClick}
            style={{ marginTop: "10px" }}
          >
            Refresh Now
          </Button>
        </CardContent>
      </Card>
    </div>,
  ];

  return (
    <>
      {errorMessage ? (
        <Box>
          <Backdrop
            open={true}
            sx={{
              position: "relative",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          >
            <Alert variant="filled" severity="warning">
              {errorMessage ? errorMessage : null}
            </Alert>
          </Backdrop>
        </Box>
      ) : deviceDiagnosticsData ? (
        <GeneralTable
          data={deviceDiagnosticsData.map((dataItem) => ({
            FirmwareVersion: dataItem.firmware_version,
            SignalStrength: dataItem.signal_strength,
            DateAndTime: dataItem.date_time,
            AxleGroups: (
              <NestedTable
                data={deviceDiagnosticsData[0].axle_groups}
                columns={columns.AxleGroups.subColumns}
              />
            ),
          }))}
          columns={columns}
          toolbar={toolbar}
          toolbarActions={toolbarActions}
          loading={false}
          noDataMessage="No Diagnostics Data"
          customTableStyle={autoWidthTableStyle}
        />
      ) : (
        <Skeleton variant="rectangular" width={650} height={200} />
      )}
    </>
  );
}
