import { Check } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Skeleton,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { AppContext } from "../../../utils/themes/theme-context";
import { useContext, useEffect, useRef, useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { SingleProductFirmwareGET } from "../../../utils/interfaces/products/single-product-details-interface";
import { fetchSingleProductFirmwareData } from "../../../utils/requests/products/get-singleproduct-firmware-data";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Columns,
  Data,
} from "../../../utils/interfaces/table/table-interfaces";
import GeneralTable from "../../../utils/generic-components/table/table";
import { ProductsDevicesTabList } from "../../../utils/interfaces/products/products-devicestab-list";
import { getRecentDevices } from "../../../utils/requests/products/getRecentDevices";

export default function ActiveDevicesProducts() {
  var { theme } = useContext(AppContext);
  const { id, version } = useParams<{ id?: string; version?: string }>();
  const [devicesTabData, setDevicesTabData] = useState<
    ProductsDevicesTabList[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  let userNavigate = useNavigate();

  const autoWidthTableStyle = {
    width: "auto", // Set your desired width here
  };

  let columns: Columns = {
    ID: {
      type: "button",
      display: "Device ID",
      data: "id",
      sort: "ID",
      onClick: (rowData: { ID: any }) => {
        const deviceId = rowData.ID;
        userNavigate(`/device/${deviceId}`);
      },
    },
    InstallationDate: {
      type: "time",
      display: "Installation Date",
      data: "installation_date",
      sort: "InstallationDate",
    },
    RegistrationDate: {
      type: "time",
      display: "Registration Date",
      data: "registration_date",
      sort: "RegistrationDate",
    },
    VehicleId: {
      type: "button",
      display: "Vehicle Id",
      data: "vehicle_id",
      sort: "VehicleId",
      onClick: (rowData: { VehicleId: any }) => {
        const vehicleId = rowData.VehicleId;
        userNavigate(`/vehicles/${vehicleId}`);
      },
    },
    ClientId: {
      type: "button",
      display: "Client Id",
      data: "client_id",
      sort: "ClientId",
      onClick: (rowData: { ClientId: any }) => {
        const clientId = rowData.ClientId;
        userNavigate(`/clients/${clientId}`);
      },
    },
    Serial: {
      type: "text",
      display: "Serial",
      data: "serial",
      sort: "Serial",
    },
    FirmwareId: {
      type: "text",
      display: "Firmware Id",
      data: "firmware_id",
      sort: "FirmwareId",
    },
    UpdatedAt: {
      type: "time",
      display: "Updated At",
      data: "updated_at",
      sort: "UpdatedAt",
    },
  };

  useEffect(() => {
    const fetchDevicesTabData = async () => {
      try {
        setLoading(true);
        const responseData = await getRecentDevices(id, "");
        setDevicesTabData(responseData || []); // Ensure responseData.data is not undefined
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false); // Set loading to false after receiving the response
      }
    };
    fetchDevicesTabData();
  }, [id]);

  const loadingDisplay = () => {
    return (
      <>
        <LinearProgress />
        <Stack spacing={2}>
          <Skeleton variant="rectangular" height={50} animation="wave" />
          <Skeleton variant="rectangular" height={50} animation="wave" />
          <Skeleton variant="rectangular" height={50} animation="wave" />
        </Stack>
      </>
    );
    return null;
  };

  return (
    <>
      <Card elevation={8} sx={{ borderRadius: 3 }}>
        <CardHeader
          title="Active Devices"
          action={
            <IconButton aria-label="info">
              <InfoOutlinedIcon />
            </IconButton>
          }
        />
        <CardContent>
          <Divider />
          <Box paddingTop={2}>
            {loading ? ( // Show skeleton when loading is true
              loadingDisplay()
            ) : devicesTabData && devicesTabData.length === 0 ? (
              <Alert variant="filled" severity="warning">
                <AlertTitle>No devices available for the Product</AlertTitle>
              </Alert>
            ) : (
              <GeneralTable
                data={devicesTabData.map((deviceData) => ({
                  ID: deviceData.id,
                  InstallationDate: deviceData.installation_date,
                  RegistrationDate: deviceData.registration_date,
                  VehicleId: deviceData.vehicle_id,
                  ClientId: deviceData.client_id,
                  Serial: deviceData.serial,
                  FirmwareId: deviceData.firmware_id,
                  UpdatedAt: deviceData.updated_at,
                }))}
                columns={columns}
                noDataMessage={"No Devices to fetch "}
                rows={10}
                rowsoptions={[10, 25, 50, 100]}
                customTableStyle={autoWidthTableStyle}
              />
            )}
          </Box>
        </CardContent>
      </Card>
    </>
  );
}
