import {
	Client,
	getClient,
} from "../../../utils/interfaces/clients/clients-list-interface";
import DetailsTable from "../../../utils/generic-components/Details Table/details-table";
import { DetailTableData } from "../../../utils/interfaces/details-table/details-table-interfaces";
import { rfcToDate, rfcToDateAndTime } from "../../../utils/custom-functions/rfc3339-conversions";
import { putUpdatedClient } from "../../../utils/requests/clients/put-update-clientdetails";
import { fetchLatestClientData, getSingleClient } from "../../../utils/requests/clients/get-single-client";

export default function Details(props: { client: getClient; updatedat: string }) {

	let detailTableData: DetailTableData = {
		data: [
			{
				label: "Name",
				content: props.client.data.client,
				editable: false,
				error: "",
			},
			{
				label: "Service Plan",
				content: props.client.data.service_plan_id,
				editable: false,
				error: "",
			},
			{
				label: "Date Created", //is date created same as registration date
				content: rfcToDateAndTime(props.client.data.registration_date),
				editable: false,
				error: "",
			},
			{
				label: "Contact",
				content: props.client.data.contact,
				editable: true,
				error: "",
			},
			{
				label: "Email",
				content: props.client.data.contact_email,
				editable: true,
				error: "",
			},
			{
				label: "Phone",
				content: props.client.data.contact_phone,
				editable: true,
				error: "",
			},
			{
				label: "Comments",
				content: props.client.data.comments,
				editable: true,
				error: "",
			},
		],
	};

	const putCallback = async (
		data: DetailTableData,
		setAlert: Function,
		setEditingMode: Function,
		setLoadingButton: Function
	) => {
		try{
			const latestClientData = await fetchLatestClientData(props.client.data.id);

			const updatedClient = {
				  contact: data.data[3].content !== null ? data.data[3].content : '', // Assuming index 3 corresponds to 'Contact'
				  contact_email: data.data[4].content !== null ? data.data[4].content : '', // Assuming index 4 corresponds to 'Email'
				  contact_phone: data.data[5].content !== null ? data.data[5].content : '', // Assuming index 5 corresponds to 'Phone'
				  comments: data.data[6].content !== null ? data.data[6].content : '', // Assuming index 6 corresponds to 'Comments'
				  updated_at: props.updatedat,
			  };

			// Include the updated_at value from the latest client data
			updatedClient.updated_at = latestClientData.updated_at;
	

			try {
				const response = await putUpdatedClient(updatedClient, props.client.data.id);

				if (response && response.data) {
					const fetchedUpdatedClient = response.data;		  

					const isUpdated =
						fetchedUpdatedClient.contact === updatedClient.contact &&
						fetchedUpdatedClient.contact_email === updatedClient.contact_email &&
						fetchedUpdatedClient.contact_phone === updatedClient.contact_phone &&
						fetchedUpdatedClient.comments === updatedClient.comments;
				} else {
					console.log("Error updating client data");
				}
			} catch (error) {
				setAlert("Unable to save, Try Again");
			}
		} catch (error) {
			setAlert("Unable to save, Try Again");
		}

		setEditingMode(false);
		setLoadingButton(false);
	};

	return (
		<>
			
			<DetailsTable
				data={detailTableData}
				putCallback={putCallback}
				editing={true}
				close={false}
			/>
		</>
	);
}
