import React, { useContext } from 'react';
import { Theme } from '../../../utils/themes/theme-context';
import { Button, Typography } from '@mui/material';

interface DownloadCSVTemplateProps {
    theme: Theme; 
  }
  
  const DownloadCSVTemplate: React.FC<DownloadCSVTemplateProps> = ({ theme }) => {
    const handleDownloadTemplate = () => {
      // Create and download the CSV template file here.
      const csvTemplateData =
        "username,name,email,users_create,users_edit,users_read,calibration_read,calibration_edit,configuration_profiles_read,configuration_profiles_edit,device_registration,device_unregister,linked_assets_read,linked_assets_edit,preferences_read,preferences_edit,sensor_setup_read,sensor_setup_edit,update_firmware\n";
      const blob = new Blob([csvTemplateData], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "user_template.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    };

    return (
    <div>
      <Typography
        paddingTop={5}
        variant="h5"
        align="center"
        style={{
          marginBottom: "16px",
          borderBottom: "2px solid #304D6D",
          paddingBottom: "8px",
          color: theme.selectedbuttoncolor,
          fontWeight: "bold",
        }}
      >
        Download CSV File Template
      </Typography>
      <Typography variant="subtitle1" align="center">
        Click the button below to download the CSV template file.
      </Typography>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "16px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          style={{
            backgroundColor: theme.selectedbuttoncolor,
            color: "white",
            padding: "10px 15px",
            borderRadius: "5px",
            border: "none",
            cursor: "pointer",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            transition: "background-color 0.3s, transform 0.3s",
            display: "inline-block",
          }}
          onClick={handleDownloadTemplate}
        >
          Download Template
        </Button>
      </div>
    </div>
  );
};

export default DownloadCSVTemplate;
