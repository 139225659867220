import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ServicePlansInterface } from "../../../utils/interfaces/service/service-list-interface";
import { Check } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { AppContext } from "../../../utils/themes/theme-context";
import { LoadingButton } from "@mui/lab";
import { putUpdatedServicePlans } from "../../../utils/requests/serviceplans/put-service-plan";

export default function Features(props: {
  servicePlan: ServicePlansInterface | null;
}) {
  const initialFirmwareUpdates =
    props.servicePlan?.service_plan_features.firmware_updates;
  const initialHeartbeatMonitoring =
    props.servicePlan?.service_plan_features.heartbeat_alerts;
  const initialFaultAlerts =
    props.servicePlan?.service_plan_features.fault_alerts;
  var { theme } = useContext(AppContext);
  const [isFirmwareUpdatesEnabled, setIsFirmwareUpdatesEnabled] = useState(
    initialFirmwareUpdates || false
  );
  const [isHeartbeatMonitoringEnabled, setIsHeartbeatMonitoringEnabled] =
    useState(initialHeartbeatMonitoring || false);
  const [isFaultAlertsEnabled, setIsFaultAlertsEnabled] = useState(
    initialFaultAlerts || false
  );
  const [isChanged, setIsChanged] = useState(false); // Track changes
  const [editingMode, setEditingMode] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);

  const handleToggleFirmwareUpdates = () => {
    setIsFirmwareUpdatesEnabled((prev) => !prev);
    setIsChanged(true); // Set changes flag to true
  };
  const handleToggleHeartbeatMonitoring = () => {
    setIsHeartbeatMonitoringEnabled((prev) => !prev);
    setIsChanged(true); // Set changes flag to true
  };
  const handleToggleFaultAlerts = () => {
    setIsFaultAlertsEnabled((prev) => !prev);
    setIsChanged(true); // Set changes flag to true
  };

  const readOnlyList = () => {
    return (
      <TableBody>
        <TableRow>
          <TableCell>
            <Typography fontWeight="bold">Firmware Updates</Typography>
            <Typography color={theme.subtitle}>
              ACCESS TO THE MAIN DASHBOARD PAGE AND ITS REPORTS
            </Typography>
          </TableCell>
          <TableCell align="right">
            {isFirmwareUpdatesEnabled ? (
              <Chip icon={<Check />} label="True" color="success" />
            ) : (
              <Chip icon={<CloseIcon />} label="False" color="error" />
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography fontWeight="bold">Heartbeat Monitoring</Typography>
            <Typography color={theme.subtitle}>
              ACCESS TO THE USER LIST, ADD, DELETE, SUSPEND, PASSWORD RESET,
              ETC.
            </Typography>
          </TableCell>
          <TableCell align="right">
            {isHeartbeatMonitoringEnabled ? (
              <Chip icon={<Check />} label="True" color="success" />
            ) : (
              <Chip icon={<CloseIcon />} label="False" color="error" />
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography fontWeight="bold">Firmware Alerts</Typography>
            <Typography color={theme.subtitle}>
              ACCESS TO THE INTEGRATION PARTNER LIST, ABILITY TO TRIGGER MANUAL
              UPDATES, REVIEW LOGS, ETC.
            </Typography>
          </TableCell>
          <TableCell align="right">
            {isFaultAlertsEnabled ? (
              <Chip icon={<Check />} label="True" color="success" />
            ) : (
              <Chip icon={<CloseIcon />} label="False" color="error" />
            )}
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  const writeList = () => {
    return (
      <TableBody>
        <TableRow>
          <TableCell>
            <Typography fontWeight="bold">Firmware Updates</Typography>
            <Typography color={theme.subtitle}>
              ACCESS TO THE MAIN DASHBOARD PAGE AND ITS REPORTS
            </Typography>
          </TableCell>
          <TableCell>
            <RadioGroup row>
              <FormControlLabel
                onChange={handleToggleFirmwareUpdates}
                value="true"
                control={<Radio />}
                label="True"
                checked={isFirmwareUpdatesEnabled}
              />
              <FormControlLabel
                onChange={handleToggleFirmwareUpdates}
                value="false"
                control={<Radio />}
                label="False"
                checked={!isFirmwareUpdatesEnabled}
              />
            </RadioGroup>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography fontWeight="bold">Heartbeat Monitoring</Typography>
            <Typography color={theme.subtitle}>
              ACCESS TO THE USER LIST, ADD, DELETE, SUSPEND, PASSWORD RESET,
              ETC.
            </Typography>
          </TableCell>
          <TableCell>
            <RadioGroup row>
              <FormControlLabel
                onChange={handleToggleHeartbeatMonitoring}
                value="true"
                control={<Radio />}
                label="True"
                checked={isHeartbeatMonitoringEnabled}
              />
              <FormControlLabel
                onChange={handleToggleHeartbeatMonitoring}
                value="false"
                control={<Radio />}
                label="False"
                checked={!isHeartbeatMonitoringEnabled}
              />
            </RadioGroup>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography fontWeight="bold">Firmware Alerts</Typography>
            <Typography color={theme.subtitle}>
              ACCESS TO THE INTEGRATION PARTNER LIST, ABILITY TO TRIGGER MANUAL
              UPDATES, REVIEW LOGS, ETC.
            </Typography>
          </TableCell>
          <TableCell>
            <RadioGroup row>
              <FormControlLabel
                onChange={handleToggleFaultAlerts}
                value="true"
                control={<Radio />}
                label="True"
                checked={isFaultAlertsEnabled}
              />
              <FormControlLabel
                onChange={handleToggleFaultAlerts}
                value="false"
                control={<Radio />}
                label="False"
                checked={!isFaultAlertsEnabled}
              />
            </RadioGroup>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  const editClickHandler = () => {
    setEditingMode(!editingMode);
    setSaving(false);
  };

  const saveClickHandler = async () => {
    //"pressing save"
    setSaving(true);
    const editedServicePlan: ServicePlansInterface = {
      ...(props.servicePlan || {}),
      name: props.servicePlan!.name,
      comments: props.servicePlan!.comments,
      service_plan_features: {
        ...(props.servicePlan?.service_plan_features || {}),
        firmware_updates: isFirmwareUpdatesEnabled,
        heartbeat_alerts: isHeartbeatMonitoringEnabled,
        fault_alerts: isFaultAlertsEnabled,
      },
      updated_at: props.servicePlan!.updated_at,
    };

    try {
      await putUpdatedServicePlans(editedServicePlan);
      setIsChanged(false); // Reset changes flag after saving
    } catch (error) {
      console.error("Error updating service plan:", error);
      // Handle error
    }
    // Implement save logic
    setEditingMode(false);
    setIsChanged(false); // Reset changes flag after saving
  };

  const cancelClickHandler = () => {
    setEditingMode(false);
  };

  return (
    <>
      <Card elevation={8} sx={{ borderRadius: 3 }}>
        <CardHeader
          title="Features"
          sx={{ margin: "10px" }}
          action={
            <IconButton aria-label="info">
              <InfoOutlinedIcon />
            </IconButton>
          }
        />
        <CardContent sx={{ flexGrow: 1, margin: "10px" }}>
          <Divider style={{ marginBottom: "15px" }} />

          <Table>{editingMode ? writeList() : readOnlyList()}</Table>

          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Box margin={2}>
                {editingMode ? (
                  <Button
                    color={theme.backbuttoncolor}
                    variant="contained"
                    onClick={cancelClickHandler}
                  >
                    Cancel
                  </Button>
                ) : null}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="flex-end" margin={2}>
                {editingMode ? (
                  <LoadingButton
                    color={theme.nextbuttoncolor}
                    variant="contained"
                    onClick={saveClickHandler}
                    loading={saving}
                  >
                    Save
                  </LoadingButton>
                ) : (
                  <Button
                    color={theme.basicbuttoncolor}
                    variant="contained"
                    onClick={editClickHandler}
                  >
                    Edit
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
