import { Client, UpdateClientData } from "../../../utils/interfaces/clients/clients-list-interface";
import { Auth } from "aws-amplify";
import { buildBaseUrl } from '../build-base-url';

export const putUpdatedClient = async (updatedClient: UpdateClientData, id:string) => {
    const url = buildBaseUrl()
  try {
    const authData = await Auth.currentAuthenticatedUser();

    const response = await fetch(`${url}clients/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${authData.signInUserSession.accessToken.jwtToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedClient),
    });

    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
  }
};
