import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Skeleton,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../utils/themes/theme-context";
import { getVehicleWeights } from "../../../utils/requests/vehicles-routes/get-vehicle-weights";
import MyLineChart from "../../../utils/generic-components/graphs/line-chart";
import { rfcToDateAndTime } from "../../../utils/custom-functions/rfc3339-conversions";
import {
  AxleWeightExternal,
  DefaultWeightsReturnType,
  FormattedWeightswithData,
  GraphSingleWeightDatapoint,
  GraphVehicleData,
} from "../../../utils/interfaces/vehicles/vehicles-interfaces";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import IconButton from "@mui/material/IconButton";

export default function AlertsAndFaults() {
  var { theme } = useContext(AppContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [categories, setCategories] = useState<string[]>();
  const [data, setData] = useState<GraphSingleWeightDatapoint[] | undefined>();

  //recharts linechart needs specific data structure + sorting before it can be passed
  const formatDataForLineChart = (response: GraphVehicleData[]) => {
    let lineChartCategories: string[] = [];
    response[0].axle_weights.map((k: AxleWeightExternal) => {
      lineChartCategories.push(k.name);
    });
    lineChartCategories.push("weight");

    const lineChartData: GraphSingleWeightDatapoint[] = response.map(
      (k: GraphVehicleData) => {
        const dataPoint: GraphSingleWeightDatapoint = {
          name: rfcToDateAndTime(k.date_time),
          weight:
            k.weight.value.received === true ? k.weight.value.value : null,
        };

        k.axle_weights.map((l: AxleWeightExternal) => {
          dataPoint[l.name] =
            l.weight.received === true ? l.weight.value : null;
        });

        return dataPoint;
      }
    );

    //linechart wont sort automaticlly so do it before passing here
    lineChartData.sort(
      (a: GraphSingleWeightDatapoint, b: GraphSingleWeightDatapoint) => {
        const A = a.name;
        const B = b.name;
        return A.localeCompare(B);
      }
    );

    setCategories(lineChartCategories);
    setData(lineChartData);
  };

  return (
    <>
      <Card elevation={8} sx={{ borderRadius: 3 }}>
        <CardHeader
          title="Alerts/Faults"
          action={
            <IconButton aria-label="info">
              <InfoOutlinedIcon />
            </IconButton>
          }
        />
        <CardContent>
          <Divider />
          {!loading && data && categories ? (
            <MyLineChart data={data} categories={categories} />
          ) : (
            <Skeleton variant="rectangular" width="100%" height={200} />
          )}
        </CardContent>
      </Card>
    </>
  );
}
