import { Alert, Box, Divider, MenuItem, Select, Slide, Typography, FormControl, InputLabel, Card, CardContent, SelectChangeEvent, TextField, Autocomplete, CircularProgress, Button, FormHelperText, StepLabel, Step, Stepper } from "@mui/material";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { getClientDetails } from "../../utils/requests/clients/get-clients-list";
import { Client } from "../../utils/interfaces/clients/clients-list-interface";
import { postReports } from "../../utils/requests/reports/post-reports";
import { AppContext } from "../../utils/themes/theme-context";
import { validateEmail } from "../../utils/regex/forms";

export default function ReportsList() {
    const [activeStep, setActiveStep] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);
    const [transitionout, setTransitionOut] = useState<boolean>(true);
    const [reportType, setReportType] = useState<string>('');
    const [clients, setClients] = useState<Client[]>([]);
    const [selectedClientId, setSelectedClientId] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [reportTypeError, setReportTypeError] = useState<string | null>(null);
    const [clientError, setClientError] = useState<string | null>(null);
    const [requestMessage, setRequestMessage] = useState<JSX.Element | null>(null);
    const [emails, setEmails] = useState<string[]>([]); // New state for email management
    const [emailError, setEmailError] = useState<string | null>(null); // New state for email validation
    const { theme } = useContext(AppContext);

    const steps = ['Select Report Type', 'Report Request Result'];

    // Define report types and required parameters
    const reportTypes = [
        { value: 'client-vehicles-report', label: 'Client Vehicles Report', requiresClientId: true },
        { value: 'all-clients-vehicles-report', label: 'All Clients Vehicles Report', requiresClientId: false },
        { value: 'outdated-firmware-report', label: 'Outdated Firmware Report', requiresClientId: false },
        { value: 'uncalibrated-devices-report', label: 'Uncalibrated Devices Report', requiresClientId: false },
        { value: 'all-faults-report', label: 'All Faults Report', requiresClientId: false },
        { value: 'client-faults-report', label: 'Client Faults Report', requiresClientId: true }
    ];

    useEffect(() => {
        //Fetch Client list data and update the state
        getClientDetails()
            .then((response: { data: Client[] }) => {
                //the response object data has a property type Client[]
                //Successful response
                const data = response.data; //data property is assigened to variable data
                setClients(data);
                setLoading(false);
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    if (error.response.status === 401) {
                        // 401 Unauthorized error
                        setErrorMessage("Parameter mismatch");
                    } else if (error.response.status === 500) {
                        // 500 Internal Server Error
                        setErrorMessage("Internal Server Error");
                    } else {
                        // other errors
                        setErrorMessage(`Error: ${error.message}`);
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    setErrorMessage("No response received");
                } else {
                    // Something happened in setting up the request that triggered an error
                    setErrorMessage(`Error: ${error.message}`);
                }
            });
    }, []);

    const handleReportTypeChange = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
        setReportType(event.target.value as string);
        setSelectedClientId(null); // Reset client selection when report type changes
        setReportTypeError(null); // Clear report type error
        setClientError(null); // Clear client error
        setRequestMessage(null); // Clear request error
        setEmailError(null); // Clear email error
    };

    const handleClientChange = (event: React.ChangeEvent<{}>, newValue: Client | null) => {
        setSelectedClientId(newValue?.id || null);
        setClientError(null); // Clear client error
        setRequestMessage(null); // Clear request error
    };

    const handleEmailChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
        const newEmails = [...emails];
        newEmails[index] = event.target.value;
        setEmails(newEmails);
        setEmailError(null); // Clear email error
    };

    const addEmailField = () => {
        if (emails.length < 100) {
            setEmails([...emails, '']); // Add a new empty email field
        }
    };

    const removeEmailField = (index: number) => {
        setEmails(emails.filter((_, i) => i !== index)); // Remove email field at the specified index
    };

    const validateEmails = () => {
        for (const email of emails) {
            const error = validateEmail(email, 'Invalid email address');
            if (error) {
                setEmailError(error);
                return false;
            }
        }
        return true;
    };

    const handleRequestReports = async () => {
        if (!reportType) {
            setReportTypeError("Report type is required.");
            return;
        }

        if (reportTypes.find(type => type.value === reportType)?.requiresClientId && !selectedClientId) {
            setClientError("Client selection is required for the selected report type.");
            return;
        }

        if (!validateEmails()) {
            return;
        }

        const requestBody = {
            report_type: reportType,
            client_id: selectedClientId || "", // Client ID can be an empty string if not required
            additional_emails: emails // Include emails in the request body
        };

        try {
            const response = await postReports(requestBody);
            if (response.status === 200) {
                setRequestMessage(
                    <>
                        <Typography variant="h6" color="green" paddingTop={3}>
                            Report request is successfully submitted.
                        </Typography>
                        <Typography
                            variant="h6"
                            style={{ fontWeight: 'bold', fontStyle: 'italic', color: "green" }}
                        >
                            You will receive an email for the Report Request Received
                        </Typography>
                        <Typography variant="h6" color="green" paddingBottom={3}>
                            confirmation with instructions within 10 minutes.
                        </Typography>
                    </>
                );
            } else {
                setRequestMessage(
                    <Typography variant="h6" color="red">
                        Report Request was unsuccessful.
                    </Typography>
                );
            }
        } catch (error: any) {
            setRequestMessage(
                <Typography variant="h6" color="red">
                    Report Request was unsuccessful.
                </Typography>
            );
        }

        setActiveStep(prevStep => prevStep + 1);
    };

    const handleNewReportRequest = () => {
        // Reset state to the initial values
        setActiveStep(0);
        setReportType('');
        setSelectedClientId(null);
        setReportTypeError(null);
        setClientError(null);
        setRequestMessage(null);
        setEmails([]); // Reset email fields
        setEmailError(null);
    };

    return (
        <Slide direction="right" in={transitionout} timeout={400}>
            <Box
                flexGrow={1}
                marginLeft={"5%"}
                marginRight={"5%"}
                marginTop={10}
                marginBottom={10}
            >
                <h2>Reports Dashboard</h2>
                <Divider />
                {errorMessage && (
                    <Box paddingTop={2} color="red">
                        <Alert severity="error">{errorMessage}</Alert>
                    </Box>
                )}
                <Card variant="outlined" style={{ marginTop: 20 }}>
                    <CardContent>
                        <Stepper activeStep={activeStep}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        {activeStep === 0 ? (
                            <>
                                <Typography variant="h6" paddingTop={5}>Select Report Type</Typography>
                                <FormControl fullWidth margin="normal" error={!!reportTypeError}>
                                    <InputLabel>Report Type</InputLabel>
                                    <Select
                                        value={reportType}
                                        onChange={handleReportTypeChange}
                                        label="Report Type"
                                    >
                                        {reportTypes.map((type) => (
                                            <MenuItem key={type.value} value={type.value}>
                                                {type.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>{reportTypeError}</FormHelperText>
                                </FormControl>
                                {reportTypes.find(type => type.value === reportType)?.requiresClientId && (
                                    <FormControl fullWidth margin="normal" error={!!clientError}>
                                        <Autocomplete
                                            options={clients}
                                            getOptionLabel={(option) => option.client}
                                            value={clients.find(client => client.id === selectedClientId) || null}
                                            onChange={handleClientChange}
                                            renderInput={(params) => (
                                                <>
                                                    <TextField
                                                        {...params}
                                                        label="Client"
                                                        variant="outlined"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <>
                                                                    {loading ? <CircularProgress color="inherit" size={24} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            ),
                                                        }}
                                                    />
                                                </>
                                            )}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            loading={loading}
                                        />
                                        <FormHelperText>{clientError}</FormHelperText>
                                    </FormControl>
                                )}
                                <Typography variant="h6" paddingTop={5}>Add Email Addresses</Typography>
                                {emails.map((email, index) => (
                                    <Box key={index} marginBottom={2} width={400}> 
                                    <FormControl fullWidth margin="normal" key={index} error={!!emailError}>
                                        <TextField
                                            label={`Email ${index + 1}`}
                                            variant="outlined"
                                            value={email}
                                            onChange={(e) => handleEmailChange(e as ChangeEvent<HTMLInputElement>, index)} // Type assertion here
                                            error={!!emailError}
                                            helperText={index === emails.length - 1 && emailError}
                                        />
                                        <Button
                                            variant="contained"
                                            color={theme.backbuttoncolor}
                                            onClick={() => removeEmailField(index)}
                                            style={{ marginTop: 8 }}
                                        >
                                            Remove
                                        </Button>
                                    </FormControl>
                                    </Box>
                                ))}
                                {emails.length < 100 && (
                                    <Box marginTop={2}>
                                        <Button
                                            variant="contained"
                                            color={theme.basicbuttoncolor}
                                            onClick={addEmailField}
                                        >
                                            Add Email
                                        </Button>
                                    </Box>
                                )}
                                <Box marginTop={2}>
                                    <Button
                                        variant="contained"
                                        color={theme.basicbuttoncolor}
                                        onClick={handleRequestReports}
                                    >
                                        Request Reports
                                    </Button>
                                </Box>
                            </>
                        ) : (
                            <>
                                <>
                                    {requestMessage && (
                                        <Box paddingTop={2} marginBottom={2}>
                                            {requestMessage}
                                        </Box>
                                    )}
                                </>
                                <Box marginTop={2}>
                                    <Button
                                        variant="contained"
                                        color={theme.basicbuttoncolor}
                                        onClick={handleNewReportRequest}
                                    >
                                        Request a New Report
                                    </Button>
                                </Box>
                            </>
                        )}
                    </CardContent>
                </Card>
            </Box>
        </Slide>
    );
}
