import { Auth } from "aws-amplify";
import { buildBaseUrl } from "../build-base-url";
import { PostNewDeviceData } from "../../interfaces/devices/devices-interface";

export const postNewDevice = async (
  requestBody: PostNewDeviceData
): Promise<Response> => {
  const url = buildBaseUrl();
  const authData = await Auth.currentAuthenticatedUser();
  const response = await fetch(`${url}device`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authData.signInUserSession.accessToken.jwtToken}`,
    },
    body: JSON.stringify(requestBody),
  });
  return response;
};
