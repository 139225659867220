import {
    Alert,
    AlertTitle,
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    IconButton,
    LinearProgress,
    Skeleton,
    Stack,
  } from "@mui/material";
  import { useEffect, useState } from "react";
  import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
  import {
    Columns,
  } from "../../../utils/interfaces/table/table-interfaces";
  import GeneralTable from "../../../utils/generic-components/table/table";
  import NestedTable from "../../../utils/generic-components/table/nested-table";
  import { rfcToDateAndTime } from "../../../utils/custom-functions/rfc3339-conversions";
import { IntegratorProductList } from "../../../utils/interfaces/integration/integrator-stats-interface";
import { getIntergratorStats } from "../../../utils/requests/integraton/get-integrators-stats";
  
  export default function IntegratorProductStats(props: { intergratorId: string }) {
    const [productListData, setProductListData] = useState<IntegratorProductList[]>([]); // State to hold fetched data
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
  
    let column: Columns = {
      ProductName: {
        type: "text",
        display: "Product Name",
        sort: "ProductName"
      },
      Stats: {
        type: "text",
        display: "Stats",
        data: "stat",
        subColumns: {
          DateTime: {
            type: "time",
            display: "Date and Time",
            data: "date_time",
          },
          Total: {
            type: "text",
            display: "Total",
            data: "total",
          }
        }
      }
    }
  
    // Function to fetch client stats when component mounts
    const fetchStatsData = async () => {
      try {
        const integratorId = props.intergratorId;
        setLoading(true);
        const data = await getIntergratorStats(integratorId);
        setProductListData(data.product_list); // Set the fetched data into state
        setLoading(false);
        setError(null); // Clear any previous errors
      } catch (error: any) {
        if (error.response) {
          const { status, data } = error.response; // Destructure 'status' and 'data' from the 'response' property of the 'error' object.
          if (status === 400) {
            setError(`Bad Request: ${data.errors ? data.errors[0] : 'Invalid request'}`);
          } else if (status === 401) {
            setError(`Unauthorized: ${data.errors ? data.errors[0] : 'Unauthorized access'}`);
          } else if (status === 403) {
            setError(`Forbidden: ${data.errors ? data.errors[0] : 'Access denied'}`);
          } else if (status === 500) {
            setError(`Server Error: ${data.errors ? data.errors[0] : 'Internal server error'}`);
          } else {
            setError('An error occurred');
          }
        } else {
          // setStatsData(null); // Reset statsData to null on error
          setError('Failed to fetch integrator stats'); // Generic error message
        }
        setLoading(false);
      }
    };
  
    // Fetch data when component mounts
    useEffect(() => {
      fetchStatsData();
    }, [props.intergratorId]);
  
    const loadingDisplay = () => {
      return (
        <>
          <LinearProgress />
          <Stack spacing={2}>
            <Skeleton variant="rectangular" height={50} animation="wave" />
            <Skeleton variant="rectangular" height={50} animation="wave" />
            <Skeleton variant="rectangular" height={50} animation="wave" />
          </Stack>
        </>
      );
      return null;
    };
  
    return (
      <>
        <Card elevation={8} sx={{ borderRadius: 3 }}>
          <CardHeader
            title="Integrator Product Stats"
            action={
              <IconButton aria-label="info">
                <InfoOutlinedIcon />
              </IconButton>
            }
          />
          <CardContent>
            <Divider />
            {loading ?
              (loadingDisplay()) :
              productListData ? (<GeneralTable
                data={productListData.map((prodlist) => ({
                  ProductName: prodlist.product_name,
                  Stats: (
                    <NestedTable
                      key={prodlist.product_id}
                      data={{
                        ...prodlist.stat,
                        date_time: rfcToDateAndTime(prodlist.stat.date_time) // Apply date formatting here
                      }}
                      columns={column.Stats.subColumns}
                    />
                  )
                }))}
                columns={column}
                noDataMessage={"No Devices to fetch "}
                rows={10}
                rowsoptions={[10, 25, 50, 100]}
              />)
                : (
                  <Alert variant="filled" severity="warning">
                  <AlertTitle>No Product Stats available for the Integrator</AlertTitle>
                </Alert>
                )}
          </CardContent>
        </Card >
      </>
    );
  }
  