import { IconButton, Dialog, DialogTitle, Stepper, Step, StepLabel, Divider, DialogContent, Typography, Grid, FormControl, InputLabel, Select, MenuItem, CircularProgress, TextField, Autocomplete, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, DialogActions, Box, Button, SelectChangeEvent, FormHelperText } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ProductListData } from "../../../utils/interfaces/products/products-list-interface";
import { SingleProductFirmwareGET } from "../../../utils/interfaces/products/single-product-details-interface";
import { Device, PostNewDeviceData } from "../../../utils/interfaces/devices/devices-interface";
import { validateProductSerialNumber } from "../../../utils/regex/forms";
import { AppContext } from "../../../utils/themes/theme-context";
import { fetchSingleProductFirmwareData } from "../../../utils/requests/products/get-singleproduct-firmware-data";
import { getProducts } from "../../../utils/requests/products/get-products";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from '@mui/icons-material/Delete';
import { postNewDevice } from "../../../utils/requests/devices/post-new-device";

interface NewDeviceProps {
    isDialogOpen: boolean;
    onClose: () => void;
    devices: Device[];
}

export default function NewDevice({ isDialogOpen, onClose, devices }: NewDeviceProps) {
    const [activeStep, setActiveStep] = useState(0);
    const [submitColor, setSubmitColor] = useState("");
    const [submitResult, setSubmitResult] = useState("");
    const steps = ["Select Device Type", "Fill the Details", "Review", "Result"];
    const [formData, setFormData] = useState<PostNewDeviceData>({
        serial: "",
        firmware_id: "",
        ports: [
            {
                port_type: "",
                bitfield: 0,
            }
        ]
    });
    const [selectedDeviceType, setSelectedDeviceType] = useState({ id: '', name: '' });
    const [formErrors, setFormErrors] = useState<any>({});
    const [bitfieldOptions, setBitfieldOptions] = useState<number[]>([0, 1, 2, 3, 4, 5, 6]);
    const [productOptions, setProductOptions] = useState<ProductListData[]>([]);
    const [isFetchingProducts, setIsFetchingProducts] = useState<boolean>(false);
    const [firmwareOptions, setFirmwareOptions] = useState<SingleProductFirmwareGET[]>([]);
    const [selectedFirmware, setSelectedFirmware] = useState<string>('');
    const [inputValue, setInputValue] = useState('');
    var { theme } = useContext(AppContext);
    const [loading, setLoading] = useState<boolean>(true); //initial stage of loading is true

    useEffect(() => {
        if (isDialogOpen) {
            const fetchProducts = async () => {
                setIsFetchingProducts(true);
                try {
                    const response = await getProducts();
                    const filteredProducts = response.data.filter(
                        (product: ProductListData) => product.manufacture_registration_support
                    );
                    setProductOptions(filteredProducts);
                } catch (error) {
                    console.error("Failed to fetch products:", error);
                } finally {
                    setIsFetchingProducts(false);
                }
            };

            fetchProducts();
        }
    }, [isDialogOpen]);

    useEffect(() => {
        if (selectedDeviceType.id) {
            setLoading(true);
            fetchSingleProductFirmwareData(selectedDeviceType.id)
                .then((data) => {
                    setFirmwareOptions(data.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error("Error fetching product data:", error);
                    setLoading(false);
                });
        }
    }, [selectedDeviceType.id]);

    const handleCloseDialog = () => {

        // Reset form data
        setFormData({
            serial: "",
            firmware_id: "",
            ports: [
                {
                    port_type: "air",
                    bitfield: 0,
                }
            ]
        });

        // Clear form errors
        setFormErrors({});

        // Reset selected device type
        setSelectedDeviceType({ id: '', name: '' });
        setSelectedFirmware('');
        setActiveStep(0);
        onClose();
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    //Validate device type
    const validateStep1 = () => {
        // Validate Step 1 fields
        let errors: any = {};
        if (!selectedDeviceType.id) {
            errors.deviceType = 'Device type is required';
        }
        setFormErrors(errors);
        return Object.keys(errors).length === 0; // return true if no errors
    };

    //validate serial number, firmware version fields
    const validateStep2 = () => {
        // Validate Step 2 fields
        let errors: any = {};
        if (!formData.serial) {
            errors.serial = 'Serial number cannot be empty';
        } else {
            const error = validateProductSerialNumber(formData.serial, 'The Serial Number must be an 8-digit number where the first 3 digits are in the range 000-159, and the remaining digits must be numeric values');
            if (error) errors.serial = error;

            // Check for unique serial number
            const isSerialUnique = !devices.some(device => device.serial === formData.serial);
            if (!isSerialUnique) {
                errors.serial = 'Serial number must be unique';
            }
        }

        if (!selectedFirmware) {
            errors.firmware_id = 'Firmware version is required';
        }
        setFormErrors(errors);
        return Object.keys(errors).length === 0; // return true if no errors
    };

    const hasDuplicateBitfields = (ports: { bitfield: number }[]): boolean => {
        const bitfieldValues = ports.map(port => port.bitfield);
        const uniqueBitfieldValues = new Set(bitfieldValues);
        return bitfieldValues.length !== uniqueBitfieldValues.size;
    };

    const handleNext = async () => {
        if (activeStep === 0) {
            if (!validateStep1()) {
                return; // Do not proceed if Step 1 validation fails
            }
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else if (activeStep === 1) {
            if (!validateStep2()) {
                return; // Do not proceed if Step 2 validation fails
            }

            // Check for duplicate bitfields
            if (hasDuplicateBitfields(formData.ports)) {
                setFormErrors((prevErrors: any) => ({
                    ...prevErrors,
                    bitfield: 'Each bitfield must be unique.'
                }));
                return; // Do not proceed if bitfields are not unique
            }
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else if (activeStep === 2) {
            // Step 2: Review step
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else if (activeStep === 3) {
            // Final step: Call handleSubmit
            handleSubmit();
        }
    };

    const handleSubmit = async () => {
        // POST new device 
        const response = await postNewDevice(formData);
        if (response.status === 200) {
            setSubmitResult("New Device was successfully created");
            setSubmitColor("green");
        }
        else if (response.status === 401 || response.status === 500 || response.status === 400) {
            setSubmitResult("Failed to create new device");
            setSubmitColor("red");
        } else {
            setSubmitResult("Unknown error occurred. Failed to create new device");
            setSubmitColor("red");
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleDeviceTypeChange = (event: SelectChangeEvent<string>) => {
        const selectedId = event.target.value;

        // Find the selected product based on the ID
        const selectedProduct = productOptions.find(product => product.id === selectedId);

        if (selectedProduct) {
            setSelectedDeviceType({
                id: selectedProduct.id,
                name: selectedProduct.name
            });
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFirmwareVersionSelect = (event: React.ChangeEvent<{}>, newValue: SingleProductFirmwareGET | null) => {
        if (newValue) {
            setSelectedFirmware(newValue.firmware_version);  // Update the selectedFirmware state
            setFormData(prevFormData => ({
                ...prevFormData,
                firmware_id: newValue.id  // Store the firmware ID in formData
            }));
        } else {
            setSelectedFirmware('');  // Clear selectedFirmware if no value is selected
            setFormData(prevFormData => ({
                ...prevFormData,
                firmware_id: ''  // Clear firmware_id if no value is selected
            }));
        }
    };

    useEffect(() => {
        // Initialize form data with default values
        setFormData({
            serial: "",
            firmware_id: "",
            ports: [{ bitfield: 0, port_type: getPortTypeForBitfield(0) }] // Default 'air' for bitfield 0
        });
    }, []);


    const handleBitfieldChange = (index: number, event: SelectChangeEvent<number>) => {
        const value = event.target.value as number;
        const portType = getPortTypeForBitfield(value);

        // Update form data with new bitfield and port type
        const updatedPorts = formData.ports.map((port, i) => {
            if (i === index) {
                return { ...port, bitfield: value, port_type: portType };
            }
            return port;
        });
        setFormData({ ...formData, ports: updatedPorts });
    };

    const getPortTypeForBitfield = (bitfield: number): string => {
        if (bitfield === 0 || bitfield === 1 || bitfield === 2) {
            return 'air';
        }
        return 'mech';
    };

    // Function to add a new port
    const handleAddPort = () => {
        const newPort = {
            port_type: getPortTypeForBitfield(0), // Default to 'air' for bitfield 0
            bitfield: 0,
        };

        setFormData(prevFormData => {
            const updatedPorts = [...prevFormData.ports, newPort];
            return {
                ...prevFormData,
                ports: updatedPorts
            };
        });
    };


    // Function to remove a port
    const handleRemovePort = (index: number) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            ports: prevFormData.ports.filter((_, i) => i !== index)
        }));
    };

    return (
        <>
            <Dialog open={isDialogOpen} onClose={handleCloseDialog} PaperProps={{
                style: {
                    minWidth: '40%',
                    minHeight: '40%',
                },
            }}>
                <DialogTitle variant="h4" align={"center"}>New Device
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleCloseDialog}
                        style={{
                            position: "absolute",
                            top: "5px",
                            right: "15px",
                            color: "black",
                        }}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Stepper activeStep={activeStep} alternativeLabel sx={{ paddingTop: 3 }}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </DialogTitle>
                <Divider />
                {activeStep === 0 && (
                    // Step 1: Form inputs
                    <DialogContent>
                        <Typography variant="h5">Select the Device type: </Typography>
                        <Grid container spacing={2} paddingTop={3}>
                            <Grid item xs={12}>
                                <FormControl fullWidth margin="normal" error={!!formErrors.deviceType}>
                                    <InputLabel id="device-type-label">Device Type</InputLabel>
                                    <Select
                                        labelId="device-type-label"
                                        label="Device Type"
                                        value={selectedDeviceType.id}
                                        onChange={handleDeviceTypeChange}
                                        disabled={isFetchingProducts} // Disable while fetching
                                    >
                                        {isFetchingProducts ? (
                                            <MenuItem value="" disabled>
                                                <CircularProgress size={24} />
                                            </MenuItem>
                                        ) : (
                                            productOptions.map((product) => (
                                                <MenuItem key={product.id} value={product.id}>
                                                    {product.name}
                                                </MenuItem>
                                            ))
                                        )}
                                    </Select>
                                    <FormHelperText>{formErrors.deviceType}</FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                )}

                {activeStep === 1 && (
                    // Step 1: Form inputs
                    <DialogContent>
                        <Typography variant="h5">Enter the Device Details: </Typography>
                        <Grid container spacing={2} paddingTop={3}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    name="serial"
                                    label="Serial Number"
                                    size="small"
                                    value={formData.serial}
                                    onChange={handleChange}
                                    error={!!formErrors.serial}
                                    helperText={formErrors.serial}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth error={!!formErrors.firmware_id}>
                                    <Autocomplete
                                        value={firmwareOptions.find(option => option.firmware_version === selectedFirmware) || null}
                                        onChange={handleFirmwareVersionSelect}
                                        inputValue={inputValue}
                                        onInputChange={(event, newInputValue) => {
                                            setInputValue(newInputValue);
                                        }}
                                        options={firmwareOptions}
                                        getOptionLabel={(option) => option.firmware_version}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Firmware Version"
                                                variant="outlined"
                                                error={!!formErrors.firmware_id}
                                                helperText={formErrors.firmware_id}
                                            />
                                        )}
                                        isOptionEqualToValue={(option, value) => option.firmware_version === value.firmware_version}
                                    />
                                </FormControl>
                            </Grid>
                            {formData.ports.map((port, index) => (
                                <Grid item xs={12} container spacing={2} key={index} alignItems="center">
                                    <Grid item xs={12}>
                                        <Typography>
                                            Port {index + 1}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box display="flex" alignItems="center">
                                            <FormControl fullWidth margin="normal" error={!!formErrors.bitfield}>
                                                <InputLabel id={`bitfield-label-${index}`}>Bitfield</InputLabel>
                                                <Select
                                                    labelId={`bitfield-label-${index}`}
                                                    label="Bitfield"
                                                    value={port.bitfield}
                                                    onChange={(event) => handleBitfieldChange(index, event)}
                                                >
                                                    {bitfieldOptions.map(option => (
                                                        <MenuItem key={option} value={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                <FormHelperText>{formErrors.bitfield}</FormHelperText>
                                            </FormControl>

                                            <FormControl margin="normal" sx={{ flexGrow: 2, marginLeft: 2 }}>
                                                <TextField
                                                    id={`port-type-${index}`}
                                                    name="port_type"
                                                    value={port.port_type}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    variant="outlined"
                                                    label="Port Type"
                                                    size="small"
                                                />
                                            </FormControl>
                                            {index > 0 && ( // Only show the Delete icon for ports other than the first one
                                                <IconButton
                                                    onClick={() => handleRemovePort(index)}
                                                    size="medium"
                                                    sx={{ marginLeft: 2 }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            )}
                                        </Box>
                                    </Grid>
                                </Grid>
                            ))}

                            {/* Only render the "Add Port" button if there are less than 7 ports */}
                            {formData.ports.length < 7 && (
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleAddPort}
                                    >
                                        Add Port
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </DialogContent>
                )}

                {activeStep === 2 && (
                    // Step 2: Review table
                    <DialogContent>
                        <Typography variant="h5">Review your entries: </Typography>
                        <Grid container spacing={2} paddingTop={3}>
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>Field</TableCell>
                                                <TableCell style={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>Value</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Device Type</TableCell>
                                                <TableCell>{selectedDeviceType.name}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Serial Number</TableCell>
                                                <TableCell>{formData.serial}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Firmware Version</TableCell>
                                                <TableCell>{selectedFirmware}</TableCell>
                                            </TableRow>
                                            {formData.ports.map((port, index) => (
                                                <React.Fragment key={index}>
                                                    <TableRow>
                                                        <TableCell>Bitfield</TableCell>
                                                        <TableCell>{port.bitfield}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Port Type</TableCell>
                                                        <TableCell>{port.port_type}</TableCell>
                                                    </TableRow>
                                                </React.Fragment>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </DialogContent>
                )}

                {activeStep === 3 && (
                    // Step 3: Success or Failed message
                    <DialogContent>
                        <Typography variant="h5" justifyContent="center">Result: </Typography>
                        <Typography padding={5} fontSize={24}>
                            <span style={{ color: submitColor }}>
                                {submitResult}
                            </span>
                        </Typography>
                    </DialogContent>
                )}
                <DialogActions>
                    <Box display="flex" justifyContent="space-between" width="100%" paddingBottom={3} paddingLeft={2} paddingRight={2}>
                        {activeStep === 0 && (
                            <><Button variant="contained" color={theme.backbuttoncolor} onClick={handleCloseDialog}>
                                Cancel
                            </Button><Button variant="contained" color={theme.nextbuttoncolor} onClick={handleNext}>
                                    Next
                                </Button></>
                        )}
                        {activeStep === 1 && (
                            <><Button variant="contained" color={theme.backbuttoncolor} onClick={handleBack}>
                                Back
                            </Button><Button variant="contained" color={theme.nextbuttoncolor} onClick={handleNext}>
                                    Next
                                </Button></>
                        )}

                        {activeStep === 2 && (
                            <><Button variant="contained" color={theme.backbuttoncolor} onClick={handleBack}>
                                Back
                            </Button><Button variant="contained" color={theme.nextbuttoncolor} onClick={handleSubmit}>
                                    Submit
                                </Button></>
                        )}

                        {activeStep === 3 && (
                            <Box display="flex" justifyContent="center" width="100%" >
                                <Button variant="contained" color={theme.nextbuttoncolor} onClick={handleCloseDialog}>
                                    Done
                                </Button>
                            </Box>
                        )}
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    )
}