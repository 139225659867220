// SearchBar.js
import ClearIcon from "@mui/icons-material/Clear";
import { useRef, useState } from "react";
import { ProductListData } from "../../../utils/interfaces/products/products-list-interface";
import { getProductSearch } from "../../../utils/requests/products/get-product-search";
import { Box, Button, CircularProgress, FormControl, Grid, IconButton, InputAdornment, MenuItem, Select, TextField } from "@mui/material";

interface SearchProps {
    onUpdateData: (updatedData: ProductListData[]) => void;
    onError: (errorMessage: string) => void;
    originalProducts: ProductListData[];
}

const ProductSearchBar: React.FC<SearchProps> = ({ onUpdateData, onError, originalProducts }) => {
    const [data, setData] = useState<ProductListData[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [searchLoading, setSearchLoading] = useState<boolean>(false);
    const [searchCriterion, setSearchCriterion] = useState("name");
    const [searchTerm, setSearchTerm] = useState<string | null>("");
    const [resetVisible, setResetVisible] = useState(false); // Define resetVisible state
    const [Clientloading, setClientLoading] = useState<boolean>(false);
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const [searchResponse, setSearchResponse] = useState<any[]>([]);
    const [selectSuggestions, setSelectSuggestions] = useState<boolean>(false);
    const [AllClientNames, setAllClientNames] = useState<string[]>([]);
    const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

    const handleSearchCriterionChange = (event: {
        target: { value: React.SetStateAction<string> };
    }) => {
        setSearchCriterion(event.target.value);
        setSuggestions([]);
        setSearchTerm("");
        setResetVisible(false);
        setData(originalProducts);
        onUpdateData(originalProducts);
    };

    const handleSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newSearchTerm = event.target.value;
        setSearchTerm(newSearchTerm);

        if (newSearchTerm.trim() === "") {
            // Reset button is hidden when the search term is empty
            setResetVisible(false);
        } else {
            // Show the Reset button when there is something typed
            setResetVisible(true);
        }
    };

    const handleReset = () => {
        // Clear the search term and restore the original table data
        setSearchTerm("");
        setData(originalProducts); // Restore the original data
        onUpdateData(originalProducts);
        setResetVisible(false); // Hide the Reset button
    };

    const handleSearchKeyDown = (
        event: React.KeyboardEvent<HTMLInputElement>
    ) => {
        if (event.key === "Enter") {
            handleSearch();
        }
    };

    const handleSearch = async () => {
        try {
            setSearchLoading(true);
            const searchResponse = await getProductSearch(searchCriterion, searchTerm);
            setData(searchResponse.data); // Update the state with the mapped search results
            onUpdateData(searchResponse.data);
        } catch (error) {
            console.error("Error while searching:", error);
            setSearchLoading(false);
            // Handle errors as needed
        } finally {
            setSearchLoading(false);
        }
    };

    const handleSuggestionSelect = async (selectedItem: string | null) => {
        setSearchTerm(selectedItem);
        setSelectSuggestions(true);
    };

    return (
        <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            marginBottom={2}
            marginTop={2}
        >
            <FormControl fullWidth style={{ minWidth: "200px" }} variant="outlined">
                <Select
                    value={searchCriterion}
                    onChange={handleSearchCriterionChange}
                >
                    <MenuItem value="name">Product Name</MenuItem>
                    <MenuItem value="serial_identifier">Serial Identifier</MenuItem>
                </Select>
            </FormControl>
            <>
                <TextField
                    label="Search"
                    variant="outlined"
                    fullWidth
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                    onKeyDown={handleSearchKeyDown}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {searchLoading ? (
                                    <CircularProgress color="inherit" size={20} />
                                ) : (
                                    resetVisible && (
                                        <IconButton onClick={handleReset} size="small">
                                            <ClearIcon />
                                        </IconButton>
                                    )
                                )}
                            </InputAdornment>
                        ),
                    }}
                    style={{ width: "100%", maxWidth: "300px" }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{ height: 55 }}
                    onClick={handleSearch}
                >
                    Search
                </Button>
            </>
        </Box>

    );
};

export default ProductSearchBar;
