/*CONFLUENCE DOCUMENTATION: https://airweigh.atlassian.net/l/cp/Kc3A9urW */

import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Grid,
	TextField,
} from "@mui/material";
import {
	validateDomain,
	validateEmail,
	validateName,
	validatePhoneNumber,
} from "../../../utils/regex/forms";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { CreateClientInfo } from "../../../utils/interfaces/new-user/create-user-interface";
import {
	client_info,
	newuser,
} from "../../../utils/interfaces/new-user/new-user-interface";
import { AppContext } from "../../../utils/themes/theme-context";
import { checkClientAvailability } from "../../../utils/requests/new-user/get-clients-available";
import { clientAvailablility } from "../../../utils/interfaces/new-user/client-interface";

interface Errors {
	errorclientname: string;
	errorcontactname: string;
	errorcontactemail: string;
	errorcontactphone: string;
	errordomain: string;
}
interface curEntries {
	clientname: string;
	contactname: string;
	contactemail: string;
	contactphone: string;
	domain: string;
}

export default function CreateClient(props: {
	setChooseOrCreate: Function;
	setClient: Function;
	enterInformationDomain: Function;
	setClientId: Function;
	validateAndSetError: Function;
	newUser: newuser;
	handleNext: Function;
	setCurClientName: Function;
}) {
	var { theme } = useContext(AppContext);
	const [issubmitdisabled, setIsSubmitDisabled] = useState<boolean>(true);
	const [triggercheck, setTriggerCheck] = useState<boolean>(false);
	const [errors, setErrors] = useState<Errors>({
		errorclientname: "",
		errorcontactname: "",
		errorcontactemail: "",
		errorcontactphone: "",
		errordomain: "",
	});
	const [curentries, setCurEntries] = useState<curEntries>({
		clientname: "",
		contactname: "",
		contactemail: "",
		contactphone: "",
		domain: "",
	});
	const [clienttimer, setClientTimer] = useState<NodeJS.Timeout>();

	//runs on component load, fills existing entries from newUser and
	// checks already filled elements so errors ande next display properly
	useEffect(() => {
		if (props.newUser) {
			setCurEntries({
				...curentries,
				domain: props.newUser.user_domain,
				clientname: props.newUser.client_info.client,
				contactname: props.newUser.client_info.contact,
				contactemail: props.newUser.client_info.contact_email,
				contactphone: props.newUser.client_info.contact_phone,
			});
		}
		setTriggerCheck(!triggercheck);
	}, []);

	//checks all entries and disables or enables submit button accordingly
	useEffect(() => {
		if (curentries.clientname && curentries.domain) {
			const hasErrors = Object.values(errors).some(
				(error: string) => error !== ""
			);
			setIsSubmitDisabled(hasErrors);
		} else {
			setIsSubmitDisabled(true);
		}
	}, [triggercheck]);

	//on curentries change: checks if at least one field is filled, calls validate
	// and set error on each entry to set errors before "check" useeffect runs
	useEffect(() => {
		if (
			!curentries.clientname &&
			!curentries.contactname &&
			!curentries.contactemail &&
			!curentries.contactphone &&
			!curentries.domain
		) {
			return;
		}

		const isValidClient = props.validateAndSetError(
			curentries.clientname,
			validateName,
			"Invalid Name",
			"errorclientname",
			setErrors
		);

		props.validateAndSetError(
			curentries.contactname,
			validateName,
			"Invalid Name",
			"errorcontactname",
			setErrors
		);

		props.validateAndSetError(
			curentries.contactemail,
			validateEmail,
			"Invalid Email",
			"errorcontactemail",
			setErrors
		);

		props.validateAndSetError(
			curentries.contactphone,
			validatePhoneNumber,
			"Invalid Phone Number",
			"errorcontactphone",
			setErrors
		);

		props.validateAndSetError(
			curentries.domain,
			validateDomain,
			"Invalid Domain",
			"errordomain",
			setErrors
		);

		if (isValidClient) {
			if (curentries.clientname) {
				setClientTimer(setTimeout(checkAvailability, 1000));
			}
		}

		setTriggerCheck(!triggercheck);
	}, [curentries]);

	//domain handled separately here because it isn't inside client_info object passed
	// to stepper/newuser at the end
	const handleTextfieldEntry = (
		event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		setCurEntries({ ...curentries, [event.target.id]: event.target.value });
		if (event.target.id === "domain") {
			props.enterInformationDomain(event.target.value);
		} else if (event.target.id === "clientname") {
			clearTimeout(clienttimer);
		}
	};

	//client id is ensured to be clear so post route works, client object passed to
	// stepper/newuser, things reset for the next step
	//next step called immediately here because creating a client is a nested/special
	// case. On submit it should go to next step instead of 'next' button, which
	// stays disabled
	const handleSubmit = () => {
		props.setClientId("");
		let newclient = CreateClientInfo();
		newclient.client = curentries.clientname;
		newclient.contact = curentries.contactname;
		newclient.contact_email = curentries.contactemail;
		newclient.contact_phone = curentries.contactphone;

		props.setCurClientName(curentries.clientname);
		props.setClient(newclient);
		props.handleNext();
		props.setChooseOrCreate(false);
	};

	const handleBack = () => {
		props.setClient(CreateClientInfo());
		props.enterInformationDomain("");
		props.setChooseOrCreate(false);
	};

	const checkAvailability = async () => {
		try {
			const response = await checkClientAvailability(curentries.clientname);
			processAvailabilityResponse(response);
		} catch (err) {
			console.log(err);
		}
	};

	const processAvailabilityResponse = (response: clientAvailablility) => {
		if (!response.client_name_available) {
			setErrors({ ...errors, errorclientname: "Name Unavailable" });
		} else {
			setErrors({ ...errors, errorclientname: "" });
		}
	};

	return (
		<>
			<Card>
				<CardHeader subheader="Create New Client" />
				<CardContent>
					<Box padding={2}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									fullWidth
									id="clientname"
									label="Client Name"
									variant="outlined"
									helperText={errors.errorclientname}
									error={errors.errorclientname === "" ? false : true}
									onChange={handleTextfieldEntry}
									defaultValue={
										curentries.clientname ? curentries.clientname : ""
									}
									required={true}
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									fullWidth
									id="contactname"
									label="Contact Name"
									variant="outlined"
									helperText={errors.errorcontactname}
									error={errors.errorcontactname === "" ? false : true}
									onChange={handleTextfieldEntry}
									defaultValue={
										curentries.contactname ? curentries.contactname : ""
									}
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									fullWidth
									id="contactphone"
									label="Client Phone"
									variant="outlined"
									helperText={errors.errorcontactphone}
									error={errors.errorcontactphone === "" ? false : true}
									onChange={handleTextfieldEntry}
									defaultValue={
										curentries.contactphone ? curentries.contactphone : ""
									}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									id="contactemail"
									label="Client Email"
									variant="outlined"
									helperText={errors.errorcontactemail}
									error={errors.errorcontactemail === "" ? false : true}
									onChange={handleTextfieldEntry}
									defaultValue={
										curentries.contactemail ? curentries.contactemail : ""
									}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									id="domain"
									label="Client Domain"
									variant="outlined"
									helperText={errors.errordomain}
									error={errors.errordomain === "" ? false : true}
									onChange={handleTextfieldEntry}
									defaultValue={curentries.domain ? curentries.domain : ""}
									required={true}
								/>
							</Grid>
							<Grid item xs={4}>
								<Button
									color={theme.backbuttoncolor}
									onClick={handleBack}
									variant="contained"
								>
									Cancel
								</Button>
							</Grid>
							<Grid item xs={4}></Grid>
							<Grid item xs={4}>
								<Box display="flex" justifyContent="flex-end">
									<Button
										onClick={() => handleSubmit()}
										color={theme.nextbuttoncolor}
										disabled={issubmitdisabled}
										variant="contained"
									>
										Save
									</Button>
								</Box>
							</Grid>
						</Grid>
					</Box>
				</CardContent>
			</Card>
		</>
	);
}
