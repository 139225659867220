import { Auth } from "aws-amplify";
import { buildBaseUrl } from "../build-base-url";

export const getVehicleSearch = async (criterion: any, query: any) => {
  const url = buildBaseUrl();
  try {
    const authData = await Auth.currentAuthenticatedUser();
    const response = await fetch(`${url}vehicles/search?criterion=${criterion}&query=${query}`, {
      headers: {
        Authorization: `Bearer ${authData.signInUserSession.accessToken.jwtToken}`,
      },
    });
    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else if (response.status === 400) {
      throw new Error('Invalid request. Both criterion and query are required.');
    } else {
      throw new Error('Failed to retrieve user search results.');
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};
