import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  LinearProgress,
  Skeleton,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useNavigate, useParams } from "react-router-dom";
import {
  Columns,
} from "../../../utils/interfaces/table/table-interfaces";
import GeneralTable from "../../../utils/generic-components/table/table";
import { ProductsDevicesTabList } from "../../../utils/interfaces/products/products-devicestab-list";
import { getRecentDevices } from "../../../utils/requests/products/getRecentDevices";
import { ClientProductList } from "../../../utils/interfaces/clients/client-stats-interface";
import { getClientStats } from "../../../utils/requests/clients/get-client-stats";
import NestedTable from "../../../utils/generic-components/table/nested-table";
import { rfcToDateAndTime } from "../../../utils/custom-functions/rfc3339-conversions";

export default function ClientProductStats(props: { clientID: string }) {
  const [productListData, setProductListData] = useState<ClientProductList[]>([]); // State to hold fetched data
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  let column: Columns = {
    ProductName: {
      type: "text",
      display: "Product Name",
      sort: "ProductName"
    },
    Stats: {
      type: "text",
      display: "Stats",
      data: "stat",
      subColumns: {
        DateTime: {
          type: "time",
          display: "Date and Time",
          data: "date_time",
        },
        Total: {
          type: "text",
          display: "Total",
          data: "total",
        }
      }
    }
  }

  // Function to fetch client stats when component mounts
  const fetchStatsData = async () => {
    try {
      const clientId = props.clientID;
      setLoading(true);
      const data = await getClientStats(clientId);
      setProductListData(data.product_list); // Set the fetched data into state
      setLoading(false);
      setError(null); // Clear any previous errors
    } catch (error: any) {
      if (error.response) {
        const { status, data } = error.response; // Destructure 'status' and 'data' from the 'response' property of the 'error' object.
        if (status === 400) {
          setError(`Bad Request: ${data.errors ? data.errors[0] : 'Invalid request'}`);
        } else if (status === 401) {
          setError(`Unauthorized: ${data.errors ? data.errors[0] : 'Unauthorized access'}`);
        } else if (status === 403) {
          setError(`Forbidden: ${data.errors ? data.errors[0] : 'Access denied'}`);
        } else if (status === 500) {
          setError(`Server Error: ${data.errors ? data.errors[0] : 'Internal server error'}`);
        } else {
          setError('An error occurred');
        }
      } else {
        // setStatsData(null); // Reset statsData to null on error
        setError('Failed to fetch client stats'); // Generic error message
      }
      setLoading(false);
    }
  };

  // Fetch data when component mounts
  useEffect(() => {
    fetchStatsData();
  }, [props.clientID]);

  const loadingDisplay = () => {
    return (
      <>
        <LinearProgress />
        <Stack spacing={2}>
          <Skeleton variant="rectangular" height={50} animation="wave" />
          <Skeleton variant="rectangular" height={50} animation="wave" />
          <Skeleton variant="rectangular" height={50} animation="wave" />
        </Stack>
      </>
    );
    return null;
  };

  return (
    <>
      <Card elevation={8} sx={{ borderRadius: 3 }}>
        <CardHeader
          title="Client Product Stats"
          action={
            <IconButton aria-label="info">
              <InfoOutlinedIcon />
            </IconButton>
          }
        />
        <CardContent>
          <Divider />
          <Box paddingTop={2}>
            {loading ?
              (loadingDisplay()) :
              productListData ? (<GeneralTable
                data={productListData.map((prodlist) => ({
                  ProductName: prodlist.product_name,
                  Stats: (
                    <NestedTable
                      key={prodlist.product_id}
                      data={{
                        ...prodlist.stat,
                        date_time: rfcToDateAndTime(prodlist.stat.date_time) // Apply date formatting here
                      }}
                      columns={column.Stats.subColumns}
                    />
                  )
                }))}
                columns={column}
                noDataMessage={"No Devices to fetch "}
                rows={10}
                rowsoptions={[10, 25, 50, 100]}
              />)
                : (
                  <Alert variant="filled" severity="warning">
                    <AlertTitle>No Product Stats available for the Client</AlertTitle>
                  </Alert>
                )}
          </Box>
        </CardContent>
      </Card >
    </>
  );
}
