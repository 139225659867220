import { Check } from "@mui/icons-material";
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Chip,
	Divider,
	Grid,
	Stack,
	Typography,
} from "@mui/material";
import { getUser } from "../../../utils/interfaces/users/user-details-interfaces";
import CloseIcon from "@mui/icons-material/Close";

export default function Status(props: { user: getUser }) {
	return (
		<>
			<Card elevation={8} sx={{ borderRadius: 3 }}>
				<CardHeader title="Status" />
				<CardContent>
					<Divider />
					<Grid
						container
						spacing={2}
						alignItems={"center"}
						justifyContent={"center"}
					>
						<Grid item xs={6}>
							<Typography margin={2}>Account:</Typography>
							{props.user.data.enabled === true ? (
								<Chip icon={<Check />} label="Enabled" color="success" />
							) : (
								<Chip icon={<CloseIcon />} label="Disabled" color="error" />
							)}
						</Grid>

						<Grid item xs={6}>
							<Typography margin={2}>Status:</Typography>
							{props.user.data.status === "CONFIRMED" ? (
								<Chip icon={<Check />} label="Confirmed" color="success" />
							) : (
								<Chip
									icon={<CloseIcon />}
									label="Not Confirmed"
									color="error"
								/>
							)}
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</>
	);
}
