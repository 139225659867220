import { Auth } from "aws-amplify";
import { buildBaseUrl } from '../build-base-url';
import { ServicePlansInterface } from "../../interfaces/service/service-list-interface";

export const PostNewServicePlan = async (data: ServicePlansInterface): Promise<Response> => {
    const url = buildBaseUrl()
    const authData = await Auth.currentAuthenticatedUser();
    const response = await fetch(`${url}service_plans`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authData.signInUserSession.accessToken.jwtToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return response;
  };