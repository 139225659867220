import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  Menu,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Skeleton,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import { AppContext } from "../../../utils/themes/theme-context";
import {
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useNavigate, useParams } from "react-router-dom";
import {
  Columns,
} from "../../../utils/interfaces/table/table-interfaces";
import GeneralTable from "../../../utils/generic-components/table/table";
import { getClientPreconfiguration } from "../../../utils/requests/clients/get-client-preconfig";
import {
  Calibration,
  Group,
  Preconfiguration,
  Sensor,
} from "../../../utils/interfaces/clients/client-preconfig-interface";
import NestedTable from "../../../utils/generic-components/table/nested-table";
import CloseIcon from "@mui/icons-material/Close";
import { getProducts } from "../../../utils/requests/products/get-products";
import { ProductListData } from "../../../utils/interfaces/products/products-list-interface";
import { getAxleGroup } from "../../../utils/requests/clients/get-axelgroup";
import ReviewTable from "./preconfiguration-review-table";
import {
  ValidationResult,
  CalibrationData,
  CalibrationError,
  ConfigurationErrors,
} from "../../../utils/interfaces/clients/preconfiguration-interfaces";
import { postClientPreconfiguration } from "../../../utils/requests/clients/post-new-preconfigurations";
import { MyInterface } from "../../../utils/requests/products/preconfigPOSTinterface";
import { PreconfigData } from "../../../utils/interfaces/clients/preconfiguration-review-table-interface";
import { putClientPreconfiguration } from "../../../utils/requests/clients/putPreConfigurations";
import { deletePreconfig } from "../../../utils/requests/clients/delete-preconfigurations";

export default function PreconfigurationsClients() {
  var { theme } = useContext(AppContext);
  const { id } = useParams<{ id?: string }>();
  const [loading, setLoading] = useState<boolean>(true);
  const [preconfigurations, setPreconfigurations] = useState<
    Preconfiguration[]
  >([]);
  // State to manage the opened dialog
  const [dialogOpen, setDialogOpen] = useState(false);
  // State to store the selected preconfiguration data
  const [selectedPreconfig, setSelectedPreconfig] =
    useState<Preconfiguration | null>(null);
  const [newdialogOpen, setnewDialogOpen] = useState(false);
  const [axleGroups, setAxleGroups] = useState([]);
  const [newPreconfigData, setNewPreconfigData] = useState({
    name: "",
    type: "",
    calibration: [
      {
        axle_group_name: "",
        axle_group_number: 0,
        sensor_calibrations: [
          {
            sensor_port: 0,
            empty_adc: 0,
            empty_weight: 0,
            heavy_adc: 0,
            heavy_weight: 0,
          },
        ],
      },
    ],
    configuration: {
      baudrate: "",
      device_type: "",
      weight_display: true,
      groups: [
        {
          group_name: "",
          group_number: 0,
          sensor_ports: [
            {
              type: "",
              port: 0,
            },
          ],
        },
      ],
      sensors: [
        {
          type: "",
          port: 0,
        },
      ],
    },
  });

  const [calibrationErrors, setCalibrationErrors] = useState<
    CalibrationError[][]
  >([]);

  const [configurationErrors, setConfigurationErrors] =
    useState<ConfigurationErrors>({
      baudrate: "",
      device_type: "",
      weight_display: "",
      group_name: [],
      group_number: [],
      sensorportsportconfig: [],
    });
  const [activeStep, setActiveStep] = useState(0);
  const [editactiveStep, setEditActiveStep] = useState(0);
  const [deleteactiveStep, setDeleteActiveStep] = useState(0);
  const [nameError, setNameError] = useState("");
  const [products, setProducts] = useState<ProductListData[]>([]);
  const [productNames, setProductNames] = useState<string[]>([]);
  const [productId, setProductId] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  // const [selectedType, setSelectedType] = useState<string>("");
  const [typeError, setTypeError] = useState<string>("");
  const [finalConfigData, setFinalConfigData] = useState({});
  const [isPreConfigPosted, setisPreConfigPosted] = useState<boolean>(false);
  const [isEditPreConfigPosted, setisEditPreConfigPosted] =
    useState<boolean>(false);
  const [selectedAxleGroups, setSelectedAxleGroups] = useState<
    {
      group_name: string;
      group_number: number;
      sensor_ports: { type: string; port: number }[];
    }[]
  >([]);
  const [anchorEls, setAnchorEls] = useState<Array<null | HTMLElement>>([]); //controls the position of the actions menu
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setEditDialogOpen] = useState(false);
  const editedPreConfigName = useRef<string>("");
  const originalName = useRef<string>("");
  const editedBaudrate = useRef<string>("");
  const editedDisplayType = useRef<string>("");
  const editedWeightDisplay = useRef<boolean>(false);
  const [editedGroups, setEditedGroups] = useState<Group[]>([]);
  const [editedSensors, setEditedSensors] = useState<Sensor[]>([]);
  const [editedCalibrations, setEditedCalibrations] = useState<Calibration[]>(
    []
  );
  const [isEditButtonClicked, setisEditButtonClicked] =
    useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState(0);

  const [editedRowData, setEditedRowData] = useState<Preconfiguration | null>(
    null
  );
  const [rowDataToDelete, setRowDataToDelete] =
    useState<Preconfiguration | null>(null);
  const [deleteResultColor, setDeleteResultColor] = useState("");
  const [deleteResult, setDeleteResult] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [isFieldValid, setIsFieldValid] = useState(true);
  let userNavigate = useNavigate();
  const [originalData, setOriginalData] = useState<Preconfiguration | null>(null);

  const autoWidthTableStyle = {
    width: "auto", // Set your desired width here
  };

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    const newAnchorEls = [...anchorEls];
    newAnchorEls[index] = event.currentTarget;
    setAnchorEls(newAnchorEls);
  };

  const handleMenuClose = (index: number) => {
    const updatedAnchorEls = [...anchorEls];
    updatedAnchorEls.forEach((_, i) => {
      updatedAnchorEls[i] = null; // Close all menus
    }); //the actions menu for that product should be closed
    setAnchorEls(updatedAnchorEls);
  };

  const updatePreconfiguration = async (updatedPreconfig: Preconfiguration) => {
    setIsUpdating(true); // Set loading state to true before making the request
    try {
      // Make the PUT request to update the preconfiguration in the database
      const response = await putClientPreconfiguration(
        id,
        updatedPreconfig,
        updatedPreconfig.id
      );

      if (response.ok) {
        // If the update was successful, update the state
        const updatedPreconfigurations = preconfigurations.map((preconfig) => {
          if (preconfig.id === updatedPreconfig.id) {
            return updatedPreconfig; // Update the specific preconfig
          }
          return preconfig;
        });
        setPreconfigurations(updatedPreconfigurations);
        setisEditPreConfigPosted(true);
      } else {
        // Handle error response from the server
        console.error("Error updating preconfiguration:", response.statusText);
        setisEditPreConfigPosted(false);
      }
    } catch (error) {
      // Handle other errors
      console.error("Error:", error);
      setisEditPreConfigPosted(false);
    } finally {
      setIsUpdating(false); // Reset loading state after the request is completed
    }
  };

  // Edit Start
  const handleEdit = (rowData: Preconfiguration) => {
    setisEditButtonClicked(true);
    setOriginalData(rowData); 
    setEditedRowData(rowData);
    editedPreConfigName.current = rowData.name;
    originalName.current = rowData.name;
    editedBaudrate.current = rowData.configuration.baudrate;
    editedDisplayType.current = rowData.configuration.device_type;
    editedWeightDisplay.current = rowData.configuration.weight_display;
    setEditedGroups(rowData.configuration.groups);
    setEditedSensors(rowData.configuration.sensors);
    setEditedCalibrations(rowData.calibration);
    setEditDialogOpen(true);
    handleMenuClose(products.findIndex((product) => product.id === rowData.id));
  };

  const handleReview = () => {
    if (editedRowData && isFieldValid) {
      const editedGroupsUpdate = editedGroups.map((group) => ({
        ...group,
        group_name: group.group_name,
        group_number: parseInt(group.group_number.toString()),
        sensor_ports: group.sensor_ports,
      }));

      const updatedCalibrations = editedCalibrations.map((calibration) => {
        const editedGroup = editedGroupsUpdate.find(
          (group) =>
            parseInt(group.group_number.toString()) ===
            parseInt(calibration.axle_group_number.toString())
        );
        if (editedGroup) {
          const updatedSensorCalibrations = calibration.sensor_calibrations.map(
            (sensorCalibration) => {
              const correspondingPort = editedGroup.sensor_ports.find(
                (sensor) => sensor.port === sensorCalibration.sensor_port
              );
              return {
                ...sensorCalibration,
                sensor_port: correspondingPort
                  ? parseInt(correspondingPort.port.toString())
                  : parseInt(sensorCalibration.sensor_port.toString()),
                empty_adc: sensorCalibration.empty_adc,
                heavy_adc: sensorCalibration.heavy_adc,
                empty_weight: sensorCalibration.empty_weight,
                heavy_weight: sensorCalibration.heavy_weight,
              };
            }
          );

          return {
            ...calibration,
            axle_group_name: editedGroup.group_name,
            axle_group_number: parseInt(editedGroup.group_number.toString()),
            sensor_calibrations: updatedSensorCalibrations,
          };
        } else {
          return calibration;
        }
      });

      const editedPreconfig = {
        id: editedRowData.id,
        name: editedPreConfigName.current,
        type: editedRowData.type,
        updated_at: editedRowData.updated_at,
        date_time: editedRowData.date_time,
        calibration: updatedCalibrations,
        configuration: {
          ...editedRowData.configuration,
          baudrate: editedBaudrate.current,
          device_type: editedDisplayType.current,
          weight_display: editedWeightDisplay.current,
          groups: editedGroupsUpdate,
          sensors: editedSensors,
        },
      };

      setNewPreconfigData(editedPreconfig);
      setEditActiveStep((prevActiveStep) => prevActiveStep + 1);
      return editedPreconfig; // Return the edited preconfiguration
    }
  };

  const handleSaveEdit = () => {
    const editedPreconfig = handleReview(); // Call handleReview to get the edited preconfiguration
    if (editedPreconfig) {
      updatePreconfiguration(editedPreconfig); // Save the edited preconfiguration
      // setEditDialogOpen(false);
      setEditedRowData(null);
    }
  };

  const handleCloseEditDialog = () => {
    setEditDialogOpen(false);
    setEditActiveStep(0);
  };

  //Delete Dialog open on delete click
  const handleDelete = (rowData: Preconfiguration) => {
    setDeleteActiveStep(0);
    setDeleteResult("");
    setRowDataToDelete(rowData);
    setDeleteDialogOpen(true); // Open the delete dialog
    handleMenuClose(products.findIndex((product) => product.id === rowData.id));
    //  It finds the index of the product in the products array based on its id property and then passes
    // this index to handleMenuClose to close the actions menu associated with that product.
    //show a dialog you cannot delete
  };

  //Delete actual pre config record
  const handleDeletePreConfig = async () => {
    if (rowDataToDelete) {
      try {
        const resp = await deletePreconfig(id, rowDataToDelete.id);
        // Update table data after deletion
        setPreconfigurations((prevData) =>
          prevData.filter((item) => item !== rowDataToDelete)
        );
        setDeleteActiveStep(1);
        if (resp === "success") {
          setDeleteResult("Pre-Configuration was successfully deleted");
          setDeleteResultColor("green");
        } else {
          const errorMessages = resp.errors.join(", ");
          setDeleteResult("Error deleting Pre-Configuration: ${errorMessages}");
          setDeleteResultColor("red");
        }
      } catch (error) {
        console.error("Error deleting Pre-Configuration:", error);
        setDeleteResult("An error occurred while making the request");
        setDeleteResultColor("red");
      }
    }
  };

  const handleCloseDeleteDialog = () => {
    setRowDataToDelete(null);
    setDeleteDialogOpen(false);
    setDeleteActiveStep(0);
    setDeleteResult("");
  };

  //Action cell Icon and options
  const renderActionsCell = (rowData: Preconfiguration, index: number) => {
    return (
      <>
        <IconButton
          aria-label="actions"
          aria-controls={`actions-menu-${rowData.id}`}
          aria-haspopup="true"
          onClick={(event) => handleMenuOpen(event, index)}
        >
          <MoreHorizIcon />
        </IconButton>
        <Menu
          id={`actions-menu-${rowData.id}`}
          anchorEl={anchorEls[index]} //This attribute specifies the element that the menu should be anchored to. It uses the anchorEls state array to control the position of the menu.
          open={Boolean(anchorEls[index])} //This attribute determines whether the menu should be open or closed based on the value in the anchorEls array.
          //anchorEls[index] is null/not null. Boolean(someValue) returns true if someValue is truthy (not null, undefined, false, 0, or an empty string), and false otherwise.
          onClose={() => handleMenuClose(index)}
        >
          <MenuItem onClick={() => handleEdit(rowData)}>
            <EditIcon /> Edit
          </MenuItem>
          <MenuItem onClick={() => handleDelete(rowData)}>
            <DeleteIcon /> Delete
          </MenuItem>
        </Menu>
      </>
    );
  };

  let columns: Columns = {
    Name: {
      type: "link",
      display: "Name",
      data: "",
      sort: "Name",
    },
    Type: {
      type: "button",
      display: "Product Type",
      data: "",
      sort: "Type",
      // onClick: (rowData: { Type: any }) => {
      //   const productId = rowData.Type;
      //   userNavigate(`/products/${productId}`);
      // },
    },
    Configuration: {
      type: "text",
      display: "Configuration",
      data: "configuration",
      subColumns: {
        Baudrate: {
          type: "text",
          display: "Baudrate",
          data: "baudrate",
        },
        DeviceType: {
          type: "text",
          display: "Device Type",
          data: "device_type",
        },
        WeightDislplay: {
          type: "text",
          display: "Weight Display",
          data: "weight_display",
        },
        Groups: {
          type: "subhead",
          display: "Groups",
          data: "groups",
          subColumns: {
            GroupName: {
              type: "text",
              display: "Group Name",
              data: "group_name",
            },
            GroupNumber: {
              type: "text",
              display: "Group Number",
              data: "group_number",
            },
            SensorPorts: {
              type: "subhead",
              display: "Sensor Ports",
              data: "sensor_ports",
              subColumns: {
                SensorType: {
                  type: "text",
                  display: "Type",
                  data: "type",
                },
                SensorPort: {
                  type: "text",
                  display: "Port",
                  data: "port",
                },
              },
            },
          },
        },
        Sensors: {
          type: "subhead",
          display: "Sensors",
          data: "sensors",
          subColumns: {
            SensorType: {
              type: "text",
              display: "Type",
              data: "type",
            },
            SensorPort: {
              type: "text",
              display: "Port",
              data: "port",
            },
          },
        },
      },
    },
    UpdatedAt: {
      type: "time",
      display: "Updated At",
      data: "",
      sort: "UpdatedAt",
    },
    DateTime: {
      type: "time",
      display: "Date and Time",
      data: "",
      sort: "DateTime",
    },
    Actions: {
      type: "link",
      display: "Actions",
      render: renderActionsCell,
    },
  };

  let columns1: Columns = {
    Name: {
      type: "text",
      display: "Name",
      data: "",
      sort: "Name",
    },
    Type: {
      type: "text",
      display: "Product Type",
      data: "",
      sort: "Type",
    },
    Configuration: {
      type: "text",
      display: "Configuration",
      data: "configuration",
      subColumns: {
        Baudrate: {
          type: "text",
          display: "Baudrate",
          data: "baudrate",
        },
        DeviceType: {
          type: "text",
          display: "Device Type",
          data: "device_type",
        },
        WeightDislplay: {
          type: "text",
          display: "Weight Display",
          data: "weight_display",
        },
        Groups: {
          type: "subhead",
          display: "Groups",
          data: "groups",
          subColumns: {
            GroupName: {
              type: "text",
              display: "Group Name",
              data: "group_name",
            },
            GroupNumber: {
              type: "text",
              display: "Group Number",
              data: "group_number",
            },
            SensorPorts: {
              type: "subhead",
              display: "Sensor Ports",
              data: "sensor_ports",
              subColumns: {
                SensorType: {
                  type: "text",
                  display: "Type",
                  data: "type",
                },
                SensorPort: {
                  type: "text",
                  display: "Port",
                  data: "port",
                },
              },
            },
          },
        },
        Sensors: {
          type: "subhead",
          display: "Sensors",
          data: "sensors",
          subColumns: {
            SensorType: {
              type: "text",
              display: "Type",
              data: "type",
            },
            SensorPort: {
              type: "text",
              display: "Port",
              data: "port",
            },
          },
        },
      },
    },
    Calibration: {
      type: "subhead",
      display: "Calibration",
      data: "calibration",
      subColumns: {
        AxleGroupName: {
          type: "text",
          display: "Axle Group Name",
          data: "axle_group_name",
        },
        AxleGroupNumber: {
          type: "text",
          display: "Axle Group Number",
          data: "axle_group_number",
        },
        SensorCalibartions: {
          type: "subhead",
          display: "Sensor Calibrations",
          data: "sensor_calibrations",
          subColumns: {
            SensorPort: {
              type: "text",
              display: "Sensor Port",
              data: "sensor_port",
            },
            EmptyADC: {
              type: "text",
              display: "Empty ADC ",
              data: "empty_adc",
            },
            EmptyWeight: {
              type: "text",
              display: "Empty Weight",
              data: "empty_weight",
            },
            HeavyADC: {
              type: "text",
              display: "Heavy ADCt",
              data: "heavy_adc",
            },
            HeavyWeight: {
              type: "text",
              display: "Heavy Weight",
              data: "heavy_weight",
            },
          },
        },
      },
    },
    UpdatedAt: {
      type: "time",
      display: "Updated At",
      data: "",
      sort: "UpdatedAt",
    },
    DateTime: {
      type: "time",
      display: "Date and Time",
      data: "",
      sort: "DateTime",
    },
  };

  useEffect(() => {
    const fetchPreconfiguration = async () => {
      try {
        setLoading(true);

        // Ensure id is not undefined before making the API call
        if (id) {
          const responseData = await getClientPreconfiguration(id);
          setPreconfigurations(responseData); // Ensure responseData.data is not undefined
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false); // Set loading to false after receiving the response
      }
    };

    fetchPreconfiguration();
  }, [id, isPreConfigPosted, isEditPreConfigPosted]);

  useEffect(() => {
    //Fetch Client list data and update the state
    getProducts()
      .then((response: { data: ProductListData[] }) => {
        //the response object data has a property type Client[]
        const data = response.data; //data property is assigened to variable data
        setProducts(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            setErrorMessage("Parameter mismatch");
          } else if (error.response.status === 500) {
            setErrorMessage("Internal Server Error");
          } else {
            // other errors
            setErrorMessage(`Error: ${error.message}`);
          }
        } else if (error.request) {
          // The request was made but no response was received
          setErrorMessage("No response received");
        } else {
          // Something happened in setting up the request that triggered an error
          setErrorMessage(`Error: ${error.message}`);
        }
      });
  }, []);

  useEffect(() => {
    const device_type = newPreconfigData.configuration.device_type;
    if (device_type) {
      getAxleGroup(device_type)
        .then((groups) => {
          setAxleGroups(groups);
        })
        .catch((err) => console.log(err));
    }
  }, [newPreconfigData]);

  useEffect(() => {
    const device_type = editedDisplayType.current;
    if (device_type) {
      getAxleGroup(device_type)
        .then((groups) => {
          setAxleGroups(groups);
        })
        .catch((err) => console.log(err));
    }
  }, [isEditButtonClicked]);

  // Function to filter products and extract product names with shared_configuration_support set to true
  const filterProducts = (products: ProductListData[]) => {
    return products
      .filter((product) => product.shared_configuration_support === true)
      .map((product) => product.name);
  };

  useEffect(() => {
    // Filter products and set the product names state
    const filteredProductNames = filterProducts(products);
    setProductNames(filteredProductNames);
  }, [products]);

  useEffect(() => { }, [configurationErrors]);

  // Function to handle click on Name link
  const handleNameClick = (preconfig: Preconfiguration) => {
    setSelectedPreconfig(preconfig);
    setDialogOpen(true);
  };

  const handleProductTypeClick = (preconfig: PreconfigData) => {
    const productId = preconfig.type;
    userNavigate(`/products/${productId}`);
  };

  // Function to open the dialog
  const handleNewOpenDialog = () => {
    setnewDialogOpen(true);
  };

  // Function to close the dialog
  const handleNewCloseDialog = () => {
    setnewDialogOpen(false);
    setTypeError(""); // Clear type error
    setActiveStep(0); // Reset active step to 0
    setNewPreconfigData((prevData) => ({
      ...prevData,
      name: "",
      type: "",
      calibration: [
        {
          axle_group_name: "",
          axle_group_number: 0,
          sensor_calibrations: [
            {
              sensor_port: 0,
              empty_adc: 0,
              empty_weight: 0,
              heavy_adc: 0,
              heavy_weight: 0,
            },
          ],
        },
      ],
      configuration: {
        baudrate: "",
        device_type: "",
        weight_display: true,
        groups: [
          {
            group_name: "",
            group_number: 0,
            sensor_ports: [{ type: "", port: 0 }],
          },
        ],
        sensors: [{ type: "", port: 0 }],
      },
    }));

    // Clear configuration errors
    setConfigurationErrors({
      baudrate: "",
      device_type: "",
      weight_display: "",
      group_name: [],
      group_number: [],
      sensorportsportconfig: [],
      sensorportcongif: [],
    });
  };

  // Function to handle changes in input fields
  const handleInputChange = (event: { target: { name: any; value: any } }) => {
    const { name, value } = event.target;
    // Perform validation when the Name field changes
    if (name === "name") {
      validateName(value);
    }
    setNewPreconfigData({
      ...newPreconfigData,
      [name]: value,
    });
  };

  const handleWeightsChange = (
    event: { target: { name: string; value: string } },
    axleGroupIndex: number,
    portIndex: number,
    weightType: string,
    axle_group_name: string,
    axle_group_number: number,
    sensorPortType: string,
    sensor_port: number
  ) => {
    const { name, value } = event.target;

    const updatedData = { ...newPreconfigData };

    // Ensure calibration array is initialized
    if (!updatedData.calibration) {
      updatedData.calibration = [];
    }

    // Ensure calibration for the specific axle group is initialized
    if (!updatedData.calibration[axleGroupIndex]) {
      updatedData.calibration[axleGroupIndex] = {
        axle_group_name: axle_group_name,
        axle_group_number: axle_group_number,
        sensor_calibrations: [],
      };
    }

    // Ensure sensor calibrations array for the specific axle group is initialized
    if (!updatedData.calibration[axleGroupIndex].sensor_calibrations) {
      updatedData.calibration[axleGroupIndex].sensor_calibrations = [];
    }

    // Extracting the specific sensor calibration data
    const axleGroupCalibrations = updatedData.calibration[axleGroupIndex];

    const sensorCalibration =
      axleGroupCalibrations.sensor_calibrations[portIndex];

    // Updating the specific weight value based on weightType and index
    const updatedSensorCalibration = {
      ...sensorCalibration,
      [weightType]: value,
      sensor_port: sensor_port,
    };

    // Update axle group name, number, sensor port type, and port
    axleGroupCalibrations.axle_group_name = axle_group_name;
    axleGroupCalibrations.axle_group_number = axle_group_number;

    // Updating the sensor calibration data at the specified index
    axleGroupCalibrations.sensor_calibrations[portIndex] =
      updatedSensorCalibration;

    // Setting the updated state
    setNewPreconfigData(updatedData);
  };

  const handleBaudRateChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    const lowercaseValue = value.toLowerCase(); //send all values in lower case to API

    setNewPreconfigData((prevData) => ({
      ...prevData,
      configuration: {
        ...prevData.configuration,
        baudrate: lowercaseValue,
      },
    }));

    // Reset the error message for device type
    setConfigurationErrors((prevErrors) => ({
      ...prevErrors,
      baudrate: "",
    }));
  };

  const handleWeightDisplyChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value === "true"; // Convert selected value back to boolean

    setNewPreconfigData((prevData) => ({
      ...prevData,
      configuration: {
        ...prevData.configuration,
        weight_display: value,
      },
    }));

    // Reset the error message for device type
    setConfigurationErrors((prevErrors) => ({
      ...prevErrors,
      weight_display: "",
    }));
  };

  // Function to filter product ID based on product name
  const filterProductIdtoName = (productName: string): string | undefined => {
    const product = products.find((product) => product.name === productName);
    return product ? product.id : undefined;
  };

  // Function to handle changes in the Type selection
  const handleProductTypeChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;

    setNewPreconfigData((prevData) => ({
      ...prevData,
      type: value,
    }));

    const productId = filterProductIdtoName(value);
    if (productId) {
      setProductId(productId);
    }

    // Reset the error message for product type
    setTypeError("");
  };

  const handleDeviceTypeChange = (event: { target: { value: any } }) => {
    const { value } = event.target;
    const lowercaseValue = value.toLowerCase(); //send all values in lower case to API
    // Update state to remove error if a value is selected
    if (value !== "") {
      setConfigurationErrors((prevErrors) => ({
        ...prevErrors,
        device_type: "", // Clear the error message
      }));
    }

    setNewPreconfigData((prevState) => ({
      ...prevState,
      configuration: {
        ...prevState.configuration,
        device_type: lowercaseValue,
        groups: prevState.configuration.groups.map((group) => ({
          ...group,
          group_name: "", // Clear group_name when device type changes
        })),
      },
    }));
  };

  // Function to validate Type selection
  const validateTypeSelection = () => {
    if (!newPreconfigData.type) {
      setTypeError("Please make a selection");
      return false;
    }
    return true;
  };

  const handleAddGroup = () => {
    const newGroup = {
      group_name: "", // Initially empty
      group_number: 0, // Initially empty
      sensor_ports: [{ type: "", port: 0 }], // Initially empty
    };

    setNewPreconfigData((prevState) => ({
      ...prevState,
      configuration: {
        ...prevState.configuration,
        groups: [...prevState.configuration.groups, newGroup], // Add the new group to the existing array
      },
    }));

    // Add a new entry in the configurationErrors state for the new group
    setConfigurationErrors((prevState) => ({
      ...prevState,
      group_name: [...prevState.group_name, ""], // Add a new empty error message for the new group
      group_number: [...prevState.group_number, ""], // Add a new empty error message for the new group
      sensorportsportconfig: [...prevState.sensorportsportconfig, ""], // Add a new empty error message for the new group
    }));
  };

  // Function to handle adding new sensor ports inside groups
  const handleAddSensorPort = (groupIndex: number) => {
    const newSensorPort = { type: "", port: 0 };
    setNewPreconfigData((prevState) => {
      const updatedGroups = [...prevState.configuration.groups];
      updatedGroups[groupIndex].sensor_ports.push(newSensorPort);
      return {
        ...prevState,
        configuration: {
          ...prevState.configuration,
          groups: updatedGroups,
        },
      };
    });

    // Add a new entry in the configurationErrors state for the new sensor port
    setConfigurationErrors((prevState) => ({
      ...prevState,
      sensorportsportconfig: [...prevState.sensorportsportconfig, ""], // Add a new empty error message for the new sensor port
    }));
  };

  // Function to handle changes in the group name
  const handleGroupNameChange = (
    event: { target: { value: any } },
    index: number
  ) => {
    const { value } = event.target;
    const updatedGroups = [...newPreconfigData.configuration.groups];
    updatedGroups[index].group_name = value;
    setNewPreconfigData((prevState) => ({
      ...prevState,
      configuration: {
        ...prevState.configuration,
        groups: updatedGroups,
      },
    }));
  };

  const handleGroupNumberChange = (
    event: { target: { value: any } },
    index: number
  ) => {
    const { value } = event.target;
    const updatedGroups = [...newPreconfigData.configuration.groups];
    updatedGroups[index].group_number = value;
    setNewPreconfigData({
      ...newPreconfigData,
      configuration: {
        ...newPreconfigData.configuration,
        groups: updatedGroups,
      },
    });
  };

  // Function to determine the type based on port
  const determineTypeByPort = (port: string): string => {
    const portNumber = parseInt(port);
    return portNumber >= 0 && portNumber <= 2 ? "air" : "external";
  };

  const handleSensorPortsPortChange = (
    event: { target: { value: any } },
    groupIndex: number,
    portIndex: number
  ) => {
    const newPortValue = event.target.value;
    const updatedGroups = [...newPreconfigData.configuration.groups];

    // Update the port and type
    updatedGroups[groupIndex].sensor_ports[portIndex] = {
      ...updatedGroups[groupIndex].sensor_ports[portIndex],
      port: newPortValue,
      type: determineTypeByPort(newPortValue),
    };

    // Clear all port errors for the group
    setConfigurationErrors((prevErrors) => {
      // Create an array of empty strings with the same length as the number of sensor_ports in the group
      const updatedErrors = [...prevErrors.sensorportsportconfig];
      updatedErrors.length = updatedGroups[groupIndex].sensor_ports.length; // Ensure length matches
      updatedErrors.fill(''); // Fill with empty strings

      return {
        ...prevErrors,
        sensorportsportconfig: updatedErrors,
      };
    });

    // Update the port and type in the state
    updatedGroups[groupIndex].sensor_ports[portIndex].port = newPortValue;
    updatedGroups[groupIndex].sensor_ports[portIndex].type =
      determineTypeByPort(newPortValue); // Determine type based on port

    // Update the corresponding sensor in the sensors array
    const updatedSensors = updatedGroups.flatMap((group) =>
      group.sensor_ports.map((sensor_port) => ({
        port: sensor_port.port,
        type: sensor_port.type,
      }))
    );

    setNewPreconfigData((prevState) => ({
      ...prevState,
      configuration: {
        ...prevState.configuration,
        groups: updatedGroups,
        sensors: updatedSensors,
      },
    }));
  };

  // Function to handle deletion of a sensor row
  const handleDeleteSensorPort = (groupIndex: number, portIndex: number) => {
    setNewPreconfigData((prevData) => {
      const newData = { ...prevData };

      // Remove the sensor port from the group's sensor_ports array
      const deletedPort = newData.configuration.groups[
        groupIndex
      ].sensor_ports.splice(portIndex, 1)[0];

      // Remove the corresponding sensor from the sensors array
      newData.configuration.sensors = newData.configuration.sensors.filter(
        (sensor) => sensor.port !== deletedPort.port
      );

      return newData;
    });
  };

  // Function to handle deletion of a group row
  const handleDeleteGroup = (groupIndex: number) => {
    setNewPreconfigData((prevData) => {
      const newData = { ...prevData };
      const deletedGroup = prevData.configuration.groups[groupIndex];

      // Remove all sensors associated with the deleted group
      newData.configuration.sensors = newData.configuration.sensors.filter(
        (sensor) =>
          !deletedGroup.sensor_ports.some(
            (sensor_port) => sensor_port.port === sensor.port
          )
      );

      // Remove the group itself
      newData.configuration.groups.splice(groupIndex, 1);

      // Clear errors associated with the deleted group
      setConfigurationErrors((prevErrors) => {
        const errors = { ...prevErrors };
        errors.group_name.splice(groupIndex, 1);
        errors.group_number.splice(groupIndex, 1);
        errors.sensorportsportconfig.splice(groupIndex, 1);
        return errors;
      });

      return newData;
    });
  };

  // Function to handle submission of new preconfiguration data
  const handleSubmit = async () => {
    try {
      const postData: MyInterface = {
        name: newPreconfigData.name,
        product_id: productId,
        configuration: {
          ...newPreconfigData.configuration,
          groups: newPreconfigData.configuration.groups.map((group) => ({
            ...group,
            group_number: parseInt(group.group_number.toString()),
            sensor_ports: group.sensor_ports.map((port) => ({
              ...port,
              port: parseInt(port.port.toString()),
            })),
          })),
          sensors: newPreconfigData.configuration.sensors.map((sensor) => ({
            ...sensor,
            port: parseInt(sensor.port.toString()),
          })),
        },
        calibration: newPreconfigData.calibration.map((calibration) => ({
          ...calibration,
          axle_group_number: parseInt(calibration.axle_group_number.toString()),
          sensor_calibrations: calibration.sensor_calibrations.map(
            (sensorCalibration) => ({
              ...sensorCalibration,
              sensor_port: parseInt(sensorCalibration.sensor_port.toString()),
              empty_adc: parseInt(sensorCalibration.empty_adc.toString()),
              empty_weight: parseInt(sensorCalibration.empty_weight.toString()),
              heavy_adc: parseInt(sensorCalibration.heavy_adc.toString()),
              heavy_weight: parseInt(sensorCalibration.heavy_weight.toString()),
            })
          ),
        })),
      };

      // POST data
      const response = await postClientPreconfiguration(id, postData);

      if (response.ok) {
        // Firmware update successful
        setisPreConfigPosted(true);
        setActiveStep((prevStep) => prevStep + 1);
      } else {
        // Handle error response from the server
        setisPreConfigPosted(false);
        setActiveStep((prevStep) => prevStep + 1);
        console.error("Error updating firmware:", response.statusText);
      }
    } catch (error) {
      // Handle other errors
      setisPreConfigPosted(false);
      setActiveStep((prevStep) => prevStep + 1);
      console.error("Error:", error);
    }

    // Reset the form and close the dialog
    setNewPreconfigData((prevData) => ({
      ...prevData,
      name: "",
      calibration: [
        {
          axle_group_name: "",
          axle_group_number: 0,
          sensor_calibrations: [
            {
              sensor_port: 0,
              empty_adc: 0,
              empty_weight: 0,
              heavy_adc: 0,
              heavy_weight: 0,
            },
          ],
        },
      ],
      configuration: {
        baudrate: "",
        device_type: "",
        weight_display: true,
        groups: [
          {
            group_name: "",
            group_number: 0,
            sensor_ports: [{ type: "", port: 0 }],
          },
        ],
        sensors: [{ type: "", port: 0 }],
      },
    }));
  };

  const handleFinish = () => {
    handleNewCloseDialog();
  };

  const handleNext = () => {
    // Check if it's the step where Name field is required
    if (activeStep === 0) {
      // Validate the Name field
      const isNameValid = validateName(newPreconfigData.name);
      // If Name is not valid, don't proceed to the next step
      if (!isNameValid) {
        return;
      }
    }

    // Check if it's the step where type selection is required
    if (activeStep === 1) {
      // Validate Type selection
      const isTypeValid = validateTypeSelection();
      // If Type selection is not valid, don't proceed to the next step
      if (!isTypeValid) {
        return;
      }
    }

    // Check if it's the step where configuration fields are required
    if (activeStep === 2) {
      // Update default values if they haven't been manually changed
      const updatedConfigData = {
        ...newPreconfigData,
        configuration: {
          ...newPreconfigData.configuration,
          baudrate: newPreconfigData.configuration.baudrate || "auto",
          weight_display:
            newPreconfigData.configuration.weight_display !== undefined
              ? newPreconfigData.configuration.weight_display
              : true,
        },
      };

      // Set newPreconfigData state with updatedConfigData
      setNewPreconfigData(updatedConfigData);

      const deviceType = newPreconfigData.configuration.device_type;

      // Validate device type
      if (!deviceType) {
        setConfigurationErrors((prevErrors) => ({
          ...prevErrors,
          device_type: "Device Type is required",
        }));
        return; // Stop further processing if device type is missing
      }

      // Validate configuration fields for each group
      newPreconfigData.configuration.groups.forEach((group, index) => {
        validateConfigurationFields(
          {
            group_name: group.group_name,
            group_number: group.group_number,
            // sensorportsportconfig: group.sensor_ports[0]?.port, // Use optional chaining to avoid errors if sensor_ports or its first element is null or undefined
            sensorportsportconfig: group.sensor_ports.map((port) => port.port),
          },
          index
        );
      });

      // Check for duplicate ports within each group
      // Initialize a flag to track if there are any duplicate ports
      let hasDuplicatePorts = false;
      // Create a copy of the current configuration errors to update
      const updatedErrors = { ...configurationErrors };

      // Loop through each group in the configuration
      newPreconfigData.configuration.groups.forEach((group, groupIndex) => {
        // Extract the list of ports from the current group
        const ports = group.sensor_ports.map((port) => port.port);
        // Create a set of unique ports from the list of ports
        const uniquePorts = new Set(ports);

        // Check if the number of unique ports is less than the total number of ports
        // This indicates there are duplicates
        if (uniquePorts.size !== ports.length) {
          // Set the flag to indicate that duplicates are present
          hasDuplicatePorts = true;
          // Initialize or retrieve the current errors for sensor ports
          const errors = updatedErrors.sensorportsportconfig || [];

          // Loop through each port in the current group
          group.sensor_ports.forEach((port, portIndex) => {
            // Check if the current port is a duplicate
            if (ports.indexOf(port.port) !== ports.lastIndexOf(port.port)) {
              errors[portIndex] = 'Port must be unique within the group'; // Set error for duplicate ports
            } else {
              errors[portIndex] = ''; // Clear error if no duplicate
            }
          });
          // Update the configuration errors with the new errors array
          updatedErrors.sensorportsportconfig = errors;
        }
      });

      setConfigurationErrors(updatedErrors);

      if (hasDuplicatePorts) {
        return; // Do not proceed if duplicate ports are found
      }

      // Check if any group configuration is not valid
      const isConfigurationValid =
        !Object.values(configurationErrors).some((groupErrors) => {
          if (Array.isArray(groupErrors)) {
            return groupErrors.some((error) => error !== "");
          }
          return false;
        }) && newPreconfigData.configuration.device_type !== "";

      // If any group configuration is not valid, don't proceed to the next step
      if (!isConfigurationValid) {
        return;
      }

      // Pass selected axle groups to case 3
      setSelectedAxleGroups([...newPreconfigData.configuration.groups]);
    }

    // Check if it's the step where calibration fields are required
    if (activeStep === 3) {
      // Initialize an array to store validation results
      const validationResults: ValidationResult[] = [];

      // Update default values if they haven't been manually changed
      const updatedCalibData = {
        ...newPreconfigData,
        calibration: selectedAxleGroups.map((selectedGroup, calibIndex) => {
          const calib = newPreconfigData.calibration[calibIndex] || {}; // Use existing calib if available
          return {
            ...calib,
            axle_group_name: selectedGroup.group_name,
            axle_group_number: selectedGroup.group_number,
            sensor_calibrations: selectedGroup.sensor_ports.map(
              (sensor_port, sensorIndex) => {
                const existingSensorCalibration =
                  calib.sensor_calibrations &&
                  calib.sensor_calibrations[sensorIndex];
                return {
                  sensor_port: sensor_port.port,
                  empty_adc: existingSensorCalibration
                    ? existingSensorCalibration.empty_adc
                    : 0, // Use existing value if available
                  empty_weight: existingSensorCalibration
                    ? existingSensorCalibration.empty_weight
                    : 0,
                  heavy_adc: existingSensorCalibration
                    ? existingSensorCalibration.heavy_adc
                    : 0,
                  heavy_weight: existingSensorCalibration
                    ? existingSensorCalibration.heavy_weight
                    : 0,
                };
              }
            ),
          };
        }),
      };

      // Set newPreconfigData state with updatedConfigData
      setNewPreconfigData(updatedCalibData);

      // Validate calibration fields for each axle group
      updatedCalibData.calibration.forEach((calib, calibIndex) => {
        calib.sensor_calibrations.forEach((sensorCalibration, sensorIndex) => {
          const validationResult: ValidationResult = {
            isValid: validateCalibrationFields(
              {
                empty_adc: sensorCalibration.empty_adc,
                empty_weight: sensorCalibration.empty_weight,
                heavy_adc: sensorCalibration.heavy_adc,
                heavy_weight: sensorCalibration.heavy_weight,
              },
              calibIndex, // Pass the index of the axle group
              sensorIndex // Pass the index of the sensor
            ),
          };

          // Push the validation result to the array
          validationResults.push(validationResult);
        });
      });

      // Check if all validations passed for all sensor calibrations
      const isCalibrationValid = validationResults.every(
        (result) => result.isValid
      );

      // If calibration fields are not valid, don't proceed to the next step
      if (!isCalibrationValid) {
        return;
      }
    }

    // Proceed to the next step
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  //Edit handle

  // Function to handle name change event
  const handleEditNameChange = (e: { target: { value: any } }) => {
    setNameError("");
    const newName = e.target.value;
    // Update editedPreConfigName with the new name
    editedPreConfigName.current = newName;
  };

  const handleEditGroupNameChange = (
    event: { target: { value: any } },
    index: number
  ) => {
    const { value } = event.target;
    setEditedGroups((prevGroups) => {
      const updatedGroups = [...prevGroups];
      updatedGroups[index] = { ...updatedGroups[index], group_name: value };

      setEditedCalibrations((prevCalibrations) => {
        const updatedCalibrations = [...prevCalibrations];
        updatedCalibrations[index] = { ...updatedCalibrations[index], axle_group_name: value };
        return updatedCalibrations;
      })

      return updatedGroups;
    });
  };

  const handleEditGroupNumberChange = (
    event: { target: { value: any } },
    index: number
  ) => {
    const { value } = event.target;
    setEditedGroups((prevGroups) => {
      const updatedGroups = [...prevGroups];
      updatedGroups[index] = { ...updatedGroups[index], group_number: value };

      setEditedCalibrations((prevCalibrations) => {
        const updatedCalibrations = [...prevCalibrations];
        updatedCalibrations[index] = { ...updatedCalibrations[index], axle_group_number: value };
        return updatedCalibrations;
      })

      return updatedGroups;
    });
  };

  const handleEditSensorPortsPortChange = (
    event: { target: { value: any } },
    groupIndex: number,
    portIndex: number
  ) => {
    const { value } = event.target;
    const updatedGroups = [...editedGroups];

    // Clear all port errors for the group
    setConfigurationErrors((prevErrors) => {
      // Create an array of empty strings with the same length as the number of sensor_ports in the group
      const updatedErrors = [...prevErrors.sensorportsportconfig];
      updatedErrors.length = updatedGroups[groupIndex].sensor_ports.length; // Ensure length matches
      updatedErrors.fill(''); // Fill with empty strings

      return {
        ...prevErrors,
        sensorportsportconfig: updatedErrors,
      };
    });

    // No duplicate found, Update the port in editedGroups
    updatedGroups[groupIndex].sensor_ports[portIndex].port = parseInt(value);
    updatedGroups[groupIndex].sensor_ports[portIndex].type =
      determineTypeByPort(value);

    // Update editedGroups state
    setEditedGroups(updatedGroups);

    // Update editedSensors based on updatedGroups
    const updatedSensors = updatedGroups.flatMap((group) =>
      group.sensor_ports.map((sensor_port) => ({
        port: sensor_port.port,
        type: sensor_port.type,
      }))
    );

    // Update editedSensors state
    setEditedSensors(updatedSensors);

    // Update the corresponding sensor port in editedCalibrations
    const updatedCalibrations = editedCalibrations.map(
      (calibration, calibrationIndex) => ({
        ...calibration,
        sensor_calibrations: calibration.sensor_calibrations.map(
          (sensorCalibration, sensorIndex) => {
            // Check if the calibration's sensor index matches the port index and calibration index
            if (sensorIndex === portIndex && calibrationIndex === groupIndex) {
              // Assign the updated sensor port value
              return {
                ...sensorCalibration,
                sensor_port: parseInt(value),
              };
            }
            return sensorCalibration; // Return unchanged if not matching
          }
        ),
      })
    );

    // Update editedCalibrations state
    setEditedCalibrations(updatedCalibrations);
  };

  const handleEditCalibrationChange = (
    e: { target: { value: any } },
    calibrationIndex: number,
    sensorIndex: number,
    propertyType: string
  ) => {
    const { value } = e.target;

    // Create a copy of the editedCalibrations array
    const updatedCalibrations = [...editedCalibrations];

    // Ensure the calibration index is within bounds
    if (
      calibrationIndex >= 0 &&
      calibrationIndex < updatedCalibrations.length
    ) {
      const sensorCalibrations =
        updatedCalibrations[calibrationIndex].sensor_calibrations;

      // Check if the sensor index is within bounds and if sensor calibration data exists
      if (
        sensorIndex >= 0 &&
        sensorIndex < sensorCalibrations.length &&
        sensorCalibrations[sensorIndex]
      ) {
        const sensorCalibration = sensorCalibrations[sensorIndex];

        // Update sensor calibration properties based on propertyType
        switch (propertyType) {
          case "empty_weight":
            sensorCalibration.empty_weight = parseFloat(value);
            break;
          case "heavy_weight":
            sensorCalibration.heavy_weight = parseFloat(value);
            break;
          case "empty_adc":
            sensorCalibration.empty_adc = parseFloat(value);
            break;
          case "heavy_adc":
            sensorCalibration.heavy_adc = parseFloat(value);
            break;
          default:
            break;
        }

        // Update axle_group_name and axle_group_number based on editedGroups
        const editedGroup = editedGroups[calibrationIndex];
        if (editedGroup) {
          updatedCalibrations[calibrationIndex].axle_group_name =
            editedGroup.group_name;
          updatedCalibrations[calibrationIndex].axle_group_number = parseInt(
            editedGroup.group_number.toString()
          );
        }

        // Update sensor_port based on editedGroups
        const sensorPort =
          parseInt(
            editedGroups[calibrationIndex]?.sensor_ports[
              sensorIndex
            ]?.port.toString()
          ) || parseInt(editedSensors[sensorIndex]?.port.toString());
        if (sensorPort !== undefined) {
          sensorCalibration.sensor_port = sensorPort;
        }

        // Validate the updated calibration fields
        const isValid = validateEditCalibrationFields(
          sensorCalibration,
          calibrationIndex,
          sensorIndex
        );

        // If the calibration fields are valid, update the state with the modified array
        if (isValid) {
          setEditedCalibrations(updatedCalibrations);
          setIsFieldValid(true);
        } else {
          setIsFieldValid(false);
        }
      } else {
        // Create a new sensor calibration object for the newly added sensor
        const newSensorCalibration = {
          sensor_port: parseInt(
            editedGroups[calibrationIndex].sensor_ports[
              sensorIndex
            ].port.toString()
          ),
          empty_weight: 0,
          heavy_weight: 0,
          empty_adc: 0,
          heavy_adc: 0,
        };

        // Add the new sensor calibration object to the sensorCalibrations array
        sensorCalibrations[sensorIndex] = newSensorCalibration;

        // Update the state with the modified array
        setEditedCalibrations(updatedCalibrations);
      }
    }
  };

  const handleEditAddGroup = () => {
    const newGroup: Group = {
      group_name: "",
      group_number: 0,
      sensor_ports: [
        {
          type: "",
          port: 0,
        },
      ],
    };
    setEditedGroups((prevGroups) => [...prevGroups, newGroup]);
    // Update the sensors state with the new sensor ports
    setEditedSensors((prevSensors) => [
      ...prevSensors,
      ...newGroup.sensor_ports.map((sensor) => ({
        type: sensor.type,
        port: sensor.port,
      })),
    ]);

    // Update the calibration data with the new group's calibration
    const newCalibration: Calibration = {
      axle_group_name: newGroup.group_name,
      axle_group_number: newGroup.group_number,
      sensor_calibrations: [
        {
          sensor_port: 0,
          empty_adc: 0,
          empty_weight: 0,
          heavy_adc: 0,
          heavy_weight: 0,
        },
      ],
    };

    // Update editedCalibrations state
    setEditedCalibrations((prevCalibrations) => [
      ...prevCalibrations,
      newCalibration,
    ]);

    // Add a new entry in the configurationErrors state for the new group
    setConfigurationErrors((prevState) => ({
      ...prevState,
      group_name: [...prevState.group_name, ""], // Add a new empty error message for the new group
      group_number: [...prevState.group_number, ""], // Add a new empty error message for the new group
      sensorportsportconfig: [...prevState.sensorportsportconfig, ""], // Add a new empty error message for the new group
    }));
  };

  const handleEditAddSensorPort = (groupIndex: number) => {
    const newSensor: Sensor = {
      type: "",
      port: 0,
    };

    setEditedGroups((prevGroups) => {
      const updatedGroups = [...prevGroups];
      updatedGroups[groupIndex].sensor_ports.push(newSensor);

      // Update the sensors state with the new sensor port
      setEditedSensors((prevSensors) => [...prevSensors, newSensor]);

      // Update the calibration data for the newly added sensor port
      setEditedCalibrations((prevCalibrations) => {
        const updatedCalibrations = prevCalibrations.map(
          (calibration, calibrationIndex) => {
            // Check if the calibration belongs to the group where the new sensor was added
            if (calibrationIndex === groupIndex) {
              const newSensorCalibration = {
                sensor_port: newSensor.port,
                empty_weight: 0,
                heavy_weight: 0,
                empty_adc: 0,
                heavy_adc: 0,
              };
              return {
                ...calibration,
                sensor_calibrations: [
                  ...calibration.sensor_calibrations,
                  newSensorCalibration,
                ],
              };
            }
            return calibration;
          }
        );
        return updatedCalibrations;
      });

      return updatedGroups;
    });

    // Add a new entry in the configurationErrors state for the new sensor port
    setConfigurationErrors((prevState) => ({
      ...prevState,
      sensorportsportconfig: [...prevState.sensorportsportconfig, ""], // Add a new empty error message for the new sensor port
    }));
  };

  const handleEditDeleteGroup = (groupIndex: number) => {
    setEditedGroups((prevGroups) => {
      const updatedGroups = [...prevGroups];
      const deletedGroup = updatedGroups.splice(groupIndex, 1)[0]; // Remove the deleted group
      updatedGroups.splice(groupIndex, 1);

      // Remove the deleted group's sensor ports from the sensors state
      setEditedSensors((prevSensors) =>
        prevSensors.filter(
          (sensor) => sensor.port !== deletedGroup.group_number
        )
      );

      setEditedCalibrations((prevCalibrations) => {
        const updatedCalibrations = [...prevCalibrations];
        const deletedCalibrations = updatedCalibrations.splice(groupIndex, 1)[0];
        updatedCalibrations.splice(groupIndex, 1);
        return updatedCalibrations;
      })

      return updatedGroups;
    });
  };

  const handleEditDeleteSensorPort = (
    groupIndex: number,
    portIndex: number
  ) => {
    setEditedGroups((prevGroups) => {
      const updatedGroups = [...prevGroups];
      const deletedPort = updatedGroups[groupIndex].sensor_ports.splice(
        portIndex,
        1
      )[0];

      // Remove the deleted sensor port from the sensors state
      if (deletedPort) {
        // Add a check to ensure deletedPort is defined
        setEditedSensors((prevSensors) =>
          prevSensors.filter((sensor) => sensor.port !== deletedPort.port)
        );
      }

      setEditedCalibrations((prevCalibrations) => {
        const updatedCalibrations = [...prevCalibrations];
        const deletedCalibrations = updatedCalibrations[groupIndex].sensor_calibrations.splice(portIndex, 1)[0];

        return updatedCalibrations
      })

      return updatedGroups;
    });
  };

  const steps = [
    "Select Name",
    "Select Product Type",
    "Enter Configuration Data",
    "Enter Calibration Data",
    "Review and Submit",
    "Confirmation",
  ];

  const getStepContent = (stepIndex: any) => {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <Typography>Enter a Unique Name for Preconfiguration: </Typography>
            <TextField
              label="Name"
              name="name"
              value={newPreconfigData.name}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              error={!!nameError}
              helperText={nameError}
            />
          </>
        );
      case 1:
        return (
          <>
            <Typography>Select Product Type</Typography>
            <Select
              label="Type"
              name="type"
              onChange={handleProductTypeChange}
              fullWidth
              margin="dense"
            >
              {productNames.map((productName, index) => (
                <MenuItem key={index} value={productName}>
                  {productName}
                </MenuItem>
              ))}
            </Select>
            {typeError && (
              <Typography variant="body2" color="error">
                {typeError}
              </Typography>
            )}
          </>
        );

      case 2:
        return (
          <>
            <Typography variant="h5" align="center">
              <b>Enter Configuration Data</b>
            </Typography>
            <div style={{ display: "flex" }}>
              <div style={{ flex: 1, marginRight: "10px" }}>
                <Typography>Device Type: </Typography>
                <Select
                  label="Device Type"
                  name="devicetype"
                  value={newPreconfigData.configuration.device_type}
                  onChange={handleDeviceTypeChange}
                  fullWidth
                  margin="dense"
                  error={!!configurationErrors.device_type}
                >
                  <MenuItem value="truck">Truck</MenuItem>
                  <MenuItem value="trailer">Trailer</MenuItem>
                </Select>
                {configurationErrors.device_type && (
                  <Typography variant="body2" color="error">
                    {configurationErrors.device_type}
                  </Typography>
                )}
              </div>
              <div style={{ flex: 1, margin: "0 5px" }}>
                <Typography>Baudrate: </Typography>
                <Select
                  defaultValue="auto"
                  label="Baudrate"
                  name="baudrate"
                  value={newPreconfigData.configuration.baudrate || "auto"}
                  onChange={handleBaudRateChange}
                  fullWidth
                  margin="dense"
                >
                  <MenuItem value="auto">Auto</MenuItem>
                  <MenuItem value="250">250</MenuItem>
                  <MenuItem value="500">500</MenuItem>
                </Select>
              </div>
              <div style={{ flex: 1, marginLeft: "10px" }}>
                <Typography>Weight Display: </Typography>
                <Select
                  defaultValue="true"
                  label="Weight Display"
                  name="weightdisplay"
                  value={String(newPreconfigData.configuration.weight_display)}
                  onChange={handleWeightDisplyChange}
                  fullWidth
                  margin="dense"
                >
                  <MenuItem value="true">True</MenuItem>
                  <MenuItem value="false">False</MenuItem>
                </Select>
              </div>
            </div>

            <Divider style={{ margin: "10px 0", backgroundColor: "gray" }} />

            <Typography variant="h5" align="center" paddingTop={2}>
              <b>Groups</b>
            </Typography>
            {newPreconfigData.configuration.groups.map((group, index) => (
              <div key={index}>
                <div
                  style={{
                    marginBottom: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6">
                    <b>Group {index + 1}</b>
                  </Typography>
                  {index !== 0 && (
                    <Button
                      variant="contained"
                      onClick={() => handleDeleteGroup(index)}
                      style={{ marginTop: "10px" }}
                      color={theme.backbuttoncolor}
                    >
                      Delete Group
                    </Button>
                  )}
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <div style={{ flex: 1, marginRight: "10px" }}>
                    <Typography>Group Name: </Typography>
                    <Select
                      value={group.group_name}
                      onChange={(event) => handleGroupNameChange(event, index)}
                      fullWidth
                      margin="dense"
                      error={!!configurationErrors[`group_name-${index}`]}
                    >
                      {axleGroups &&
                        axleGroups.map(
                          (group: { value: string; display: string }) => (
                            <MenuItem key={group.value} value={group.value}>
                              {group.display}
                            </MenuItem>
                          )
                        )}
                    </Select>
                    {configurationErrors.group_name[index] && (
                      <FormHelperText error>
                        {configurationErrors.group_name[index]}
                      </FormHelperText>
                    )}
                  </div>
                  <div style={{ flex: 1, marginRight: "10px" }}>
                    <Typography>Group Number: </Typography>
                    <Select
                      type="number"
                      label="Group Number"
                      name="group_number"
                      value={group.group_number}
                      onChange={(event) =>
                        handleGroupNumberChange(event, index)
                      }
                      fullWidth
                      margin="dense"
                      error={!!configurationErrors[`group_number-${index}`]}
                    >
                      <MenuItem value="0">0</MenuItem>
                      <MenuItem value="1">1</MenuItem>
                      <MenuItem value="2">2</MenuItem>
                      <MenuItem value="3">3</MenuItem>
                      <MenuItem value="4">4</MenuItem>
                      <MenuItem value="5">5</MenuItem>
                      <MenuItem value="6">6</MenuItem>
                    </Select>
                    {configurationErrors.group_number[index] && (
                      <FormHelperText error>
                        {configurationErrors.group_number[index]}
                      </FormHelperText>
                    )}
                  </div>
                </div>
                <div style={{ flex: 1 }}>
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ marginTop: "10px" }}>
                      <Typography variant="h6">
                        <u>Sensors</u>
                      </Typography>
                    </div>
                  </div>

                  {group.sensor_ports.map((sensor_port, portIndex) => (
                    <div key={portIndex} style={{ display: "flex" }}>
                      <div
                        style={{
                          flex: 1,
                          marginRight: "10px",
                          marginTop: "2px",
                        }}
                      >
                        <Typography style={{ marginBottom: "15px" }}>
                          Port:{" "}
                        </Typography>
                        <Select
                          type="number"
                          label="Sensor Port Port"
                          name={`sensorportsportconfig-${index}-${portIndex}`}
                          value={sensor_port.port}
                          onChange={(event) =>
                            handleSensorPortsPortChange(event, index, portIndex)
                          }
                          fullWidth
                          margin="dense"
                          error={
                            !!configurationErrors.sensorportsportconfig[
                            portIndex
                            ]
                          }
                        >
                          <MenuItem value="0">0</MenuItem>
                          <MenuItem value="1">1</MenuItem>
                          <MenuItem value="2">2</MenuItem>
                          <MenuItem value="3">3</MenuItem>
                          <MenuItem value="4">4</MenuItem>
                          <MenuItem value="5">5</MenuItem>
                          <MenuItem value="6">6</MenuItem>
                        </Select>
                        {/* Display error message for the corresponding field */}
                        {configurationErrors.sensorportsportconfig[
                          portIndex
                        ] && (
                            <FormHelperText error>
                              {
                                configurationErrors.sensorportsportconfig[
                                portIndex
                                ]
                              }
                            </FormHelperText>
                          )}

                        {/* {configurationErrors.sensorportsportconfig[index] && (
                          <FormHelperText error>
                            {configurationErrors.sensorportsportconfig[index]}
                          </FormHelperText>
                        )} */}
                      </div>
                      <div style={{ flex: 1 }}>
                        <Typography>Type: </Typography>
                        <TextField
                          name={`sensorportstypeconfig-${index}-${portIndex}`}
                          InputProps={{
                            readOnly: true, // Make the TextField readonly
                          }}
                          value={sensor_port.type}
                          fullWidth
                          margin="normal"
                          error={
                            !!configurationErrors.sensorportsportconfig[
                            portIndex
                            ]
                          }
                        />
                        {/* Display error message for the corresponding field */}
                        {configurationErrors.sensorportsportconfig[
                          portIndex
                        ] && (
                            <FormHelperText error>
                              {
                                configurationErrors.sensorportsportconfig[
                                portIndex
                                ]
                              }
                            </FormHelperText>
                          )}
                      </div>
                      {/* Conditional rendering of delete button for sensor row */}
                      {portIndex !== 0 && (
                        <IconButton
                          onClick={() =>
                            handleDeleteSensorPort(index, portIndex)
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </div>
                  ))}
                </div>
                <div style={{ marginBottom: "20px" }}>
                  <Button
                    variant="contained"
                    onClick={() => handleAddSensorPort(index)}
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    Add New Sensor
                  </Button>
                </div>
                {index !== newPreconfigData.configuration.groups.length - 1 && (
                  <Divider style={{ height: "2px", backgroundColor: "gray" }} />
                )}
              </div>
            ))}

            <Divider style={{ height: "2px", backgroundColor: "gray" }} />

            <div style={{ marginTop: "20px" }}>
              <Button
                variant="contained"
                onClick={handleAddGroup}
                style={{ marginTop: "10px" }}
              >
                Add New Group
              </Button>
            </div>
          </>
        );

      case 3:
        return (
          <>
            <Typography variant="h5" align="center">
              <b>
                <u>Enter Calibration Data: </u>
              </b>
            </Typography>
            {selectedAxleGroups.map((group, index) => (
              <div
                key={index}
                style={{ marginRight: "10px", flex: 1, marginTop: "10px" }}
              >
                <Typography variant="h6" marginBottom={2}>
                  <b>Axle Group {index + 1}:</b>
                </Typography>

                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px", flex: 1 }}>
                    <Typography>Axle Group Name: </Typography>
                    <TextField
                      name="axle_group_name"
                      fullWidth
                      margin="normal"
                      value={group.group_name}
                      disabled
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <Typography>Axle Group Number: </Typography>
                    <TextField
                      type="number"
                      name="axle_group_number"
                      fullWidth
                      margin="normal"
                      value={group.group_number}
                      disabled
                    />
                  </div>
                </div>
                <div style={{ flex: 1 }}>
                  {group.sensor_ports.map((port, portIndex) => (
                    <>
                      <Typography variant="h6">
                        <u>{`Sensor ${portIndex + 1}: `}</u>
                      </Typography>
                      <div key={portIndex} style={{ marginBottom: "10px" }}>
                        <Typography variant="h6">
                          <u>
                            Enter Calibration Weight Data for Sensor{" "}
                            {portIndex + 1}:
                          </u>
                        </Typography>
                        <Typography variant="subtitle2" marginBottom={2}>
                          (Enter all weights in kgs)
                        </Typography>
                        <div style={{ display: "flex" }}>
                          <div style={{ marginRight: "10px", flex: 1 }}>
                            <Typography>Port: </Typography>
                            <TextField
                              type="number"
                              value={`${port.port}`}
                              fullWidth
                              margin="normal"
                              disabled // Disable the TextField
                            />
                          </div>
                          <div style={{ flex: 1 }}>
                            <Typography>Type: </Typography>
                            <TextField
                              value={`${port.type}`}
                              fullWidth
                              margin="normal"
                              disabled // Disable the TextField
                            />
                          </div>
                        </div>
                        <div style={{ display: "flex", marginTop: "10px" }}>
                          <div style={{ marginRight: "10px" }}>
                            <Typography>Empty ADC: </Typography>
                            <TextField
                              type="number"
                              label="Empty ADC"
                              name={`empty_adc-${index}-${portIndex}`}
                              fullWidth
                              margin="normal"
                              error={
                                !!calibrationErrors[index]?.[portIndex]
                                  ?.empty_adc
                              }
                              helperText={
                                calibrationErrors[index]?.[portIndex]
                                  ?.empty_adc || ""
                              }
                              value={
                                newPreconfigData.calibration[index]
                                  ?.sensor_calibrations[portIndex]?.empty_adc ||
                                ""
                              }
                              onChange={(event) => {
                                handleWeightsChange(
                                  event,
                                  index,
                                  portIndex,
                                  "empty_adc",
                                  group.group_name,
                                  group.group_number,
                                  port.type,
                                  port.port
                                );
                              }}
                            />
                          </div>
                          <div style={{ marginRight: "10px" }}>
                            <Typography>Empty Weight: </Typography>
                            <TextField
                              type="number"
                              label="Empty Weight"
                              name={`empty_weight-${index}-${portIndex}`}
                              fullWidth
                              margin="normal"
                              error={
                                !!calibrationErrors[index]?.[portIndex]
                                  ?.empty_weight
                              }
                              helperText={
                                calibrationErrors[index]?.[portIndex]
                                  ?.empty_weight || ""
                              }
                              value={
                                newPreconfigData.calibration[index]
                                  ?.sensor_calibrations[portIndex]
                                  ?.empty_weight || ""
                              }
                              onChange={(event) =>
                                handleWeightsChange(
                                  event,
                                  index,
                                  portIndex,
                                  "empty_weight",
                                  group.group_name,
                                  group.group_number,
                                  port.type,
                                  port.port
                                )
                              }
                            />
                          </div>
                          <div style={{ marginRight: "10px" }}>
                            <Typography>Heavy ADC: </Typography>
                            <TextField
                              type="number"
                              label="Heavy ADC"
                              name={`heavy_adc-${index}-${portIndex}`}
                              fullWidth
                              margin="normal"
                              error={
                                !!calibrationErrors[index]?.[portIndex]
                                  ?.heavy_adc
                              }
                              helperText={
                                calibrationErrors[index]?.[portIndex]
                                  ?.heavy_adc || ""
                              }
                              value={
                                newPreconfigData.calibration[index]
                                  ?.sensor_calibrations[portIndex]?.heavy_adc ||
                                ""
                              }
                              onChange={(event) =>
                                handleWeightsChange(
                                  event,
                                  index,
                                  portIndex,
                                  "heavy_adc",
                                  group.group_name,
                                  group.group_number,
                                  port.type,
                                  port.port
                                )
                              }
                            />
                          </div>
                          <div>
                            <Typography>Heavy Weight: </Typography>
                            <TextField
                              type="number"
                              label="Heavy Weight"
                              name={`heavy_weight-${index}-${portIndex}`}
                              fullWidth
                              margin="normal"
                              error={
                                !!calibrationErrors[index]?.[portIndex]
                                  ?.heavy_weight
                              }
                              helperText={
                                calibrationErrors[index]?.[portIndex]
                                  ?.heavy_weight || ""
                              }
                              value={
                                newPreconfigData.calibration[index]
                                  ?.sensor_calibrations[portIndex]
                                  ?.heavy_weight || ""
                              }
                              onChange={(event) =>
                                handleWeightsChange(
                                  event,
                                  index,
                                  portIndex,
                                  "heavy_weight",
                                  group.group_name,
                                  group.group_number,
                                  port.type,
                                  port.port
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
                {index !== selectedAxleGroups.length - 1 && (
                  <Divider style={{ height: "2px", backgroundColor: "gray" }} />
                )}
              </div>
            ))}
          </>
        );
      case 4:
        return (
          <div>
            <div>
              <ReviewTable newPreconfigData={newPreconfigData} />
            </div>
          </div>
        );
      case 5:
        return (
          /* Step 3: Result */
          <div style={{ padding: "40px" }}>
            {isPreConfigPosted ? (
              <Typography variant="h6" style={{ color: "green" }}>
                Preconfigurations Added Successful
              </Typography>
            ) : (
              <Typography variant="h6" style={{ color: "red" }}>
                Preconfiguration Update Failed. The record has been changed
                since this page was loaded. Please refresh the page and try
                again
              </Typography>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  // Function to validate the Name field
  const validateName = (name: string) => {
    // Regular expression to match the allowed pattern
    const nameRegex = /^[a-zA-Z0-9-_#\s]+$/;
    // Check if name is empty
    if (!name) {
      setNameError("Name is required");
      return false;
    }
    // Check if name matches the allowed pattern
    if (!nameRegex.test(name)) {
      setNameError(
        "Name can only contain alphanumeric characters, '-', '#', or '_', or spaces"
      );
      return false;
    }
    // Check for case insensitivity (convert all names to lowercase for comparison)
    const lowerCaseNames = preconfigurations.map((preconfig) =>
      preconfig.name.toLowerCase()
    );
    if (lowerCaseNames.includes(name.toLowerCase())) {
      setNameError("Name must be unique (case insensitive)");
      return false;
    }
    // If all validations pass, clear any existing error message and return true
    setNameError("");
    return true;
  };

  // Function to validate the Name field
  const validateEditName = (name: string, originalName: string) => {
    // Regular expression to match the allowed pattern
    const nameRegex = /^[a-zA-Z0-9-_#\s]+$/;

    // Check if name is empty
    if (!name) {
      setNameError("Name is required");
      return false;
    }

    // Check if name matches the allowed pattern
    if (!nameRegex.test(name)) {
      setNameError(
        "Name can only contain alphanumeric characters, '-', '#', or '_', or spaces"
      );
      return false;
    }

    // Check for case insensitivity (convert all names to lowercase for comparison)
    const lowerCaseNames = preconfigurations.map((preconfig) =>
      preconfig.name.toLowerCase()
    );
    if (
      lowerCaseNames.includes(name.toLowerCase()) &&
      name.toLowerCase() !== originalName.toLowerCase()
    ) {
      setNameError("Name must be unique (case insensitive)");
      return false;
    }

    // If all validations pass, clear any existing error message and return true
    setNameError("");
    return true;
  };

  // Initialize calibrationErrors state
  useEffect(() => {
    // Initialize calibrationErrors with empty objects for each sensor calibration
    const initialErrors: CalibrationError[][] = selectedAxleGroups.map(
      (group) => [
        {
          empty_adc: "",
          empty_weight: "",
          heavy_adc: "",
          heavy_weight: "",
        },
      ]
    );
    setCalibrationErrors(initialErrors);
  }, [selectedAxleGroups]);

  // Update calibrationErrors for a specific sensor calibration
  const updateCalibrationErrors = (
    axleGroupIndex: number,
    portIndex: number,
    errors: CalibrationError
  ) => {
    setCalibrationErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      // Ensure calibrationErrors for the specific axle group is initialized
      if (!newErrors[axleGroupIndex]) {
        newErrors[axleGroupIndex] = [];
      }
      // Ensure calibrationErrors for the specific sensor is initialized
      if (!newErrors[axleGroupIndex][portIndex]) {
        newErrors[axleGroupIndex][portIndex] = {
          empty_adc: "",
          empty_weight: "",
          heavy_adc: "",
          heavy_weight: "",
        };
      }
      // Update the errors for the specific sensor calibration
      newErrors[axleGroupIndex][portIndex] = errors;
      return newErrors;
    });
  };

  // Validation function for calibration fields
  const validateCalibrationFields = (
    calibrationData: CalibrationData,
    axleGroupIndex: number,
    portIndex: number
  ) => {
    const { empty_adc, empty_weight, heavy_adc, heavy_weight } =
      calibrationData;

    let errors = {
      empty_adc: "",
      empty_weight: "",
      heavy_adc: "",
      heavy_weight: "",
    };

    if (!empty_adc) {
      errors.empty_adc = "Empty ADC is required";
    } else if (empty_adc < 0 || empty_adc > 1495) {
      errors.empty_adc = "Enter valid weight for Empty ADC";
    }

    if (!empty_weight) {
      errors.empty_weight = "Empty Weight is required";
    } else if (empty_weight < 0 || empty_weight > 1495) {
      errors.empty_weight = "Enter valid weight for Empty Weight";
    }

    if (!heavy_adc) {
      errors.heavy_adc = "Heavy ADC is required";
    } else if (heavy_adc < 0 || heavy_adc > 1495) {
      errors.heavy_adc = "Enter valid weight for Heavy ADC";
    }

    if (!heavy_weight) {
      errors.heavy_weight = "Heavy Weight is required";
    } else if (heavy_weight < 0 || heavy_weight > 1495) {
      errors.heavy_weight = "Enter valid weight for Heavy Weight";
    }

    // Update errors for the specific sensor calibration
    updateCalibrationErrors(axleGroupIndex, portIndex, errors);

    return !Object.values(errors).some(Boolean); // Return true if there are no errors
  };

  // Validation function for calibration fields
  const validateEditCalibrationFields = (
    calibrationData: CalibrationData,
    axleGroupIndex: number,
    portIndex: number
  ) => {
    const { empty_adc, empty_weight, heavy_adc, heavy_weight } =
      calibrationData;

    let errors = {
      empty_adc: "",
      empty_weight: "",
      heavy_adc: "",
      heavy_weight: "",
    };

    if (empty_adc === undefined || empty_adc === null || isNaN(empty_adc)) {
      errors.empty_adc = "Empty ADC is required";
    } else if (empty_adc < 0 || empty_adc > 1495) {
      errors.empty_adc = "Enter valid weight for Empty ADC";
    }

    if (
      empty_weight === undefined ||
      empty_weight === null ||
      isNaN(empty_weight)
    ) {
      errors.empty_weight = "Empty Weight is required";
    } else if (empty_weight < 0 || empty_weight > 1495) {
      errors.empty_weight = "Enter valid weight for Empty Weight";
    }

    if (heavy_adc === undefined || heavy_adc === null || isNaN(heavy_adc)) {
      errors.heavy_adc = "Heavy ADC is required";
    } else if (heavy_adc < 0 || heavy_adc > 1495) {
      errors.heavy_adc = "Enter valid weight for Heavy ADC";
    }

    if (
      heavy_weight === undefined ||
      heavy_weight === null ||
      isNaN(heavy_weight)
    ) {
      errors.heavy_weight = "Heavy Weight is required";
    } else if (heavy_weight < 0 || heavy_weight > 1495) {
      errors.heavy_weight = "Enter valid weight for Heavy Weight";
    }

    // Update errors for the specific sensor calibration
    updateCalibrationErrors(axleGroupIndex, portIndex, errors);

    return !Object.values(errors).some(Boolean); // Return true if there are no errors
  };

  const validateConfigurationFields = (
    configurationData: {
      group_name: string;
      group_number: number;
      sensorportsportconfig: number[];
    },
    groupIndex: number
  ) => {
    const { group_name, group_number, sensorportsportconfig } =
      configurationData;
    const { device_type } = newPreconfigData.configuration;

    // Initialize errors object with empty arrays
    let errors = { ...configurationErrors };

    if (!device_type) {
      errors.device_type = "Device Type Number is required";
    }

    // Validate group name
    if (!group_name) {
      errors.group_name[groupIndex] = "Group Name is required";
    } else {
      // Check if the group name is unique
      const groupNames = newPreconfigData.configuration.groups.map(
        (group) => group.group_name
      );
      const isNameUnique = new Set(groupNames).size === groupNames.length;
      if (!isNameUnique) {
        errors.group_name[groupIndex] = "Group Name must be unique";
      } else {
        errors.group_name[groupIndex] = ""; // Clear the error if the name is unique
      }
    }

    // Validate group number
    if (!group_number) {
      errors.group_number[groupIndex] = "Group Number is required";
    } else {
      // Check if the group number is unique
      const groupNumbers = newPreconfigData.configuration.groups.map(
        (group) => group.group_number
      );
      const isNumberUnique = new Set(groupNumbers).size === groupNumbers.length;
      if (!isNumberUnique) {
        errors.group_number[groupIndex] = "Group Number must be unique";
      } else {
        errors.group_number[groupIndex] = ""; // Clear the error if the number is unique
      }
    }

    // Validate sensor ports
    sensorportsportconfig.forEach((port, portIndex) => {
      if (!port) {
        // Set error message if port is empty
        errors.sensorportsportconfig[portIndex] = "Port is required";
      } else {
        // Clear error message if port is provided
        errors.sensorportsportconfig[portIndex] = "";
      }
    });

    // Set the errors in state
    setConfigurationErrors(errors);

    // Return true if there are no errors
    return !Object.values(errors).some(
      (error) => error !== "" && error.length > 0
    );
  };

  const validateEditConfigurationFields = (
    configurationData: {
      group_name: string;
      group_number: number;
      sensorportsportconfig: string[]; //type validation as port is initialized as 0
    },
    groupIndex: number
  ) => {
    const { group_name, group_number, sensorportsportconfig } =
      configurationData;

    // Initialize errors object with empty arrays
    let errors = { ...configurationErrors };

    // Validate group name
    if (!group_name) {
      errors.group_name[groupIndex] = "Group Name is required";
    } else {
      // Check if the group name is unique
      const groupNames = editedGroups.map((group) => group.group_name);
      const isNameUnique = new Set(groupNames).size === groupNames.length;
      if (!isNameUnique) {
        errors.group_name[groupIndex] = "Group Name must be unique";
      } else {
        errors.group_name[groupIndex] = ""; // Clear the error if the name is unique
      }
    }

    // Validate group number
    if (group_number === null || group_number === undefined) {
      errors.group_number[groupIndex] = ""; // No error if group number is null or undefined
    } else {
      // Check if the group number is unique
      const groupNumbers = editedGroups.map((group) => group.group_number);
      const isNumberUnique = new Set(groupNumbers).size === groupNumbers.length;
      if (!isNumberUnique) {
        errors.group_number[groupIndex] = "Group Number must be unique";
      } else {
        errors.group_number[groupIndex] = ""; // Clear the error if the number is unique
      }
    }

    // Validate sensor ports
    sensorportsportconfig.forEach((type, portIndex) => {
      if (!type) {
        // Set error message if port is empty
        errors.sensorportsportconfig[portIndex] =
          "Type is required, Select Port number to get the type";
      } else {
        // Clear error message if port is provided
        errors.sensorportsportconfig[portIndex] = "";
      }
    });

    // Set the errors in state
    setConfigurationErrors(errors);

    // Return true if there are no errors
    return !Object.values(errors).some(
      (error) => error !== "" && error.length > 0
    );
  };

  // Function to get product name by ID
  const getProductNameById = (productId: string) => {
    const product = products.find((product) => product.id === productId);
    return product ? product.name : "Unknown"; // Return 'Unknown' if product is not found
  };

  const handleEditNextStep = () => {
    // Check if it's the step where Name field is required
    if (editactiveStep === 0) {
      // Validate the Name field
      const isNameValid = validateEditName(
        editedPreConfigName.current,
        originalName.current
      );
      if (!isNameValid) {
        return; // Stay on the current step if name is not valid
      }

      // Validate configuration fields for each group
      editedGroups.forEach((group, index) => {
        validateEditConfigurationFields(
          {
            group_name: group.group_name,
            group_number: group.group_number,
            sensorportsportconfig: group.sensor_ports.map((type) => type.type),
          },
          index
        );
      });

      // Check for duplicate ports within each group
      // Initialize a flag to track if there are any duplicate ports
      let hasDuplicatePorts = false;
      // Create a copy of the current configuration errors to update
      const updatedErrors = { ...configurationErrors };

      // Loop through each group in the configuration
      editedGroups.forEach((group, groupIndex) => {
        // Extract the list of ports from the current group
        const ports = group.sensor_ports.map((port) => port.port);
        // Create a set of unique ports from the list of ports
        const uniquePorts = new Set(ports);

        // Check if the number of unique ports is less than the total number of ports
        // This indicates there are duplicates
        if (uniquePorts.size !== ports.length) {
          // Set the flag to indicate that duplicates are present
          hasDuplicatePorts = true;
          // Initialize or retrieve the current errors for sensor ports
          const errors = updatedErrors.sensorportsportconfig || [];

          // Loop through each port in the current group
          group.sensor_ports.forEach((port, portIndex) => {
            // Check if the current port is a duplicate
            if (ports.indexOf(port.port) !== ports.lastIndexOf(port.port)) {
              errors[portIndex] = 'Port must be unique within the group'; // Set error for duplicate ports
            } else {
              errors[portIndex] = ''; // Clear error if no duplicate
            }
          });
          // Update the configuration errors with the new errors array
          updatedErrors.sensorportsportconfig = errors;
        }
      });

      setConfigurationErrors(updatedErrors);

      if (hasDuplicatePorts) {
        return; // Do not proceed if duplicate ports are found
      }

      // Check if any group configuration is not valid
      const isConfigurationValid = !Object.values(configurationErrors).some(
        (groupErrors) => {
          if (Array.isArray(groupErrors)) {
            return groupErrors.some((error) => error !== "");
          }
          return false;
        }
      );

      // If the configuration is not valid, don't proceed to the next step
      if (!isConfigurationValid) {
        return;
      }
    }

    // Proceed to the next step
    setEditActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleEditPreviousStep = () => {
    setEditActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const stepsEdit = [
    "Step 1: EditConfiguration Details",
    "Step 2: Edit Calibration Details",
    "Step 3: Review",
    "Step 4: Confirmation",
  ];

  const loadingDisplay = () => {
    return (
      <>
        <LinearProgress />
        <Stack spacing={2}>
          <Skeleton variant="rectangular" height={50} animation="wave" />
          <Skeleton variant="rectangular" height={50} animation="wave" />
          <Skeleton variant="rectangular" height={50} animation="wave" />
        </Stack>
      </>
    );
    return null;
  };

  return (
    <>
      <Card elevation={8} sx={{ borderRadius: 3 }}>
        <CardHeader
          title="Preconfiguration Details"
          action={
            <>
              <Button
                variant="contained"
                onClick={handleNewOpenDialog} // Open dialog when clicked
              >
                Add pre config
              </Button>
              <IconButton aria-label="info">
                <InfoOutlinedIcon />
              </IconButton>
            </>
          }
        />

        <CardContent>
          <Divider />
          <Box paddingTop={2}>
            {loading ? (
              // Show skeleton when loading is true
              loadingDisplay()
            ) : preconfigurations.length === 0 ? (
              <Alert variant="filled" severity="warning">
                <AlertTitle>No preconfigurations available</AlertTitle>
              </Alert>
            ) : (
              // Display the GeneralTable when loading is false and preconfig is not empty
              <GeneralTable
                data={preconfigurations.map((preconfig, index) => ({
                  Name: (
                    <Button onClick={() => handleNameClick(preconfig)}>
                      {preconfig.name}
                    </Button>
                  ),
                  Type: (
                    <Button onClick={() => handleProductTypeClick(preconfig)}>
                      {getProductNameById(preconfig.type)}
                    </Button>
                  ),
                  Configuration: (
                    <NestedTable
                      data={preconfig.configuration}
                      columns={columns.Configuration.subColumns}
                    />
                  ),
                  UpdatedAt: preconfig.updated_at,
                  DateTime: preconfig.date_time,
                  Actions: renderActionsCell(preconfig, index),
                }))}
                columns={columns}
                noDataMessage={"No Devices to fetch "}
                rows={10}
                rowsoptions={[10, 25, 50, 100]}
                customTableStyle={autoWidthTableStyle}
              />
            )}
            {/* // Dialog to display calibration information */}
            <Dialog
              open={dialogOpen}
              onClose={() => setDialogOpen(false)}
              PaperProps={{ style: { maxWidth: "98vw" } }}
            >
              <DialogTitle variant="h4" align="center">
                Preconfiguration Information
                <IconButton
                  aria-label="close"
                  onClick={() => setDialogOpen(false)}
                  sx={{ position: "absolute", right: 0, top: 0 }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <Divider />
                <GeneralTable
                  data={[
                    {
                      Name: selectedPreconfig?.name || "",
                      Type: selectedPreconfig?.type || "",
                      Configuration: (
                        <NestedTable
                          data={selectedPreconfig?.configuration || []}
                          columns={columns1.Configuration.subColumns}
                        />
                      ),
                      Calibration: (
                        <NestedTable
                          data={selectedPreconfig?.calibration || []}
                          columns={columns1.Calibration.subColumns}
                        />
                      ),
                      "Updated At": selectedPreconfig?.updated_at || "",
                      "Date and Time": selectedPreconfig?.date_time || "",
                    },
                  ]}
                  columns={columns1}
                  noDataMessage={"No Devices to fetch "}
                  rows={10}
                  rowsoptions={[10, 25, 50, 100]}
                  customTableStyle={autoWidthTableStyle}
                />
              </DialogContent>
            </Dialog>

            {/* Dialog for adding new preconfig */}
            <Dialog open={newdialogOpen} onClose={handleNewCloseDialog}>
              <div style={{ margin: "10px" }}>
                <DialogTitle variant="h4" align="center">
                  Add New Preconfiguration
                  <IconButton
                    aria-label="close"
                    onClick={handleNewCloseDialog}
                    sx={{ position: "absolute", right: 0, top: 0 }}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
              </div>
              <DialogContent>
                <Box sx={{ width: "100%" }}>
                  <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label, index) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                  <Box sx={{ mt: 3 }}>
                    <Typography>{getStepContent(activeStep)}</Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mt: 3,
                      }}
                    >
                      <Button
                        disabled={
                          activeStep === 0 || activeStep === steps.length - 1
                        }
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                        variant="contained"
                        color={theme.backbuttoncolor}
                      >
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        color={theme.nextbuttoncolor}
                        onClick={
                          activeStep === steps.length - 2
                            ? handleSubmit
                            : activeStep === steps.length - 1
                              ? handleFinish
                              : handleNext
                        }
                        sx={{ mr: 1 }}
                      >
                        {activeStep === steps.length - 2
                          ? "Submit"
                          : activeStep === steps.length - 1
                            ? "Finish"
                            : "Next"}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </DialogContent>
            </Dialog>

            {/* Do not allow delete */}
            <Dialog open={isDeleteDialogOpen} onClose={handleCloseDeleteDialog}>
              <DialogTitle variant="h4" textAlign="center">
                Delete Pre-Configuration
                <IconButton
                  aria-label="close"
                  onClick={handleCloseDeleteDialog}
                  sx={{ position: "absolute", right: 0, top: 0 }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <Divider />
              <DialogContent
                style={{ paddingTop: 40, paddingLeft: 40, paddingRight: 40 }}
              >
                <Stepper activeStep={deleteactiveStep} alternativeLabel>
                  <Step>
                    <StepLabel>Confirmation</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>Result</StepLabel>
                  </Step>
                </Stepper>
                <Divider style={{ paddingTop: 40 }} />
                {deleteactiveStep === 0 && (
                  <div style={{ paddingTop: 40 }}>
                    <Typography variant="h6">
                      This is an irreversible action. We cannot retrieve this
                      preconfiguration record after it is deleted.
                    </Typography>
                    <Typography variant="h6">
                      This action can cause serious consequences like deletion
                      of other important details linked to this record
                    </Typography>
                    <Typography color="red" variant="h6">
                      Are you sure you want to Delete this preconfiguration
                      record?
                    </Typography>
                    <Box
                      mt={2}
                      display="flex"
                      justifyContent="space-between"
                      style={{ padding: 40 }}
                    >
                      <Button
                        onClick={handleCloseDeleteDialog}
                        variant="contained"
                        color={theme.nextbuttoncolor}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={handleDeletePreConfig}
                        variant="contained"
                        color={theme.backbuttoncolor}
                      >
                        Yes
                      </Button>
                    </Box>
                  </div>
                )}
                {deleteactiveStep === 1 && (
                  <div>
                    <Typography padding={5} fontSize={24}>
                      <span style={{ color: deleteResultColor }}>
                        {deleteResult}
                      </span>
                    </Typography>
                    <Box
                      mt={2}
                      display="flex"
                      justifyContent="center"
                      style={{ padding: 40 }}
                    >
                      <Button
                        onClick={handleCloseDeleteDialog}
                        variant="contained"
                        color={theme.nextbuttoncolor}
                      >
                        Done
                      </Button>
                    </Box>
                  </div>
                )}
              </DialogContent>
            </Dialog>

            {/*  Dialog for Edit Product */}
            <Dialog open={isEditDialogOpen} onClose={handleCloseEditDialog}>
              <DialogTitle variant="h4" align="center">
                Edit Pre-Configuration Details
                <IconButton
                  aria-label="close"
                  onClick={handleCloseEditDialog}
                  sx={{ position: "absolute", right: 0, top: 0 }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent
              // ref={editDialogRef}
              >
                <Stepper activeStep={editactiveStep} alternativeLabel>
                  {stepsEdit.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>

                {/*------Step 1------  */}
                {editactiveStep === 0 && (
                  <div>
                    <TextField
                      label="Name"
                      defaultValue={editedPreConfigName.current}
                      onChange={(e) => handleEditNameChange(e)}
                      fullWidth
                      margin="normal"
                      error={!!nameError}
                      helperText={nameError}
                    />
                    <Divider
                      style={{
                        height: "2px",
                        backgroundColor: "gray",
                        marginBottom: "10px",
                        marginTop: "10px",
                      }}
                    />
                    <Typography variant="h5">Configuration Details</Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Typography>Baudrate: </Typography>
                        <Select
                          label="Baudrate"
                          defaultValue={editedBaudrate.current}
                          onChange={(e) =>
                            (editedBaudrate.current = e.target.value)
                          }
                          fullWidth
                          margin="dense"
                        >
                          <MenuItem value="auto">Auto</MenuItem>
                          <MenuItem value="250">250</MenuItem>
                          <MenuItem value="500">500</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={4} style={{ paddingTop: "33px" }}>
                        <TextField
                          label="Device Type"
                          defaultValue={editedDisplayType.current}
                          onChange={(e) =>
                            (editedDisplayType.current = e.target.value)
                          }
                          fullWidth
                          margin="dense"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>Weight Display: </Typography>
                        <Select
                          defaultValue={editedWeightDisplay.current ? "true" : "false"}
                          label="Weight Display"
                          onChange={(e) =>
                            (editedWeightDisplay.current = e.target.value === "true")
                          }
                          fullWidth
                          margin="dense"
                        >
                          <MenuItem value="true">True</MenuItem>
                          <MenuItem value="false">False</MenuItem>
                        </Select>
                      </Grid>
                    </Grid>
                    <Typography variant="h5">Groups</Typography>
                    {editedGroups.map((group, index) => (
                      <div key={index}>
                        <div
                          style={{
                            marginBottom: "10px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography variant="h6">
                            <b>Group {index + 1}</b>
                          </Typography>
                          {index !== 0 && (
                            <Button
                              variant="contained"
                              onClick={() => handleEditDeleteGroup(index)}
                              style={{ marginTop: "10px" }}
                              color={theme.backbuttoncolor}
                            >
                              Delete Group
                            </Button>
                          )}
                        </div>
                        {/* Group Name and Number in a line */}
                        <div style={{ display: "flex", marginBottom: "10px" }}>
                          <div style={{ width: "100%", marginRight: "10px" }}>
                            <Typography style={{ marginBottom: "10px" }}>
                              Group Name
                            </Typography>
                            <Select
                              label="Group Name"
                              value={group.group_name}
                              name="editgroup_name"
                              onChange={(e) =>
                                handleEditGroupNameChange(e, index)
                              }
                              fullWidth
                              margin="dense"
                              error={
                                !!configurationErrors[`editgroup_name-${index}`]
                              }
                            >
                              {axleGroups &&
                                axleGroups.map(
                                  (group: {
                                    value: string;
                                    display: string;
                                  }) => (
                                    <MenuItem
                                      key={group.value}
                                      value={group.value}
                                    >
                                      {group.display}
                                    </MenuItem>
                                  )
                                )}
                            </Select>
                            {configurationErrors.group_name[index] && (
                              <FormHelperText error>
                                {configurationErrors.group_name[index]}
                              </FormHelperText>
                            )}
                          </div>
                          <div style={{ width: "100%" }}>
                            <Typography style={{ marginBottom: "10px" }}>
                              Group Number
                            </Typography>
                            <Select
                              label="Group Number"
                              type="number"
                              name="editgroup_number"
                              value={group.group_number}
                              onChange={(e) =>
                                handleEditGroupNumberChange(e, index)
                              }
                              fullWidth
                              margin="dense"
                              error={
                                !!configurationErrors[
                                `editgroup_number-${index}`
                                ]
                              }
                            >
                              <MenuItem value="0">0</MenuItem>
                              <MenuItem value="1">1</MenuItem>
                              <MenuItem value="2">2</MenuItem>
                              <MenuItem value="3">3</MenuItem>
                              <MenuItem value="4">4</MenuItem>
                              <MenuItem value="5">5</MenuItem>
                              <MenuItem value="6">6</MenuItem>
                            </Select>
                            {configurationErrors.group_number[index] && (
                              <FormHelperText error>
                                {configurationErrors.group_number[index]}
                              </FormHelperText>
                            )}
                          </div>
                        </div>
                        {/* Ports and Types in a line */}
                        {group.sensor_ports.map((sensor, portIndex) => (
                          <>
                            <Typography>Sensor {portIndex + 1}</Typography>
                            <div
                              key={`${index}-${portIndex}`}
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                style={{ width: "100%", marginRight: "10px" }}
                              >
                                <Typography style={{ marginBottom: "10px" }}>
                                  Port
                                </Typography>
                                <Select
                                  label="Port"
                                  value={sensor.port}
                                  onChange={(e) =>
                                    handleEditSensorPortsPortChange(
                                      e,
                                      index,
                                      portIndex
                                    )
                                  }
                                  fullWidth
                                  margin="dense"
                                  error={
                                    !!configurationErrors.sensorportsportconfig[
                                    portIndex
                                    ]
                                  }
                                >
                                  <MenuItem value="0">0</MenuItem>
                                  <MenuItem value="1">1</MenuItem>
                                  <MenuItem value="2">2</MenuItem>
                                  <MenuItem value="3">3</MenuItem>
                                  <MenuItem value="4">4</MenuItem>
                                  <MenuItem value="5">5</MenuItem>
                                  <MenuItem value="6">6</MenuItem>
                                </Select>
                                {/* Display error message for the corresponding field */}
                                {configurationErrors.sensorportsportconfig[
                                  portIndex
                                ] && (
                                    <FormHelperText error>
                                      {
                                        configurationErrors.sensorportsportconfig[
                                        portIndex
                                        ]
                                      }
                                    </FormHelperText>
                                  )}
                              </div>
                              <div style={{ width: "100%" }}>
                                <Typography>Type</Typography>
                                <TextField
                                  label="Type"
                                  value={sensor.type}
                                  InputProps={{
                                    readOnly: true, // Make the TextField readonly
                                  }}
                                  fullWidth
                                  margin="normal"
                                  error={
                                    !!configurationErrors.sensorportsportconfig[
                                    portIndex
                                    ]
                                  }
                                />
                                {/* Display error message for the corresponding field */}
                                {configurationErrors.sensorportsportconfig[
                                  portIndex
                                ] && (
                                    <FormHelperText error>
                                      {
                                        configurationErrors.sensorportsportconfig[
                                        portIndex
                                        ]
                                      }
                                    </FormHelperText>
                                  )}
                              </div>
                              {/* Conditional rendering of delete button for sensor row */}
                              {portIndex !== 0 && (
                                <IconButton
                                  onClick={() =>
                                    handleEditDeleteSensorPort(index, portIndex)
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              )}
                            </div>
                          </>
                        ))}
                        <div style={{ marginBottom: "20px" }}>
                          <Button
                            variant="contained"
                            onClick={() => handleEditAddSensorPort(index)}
                            style={{
                              marginTop: "10px",
                            }}
                          >
                            Add New Sensor
                          </Button>
                        </div>
                        {index !==
                          newPreconfigData.configuration.groups.length - 1 && (
                            <Divider
                              style={{ height: "2px", backgroundColor: "gray" }}
                            />
                          )}
                      </div>
                    ))}
                    <Divider
                      style={{ height: "2px", backgroundColor: "gray" }}
                    />

                    <div style={{ marginTop: "20px" }}>
                      <Button
                        variant="contained"
                        onClick={handleEditAddGroup}
                        style={{ marginTop: "10px", marginBottom: "10px" }}
                      >
                        Add New Group
                      </Button>
                    </div>
                    <Divider
                      style={{ height: "2px", backgroundColor: "gray" }}
                    />
                  </div>
                )}
                {/* ----Step 1 end--- */}

                {/* -----Step 2 ----*/}
                {editactiveStep === 1 && (
                  <div>
                    <Typography variant="h5">Calibration Details</Typography>
                    {editedGroups.map((group, groupIndex) => (
                      <div
                        key={groupIndex}
                        style={{
                          marginRight: "10px",
                          flex: 1,
                          marginTop: "10px",
                        }}
                      >
                        <Typography variant="h6" marginBottom={2}>
                          <b>Axle Group {groupIndex + 1}:</b>
                        </Typography>
                        <div style={{ display: "flex" }}>
                          <div style={{ marginRight: "10px", flex: 1 }}>
                            <Typography>Axle Group Name: </Typography>
                            <TextField
                              name="axle_group_name"
                              fullWidth
                              margin="normal"
                              value={group.group_name}
                              disabled
                            />
                          </div>
                          <div style={{ flex: 1 }}>
                            <Typography>Axle Group Number: </Typography>
                            <TextField
                              type="number"
                              name="axle_group_number"
                              fullWidth
                              margin="normal"
                              value={group.group_number}
                              disabled
                            />
                          </div>
                        </div>
                        {group.sensor_ports.map((sensor, sensorIndex) => (
                          <div key={`${groupIndex}-${sensorIndex}`}>
                            <Typography variant="h6">
                              <u>{`Sensor ${sensorIndex + 1}: `}</u>
                            </Typography>
                            <div key={sensorIndex}>
                              <Typography variant="h6">
                                <u>
                                  Enter Calibration Weight Data for Sensor{" "}
                                  {sensorIndex + 1}:
                                </u>
                              </Typography>
                              <Typography variant="subtitle2" marginBottom={2}>
                                (Enter all weights in kgs)
                              </Typography>
                              <div style={{ display: "flex" }}>
                                <div style={{ marginRight: "10px", flex: 1 }}>
                                  <Typography>Port: </Typography>
                                  <TextField
                                    type="number"
                                    value={`${sensor.port}`}
                                    fullWidth
                                    margin="normal"
                                    disabled // Disable the TextField
                                  />
                                </div>
                              </div>
                              <Grid container spacing={2}>
                                <Grid item xs={3}>
                                  <TextField
                                    type="number"
                                    label="Empty ADC"
                                    value={
                                      editedCalibrations[groupIndex]
                                        ?.sensor_calibrations[sensorIndex]
                                        ?.empty_adc
                                    }
                                    onChange={(e) =>
                                      handleEditCalibrationChange(
                                        e,
                                        groupIndex,
                                        sensorIndex,
                                        "empty_adc"
                                      )
                                    }
                                    fullWidth
                                    margin="normal"
                                    error={
                                      !!calibrationErrors[groupIndex]?.[
                                        sensorIndex
                                      ]?.empty_adc || // Check if there's an error message
                                      (editedCalibrations[groupIndex]
                                        ?.sensor_calibrations[sensorIndex]
                                        ?.empty_adc === undefined && // Check if the input is undefined (not yet populated)
                                        calibrationErrors[groupIndex]?.[
                                          sensorIndex
                                        ]?.empty_adc === "") // Check if the input is empty (but not undefined)
                                    }
                                    helperText={
                                      calibrationErrors[groupIndex]?.[
                                        sensorIndex
                                      ]?.empty_adc
                                    }
                                    FormHelperTextProps={{ error: true }}
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <TextField
                                    type="number"
                                    label="Empty Weight"
                                    value={
                                      editedCalibrations[groupIndex]
                                        ?.sensor_calibrations[sensorIndex]
                                        ?.empty_weight
                                    }
                                    onChange={(e) =>
                                      handleEditCalibrationChange(
                                        e,
                                        groupIndex,
                                        sensorIndex,
                                        "empty_weight"
                                      )
                                    }
                                    fullWidth
                                    margin="normal"
                                    error={
                                      !!calibrationErrors[groupIndex]?.[
                                        sensorIndex
                                      ]?.empty_weight || // Check if there's an error message
                                      (editedCalibrations[groupIndex]
                                        ?.sensor_calibrations[sensorIndex]
                                        ?.empty_weight === undefined && // Check if the input is undefined (not yet populated)
                                        calibrationErrors[groupIndex]?.[
                                          sensorIndex
                                        ]?.empty_weight === "") // Check if the input is empty (but not undefined)
                                    }
                                    helperText={
                                      calibrationErrors[groupIndex]?.[
                                        sensorIndex
                                      ]?.empty_weight
                                    }
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <TextField
                                    type="number"
                                    label="Heavy ADC"
                                    value={
                                      editedCalibrations[groupIndex]
                                        ?.sensor_calibrations[sensorIndex]
                                        ?.heavy_adc
                                    }
                                    onChange={(e) =>
                                      handleEditCalibrationChange(
                                        e,
                                        groupIndex,
                                        sensorIndex,
                                        "heavy_adc"
                                      )
                                    }
                                    fullWidth
                                    margin="normal"
                                    error={
                                      !!calibrationErrors[groupIndex]?.[
                                        sensorIndex
                                      ]?.heavy_adc || // Check if there's an error message
                                      (editedCalibrations[groupIndex]
                                        ?.sensor_calibrations[sensorIndex]
                                        ?.heavy_adc === undefined && // Check if the input is undefined (not yet populated)
                                        calibrationErrors[groupIndex]?.[
                                          sensorIndex
                                        ]?.heavy_adc === "") // Check if the input is empty (but not undefined)
                                    }
                                    helperText={
                                      calibrationErrors[groupIndex]?.[
                                        sensorIndex
                                      ]?.heavy_adc
                                    }
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <TextField
                                    type="number"
                                    label="Heavy Weight"
                                    value={
                                      editedCalibrations[groupIndex]
                                        ?.sensor_calibrations[sensorIndex]
                                        ?.heavy_weight
                                    }
                                    onChange={(e) =>
                                      handleEditCalibrationChange(
                                        e,
                                        groupIndex,
                                        sensorIndex,
                                        "heavy_weight"
                                      )
                                    }
                                    fullWidth
                                    margin="normal"
                                    error={
                                      !!calibrationErrors[groupIndex]?.[
                                        sensorIndex
                                      ]?.heavy_weight || // Check if there's an error message
                                      (editedCalibrations[groupIndex]
                                        ?.sensor_calibrations[sensorIndex]
                                        ?.heavy_weight === undefined && // Check if the input is undefined (not yet populated)
                                        calibrationErrors[groupIndex]?.[
                                          sensorIndex
                                        ]?.heavy_weight === "") // Check if the input is empty (but not undefined)
                                    }
                                    helperText={
                                      calibrationErrors[groupIndex]?.[
                                        sensorIndex
                                      ]?.heavy_weight
                                    }
                                  />
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                )}
                {/*----- Step 2 end----- */}

                {/* ----Step 3---- */}
                {editactiveStep === 2 && (
                  <div>
                    <Typography variant="h5">Review</Typography>
                    <div>
                      <div>
                        <ReviewTable newPreconfigData={newPreconfigData} />
                      </div>
                    </div>
                  </div>
                )}
                {/* ----Step 3 end ------ */}

                {/* ----Step 4---- */}
                {editactiveStep === 3 && (
                  /* Step 3: Result */
                  <div style={{ padding: "40px" }}>
                    {isUpdating && <CircularProgress />}
                    {isEditPreConfigPosted && !isUpdating && (
                      <Typography variant="h6" style={{ color: "green" }}>
                        Preconfigurations Added Successfully
                      </Typography>
                    )}
                    {!isEditPreConfigPosted && !isUpdating && (
                      <Typography variant="h6" style={{ color: "red" }}>
                        Preconfiguration Update Failed
                      </Typography>
                    )}
                  </div>
                )}
                {/* ----Step 4 end ------ */}
              </DialogContent>
              <DialogActions>
                <Button
                  disabled={editactiveStep === 0}
                  onClick={handleEditPreviousStep}
                  variant="contained"
                  color={theme.backbuttoncolor}
                >
                  Back
                </Button>
                {editactiveStep === stepsEdit.length - 2 ? (
                  <Button variant="contained" onClick={handleSaveEdit} color={theme.nextbuttoncolor}>
                    Save
                  </Button>
                ) : editactiveStep === stepsEdit.length - 3 ? (
                  <Button variant="contained" onClick={handleReview} color={theme.nextbuttoncolor}>
                    Review
                  </Button>
                ) : editactiveStep === stepsEdit.length - 1 ? (
                  <Button variant="contained" onClick={handleCloseEditDialog} color={theme.nextbuttoncolor}>
                    Done
                  </Button>
                ) : (
                  <Button variant="contained" onClick={handleEditNextStep} color={theme.nextbuttoncolor}>
                    Next
                  </Button>
                )}
              </DialogActions>
            </Dialog>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}
