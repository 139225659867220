import { useEffect, useState } from "react";
import { MyMap } from "../../../utils/generic-components/map/map";
import {
  Alert,
  AlertTitle,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Skeleton,
} from "@mui/material";
import {
  AxleWeightExternal,
  FormattedWeight,
  FormattedWeightswithData,
  WeightsCallbackResponse,
} from "../../../utils/interfaces/vehicles/vehicles-interfaces";
import { getBounds, getCenterOfBounds } from "geolib";
import { rfcToDateAndTime } from "../../../utils/custom-functions/rfc3339-conversions";
import DetailsTable from "../../../utils/generic-components/Details Table/details-table";
import { LngLatBoundsLike } from "maplibre-gl";
import {
  DetailTableData,
  DetailTableDataPoint,
} from "../../../utils/interfaces/details-table/details-table-interfaces";
import {
  DefaultCVWeightsReturnType,
  WeightsCVCallbackResponse,
} from "../../../utils/interfaces/clients/client-vehicle-map-interface";
import { MapResponse } from "../../../utils/interfaces/map/generic-interface";
import { VEHICLE_WEIGHT_POINT } from "../../../utils/interfaces/map/vehicle-map-interface";

export default function WeightsLocationsVehicleTab(props: {
  fetchWeights: Function;
}) {
  const [clientId, setClientId] = useState<string>("");
  const [vehicleId, setVehicleId] = useState<string>("");
  const [formattedWeights, setFormattedWeights] =
    useState<FormattedWeightswithData | null>();
  const [unformattedData, setUnformattedData] = useState<
    DefaultCVWeightsReturnType | undefined
  >();
  const [longlat, setLongLat] = useState<[number, number]>([-123, 44]); //default, air weighs coordinates haha
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [modalData, setModalData] = useState<
    DetailTableData | null | undefined
  >(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [fitBounds, setFitBounds] = useState<LngLatBoundsLike | undefined>();
  const [currentSnapshot, setCurrentSnapshot] =
    useState<WeightsCallbackResponse>();

  //all weights for vehicle, pass in time ranges.. also calculates center of map to pass to inital map render
  useEffect(() => {
    setLoading(true);
    props
      .fetchWeights()
      .then(
        (
          response: [FormattedWeightswithData, DefaultCVWeightsReturnType]
        ) => {
          if (!response) {
            setFormattedWeights(null);
            setLoading(false);
          } else {
            findCenter(response[0]);
            setFormattedWeights(response[0]);
            setUnformattedData(response[1]);
            setLoading(false);
          }
        }
      )
      .catch((err: string[]) => {
        console.log(err);
      });
  }, [startDate, endDate]);

  //zoom after formatted weights have been set
  useEffect(() => {
    if (formattedWeights && !loading) {
      const longlatInputs = formattedWeights.data.map(
        (weightObject: FormattedWeight) => ({
          latitude: weightObject.latitude,
          longitude: weightObject.longitude,
        })
      );
      const bounds = getBounds(longlatInputs);
      setFitBounds([
        [bounds.minLng, bounds.minLat],
        [bounds.maxLng, bounds.maxLat],
      ]);
    }
  }, [formattedWeights]);

  //centers camera before "zoom animation"
  const findCenter = (response: { data: FormattedWeight[] }) => {
    if (response.data.length === 0)
      return;

    // set center to newest weight
    const center = getCenterOfBounds([
      {
        latitude: response.data[0].latitude,
        longitude: response.data[0].longitude,
      },
    ]);
    setLongLat([center.longitude, center.latitude]);
  };

  //callback for searching a location
  const mapSearchCallback = (response: MapResponse) => {};

  //more details popup click (mapclick)
  const popupClickCallback = (datapoint: WeightsCallbackResponse) => {
    setModalData(formatResponseForDetailsTable(datapoint));
    setModalOpen(true);
  };

  //adjusting dates on toolbar of table
  const timeCallback = (start_time: string, end_time: string) => {
    setStartDate(start_time);
    setEndDate(end_time);
  };

  // Function to convert kilograms to pounds and append the unit
  function kgToPounds(kilograms: number) {
    const pounds = kilograms * 2.20462; //1 kilogram is approximately equal to 2.20462 pounds.
    const roundedPounds = Math.round(pounds / 10) * 10;
    return `${roundedPounds} lbs`;
  }

  //details modal formatting
  const formatResponseForDetailsTable = (
    response: WeightsCVCallbackResponse
  ) => {
    if (unformattedData) {
      const weightDataForDetailTableData = unformattedData.data[
        // array transformation on data stored in unformattedData.data.
        response.number - 1 //used to calculate an index in the unformattedData.data array.
      ].latest_weights[0].axle_weights.map((weightdata: AxleWeightExternal) => {
        // Check if the name is not "net" or "gross" before including it in the table
        if (
          weightdata.name.toLowerCase() !== "net" &&
          weightdata.name.toLowerCase() !== "gross"
        ) {
          return {
            label: `${weightdata.name} Weight`,
            content:
              weightdata.weight.received === true
                ? kgToPounds(weightdata.weight.value) // Convert grams to pounds
                : null,
            editable: false,
            error: "",
          };
        }
        return null; // Return null for "net" and "gross" Weight, which will be filtered out
      });

      const filteredWeightData = weightDataForDetailTableData.filter(Boolean);
      //filter(Boolean) method is used to remove any null values from the weightDataForDetailTableData array.

      const detailTableData: DetailTableData = {
        data: [
          {
            label: "Date & Time",
            content: rfcToDateAndTime(
              unformattedData.data[response.number - 1]
                .latest_weights[0].date_time
            ),
            editable: false,
            error: "",
          },
          {
            label: "Latitude & Longitude",
            content: `${unformattedData.data[response.number - 1]
                .latest_weights[0].latitude.received === true
                ? unformattedData.data[response.number - 1]
                  .latest_weights[0].latitude.value
                : null
              }, ${unformattedData.data[response.number - 1]
                .latest_weights[0].longitude.received === true
                ? unformattedData.data[response.number - 1]
                  .latest_weights[0].longitude.value
                : null
              }`,
            editable: false,
            error: "",
          },
          {
            label: "Updated At",
            content: rfcToDateAndTime(
              unformattedData.data[response.number - 1].updated_at
            ),
            editable: false,
            error: "",
          },
          {
            label: "Vehicle ID",
            content:
              unformattedData.data[response.number - 1].vehicle_id,
            editable: false,
            error: "",
          },
          {
            label: "Gross Weight",
            content:
              unformattedData.data[response.number - 1]
                .latest_weights[0].weight.value.received === true
                ? kgToPounds(
                  unformattedData.data[response.number - 1]
                    .latest_weights[0].weight.value.value
                ) // Convert grams to pounds
                : null,
            editable: false,
            error: "",
          },
        ],
      };

      const selectedVehicle =
        unformattedData?.data[response.number - 1];
      setClientId(selectedVehicle?.client_id || "");
      setVehicleId(selectedVehicle?.vehicle_id || "");

      detailTableData.data = [
        ...detailTableData.data,
        ...filteredWeightData,
      ] as DetailTableDataPoint[];
      return detailTableData;
    }
  };

  //reset modal data on close
  const detailsTableCloseCallback = () => {
    setModalOpen(false);
    setModalData(null);
  };

  return (
    <>
      <Card>
        <CardHeader title={"Weights & Locations"} />
        <CardContent>
          <Divider />
          <Box marginTop={2}>
            {loading ? (
              <Skeleton variant="rectangular" height={350} />
            ) : (
              <MyMap
                title={"Weights & Locations"}
                longlat={longlat}
                recurse={true}
                dataPoints={
                  // Map the formatted weights to the dataPoints. Use VehicleWeightPoint
                  formattedWeights
                    ? formattedWeights.data.map((data) => {
                        return {
                          ...data,
                          objectType: VEHICLE_WEIGHT_POINT,
                        };
                      })
                    : []
                }                searchBar={false}
                mapSearchCallback={mapSearchCallback}
                count={0}
                zoom={6}
                fitBounds={fitBounds}
                popupClickCallback={popupClickCallback}
                currentSnapshot={currentSnapshot}
              />
            )}
            {!loading && formattedWeights && unformattedData ? (
              unformattedData.next_page ? (
                <Box margin={2}>
                  <Alert
                    severity="error"
                    action={<Button variant="outlined">Next Page</Button>}
                  >
                    <AlertTitle>
                      Not All Data Retrived, Click To Retrieve Next Page.
                    </AlertTitle>
                  </Alert>
                </Box>
              ) : null
            ) : null}
          </Box>
        </CardContent>
      </Card>
      {modalData ? (
        <Backdrop open={modalOpen} sx={{ zIndex: 500 }}>
          <Card>
            <Box
              marginLeft={{ xs: 2, md: 5 }} // Adjust margins for different screen sizes
              marginTop={{ xs: 2, md: 5 }} // Adjust margins for different screen sizes
              marginBottom={{ xs: 2, md: 5 }} // Adjust margins for different screen sizes
              marginRight={{ xs: 2, md: 2 }} // Adjust margins for different screen sizes
            >
              <div
                style={{
                  maxHeight: "80vh",
                  maxWidth: "30vw",
                  overflowY: "auto",
                }}
              >
                <DetailsTable
                  data={modalData}
                  putCallback={detailsTableCloseCallback}
                  editing={false}
                  close={true}
                />
              </div>

              <Box marginBottom={1}>
                <Button
                  variant="contained"
                  color="primary"
                  href={`/vehicles/${vehicleId}`}
                >
                  View Vehicle Details
                </Button>
              </Box>
            </Box>
          </Card>
        </Backdrop>
      ) : null}
    </>
  );
}
